import { Component, HostListener, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { TokenService } from '../../shared/token.service';
import { AuthStateService } from '../../shared/auth-state.service';
import { Router, ActivatedRoute, NavigationEnd, NavigationStart, NavigationError } from '@angular/router';
import { RegisterService } from 'src/app/services/register.service';
import { CookieService } from 'ngx-cookie-service';
import { CommonService } from '../../services/common.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import * as $ from 'jquery';

@Component({
  selector: 'app-login-header',
  templateUrl: './login-header.component.html',
  styleUrls: ['./login-header.component.css']
})
export class LoginHeaderComponent implements OnInit {
  @Output() onDatePicked = new EventEmitter<any>();

  navbarFixed: boolean = true;
  isSignedIn!: boolean;
  isRegisterDone: boolean = false;
  havePicture: boolean = false;
  profileSRC: any;
  fullName: any;
  gender: any;
  user_id: any;
  urlchek: any;

  @Output() getUserPackage: EventEmitter<any> = new EventEmitter();
  editId: string;
  matchesActive: boolean;
  currentRoute: string;
  currentPage: any;
  register_id: number = 0;
  bioData = false;

  currentUrl: string = '';

  constructor(
    private authService: AuthService,
    private auth: AuthStateService,
    public router: Router,
    public token: TokenService,
    private cookieService: CookieService,
    private registerservice: RegisterService,
    private commonService: CommonService,
    private route: ActivatedRoute,
    private ref: ChangeDetectorRef,
    private toastrService: ToastrService,

  ) {
    this.user_id = this.cookieService.get('user_id');
    this.register_id = parseInt(localStorage.getItem('register_id'));
    // console.log('register_id::',this.register_id)
    this.commonService.subscribeRegisterDoneStep.subscribe(step => {
      this.register_id = step;
    });

    this.route.queryParams
      .subscribe(params => {
        if (params && params.status == 0 && params.msg) {
          this.toastrService.error(params.msg);
        } else if (params && params.status == 1 && params.msg) {
          this.toastrService.success(params.msg);
        }
      });

    let routings = {
      'communications': ['/comunication-inbox', '/accepted', '/inbox-sent', '/inbox-followUp', '/inbox-cancelled', '/inbox-blocked'],
      'matches': ['/matches', '/matches-view', '/matches-shortlist', '/matches-favourite'],
      'dashboard': ['/myInfo', '/myProfile', '/myPhotoGallery'],
      'chat': ['/chat'],
      'blog': ['/blog'],
      // 'myProfile': ['/myProfile']
    }

    this.currentPage = '';
    for (let x in routings) {
      // before sonar
      // for (let i = 0; i < routings[x].length; i++) {
      // if (window.location.href.indexOf(routings[x][i]) > -1) {
      // after sonar
      for (const element of routings[x]) {
        if (window.location.href.indexOf(element) > -1) {
          setTimeout(() => {
            this.currentPage = x;
          }, 2000);
          this.currentPage = x;
          break;
        }
      }
      if (this.currentPage != '') {
        break;
      }
    }

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        this.currentPage = '';
        for (let x in routings) {
          if (routings[x].indexOf(event.url) > -1) {
            setTimeout(() => {
              this.currentPage = x;
            }, 2000);
            this.currentPage = x;
            return false;
          }
        }
      }

      if (event instanceof NavigationEnd) {
        // Hide loading indicator
        this.currentRoute = event.url;
        let button = document.getElementById('closeNav'); // Assuming 'closeNav' is the ID of your button
        if (button) {
          let ariaExpandedValue = button.getAttribute('aria-expanded');
          if (ariaExpandedValue == "true") {
            $('#closeNav').click();
          }
          return ariaExpandedValue === 'true';
        } else {
          // console.error("Button element not found");
          return false;
        }
        // before sonar uncomment
        //console.log('NavigationEnd', event, this.currentRoute);
      }

      if (event instanceof NavigationError) {
        // Hide loading indicator

        // Present error to user
        // console.log('NavigationError',event.error);
      }
    });
  }

  ngOnInit(): void {
    this.commonService.currentApprovalStageMessage1.subscribe(status => {
      this.urlchek = environment.baseURL;
      this.profileSRC = status;
      let pageId = this.route.snapshot.params;
      // console.log(pageId)
    });

    window.scroll(11, 11);
    let userAgent = navigator.userAgent;
    // console.log("User Agent:", userAgent);
    setTimeout(() => {
      window.scrollTo(15, 15)

    }, 2000);
    let dd = this.cookieService.get('user_id');
    let data = {
      user_id: dd,
      comp: 'login-header'
    }
    this.registerservice.getUserDetails(data)
      .subscribe((res: any) => {
        let register_id = res.userDetails.register_done;

        this.commonService.setRegisterDoneStep(register_id);
        if (register_id != null && register_id < 7) {
          this.checkStepAndRedirect(register_id);
        }
        localStorage.setItem('register_id', register_id);
        if (res?.userDetails.register_done) {

          this.commonService.setUserDetails(res.userDetails)
          localStorage.setItem('package_id', res.package_id);
          // console.log(res, "sagar", res.userDetails.register_done)

          if (res.userDetails.register_done == 7) {
            this.isRegisterDone = true;
          } else {
            this.isRegisterDone = false;

          }
        }
        if (res?.userDetails.profile_photo) {
          if (res.userDetails.profile_photo) {
            this.havePicture = true;
            this.profileSRC = res.userDetails.profile_photo;
          } else {
            this.havePicture = false;
          }
        }
        if (res?.userDetails.gender) {
          if (res.userDetails.gender == "Male") {
            this.gender = "Male";
          } else {
            this.gender = "Female";
          }
        }
        localStorage.setItem('package_id', res.userDetails.package_id);
      },
        (error) => {

        },)

    this.registerservice.getUser(data)
      .subscribe((res: any) => {
        if (res?.userDetails.firstName && res.userDetails.lastName) {
          this.fullName = res.userDetails.firstName + ' ' + res.userDetails.lastName;
        }
      })

    let routings = {
      'communications': ['/comunication-inbox', '/accepted', '/inbox-sent', '/inbox-followUp', '/inbox-cancelled', '/inbox-blocked'],
      'matches': ['/matches', '/matches-view', '/matches-shortlist', '/matches-favourite'],
      'dashboard': ['/myInfo', '/myProfile', '/myPhotoGallery'],
      'chat': ['/chat'],
      'myProfile': ['/myProfile'],
      'about': ['/about'],
      'blog': ['/blog'],
    }

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {

        this.currentPage = '';
        for (let x in routings) {
          if (routings[x].indexOf(event.url) > -1) {
            setTimeout(() => {
              this.currentPage = x;
            }, 2000);
            this.currentPage = x;
            return false;
          }
        }
      }
      if (event instanceof NavigationEnd) {
        // Hide loading indicator
        // console.log('NavigationEnd',event);
        this.currentRoute = event.url;
        let button = document.getElementById('closeNav'); // Assuming 'closeNav' is the ID of your button
        if (button) {
          let ariaExpandedValue = button.getAttribute('aria-expanded');
          if (ariaExpandedValue == "true") {
            $('#closeNav').click();
          }
          return ariaExpandedValue === 'true';
        } else {
          // console.error("Button element not found");
          return false;
        }
      }

      if (event instanceof NavigationError) {
        // Hide loading indicator

        // Present error to user
        // console.log('NavigationError',event.error);
      }
    });
  }

  getBtn() {
    let button = document.getElementById('closeNav'); // Assuming 'closeNav' is the ID of your button
    if (button) {
      let ariaExpandedValue = button.getAttribute('aria-expanded');
      return ariaExpandedValue === 'true';
    } else {
      // console.error("Button element not found");
      return false;
    }
  }

  backToStep() {
    if (this.isRegisterDone) {
      this.router.navigate(['myProfile']);
    } else {
      $('#openModal111').click();
    }
  }

  checkStepAndRedirect(register_id) {
    if (register_id == 0) {
      this.router.navigate(['register']);
    }
    else if (register_id == 1) {
      this.router.navigate(['registerNxt']);
    }
    else if (register_id == 2) {
      this.router.navigate(['step']);
    }
    else if (register_id == 3) {
      this.router.navigate(['stepTwo']);
    }
    else if (register_id == 4) {
      this.router.navigate(['stepDone']);
    }
    else if (register_id == 5) {
      this.router.navigate(['ProfilePage1']);
    }
    else if (register_id == 6) {
      this.router.navigate(['PartnerPreferences']);
    }
  }

  signOut() {
    let data = {
      id: this.user_id
    }
    this.auth.logout(data)
      .subscribe((res: any) => {
        // console.log("Succes Logout");
        this.toastrService.success(res.message);
        this.auth.setAuthState(false);
        this.token.removeToken();
        // this.cookieService.delete('auth_token');
        localStorage.removeItem('register_id');
        localStorage.clear();
        this.cookieService.deleteAll('/');
        this.cookieService.deleteAll('/2757_JubWeMet');
        this.delete_cookie('auth_token');


        // code for child route
        // Dynamically determine the path based on current route
        // let currentPath = window.location.pathname;
        // let basePath = currentPath.split('/').slice(0, -1).join('/'); // Remove last part of path (current route)
        // let cookiePath = basePath || '/'; // Default to root path if basePath is empt
        // console.log(currentPath,'currentPath');
        // console.log(basePath,'basePath');
        // console.log(cookiePath,'cookiePath');
        // document.cookie = `auth_token=; Path=${cookiePath}; Expires=Thu, 01 Jan 2024 00:00:01 GMT;`;
        // 

        this.router.navigate(['home']);
      },
        (error) => {
          this.delete_cookie('auth_token');
          if (error.message == 'Token could not be parsed from the request.') {
            this.router.navigate(['home']);
          }
        });
    // console.log("Succes Logout outside");
  }

  delete_cookie(name) {
    this.cookieService.deleteAll('/');
    this.cookieService.deleteAll('/2757_JubWeMet/detail');
    this.cookieService.deleteAll('/2757_JubWeMet/compare');
    this.cookieService.deleteAll('/2757_JubWeMet/Profile-Preview');
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    document.cookie = name + '=; Path=; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

  @HostListener('window:scroll', ['$event']) onscroll() {
    if (window.scrollY > 100) {
      this.navbarFixed = true;
    }
    else {
      // unused code
      // this.navbarFixed = false;
      // console.log('scroll Fixed')

    }
  }


  backOnRealstep() {
    if (this.register_id == 0) {
      this.router.navigate(['register']);
    } else if (this.register_id == 1) {
      this.router.navigate(['registerNxt']);
    } else if (this.register_id == 2) {
      this.router.navigate(['step']);
    } else if (this.register_id == 3) {
      this.router.navigate(['stepTwo']);
    } else if (this.register_id == 4) {
      this.router.navigate(['stepDone']);
    } else if (this.register_id == 5) {
      this.router.navigate(['ProfilePage1']);
    } else if (this.register_id == 6) {
      this.router.navigate(['PartnerPreferences']);
    } else {
      this.router.navigate(['myInfo']);
    }

  }
}
