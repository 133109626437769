<div class="container border border-1 shadow-sm my-4 p-0 position-relative main-pt">

  <nav class="position-sticky z-index-60 top-70">
    <div class="nav nav-tabs d-flex align-items-center justify-content-between p-2 bg-warning position-sticky"
      id="nav-tab" role="tablist">

      <a button
        class="my-2 py-2 px-3 btn-outline-danger active rounded-0 border-0 flex-auto rounded-pill mx-1 text-center"
        routerLink="/matches" type="button" aria-selected="true">My Matches ({{matchedProfilesCount}})</a>

      <a button
        class="my-2 py-2 px-3 btn-outline-danger rounded-0 border-0 flex-auto rounded-pill mx-1 text-center dashboard-card123"
        routerLink="/matches-view" type="button" aria-selected="true"> Viewed By Others ({{viewedByProfilesCount}})</a>

      <a class="my-2 py-2 px-3 btn-outline-danger rounded-0 border-0 flex-auto rounded-pill mx-1 text-center dashboard-card123"
        routerLink="/matches-shortlist" type="button" aria-selected="true">
        My Shortlist ({{shortlistedProfilesCount}})</a>

      <a class="my-2 py-2 px-3 btn-outline-danger rounded-0 border-0 flex-auto rounded-pill mx-1 text-center dashboard-card123"
        routerLink="/matches-favourite" type="button" aria-selected="true">
        My Favourite ({{favouriteProfilesCount}})</a>

    </div>
  </nav>

  <div class="tab-content bg-white min_content_height_246 mt-68" id="nav-tabContent">

    <!-- ----------start-main tabs----------------- -->
    <form #matchesNgForm="ngForm" id="matches" [formGroup]="matchesForm">
      <div class="tab-pane fade show active p-3" id="nav-dashboard" role="tabpanel" aria-labelledby="nav-dashboard-tab">

        <mat-drawer-container class="example-container w-100 min-full-screen" hasBackdrop="false" autosize>
          <mat-drawer #drawer class="example-sidenav " mode="side">
            <div class="row mx-0">
              <div class="col-12">
                <h5 class="pb-2 fw-bold border border-2 lh-base border-top-0 border-danger border-start-0 border-end-0">
                  Advanced Search </h5>
              </div>

              <h6 class="fw-600 mb-0">Age</h6>
              <div class="col-12" id="age">
                <ngx-slider [(ngModel)]="rangeAge" (userChangeEnd)="sliderValueChangeAgeEndPoints()"
                  (click)="handleChange()" step="1" [(value)]="value" [(highValue)]="highValue" [options]="options"
                  [ngModelOptions]="{standalone: true}">
                </ngx-slider>
              </div>
              <div class="col-12 mb-4">
                <div class="d-flex p-sm align-items-center justify-content-between">
                  <div class="flex-auto">
                    <select class="form-select p-sm" aria-label="Default select example" [formControlName]="'age_from'"
                      (change)="handleRange()">
                      <option value selected disabled hidden>Select</option>
                      <option value="{{age.id}}" *ngFor="let age of this.age_from">{{ age.id }}
                      </option>
                    </select>
                  </div>
                  <div class="px-2 text-dark"> To </div>
                  <div class="flex-auto">
                    <select class="form-select p-sm" aria-label="Default select example" [formControlName]="'age_to'"
                      (change)="handleRange()">
                      <option value selected disabled hidden>Select</option>
                      <option value="{{ age.id }}" *ngFor="let age of this.age_to">{{ age.id }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <h6 class="fw-600 mb-0">Height</h6>
              <div class="col-12">
                <ngx-slider [(ngModel)]="rangeHeight" (userChangeEnd)="sliderValueChangeHeightEndPoints()"
                  (click)="handleChange()" [(value)]="minValue" [(highValue)]="maxValue" [options]="options2"
                  [ngModelOptions]="{standalone: true}">
                </ngx-slider>
              </div>
              <div class="col-12 mb-4">
                <div class="d-flex p-sm align-items-center justify-content-between">
                  <div class="flex-auto">
                    <select class="form-select p-sm" aria-label="Default select example"
                      [formControlName]="'height_from'" (change)="handleRange()">
                      <option value selected disabled hidden>Select</option>
                      <option value="{{ height_from.i }}" *ngFor="let height_from of this.height_from">
                        {{ height_from.i }} cm ({{ height_from.i | heightConverter : "Ft" }})
                      </option>
                    </select>
                  </div>
                  <div class="px-2 text-dark"> To </div>
                  <div class="flex-auto">
                    <select class="form-select p-sm" aria-label="Default select example" [formControlName]="'height_to'"
                      (change)="handleRange()">
                      <option value selected disabled hidden>Select</option>
                      <option value="{{ height_to.i }}" *ngFor="let height_to of this.height_to">
                        {{ height_to.i }} cm ({{ height_to.i | heightConverter : "Ft" }})
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <h6 class="fw-600 mb-0">Weight</h6>
              <div class="col-12">
                <ngx-slider [(ngModel)]="rangeWeight" (userChangeEnd)="sliderValueChangeWeightEndPoints()"
                  (click)="handleChange()" [(value)]="minValue3" [(highValue)]="maxValue3" [options]="options3"
                  [ngModelOptions]="{standalone: true}">
                </ngx-slider>
              </div>
              <div class="col-12 mb-4">
                <div class="d-flex p-sm align-items-center justify-content-between">
                  <div class="flex-auto">
                    <select class="form-select p-sm" aria-label="Default select example"
                      [formControlName]="'weight_from'" (change)="handleRange()">
                      <option value selected disabled hidden>Select</option>
                      <option value="{{ weight_from.i }}" *ngFor="let weight_from of this.weight_from">
                        {{weight_from.i }} kg ({{ weight_from.i | weightConverter : "Pounds" }}
                        lb)</option>
                    </select>
                  </div>
                  <div class="px-2 text-dark"> To </div>
                  <div class="flex-auto">
                    <select class="form-select p-sm" aria-label="Default select example" (change)="handleRange()"
                      [formControlName]="'weight_to'" required>
                      <option value selected disabled hidden>Select</option>
                      <option value="{{ weight_to.i }}" *ngFor="let weight_to of this.weight_to">
                        {{weight_to.i }} kg ({{ weight_to.i | weightConverter : "Pounds" }} lb)
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="accordion overflow-hidden" id="accordionPanelsStayOpenExample">

                <div class="accordion-item rounded-0 shadow-sm border-0">
                  <div class="accordion-header py-0 rounded-0 border border-1 bg-light d-flex justify-content-between
                    align-items-center" id="sidebarFilterOne">
                    <div class="ps-2 fw-bold mb-0 h6 text-muted">
                      Living With Family
                    </div>
                    <div class="border-0 px-2 py-1 accordion-btn" type="button" data-bs-toggle="collapse"
                      data-bs-target="#sidebarFilterOneMain" aria-expanded="true" aria-controls="sidebarFilterOneMain">
                      <i class="fas fa-sort-down fs-5 me-2"></i>
                    </div>
                  </div>
                  <div id="sidebarFilterOneMain" class="accordion-collapse collapse show"
                    aria-labelledby="panelsStayOpen-headingOne">
                    <div class="accordion-body text-muted p-sm">
                      <div class="form-check">
                        <input class="form-check-input" type="radio" id="LivingSidebarFilter1"
                          [formControlName]="'living_with_family'" value="Yes" (change)="LivingWithFamily('Yes')">
                        <label class="form-check-label text-muted" for="LivingSidebarFilter1">
                          Yes
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" id="LivingSidebarFilter2"
                          [formControlName]="'living_with_family'" value="No" (change)="LivingWithFamily('No')">
                        <label class="form-check-label text-muted" for="LivingSidebarFilter2">
                          No </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" id="LivingSidebarFilter3"
                          [formControlName]="'living_with_family'" value (change)="LivingWithFamily('')">
                        <label class="form-check-label text-muted" for="LivingSidebarFilter3">
                          Doesn't
                          Matter </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item rounded-0 shadow-sm border-0" *ngIf="showFamilyType">
                  <div class="accordion-header py-0 rounded-0 border border-1 bg-light d-flex justify-content-between
                    align-items-center" id="familtTypeSidebarMain">
                    <div class="ps-2 fw-bold mb-0 h6 text-muted">
                      Family Type
                    </div>
                    <div class="border-0 px-2 py-1 accordion-btn" type="button" data-bs-toggle="collapse"
                      data-bs-target="#familyTypeSidebarTwo" aria-expanded="true" aria-controls="familyTypeSidebarTwo">
                      <i class="fas fa-sort-down fs-5 me-2"></i>
                    </div>
                  </div>
                  <div id="familyTypeSidebarTwo" class="accordion-collapse collapse show"
                    aria-labelledby="familtTypeSidebarMain">
                    <div class="accordion-body text-muted p-sm" id="FamilyType">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" [formControlName]="'family_type'"
                          id="familyTypeSidebar1" (click)="filterFamilyType('All')">
                        <label class="form-check-label fw-bold text-muted" for="familyTypeSidebar1">
                          All
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="familyTypeSidebar2"
                          (change)="filterFamilyType('Extended Family')">
                        <label class="form-check-label text-muted" for="familyTypeSidebar2">
                          Extended Family
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="familyTypeSidebar3"
                          (change)="filterFamilyType('Immediate Family')">
                        <label class="form-check-label text-muted" for="familyTypeSidebar3">
                          Immediate Family
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="familyTypeSidebar4"
                          (change)="filterFamilyType('Joint Family')">
                        <label class="form-check-label text-muted" for="familyTypeSidebar4">
                          Joint Family
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="familyTypeSidebar5"
                          (change)="filterFamilyType('Nuclear Family')">
                        <label class="form-check-label text-muted" for="familyTypeSidebar5">
                          Nuclear Family
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item rounded-0 shadow-sm border-0">
                  <div class="accordion-header py-0 rounded-0 border border-1 bg-light d-flex justify-content-between
                    align-items-center" id="panelsStayOpen-heading5">
                    <div class="ps-2 fw-bold mb-0 h6 text-muted">
                      Marital Status
                    </div>
                    <div class="border-0 px-2 py-1 accordion-btn" type="button" data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapse5" aria-expanded="true"
                      aria-controls="panelsStayOpen-collapse5">
                      <i class="fas fa-sort-down fs-5 me-2"></i>
                    </div>
                  </div>
                  <div id="panelsStayOpen-collapse5" class="accordion-collapse collapse show"
                    aria-labelledby="panelsStayOpen-heading5">
                    <div class="accordion-body p-sm text-muted">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" [formControlName]="'marital_status'"
                          id="MaritalStatus1" (click)="filterMaritalStatus('All')">
                        <label class="form-check-label fw-bold" for="MaritalStatus1">
                          All
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" (change)="filterMaritalStatus('Annulled')"
                          id="MaritalStatus2">
                        <label class="form-check-label" for="MaritalStatus2">
                          Annulled
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox"
                          (change)="filterMaritalStatus('Awaiting Divorce')" id="MaritalStatus3">
                        <label class="form-check-label text-truncate" for="MaritalStatus3">
                          Awaiting Divorce
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" (change)="filterMaritalStatus('Divorced')"
                          id="MaritalStatus4">
                        <label class="form-check-label text-truncate" for="MaritalStatus4">
                          Divorced
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" (change)="filterMaritalStatus('Single')"
                          id="MaritalStatus5">
                        <label class="form-check-label text-truncate" for="MaritalStatus5">
                          Single
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" (change)="filterMaritalStatus('Widowed')"
                          id="MaritalStatus6">
                        <label class="form-check-label text-truncate" for="MaritalStatus6">
                          Widowed
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item rounded-0 shadow-sm border-0">
                  <div class="accordion-header py-0 rounded-0 border border-1 bg-light d-flex justify-content-between
                    align-items-center" id="HaveChildrenMain">
                    <div class="ps-2 fw-bold mb-0 h6 text-muted">
                      Have Children
                    </div>
                    <div class="border-0 px-2 py-1 accordion-btn" type="button" data-bs-toggle="collapse"
                      data-bs-target="#HaveChildrenTwo" aria-expanded="true" aria-controls="HaveChildrenTwo">
                      <i class="fas fa-sort-down fs-5 me-2"></i>
                    </div>
                  </div>
                  <div id="HaveChildrenTwo" class="accordion-collapse collapse show" aria-labelledby="HaveChildrenMain">
                    <div class="accordion-body text-muted p-sm">
                      <div class="form-check">
                        <input class="form-check-input" type="radio" (change)="haveChildren('Yes')"
                          id="HaveChildrenSidebar1" [formControlName]="'have_children'" value="Yes">
                        <label class="form-check-label text-muted" for="HaveChildrenSidebar1">
                          Yes
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" (change)="haveChildren('No')"
                          [formControlName]="'have_children'" value="No" id="HaveChildrenSidebar2">
                        <label class="form-check-label text-muted" for="HaveChildrenSidebar2">
                          No
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" (change)="haveChildren('')"
                          id="HaveChildrenSidebar3" [formControlName]="'have_children'" value>
                        <label class="form-check-label text-muted" for="HaveChildrenSidebar3">
                          Doesn't Matter
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item rounded-0 shadow-sm border-0">
                  <div class="accordion-header py-0 rounded-0 border border-1 bg-light d-flex justify-content-between
                    align-items-center" id="panelsStayOpen-heading6">
                    <div class="ps-2 fw-bold mb-0 h6 text-muted">
                      Religious Views
                    </div>
                    <div class="border-0 px-2 py-1 accordion-btn" type="button" data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapse6" aria-expanded="true"
                      aria-controls="panelsStayOpen-collapse6">
                      <i class="fas fa-sort-down fs-5 me-2"></i>
                    </div>
                  </div>
                  <div id="panelsStayOpen-collapse6" class="accordion-collapse collapse show"
                    aria-labelledby="panelsStayOpen-heading6">
                    <div class="accordion-body p-sm text-muted">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="Religion" [formControlName]="'religion'"
                          (click)="filterReligion('All')">
                        <label class="form-check-label fw-bold" for="Religion">
                          All
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" (change)="filterReligion('Buddhist')"
                          id="Religion1">
                        <label class="form-check-label" for="Religion1">
                          Buddhist
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" (change)="filterReligion('Christian')"
                          id="Religion2">
                        <label class="form-check-label" for="Religion2">
                          Christian
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" (change)="filterReligion('Hindu')"
                          id="Religion3">
                        <label class="form-check-label" for="Religion3">
                          Hindu
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" (change)="filterReligion('Parsi')"
                          id="Religion9">
                        <label class="form-check-label" for="Religion9">
                          Parsi
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" (change)="filterReligion('Jain')"
                          id="Religion4">
                        <label class="form-check-label" for="Religion4">
                          Jain
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" (change)="filterReligion('Muslim')"
                          id="Religion5">
                        <label class="form-check-label" for="Religion5">
                          Muslim
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" (change)="filterReligion('Sikh')"
                          id="Religion6">
                        <label class="form-check-label" for="Religion6">
                          Sikh
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" (change)="filterReligion('No Religion')"
                          id="Religion7">
                        <label class="form-check-label" for="Religion7">
                          No Religion
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" (change)="filterReligion('Other')"
                          id="Religion8">
                        <label class="form-check-label" for="Religion8">
                          Other
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item rounded-0 shadow-sm border-0">
                  <div class="accordion-header py-0 rounded-0 border border-1 bg-light d-flex justify-content-between
                    align-items-center" id="panelsStayOpen-heading8">
                    <div class="ps-2 fw-bold mb-0 h6 text-muted">
                      Preferred Language
                    </div>
                    <div class="border-0 px-2 py-1 accordion-btn" type="button" data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapse8" aria-expanded="true"
                      aria-controls="panelsStayOpen-collapse8">
                      <i class="fas fa-sort-down fs-5 me-2"></i>
                    </div>
                  </div>
                  <div id="panelsStayOpen-collapse8" class="accordion-collapse collapse show"
                    aria-labelledby="panelsStayOpen-heading8">
                    <div class="accordion-body p-sm text-muted">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="LanguagePreference"
                          [formControlName]="'languages'" (click)="filterLanguage('All')">
                        <label class="form-check-label fw-bold" for="LanguagePreference">
                          All
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" (change)="filterLanguage('Bengali')"
                          id="LanguagePreference1">
                        <label class="form-check-label" for="LanguagePreference1">
                          Bengali
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" (change)="filterLanguage('English')"
                          id="LanguagePreference2">
                        <label class="form-check-label" for="LanguagePreference2">
                          English
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" (change)="filterLanguage('Gujarati')"
                          id="LanguagePreference3">
                        <label class="form-check-label" for="LanguagePreference3">
                          Gujarati
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" (change)="filterLanguage('Hindi')"
                          id="LanguagePreference4">
                        <label class="form-check-label" for="LanguagePreference4">
                          Hindi
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" (change)="filterLanguage('Marathi')"
                          id="LanguagePreference5">
                        <label class="form-check-label" for="LanguagePreference5">
                          Marathi
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="LanguagePreference6"
                          (change)="filterLanguage('Punjabi')">
                        <label class="form-check-label" for="LanguagePreference6">
                          Punjabi
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" (change)="filterLanguage('Tamil')"
                          id="LanguagePreference7">
                        <label class="form-check-label" for="LanguagePreference7">
                          Tamil
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" (change)="filterLanguage('Telugu')"
                          id="LanguagePreference9">
                        <label class="form-check-label" for="LanguagePreference9">
                          Telugu
                        </label>
                      </div>

                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" (change)="filterLanguage('Other')"
                          id="LanguagePreference8">
                        <label class="form-check-label" for="LanguagePreference8">
                          Other
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item rounded-0 shadow-sm border-0">
                  <div class="accordion-header py-0 rounded-0 border border-1 bg-light d-flex justify-content-between
                    align-items-center" id="panelsStayOpen-heading9">
                    <div class="ps-2 fw-bold mb-0 h6 text-muted">
                      Country Living in
                    </div>
                    <div class="border-0 px-2 py-1 accordion-btn" type="button" data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapse9" aria-expanded="true"
                      aria-controls="panelsStayOpen-collapse9">
                      <i class="fas fa-sort-down fs-5 me-2"></i>
                    </div>
                  </div>
                  <div id="panelsStayOpen-collapse9" class="accordion-collapse collapse show"
                    aria-labelledby="panelsStayOpen-heading9">
                    <div class="accordion-body p-sm text-muted">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="All" id="AllCountry"
                          (change)="onSelectAllCountry()" [formControlName]="'allCountry'">
                        <label class="form-check-label fw-bold" for="AllCountry">
                          All
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="38" id="Canada"
                          (change)="onSelectCountry($event)" [formControlName]="'country'">
                        <label class="form-check-label" for="Canada">
                          Canada
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="231" id="America"
                          (change)="onSelectCountry($event)" [formControlName]="'country'">
                        <label class="form-check-label" for="America">
                          America
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="13" id="Australia"
                          (change)="onSelectCountry($event)" [formControlName]="'country'">
                        <label class="form-check-label" for="Australia">
                          Australia
                        </label>
                      </div>

                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="44" id="China"
                          (change)="onSelectCountry($event)" [formControlName]="'country'">
                        <label class="form-check-label" for="China">
                          China
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="101" id="India"
                          (change)="onSelectCountry($event)" [formControlName]="'country'">
                        <label class="form-check-label" for="India">
                          India
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="75" id="France"
                          (change)="onSelectCountry($event)" [formControlName]="'country'">
                        <label class="form-check-label" for="France">
                          France
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="82" id="Germany"
                          (change)="onSelectCountry($event)" [formControlName]="'country'">
                        <label class="form-check-label" for="Germany">
                          Germany
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" [formControlName]="'other_country'"
                          value="OtherCountry" id="otherCountries" (change)="onSelectOtherCountry()">
                        <label class="form-check-label" for="otherCountries">
                          Other
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item rounded-0 shadow-sm border-0" *ngIf="showStates">
                  <div class="accordion-header py-0 rounded-0 border border-1 bg-light d-flex justify-content-between
                    align-items-center" id="panelsStayOpen-heading10">
                    <div class="ps-2 fw-bold mb-0 h6 text-muted">
                      State/Province
                    </div>
                    <div class="border-0 px-2 py-1 accordion-btn" type="button" data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapse10" aria-expanded="true"
                      aria-controls="panelsStayOpen-collapse10">
                      <i class="fas fa-sort-down fs-5 me-2"></i>
                    </div>
                  </div>
                  <div id="panelsStayOpen-collapse10" class="accordion-collapse collapse show"
                    aria-labelledby="panelsStayOpen-heading10" *ngIf="states.length>0">
                    <div class="accordion-body p-sm text-muted">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value id="StateProvince">
                        <label class="form-check-label fw-bold" for="StateProvince">
                          All
                        </label>
                      </div>
                      <div class="form-check" *ngFor="let state of states">
                        <input class="form-check-input" type="checkbox" value="{{state.id}}" id="{{state.id}}"
                          (change)="onSelectState($event)">
                        <label class="form-check-label" for="{{state.state_name}}">
                          {{state.state_name}}
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="001" id="001">
                        <label class="form-check-label" for="StateProvince6">
                          Other
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item rounded-0 shadow-sm border-0">
                  <div class="accordion-header py-0 rounded-0 border border-1 bg-light d-flex justify-content-between
                    align-items-center" id="residencyFilterSidebarMain">
                    <div class="ps-2 fw-bold mb-0 h6 text-muted">
                      Residency Status
                    </div>
                    <div class="border-0 px-2 py-1 accordion-btn" type="button" data-bs-toggle="collapse"
                      data-bs-target="#residencyFilterSidebar" aria-expanded="true"
                      aria-controls="residencyFilterSidebar">
                      <i class="fas fa-sort-down fs-5 me-2"></i>
                    </div>
                  </div>
                  <div id="residencyFilterSidebar" class="accordion-collapse collapse show"
                    aria-labelledby="residencyFilterSidebarMain">
                    <div class="accordion-body text-muted p-sm">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="SidebarResidencyFilter1"
                          [formControlName]="'residency_status'" (click)="filterResidency('All')">
                        <label class="form-check-label fw-bold text-muted" for="SidebarResidencyFilter1"> All
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" (change)="filterResidency('Citizen')"
                          id="SidebarResidencyFilter2">
                        <label class="form-check-label text-muted" for="SidebarResidencyFilter2">
                          Citizen
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" (change)="filterResidency('Work Permit')"
                          id="SidebarResidencyFilter3">
                        <label class="form-check-label text-muted" for="SidebarResidencyFilter3">
                          Work Permit
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" (change)="filterResidency('Permanent Resident')"
                          id="SidebarResidencyFilter4">
                        <label class="form-check-label text-muted" for="SidebarResidencyFilter4">
                          Permanent Resident
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" (change)="filterResidency('Student Visa')"
                          id="SidebarResidencyFilter5">
                        <label class="form-check-label text-muted" for="SidebarResidencyFilter5">
                          Student Visa
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" (change)="filterResidency('Visitor Visa')"
                          id="SidebarResidencyFilter6">
                        <label class="form-check-label text-muted" for="SidebarResidencyFilter6">
                          Visitor Visa
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" (change)="filterResidency('Other')"
                          id="SidebarResidencyFilter7">
                        <label class="form-check-label text-muted" for="SidebarResidencyFilter7">
                          Other
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item rounded-0 shadow-sm border-0">
                  <div class="accordion-header py-0 rounded-0 border border-1 bg-light d-flex justify-content-between
                    align-items-center" id="panelsStayOpen-heading16">
                    <div class="ps-2 fw-bold mb-0 h6 text-muted">
                      Education
                    </div>
                    <div class="border-0 px-2 py-1 accordion-btn" type="button" data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapse16" aria-expanded="true"
                      aria-controls="panelsStayOpen-collapse16">
                      <i class="fas fa-sort-down fs-5 me-2"></i>
                    </div>
                  </div>
                  <div id="panelsStayOpen-collapse16" class="accordion-collapse collapse show"
                    aria-labelledby="panelsStayOpen-heading16">
                    <div class="accordion-body p-sm text-muted">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" (click)="filterEducation('All')"
                          id="Qualification" [formControlName]="'education'">
                        <label class="form-check-label fw-bold" for="Qualification">
                          All
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" (change)="filterEducation('High School')"
                          id="Qualification1">
                        <label class="form-check-label" for="Qualification1">
                          High School
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" (change)="filterEducation('Some College')"
                          id="Qualification2">
                        <label class="form-check-label" for="Qualification2">
                          Some College
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" (change)="filterEducation('Some University')"
                          id="Qualification3">
                        <label class="form-check-label" for="Qualification3">
                          Some University
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" (change)="filterEducation('Associate`s Degree')"
                          id="Qualification4">
                        <label class="form-check-label" for="Qualification4">
                          Associate's Degree
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" (change)="filterEducation('Bachelor`s Degree')"
                          id="Qualification5">
                        <label class="form-check-label" for="Qualification5">
                          Bachelor's Degree
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" (change)="filterEducation('Master`s Degree')"
                          id="Qualification6">
                        <label class="form-check-label" for="Qualification6">
                          Master`s Degree
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" (change)="filterEducation('Graduate Degree')"
                          id="Qualification7">
                        <label class="form-check-label" for="Qualification7">
                          Graduate Degree
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" (change)="filterEducation('PhD/Post Doctorate')"
                          id="Qualification8">
                        <label class="form-check-label" for="Qualification8">
                          PhD/Post Doctorate
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" (change)="filterEducation('Other')"
                          id="Qualification9">
                        <label class="form-check-label" for="Qualification9">
                          Other
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item rounded-0 shadow-sm border-0">
                  <div class="accordion-header py-0 rounded-0 border border-1 bg-light d-flex justify-content-between
                    align-items-center" id="natureSidebarFilter">
                    <div class="ps-2 fw-bold mb-0 h6 text-muted">
                      Nature of Work
                    </div>
                    <div class="border-0 px-2 py-1 accordion-btn" type="button" data-bs-toggle="collapse"
                      data-bs-target="#natureSidebarFilterMain" aria-expanded="true"
                      aria-controls="natureSidebarFilterMain">
                      <i class="fas fa-sort-down fs-5 me-2"></i>
                    </div>
                  </div>
                  <div id="natureSidebarFilterMain" class="accordion-collapse collapse show"
                    aria-labelledby="natureSidebarFilter">
                    <div class="accordion-body text-muted p-sm">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" (click)="filterWork('All')"
                          id="natureSidebarBtn1" [formControlName]="'nature_of_work'">
                        <label class="form-check-label fw-bold text-muted" for="natureSidebarBtn1">
                          All
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" (change)="filterWork('Business')"
                          id="natureSidebarBtn2">
                        <label class="form-check-label text-muted" for="natureSidebarBtn2">
                          Business
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" (change)="filterWork('Government Company')"
                          id="natureSidebarBtn3">
                        <label class="form-check-label text-muted" for="natureSidebarBtn3">
                          Government Company
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" (change)="filterWork('Private Company')"
                          id="natureSidebarBtn4">
                        <label class="form-check-label text-muted" for="natureSidebarBtn4">
                          Private Company
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" (change)="filterWork('Self Employed')"
                          id="natureSidebarBtn7">
                        <label class="form-check-label text-muted" for="natureSidebarBtn7">
                          Self Employed
                        </label>
                      </div>

                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" (change)="filterWork('Not Working')"
                          id="natureSidebarBtn5">
                        <label class="form-check-label text-muted" for="natureSidebarBtn5">
                          Not Working
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" (change)="filterWork('Other')"
                          id="natureSidebarBtn6">
                        <label class="form-check-label text-muted" for="natureSidebarBtn6">
                          Other
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item rounded-0 shadow-sm border-0">
                  <div class="accordion-header py-0 rounded-0 border border-1 bg-light d-flex justify-content-between
                    align-items-center" id="panelsStayOpen-heading13">
                    <div class="ps-2 fw-bold mb-0 h6 text-muted">
                      Profession Area
                    </div>
                    <div class="border-0 px-2 py-1 accordion-btn" type="button" data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapse13" aria-expanded="true"
                      aria-controls="panelsStayOpen-collapse13">
                      <i class="fas fa-sort-down fs-5 me-2"></i>
                    </div>
                  </div>
                  <div id="panelsStayOpen-collapse13" class="accordion-collapse collapse show"
                    aria-labelledby="panelsStayOpen-heading13">
                    <div class="accordion-body p-sm text-muted">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" (click)="filterProfession('All')"
                          id="ProfessionArea" [formControlName]="'profession_area'">
                        <label class="form-check-label fw-bold" for="ProfessionArea">
                          All
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox"
                          (change)="filterProfession('Accounting - Banking & Finance')" id="ProfessionArea1">
                        <label class="form-check-label" for="ProfessionArea1">
                          Accounting - Banking & Finance
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox"
                          (change)="filterProfession('Administration & HR')" id="ProfessionArea2">
                        <label class="form-check-label" for="ProfessionArea2">
                          Administration & HR
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox"
                          (change)="filterProfession('Airline & Aviation')" id="ProfessionArea3">
                        <label class="form-check-label" for="ProfessionArea3">
                          Airline & Aviation
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" (change)="filterProfession('Agriculture')"
                          id="ProfessionArea4">
                        <label class="form-check-label" for="ProfessionArea4">
                          Agriculture
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox"
                          (change)="filterProfession('Beauty - Fashion & Jewellery Designers')" id="ProfessionArea5">
                        <label class="form-check-label" for="ProfessionArea5">
                          Beauty - Fashion & Jewellery Designers
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" (change)="filterProfession('Sales & Marketing')"
                          id="ProfessionArea6">
                        <label class="form-check-label" for="ProfessionArea6">
                          Sales & Marketing
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" (change)="filterProfession('Merchant Navy')"
                          id="ProfessionArea8">
                        <label class="form-check-label" for="ProfessionArea8">
                          Merchant Navy
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" (change)="filterProfession('Other')"
                          id="ProfessionArea7">
                        <label class="form-check-label" for="ProfessionArea7">
                          Other
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <h6 class="fw-600 mb-0 mt-3">Annual Income</h6>
                <div class="col-12" *ngIf="AnualIncomeHide">
                  <ngx-slider [(value)]="minValue4" [(highValue)]="maxValue4" [options]="options4"
                    (click)="handleChange()">
                  </ngx-slider>
                </div>
                <div class="col-12 mb-4">
                  <div class="col-md-12 mb-3 mt-2">
                    <div>
                      <select class="form-select" (change)="annualIncomeType($event)">
                        <option value="Open To All">Open To All</option>
                        <option value="SpecifyRange"> Specify Range </option>
                      </select>
                    </div>
                  </div>
                  <div *ngIf="annualIncome=='SpecifyRange'" class="col-12 mb-3">
                    <ng-select [items]="this.countries" bindLabel="fullnumber" bindValue="id"
                      [formControlName]="'income_currency'" (change)="currencyChange()" [(ngModel)]="currencyCode"
                      placeholder="Select Country Code" dropdownPosition="auto" [virtualScroll]="true">
                      <ng-template ng-label-tmp let-item="item">
                        <img height="20" width="20" src="assets/images/flag/Flag_of_{{
                          item.country_name
                          | replaceSpaceWithUnderscore
                          }}.svg" alt="" style="margin-right: 5px" />
                        {{ item.country_name }} <b> {{ item.currency }}</b>
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item" let-index="index">
                        <img height="20" width="20" src="assets/images/flag/Flag_of_{{
                          item.country_name
                          | replaceSpaceWithUnderscore
                          }}.svg" alt="" style="margin-right: 5px" />
                        {{ item.country_name }} <b> {{ item.currency }}</b>
                      </ng-template>
                    </ng-select>
                  </div>
                  <div *ngIf="annualIncome=='SpecifyRange'"
                    class="d-flex p-sm align-items-center justify-content-between">
                    <div class="flex-1">
                      <input type="text" class="form-control p-sm" aria-label="Default select example"
                        [formControlName]="'income_range_from'" (keyup)="checkIncomeRange()" maxlength="12"
                        (input)="keyPressNumeric($event)" (paste)="pasteNumeric($event)">
                    </div>
                    <div class="px-1 text-dark"> To </div>
                    <div class="flex-1">
                      <input type="text" class="form-control p-sm" aria-label="Default select example"
                        [formControlName]="'income_range_to'" (keyup)="checkIncomeRange()" maxlength="12"
                        (input)="keyPressNumeric($event)" (paste)="pasteNumeric1($event)">

                    </div>
                  </div>
                  <div *ngIf="incomeRange">
                    <div class="form-label mb-2 py-1 fw-bold
                      text-danger">
                      Range is not valid
                    </div>
                  </div>
                </div>

                <div class="accordion-item rounded-0 shadow-sm border-0">
                  <div class="accordion-header py-0 rounded-0 border border-1 bg-light d-flex justify-content-between
                    align-items-center" id="panelsStayOpen-heading15">
                    <div class="ps-2 fw-bold mb-0 h6 text-muted">
                      Food Preference
                    </div>
                    <div class="border-0 px-2 py-1 accordion-btn" type="button" data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapse15" aria-expanded="true"
                      aria-controls="panelsStayOpen-collapse15">
                      <i class="fas fa-sort-down fs-5 me-2"></i>
                    </div>
                  </div>
                  <div id="panelsStayOpen-collapse15" class="accordion-collapse collapse show"
                    aria-labelledby="panelsStayOpen-heading15">
                    <div class="accordion-body p-sm text-muted">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" (click)="filterFood('All')" id="FoodPreference"
                          [formControlName]="'food_preference'">
                        <label class="form-check-label fw-bold" for="FoodPreference">
                          All
                        </label>
                      </div>

                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" (click)="filterFood('Veg')"
                          id="FoodPreference1">
                        <label class="form-check-label" for="FoodPreference1">
                          Veg
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" (click)="filterFood('Non Veg')"
                          id="FoodPreference2">
                        <label class="form-check-label" for="FoodPreference2">
                          Non-Veg
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" (click)="filterFood('Occasionally Non-Veg')"
                          id="FoodPreference4">
                        <label class="form-check-label" for="FoodPreference4">
                          Occasionally Non-Veg
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" (click)="filterFood('Eggetarian')"
                          id="FoodPreference3">
                        <label class="form-check-label" for="FoodPreference3">
                          Eggetarian
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" (click)="filterFood('Pescatarian')"
                          id="FoodPreference8">
                        <label class="form-check-label" for="FoodPreference8">
                          Pescatarian
                        </label>
                      </div>

                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" (click)="filterFood('Jain')"
                          id="FoodPreference5">
                        <label class="form-check-label" for="FoodPreference5">
                          Jain
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" (click)="filterFood('Vegan')"
                          id="FoodPreference6">
                        <label class="form-check-label" for="FoodPreference6">
                          Vegan
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" (click)="filterFood('Other')"
                          id="FoodPreference7">
                        <label class="form-check-label" for="FoodPreference7">
                          Other
                        </label>
                      </div>

                    </div>
                  </div>
                </div>

              </div>

            </div>

          </mat-drawer>

          <mat-drawer-content class="row bg-white" [ngClass]="(!this.advanceFilter)?'advance-sect':'advance-sectone'">
            <div class="col-12 mb-3">
              <h5 class="fw-bold mb-0"> My Matches </h5>
            </div>
            <div class="col-12">
              <div class="row">
                <div class="col-12 col-md-8">
                  <p class="fw-500 text-muted">Members who match most of your preferences
                    <a href="javascript:void(0)" routerLink="/myInfo" (click)="EditPartnerPrefrence()"
                      class="text-danger">Edit
                      <i class="fas fa-angle-right"></i>
                    </a>
                  </p>
                </div>

              </div>

              <div class="col-12">
                <div class="row justify-content-between">

                  <div class="col-12 col-md-8 d-flex align-items-center">
                    <div class="mb-1">
                      <label class="form-check-label" for="flexSwitchCheckChecked">
                        My Preference
                      </label>
                    </div>
                    <div class="form-check form-switch mb-0">
                      <input class="form-check-input" (click)="drawer.toggle()" type="checkbox"
                        id="flexSwitchCheckChecked" (change)="onToggle()">
                      <label class="form-check-label" for="flexSwitchCheckChecked">
                        Filter by Criteria
                      </label>
                    </div>
                    <div class="mb-1 ms-2 no-cursor-pointer">
                      <div class="btn btn-danger w-20-h-20 position-absolute center no-cursor-pointer"
                        *ngIf="!inboxLoader"><i class=" fas fa-arrow-left"></i>
                      </div>
                      <div class="ms-4" *ngIf="matches.length>1 && !inboxLoader || !toggle">
                        <h6 class="mb-0 ms-2 text-center text-danger fw-bold no-cursor-pointer" *ngIf="toggle">
                          Toggle to search by My Preference
                        </h6>
                        <h6 class="mb-0 ms-2 text-center text-danger fw-bold no-cursor-pointer" *ngIf="!toggle">
                          <!-- <div class="btn btn-danger w-20-h-20 center ms-5"><i class="fas fa-arrow-left"></i>
                          </div> -->
                          Toggle to search by Filter by Criteria
                        </h6>

                      </div>
                    </div>
                  </div>


                  <div class="col-12 col-md-4 text-start text-md-end" *ngIf="matches.length>1 && !inboxLoader">
                    <div class="dropdown">
                      <button class="btn btn-outline-danger px-2 rounded-3 py-0" type="button" id="dropdownMenuButton1"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        Sort By
                      </button>
                      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li class="dropdown-item" [ngClass]="{'current_sort': current_sort == 'default'}" id
                          (click)="sortedBy($event,'default')"> Default </li>
                        <li class="dropdown-item" [ngClass]="{'current_sort': current_sort == 'earliest'}" id="ASC"
                          (click)="sortedBy($event,'earliest')"> Earliest </li>
                        <li class="dropdown-item" [ngClass]="{'current_sort': current_sort == 'latest'}" id="DESC"
                          (click)="sortedBy($event,'latest')"> Latest </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Content loader -->
              <div class="row shadow-sm border border-0 h-100 mx-0 overflow-hidden position-relative"
                *ngIf="(inboxLoader) && toggle== true">
                <div *ngFor="let number of [0,1,3,4]" class="col-12 col-sm-12 col-lg-6 my-3">
                  <div
                    class="row card row shadow-sm border border-1 bg-light h-100 mx-0 overflow-hidden position-relative"
                    style="width:auto;">
                    <div class=" mainCardBox col-12 card-body d-flex p-1 ">
                      <img src="assets/images/default-profile-picture.svg" alt="" class="img_Box1 animated-background" />
                      <div class="card-contentBox p-1 ">
                        <div class="row mx-0 pb-2 border border-2 border-top-0 border-start-0 border-end-0 card-nav">
                          <span class="nameBlank-box1 mt-1 animated-background-name "></span>
                          <div class="ms-auto d-flex align-items-center justify-content-center btn w-25-h-25 text-center border border-1 rounded-circle
                      border-black p-1 bg-grey animated-background-name">
                            <i class="fas fa-caret-down h-4"></i>
                          </div>
                        </div>
                        <div class="row mt-3 ">
                          <div class="col-6 ">
                            <span class="blank-box mt-3 animated-background-name"></span>
                            <span class="blank-box mt-3 animated-background-name"></span>
                            <span class="blank-box mt-3 animated-background-name"></span>
                          </div>
                          <div class="col-6">
                            <span class="blank-box mt-3 animated-background-name"></span>
                            <span class="blank-box mt-3 animated-background-name"></span>
                            <span class="blank-box mt-3 animated-background-name"></span>
                            <span class="blank-box mt-3 animated-background-name"></span>
                          </div>
                        </div>
                        <span class="footerBlankBox mt-3 animated-background-name"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- End content loader -->

              <!-- Content loader -->
              <div class="row shadow-sm border border-0 h-100 mx-0 overflow-hidden position-relative"
                *ngIf="(inboxLoader1) && toggle== false">
                <div *ngFor="let number of [0,1,2,3]" class="col-12 col-sm-12 col-lg-6 my-3">
                  <div
                    class="row card row shadow-sm border border-1 bg-light h-100 mx-0 overflow-hidden position-relative"
                    style="width:auto;">
                    <div class=" mainCardBox col-12 card-body d-flex p-1 ">
                      <img src="assets/images/default-profile-picture.svg" alt="" class="img_Box animated-background" />
                      <div class="card-contentBox p-1 ">
                        <div class="row mx-0 pb-2 border border-2 border-top-0 border-start-0 border-end-0 card-nav">
                          <span class="nameBlank-box mt-1 animated-background-name "></span>
                          <div class="ms-auto d-flex align-items-center justify-content-center btn w-25-h-25 text-center border border-1 rounded-circle
                        border-black p-1 bg-grey animated-background-name">
                            <i class="fas fa-caret-down h-4"></i>
                          </div>
                        </div>
                        <div class="row mt-3 ">
                          <div class="col-6 ">
                            <span class="blank-box mt-3 animated-background-name"></span>
                            <span class="blank-box mt-3 animated-background-name"></span>
                            <span class="blank-box mt-3 animated-background-name"></span>
                          </div>
                          <div class="col-6">
                            <span class="blank-box mt-3 animated-background-name"></span>
                            <span class="blank-box mt-3 animated-background-name"></span>
                            <span class="blank-box mt-3 animated-background-name"></span>
                            <span class="blank-box mt-3 animated-background-name"></span>
                          </div>
                        </div>
                        <span class="footerBlankBox mt-3 animated-background-name"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- End content loader -->

              <div class="row">
                <div *ngIf="matches.length==0 && !inboxLoader && toggle== true " class="center "
                  style="min-height: calc(100vh - 398px);">
                  <strong class="h-600">No profiles available according to your partner preferences, please go on
                    Advance search (Include more matches) or edit the partner preferences.</strong>
                </div>

                <div *ngIf="matches.length==0 && !inboxLoader1 && toggle== false" class="center "
                  style="min-height: calc(100vh - 398px);">
                  <strong class="h-600">No profiles available according to your partner preferences, please go on
                    Advance search (Include more matches) or edit the partner preferences.</strong>
                </div>

                <div class="col-12 col-sm-6 col-lg-6 my-3" *ngIf="matchesLoader">
                  <div>
                  </div>

                </div>

                <ng-container *ngIf="matches.length>0 && !inboxLoader">
                  <div *ngFor="let profile of matches,let i= index" class="col-12 col-sm-12 col-lg-6 my-3">
                    <div class="row shadow-sm border border-1 bg-light h-100 mx-0 overflow-hidden position-relative">
                      <div class="col-12 col-lg-4 px-0">
                        <div class="ratio-1 card-image">
                          <img class="image-cover image-cover-first mt-3"
                            *ngIf="!profile?.profile_photo || profile.profile_photo_approved!=1 "
                            src="assets/images/default-profile-picture.svg" alt="assets/images/men-avtar-2.svg">
                          <div
                            *ngIf="profile?.profile_photo != null && !!profile?.profile_photo && profile.profile_photo_approved==1 ">
                            <ng-container
                              *ngIf="profile?.photo_visiblity == 'No one' || (profile?.photo_visiblity == 'Visible to connected members' && profile?.stage != 5)">
                              <img class="image-cover" src="assets/images/default-profile-picture.svg"
                                alt="assets/images/men-avtar-2.svg">
                            </ng-container>
                            <ng-container
                              *ngIf="profile?.photo_visiblity == 'Visible to all members' || (profile?.photo_visiblity == 'Visible to connected members' && profile?.stage == 5)">
                              <app-progressive-image class="progressive-image">
                                <img appProgressiveImage [src]="profile?.profile_photo"
                                  class="img-fluid all_members margin-top-50" alt="" width="100%">
                                <img
                                  *ngIf="profile?.photo_visiblity == 'Visible to connected members' && profile?.stage != 5"
                                  appProgressiveImage src="assets/images/default-profile-picture.svg" alt=""
                                  class="img-fluid not_connected">
                                <img *ngIf="profile?.photo_visiblity == 'No one'" appProgressiveImage
                                  src="assets/images/default-profile-picture.svg" alt="" class="img-fluid no_one">

                                <div appProgressiveImagePlaceholder class="placeholderOneOrThree-matches">
                                  <div id="loaderProgressive" class="centerProgressive1"></div>
                                </div>
                                <div appProgressiveImageFallback class="fallback">
                                  <img appProgressiveImageFallback class="img-fluid"
                                    src="assets/images/default-profile-picture.svg" alt="">
                                </div>
                              </app-progressive-image>
                            </ng-container>

                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-lg-8 py-2">
                        <div class="row h-1001">
                          <div class="col-12 h-100 d-flex flex-column">
                            <div
                              class="row mx-0 pb-2 border border-2 border-top-0 border-start-0 border-end-0 card-nav">
                              <div class="col-10 px-0">
                                <!-- <a (click)="ViewProfile(profile.user_id,profile.display_name)"></a> -->
                                <h6 class="text-danger fw-bold mb-1" *ngIf="!!profile.display_name">
                                  {{profile.display_name}}
                                </h6>
                                <h6 class="text-danger fw-bold mb-1" *ngIf="!profile.display_name">
                                  {{profile.unique_key}}
                                </h6>
                                <!-- <p [beforeunload]="beforeunload"></p> -->
                                <div class="d-flex align-items-center">
                                  <span class="user_email_verified"
                                    [ngbTooltip]="profile?.is_user_verified ==1 ? 'Verified user': 'Verified email'"
                                    placement="bottom">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                                      class="bi bi-shield-fill-check mb-1 " [ngClass]="{'text-muted':profile?.is_user_verified != 1,
                                               'text-success':profile?.is_user_verified == 1}" viewBox="0 0 16 16">
                                      <path fill-rule="evenodd" d="M8 0c-.69 0-1.843.265-2.928.56-1.11.3-2.229.655-2.887.87a1.54 1.54 0 0
                                    0-1.044
                                    1.262c-.596 4.477.787 7.795 2.465 9.99a11.777 11.777 0 0 0 2.517
                                    2.453c.386.273.744.482
                                    1.048.625.28.132.581.24.829.24s.548-.108.829-.24a7.159
                                    7.159 0 0 0 1.048-.625 11.775 11.775 0 0 0 2.517-2.453c1.678-2.195
                                    3.061-5.513
                                    2.465-9.99a1.541 1.541 0 0 0-1.044-1.263 62.467 62.467 0 0
                                    0-2.887-.87C9.843.266
                                    8.69 0 8 0zm2.146 5.146a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708
                                    0l-1.5-1.5a.5.5 0
                                    1 1 .708-.708L7.5 7.793l2.646-2.647z" />
                                    </svg>
                                  </span>

                                  <span class="user_email_verified1 mx-1" *ngIf="profile?.is_user_verified== 0 "
                                    placement="bottom" ngbTooltip="Verified email"><i class="fa fa-check fs-5"
                                      style="color:green"></i>
                                  </span>
                                  <span class="user_email_verified1 mx-1" *ngIf="profile?.is_user_verified== 1 "
                                    placement="bottom" ngbTooltip="Verified user"><i class="fa fa-check-double fs-5 "
                                      style="color:green"></i>
                                  </span>

                                  <div class="p-xsm mb-0 text-success" *ngIf="profile.is_logged_in">
                                    <div class="flash d-inline-block me-1">
                                      <div class>
                                        <i class="fas fa-globe"></i>
                                      </div>
                                    </div>
                                    <div class="d-inline-block">
                                      Online
                                    </div>
                                  </div>
                                  <div class="p-xsm mb-0 text-default" *ngIf="!profile.is_logged_in">
                                    <div class="d-inline-block me-1">
                                      <div class>
                                        <i class="fas fa-globe"></i>
                                      </div>
                                    </div>
                                    <div class="d-inline-block">
                                      Offline
                                    </div>
                                  </div>

                                  <div class="ms-2">
                                    <img src="assets/images/icon/{{
                                      profile.package_icon}}" class="width-30" alt placement="bottom"
                                      ngbTooltip="Elite Member" *ngIf="profile.package_id == 3" />
                                    <img src="assets/images/icon/{{
                                      profile.package_icon}}" class="width-30" alt placement="top"
                                      ngbTooltip="Premium Member" *ngIf="profile.package_id == 2" />
                                  </div>
                                </div>
                              </div>
                              <div class="col-2 px-0 d-flex justify-content-end">
                                <div class="me-2 stage_wrp justify-content-end">
                                  <a href="chat" *ngIf="profile.stage == 10" placement="left" ngbTooltip="Chat Now"
                                    class="w-30-h-30 center btn-danger rounded-circle border-0">
                                    <i class="fas fa-comments fs-6"></i>
                                  </a>
                                  <a href="communications/inbox-followUp" *ngIf="profile.stage == 9   && profile.sender == user_id  " placement="left"
                                    ngbTooltip="Follow Up" class="w-30-h-30 center btn-danger rounded-circle border-0">
                                    <i class="fas fa-question"></i>
                                  </a>
                                  <a href="communications/inbox-blocked" *ngIf="profile.stage == 11" placement="left"
                                    ngbTooltip="Reported Profile"
                                    class="w-30-h-30 center btn-danger rounded-circle border-0">
                                    <i class="fas fa-user-clock"></i>
                                  </a>
                                  <a href="communications/inbox-blocked" *ngIf="profile.stage == 8" placement="left"
                                    ngbTooltip="Blocked Profile"
                                    class="w-30-h-30 center btn-danger rounded-circle border-0">
                                    <i class="fas fa-user-lock"></i>
                                  </a>
                                  <a href="communications/inbox-cancelled" *ngIf="profile.stage == 6 && profile.receiver != user_id"
                                    placement="left" ngbTooltip="Cancelled by them"
                                    class="w-30-h-30 center btn-danger rounded-circle border-0">
                                    <i class="fas fa-user-times"></i>
                                  </a>
                                  <a href="communications/inbox-cancelled" *ngIf="profile.stage == 7 && profile.receiver != user_id"
                                    placement="left" ngbTooltip="Cancelled by me"
                                    class="w-30-h-30 center btn-danger rounded-circle border-0">
                                    <i class="fas fa-user-minus"></i>
                                  </a>
                                  <a href="communications/inbox-cancelled" *ngIf="profile.stage == 15 && profile.receiver != user_id"
                                    placement="left" ngbTooltip="Cancelled automatically"
                                    class="w-30-h-30 center btn-danger rounded-circle border-0">
                                    <i class="fas fa-user-slash"></i>
                                  </a>
                                  <ng-container *ngIf="profile.stage == 6">
                                    <a (click)="connectCancelled(profile.user_id,profile.display_name,profile.profile_photo,profile)"
                                      *ngIf="profile.sender == profile.user_id && !profile.favourite_profile && !profile.shortlisted_profile"
                                      placement="left" ngbTooltip="Rejected by me"
                                      class="w-30-h-30 center btn-danger rounded-circle border-0">
                                      <i class="fas fa-ban"></i>
                                    </a>
                                  </ng-container>
                                  <a href="communications/accepted" *ngIf="profile.stage == 5" placement="left"
                                    ngbTooltip="Request Accepted"
                                    class="w-30-h-30 center btn-danger rounded-circle border-0">
                                    <i class="fas fa-user-friends"></i>
                                  </a>
                                  <a href="communications/comunication-inbox" *ngIf="profile.stage == 4" placement="left"
                                    ngbTooltip="Request Received"
                                    class="w-30-h-30 center btn-danger rounded-circle border-0">
                                    <i class="fas fa-inbox"></i>
                                  </a>

                                  <span *ngIf="profile.stage == 3">
                                    <a href="communications/inbox-sent" placement="left" ngbTooltip="Request Sent"
                                      *ngIf="profile.receiver == profile.user_id"
                                      class="w-30-h-30 center btn-danger rounded-circle border-0">
                                      <i class="far fa-paper-plane"></i>
                                    </a>
                                    <!-- <a href="comunication-inbox" *ngIf="profile.sender == profile.user_id"
                                      placement="left" ngbTooltip="Request Received"
                                      class="w-30-h-30 center btn-danger rounded-circle border-0">
                                      <i class="fas fa-inbox"></i>
                                    </a> -->
                                  </span>
                                  <span *ngIf="profile.stage == 9"> 
                                    <a href="communications/comunication-inbox" *ngIf="profile.sender == profile.user_id"
                                    placement="left" ngbTooltip="Request Received"
                                    class="w-30-h-30 center btn-danger rounded-circle border-0">
                                    <i class="fas fa-inbox"></i>
                                     </a>
                                  </span>
                                  
                                  <ng-container *ngIf="profile.stage <3 ||profile.stage == 6|| ((profile.stage == 7 || profile.stage == 15) &&
                                    profile.receiver ==
                                    user_id)">
                                    <ng-container *ngIf="!!profile.reported_profle">
                                      <a href="communications/inbox-blocked" placement="left" ngbTooltip="Reported Profile"
                                        class="w-30-h-30 center btn-danger rounded-circle border-0">
                                        <i class="fas fa-user-clock"></i>
                                      </a>
                                    </ng-container>
                                    <ng-container *ngIf="!!profile.blocked_profile && !profile.reported_profle">
                                      <a href="communications/inbox-blocked" placement="left" ngbTooltip="Blocked Profile"
                                        class="w-30-h-30 center btn-danger rounded-circle border-0">
                                        <i class="fas fa-user-lock"></i>
                                      </a>
                                    </ng-container>

                                    <ng-container *ngIf="!profile.blocked_profile">
                                      <a href="matches-favourite" *ngIf="!!profile.favourite_profile " placement="left"
                                        ngbTooltip="Favourited Profile"
                                        class="w-30-h-30 center btn-danger rounded-circle border-0">
                                        <i class="fas fa-heart "></i>
                                      </a>
                                      <a href="matches-shortlist" *ngIf="!!profile.shortlisted_profile "
                                        class="w-30-h-30 center btn-danger rounded-circle border-0" placement="left"
                                        ngbTooltip="Shortlisted Profile">
                                        <i class="fas fa-bookmark"></i>
                                      </a>
                                    </ng-container>

                                  </ng-container>

                                  <a href="inbox-followUp" *ngIf="profile?.stage == 12 && profile.receiver != user_id"
                                    placement="left" ngbTooltip="Uncancelled "
                                    class="w-30-h-30 center btn-danger rounded-circle border-0">
                                    <i class="fa fa-undo"></i>
                                  </a>

                                </div>
                                <div *ngIf="!(profile?.reported_profle)"
                                  class="ms-auto center btn w-30-h-30 rounded-circle btn-danger border-0">
                                  <div class="ms-auto center btn w-30-h-30 btn-danger text-center border border-0
                                    rounded-circle border-danger" type="button" id="{{profile.user_id}}"
                                    (click)="getId($event)" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i class="fas fa-caret-down h4 mt-2" id="{{profile.user_id}}"
                                      (click)="getId($event)"></i>
                                  </div>
                                  <ul class="dropdown-menu py-1 border border-1 border-danger"
                                    aria-labelledby="dropdownMenuButton1">
                
                                    <ng-container *ngIf="profile.favourite_profile == null && profile.shortlisted_profile == null &&
                                      profile.blocked_profile ==
                                      null && profile.reported_profile == null">
                                      <li
                                        *ngIf="((profile.stage == 7 || profile.stage == 12) && profile.receiver == user_id) || profile.stage <3    || (profile.stage ==15 && profile.sender!=user_id )  "
                                        class="dropdown-item p-sm px-2" id="add_to_shortlist"
                                        (click)="actionSelected($event,profile.user_id)">
                                        <i class="fas fa-bookmark"></i>&nbsp; Add to
                                        Shortlist
                                      </li>
                                      <!-- || profile.stage ==9 -->
                                    </ng-container>
                                    <button style="display:none;" id="openConnectModal" data-bs-target="#ReConnectModal"
                                      data-bs-toggle="modal"></button>
                                    <li *ngIf="!profile.blocked_profile || profile.blocked_profile== 0 "
                                      class="dropdown-item p-sm px-2" id="block_profile"
                                      (click)="actionSelected($event,profile.user_id)">
                                      <i class="fas fa-user-lock"></i> Block

                                    </li>
                                    <li *ngIf="!profile.reported_profle || profile.reported_profle== 0 "
                                      class="dropdown-item p-sm px-2" id="block_and_report_profile"
                                      data-bs-toggle="modal" data-bs-target="#blockReportModal"
                                      (click)="userBlockReport(profile)">
                                      <i class="fas fa-user-clock"></i> Block & Report
                                    </li>

                                  </ul>
                                </div>

                              </div>
                            </div>

                            <div class="row">
                              <div class="col-12 col-md-6 py-1 lh-lg">
                                <!-- <p class="mb-0 fw-bold"> {{ profile?.display_name}} <small class="p-sm"></small> </p> -->
                                <!-- <p class="text-muted mb-0 py-1 p-sm"> {{profile.dob |
                                  ageConvertPipe }}, {{
                                  profile?.height|heightConverter:"Ft"}} </p> -->
                                <p class="text-muted mb-0 py-1 p-sm"> {{profile.age }} yrs, {{
                                    profile?.height|heightConverter:"Ft"}} </p>
                                <p class="text-muted mb-0 py-1 p-sm"> {{
                                  profile?.marital_status | limit :18}} </p>
                                <p class="text-muted mb-0 py-1 p-sm"> {{
                                  profile?.religion | limit : 15}}<span *ngIf="profile.caste">, {{ profile?.caste |
                                    limit : 14}}</span>
                                </p>
                              </div>
                              <div class="col-12 col-md-6 py-1 lh-lg">
                                <p class="text-muted mb-0 py-1 p-sm" *ngIf="!!profile?.working_as"> {{
                                  profile?.working_as | limit : 20}}</p>
                                <p class="text-muted mb-0 py-1 p-sm"> {{
                                  profile?.current_city | limit : 15}}
                                </p>
                                <p class="text-muted mb-0 py-1 p-sm"> {{
                                  profile?.stateName | limit : 20}}
                                </p>
                                <p class="text-muted mb-0 py-1 p-sm"> {{
                                  profile?.living_in_country_name | limit :
                                  20}}
                                </p>

                              </div>
                              <div class="col-12 py-1 lh-lg">
                                <p class="text-muted p-sm mb-0">{{profile.description |
                                  limit : 75}} <a href="javascript:void(0)"
                                    (click)="ViewProfile(profile.user_id,profile.display_name)" class="default_link text-underline">View
                                    Profile</a>
                                </p>
                                <button style="display:none;" id="saveFilter" data-bs-target="#saveFilterModal"
                                  data-bs-toggle="modal"></button>
                                <button style="display:none;" id="saveFilter1" data-bs-target="#saveFilterModal1"
                                  data-bs-toggle="modal"></button>

                                <button style="display:none;" id="goToMyInfo" routerLink="/myInfo"></button>
                              </div>
                            </div>
                            <div class="row">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>

              </div>

              <div class="row mx-2">

              </div>

              <div class="row mt-3 justify-content-end" *ngIf="TotalRecord> 0 ">
                <div class="col-md-5 col-8 justify-content-center">
                  <mat-paginator #paginator [pageSizeOptions]="[8, 16, 50, 100]" [length]="TotalRecord"
                    showFirstLastButtons aria-label="Select page of periodic elements" (page)="handlePage($event)"
                    [pageSize]="itemPerPage" [hidePageSize]="hidePageSize">
                  </mat-paginator>
                </div>
                <div class="col-md-2 col-4 text-center" style="text-align:start; padding-top:20px;">
                  <p class="mat-paginator-range text-nowrap">Page {{pageNo}} of {{lastPage}}</p>
                </div>
              </div>
            </div>
          </mat-drawer-content>
        </mat-drawer-container>

      </div>
      <!------------------------ end-main-tab --------------->
    </form>

  </div>
  <!-- end tabs content -->

</div>

<app-login-footer></app-login-footer>
<div class="modal fade" id="upgradeModal" tabindex="-1" aria-labelledby="upgradeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
    <div class="modal-content position-relative">

      <div class="modal-body text-center p-4">
        <button type="button" class="btn btn-danger position-absolute top-0 right-0 m-2 center w-30-h-30"
          data-bs-dismiss="modal" aria-label="Close">
          <i class="fas fa-times fs-5"></i>
        </button>

        <img src="assets/images/logo.png" alt="" width="200" class="mb-6">

        <p class="p-sm mb-4">
          <!-- Upgrade Now to start communicating with {{displayName}}. -->
          Upgrade now to view the profile of {{displayName}}.
          <!-- Click Upgrade Now to View Profile! KamalPreet S  -->
        </p>

        <button class="btn btn-danger rounded-pill" data-bs-dismiss="modal" aria-label="Close"
          routerLink="/upgrade">Upgrade Now </button>
      </div>

    </div>
  </div>
</div>

<!-- Connect  modal -->

<div class="modal fade" id="ConnectModal" tabindex="-1" aria-labelledby="ConnectModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
    <div class="modal-content position-relative">

      <div class="modal-body text-center p-4">
        <button type="button" class="btn btn-danger position-absolute top-0 right-0 m-2 center w-30-h-30"
          data-bs-dismiss="modal" aria-label="Close">
          <i class="fas fa-times fs-5"></i>
        </button>

        <div class="w-150 h-150 mx-auto border border-1 border-danger rounded-3 mb-2">
          <img src="assets/images/banner-7.jpg" alt="" class="image-cover p-1 rounded-3">
        </div>
        <h5 class="text-danger mb-4"> Anouskq P </h5>

        <p class="p-sm mb-4">
          Do you want to connect with Anouskq P?”
        </p>
        <button class="btn btn-outline-danger">No </button>
        <button class="btn btn-danger ms-2">Yes </button>
      </div>

    </div>
  </div>
</div>

<!-- Connect to cancelled  request modal -->

<div class="modal fade" id="ReConnectModal" tabindex="-1" aria-labelledby="ReConnectModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
    <div class="modal-content position-relative">

      <div class="modal-body text-center p-4">
        <button type="button" class="btn btn-danger position-absolute top-0 right-0 m-2 center w-30-h-30"
          data-bs-dismiss="modal" aria-label="Close">
          <i class="fas fa-times fs-5"></i>
        </button>

        <div class="w-150 h-150 mx-auto border border-1 border-danger rounded-3 mb-2">

          <img src="{{photoSrc}}" alt="" class="image-cover p-1 rounded-3">

        </div>
        <h5 class="text-danger mb-4"> {{displayName}} </h5>

        <p class="p-sm mb-4">
          You rejected {{displayName}}'s request , do you want to add to shortlist ?”
        </p>
        <button class="btn btn-outline-danger" data-bs-dismiss="modal" aria-label="Close">No </button>
        <button class="btn btn-danger ms-2" data-bs-dismiss="modal" aria-label="Close" (click)="
          actionOnProfile()
          ">Yes </button>
      </div>

    </div>
  </div>
</div>

<!-- Report & Block Modal -->
<div class="modal fade" id="blockReportModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
    <div class="modal-content">
      <div class="row modal-body">
        <div class="col-12 mb-2">
          <h5 class="fw-600 text-danger"> Please select reason:</h5>
        </div>
        <div class="col-12">
          <div class="form-check ps-0" *ngFor='let user of reportReasonsList ;let i = index'>
            <input class="checkbox me-2" type="radio" name="Serious" id="{{user.id}}" (click)="blockReportId(user)">
            <label class role="" for="{{user.id}}">
              {{user.label}}</label>
          </div>
          <div class="col-12 mb-3" *ngIf="otherReason">
            <form #wordCountNgForm="ngForm" [formGroup]="wordCountForm">
              <label for="NewPassword" id="reason" class="fw-bold"> Reason</label>
              <textarea id="reason" #texts rows="3" class="form-control" [(ngModel)]="reportReason"
                [formControlName]="'description'" (paste)="pasteNumeric6($event)" (change)="compare()"
                (keypress)="wordCounter($event)" maxlength="500"></textarea> <!-- -->

              <div class="form-label mb-2 py-1 fw-bold text-danger" *ngIf="exceedLimit"> You cannot enter more than the
                maximum of 20 words</div>
              <p class="text-muted p-xsm">Max 20 Words. </p>
              <p class="text-muted p-xsm">Entered- {{words}} Words</p>

            </form>
          </div>
        </div>
      </div>

      <div class="mt-4 text-end">
        <button class="btn btn-light border border-1 border-danger me-2" data-bs-dismiss="modal"
          aria-label="Close">Cancel </button>
        <button class="btn btn-danger m-2" data-bs-dismiss="modal" aria-label="Close" (click)="blockAndReportNow()"
          [disabled]="!reportReason">Submit</button>

      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="saveFilterModal" tabindex="-1" aria-labelledby="saveFilterModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
    <div class="modal-content position-relative">

      <div class="modal-body text-center p-4">
        <button type="button" class="btn btn-danger position-absolute top-0 right-0 m-2 center w-30-h-30"
          data-bs-dismiss="modal" aria-label="Close">
          <i class="fas fa-times fs-5"></i>
        </button>

        <img src="assets/images/logo.png" alt="" width="200" class="mb-6">
        <div href="javascript:void(0)">

          <h5 class=" mt-2 text-center" style="display: block;">Do you want save the filter for later ?
          </h5>
          <textarea id="reason" #texts rows="1" class="form-control mb-2" [(ngModel)]="filterName" maxlength="500"
            placeholder="Filter name"></textarea>


          <button class="btn btn-danger rounded-pill me-2" data-bs-dismiss="modal" aria-label="Close"
            [disabled]="!filterName" (click)="saveFilters()">Yes </button>
          <button class="btn btn-danger rounded-pill " data-bs-dismiss="modal" id="closeFilter" aria-label="Close">No
          </button>
        </div>

      </div>

    </div>
  </div>
</div>

<div class="modal fade" id="saveFilterModal1" tabindex="-1" aria-labelledby="saveFilterModalLabel1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
    <div class="modal-content position-relative">

      <div class="modal-body text-center p-4">
        <button type="button" class="btn btn-danger position-absolute top-0 right-0 m-2 center w-30-h-30"
          data-bs-dismiss="modal" aria-label="Close">
          <i class="fas fa-times fs-5"></i>
        </button>

        <img src="assets/images/logo.png" alt="" width="200" class="mb-6">
        <div href="javascript:void(0)">

          <h5 class=" mt-2 text-center" style="display: block;">Do you want apply the last searched filter ?
          </h5>
          <button class="btn btn-danger rounded-pill me-2" data-bs-dismiss="modal" aria-label="Close"
            (click)="lastFilterApply()">Yes </button>
          <button class="btn btn-danger rounded-pill " data-bs-dismiss="modal" id="closeFilter" aria-label="Close">No
          </button>
        </div>

      </div>

    </div>
  </div>
</div>
