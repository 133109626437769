<div class="w-100 bg-maroon">
  <app-header *ngIf="!isLoggedIn"></app-header>
</div>
<div>
  <app-progressive-image class="progressive-image">
    <img appProgressiveImage src="page-header" alt="image" title="About Image" class="page-header">
    <link rel="preload" as="image" href="assets/images/about.webp" />
    <div class="page-header mt-93">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="page-caption">
            </div>
          </div>
        </div>
      </div>
      <div appProgressiveImagePlaceholder class="placeholderOneOrThree">
        <div id="loaderProgressive" class="centerProgressive"></div>
      </div>
    </div>
    <div appProgressiveImageFallback class="fallback">
      <img appProgressiveImageFallback src alt>
    </div>
  </app-progressive-image>
  <div class="py-5 bg-gray">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="about-descriptions text-center">
            <!-- <h2 class="text-center">About Us</h2> -->
            <h2 class="text-center">About Us </h2>
            <div class="seperator-line mx-auto centered mb-4"></div>
          </div>
        </div>

      </div>

      <section class="py-4 container px-0">

        <div class="row">
          <div class="col-12 p-3 p-sm-4">
            <h4 class="fw-bold text-danger">Eternal Strings – Weaving Lifelong Connections </h4>
            <p>Welcome to Eternal Strings, where the art of matchmaking meets the essence of timeless love. We are more
              than just a matrimonial service; we are your partners in discovering a love that resonates with your
              values, dreams, and aspirations.</p>
            <h4 class="fw-bold text-danger mt-4 mt-sm-5">Our Story: Born from Tradition, Evolving with Time </h4>

            <p>
              Eternal Strings was founded on a simple belief: that true love is built on a foundation of shared values,
              mutual respect, and a deep understanding of each other's journeys. As we navigate the fast-paced,
              ever-changing world, the essence of relationships remains rooted in these timeless principles.
            </p>
            <p>
              Our journey began with a vision to bridge tradition and modernity. Gail, our founder, was deeply
              influenced
              by the stories of love and commitment she grew up with. She saw how the core values of respect, trust, and
              shared goals created strong, lasting relationships in her community. These experiences inspired her to
              create Eternal Strings—a platform where these timeless principles could meet the needs of today's
              generation.
            </p>
            <p>Gail’s journey wasn't just about creating a matchmaking service; it was about crafting a space where
              individuals could find not just a life partner, but a soulmate who complements their life in every way.
              From the first glance to the final vows, we are here to guide you in weaving the beautiful tapestry of
              your love story.</p>

            <h4 class="fw-bold text-danger mt-4 mt-sm-5">Our Mission: Crafting Meaningful Connections</h4>
            <p>
              At Eternal Strings, our mission is to create meaningful connections that stand the test of time. We
              understand that finding the right partner is not just about shared interests but about aligning on deeper
              values like family, culture, and life goals.
            </p>
            <p>Our process is meticulous and personalized, ensuring that every match we facilitate is thoughtful,
              intentional, and rooted in a deep understanding of what makes each person unique. We prioritize quality
              over quantity, focusing on creating connections that lead to lasting, fulfilling relationships.</p>


            <h4 class="fw-bold text-danger mt-4 mt-sm-5">Why Choose Us: Your Journey, Our Commitment</h4>
            <p>
              Personalized Matchmaking: We recognize that every individual’s journey to finding love is unique. That’s
              why we take the time to understand your values, preferences, and what truly matters to you. Our
              experienced matchmakers uses this insight to curate matches that are not just compatible but are in
              harmony with your life's rhythm.
            </p>
            <p>
              Value-Driven Matches: At the heart of every successful relationship are shared values. Whether it’s
              cultural traditions, family values, or life aspirations, we ensure that the matches we propose are aligned
              with what you hold dear. This alignment is the cornerstone of a strong, enduring partnership.
            </p>
            <p>Global Reach, Cultural Insight: Love knows no boundaries, and neither do we. Whether you’re in Canada,
              India, or anywhere in between, our platform connects you with like-minded individuals across the globe.
              With a deep understanding of diverse cultural nuances, we bridge the gap between tradition and
              contemporary lifestyles</p>
            <p>Discreet and Secure: We value your privacy and handle every interaction with the utmost discretion.
              Your journey with us is secure, confidential, and respected at every step. We believe that trust is the
              foundation of all relationships, including the one you have with us.</p>
            <h4 class="fw-bold text-danger mt-4 mt-sm-5">Our Community: A Network of Love and Support</h4>
            <p>
              Eternal Strings is not just about finding a partner; it’s about being part of a community that supports
              and celebrates love in all its forms. Our platform brings together individuals who are serious about
              building a life together, supported by families who share in the joy of this journey.
            </p>
            <p>
              We offer a range of resources, from expert advice on relationship building to cultural insights and
              wedding
              planning tips, ensuring that you are well-prepared for every step of your journey.
            </p>

            <h4 class="fw-bold text-danger mt-4 mt-sm-5">Join Us: Your Story Begins Here</h4>
            <p>Every great love story begins with a single step. Take that step with Eternal Strings, and let us help
              you find the one who will walk with you through every chapter of life.</p>
            <p>Sign up today and embark on a journey of love, commitment, and happiness. Your perfect match is waiting,
              and together, we will create a story that is uniquely yours.</p>


            <!-- <div class="col-12"> -->
            <div class="about-descriptions text-center mt-">
              <!-- <h2 class="text-center">About Us</h2> -->
              <p class="text-start "><b>Discover the joy of a relationship built on values, trust, and
                  a
                  shared
                  vision for the future. Join Eternal Strings and start your journey towards a lifetime of love and
                  happiness.<a href="javascript:void(0);" routerLink="/registerDone" *ngIf="!this.isLoggedIn"> Sign-Up
                    Now! </a> </b></p>
              <!-- <div class="seperator-line mx-auto centered mb-4"></div> -->
            </div>
            <!-- </div> -->

          </div>
        </div>
      </section>
    </div>
  </div>

  <div class="py-5 bg-white">
    <div class="container">
      <div class="row">
        <!--  about-details  -->
        <div class="offset-xl-2 col-xl-8 offset-lg-2 col-lg-8 col-md-12 col-sm-12 col-12 mb60 ">
          <div class="about-descriptions text-center">

            <h2 class="text-center">A Vision Beyond Your Dreams</h2>
            <div class="seperator-line mx-auto centered mb-4"></div>
            <p class="plan-sub-heading lead">Your perfect life partner is within your reach. Determine your preferences
              and we make it a reality. Dreams can come true.
            </p>
          </div>
        </div>
        <div class="col-12 px-0 text-center position-relative overflow-hidden">
          <img src="assets/images/about-img-5.webp" alt="image" title="Image" class="w-100 img-fluid">
        </div>

      </div>

    </div>
  </div>

  <section class="bg-grey pt-5 pb-2">
    <div class="container py-5">
      <div class="row row-cols-1 row-cols-md-2 g-4">
        <div class="col">
          <div
            class="card h-100 shadow-sm rounded-5 border border-1 border-danger-rm cursor-pointer-rm p-4 text-center card-hover-4-rm">

            <div class="contact-us-icon rounded-circle mx-auto mb-4 center">
              <i class="fa fa-cubes fa-2x text-center" aria-hidden="true"></i>
            </div>
            <h3>Our Mission</h3>
            <p>Use technology to enrich peoples lives with love, purpose, and find their life partner.</p>

          </div>
        </div>
        <div class="col">
          <div
            class="card h-100 shadow-sm rounded-5 border border-1 border-danger-rm cursor-pointer-rm p-4 text-center card-hover-4-rm">

            <div class="contact-us-icon rounded-circle mx-auto mb-4 center">
              <i class="fas fa-edit fa-2x text-center"></i>
            </div>
            <h3>Our Vision</h3>
            <p>To give everyone the opportunity to connect and create long lasting relationships.</p>

          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="call-out" class data-bs-toggle="modal" data-bs-target="#planningModal">
    <div class="parallax"
      style="background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('assets/images/banner-5-v1.jpg');">
      <div class="overlay-bg"></div>
      <div class="container text-center">
        <div class="call-out-dtl">
          <h2 class="call-out-heading text-white mb-4"> Start Planning Today </h2>
        </div>
      </div>
    </div>
  </section>
</div>

<!-- start today plannin madal -->
<div class="modal fade" id="planningModal" tabindex="-1" aria-labelledby="planningModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content mx-auto mx-width-400 rounded-10 position-relative overflow-hidden">
      <div class="modal-header text-white bg-maroon border-0 pt-3 mb-3 pb-1">
        <img src="assets/images/second_main_logo_webp.webp" class="mx-auto text-start"
          alt="Description Eternalstrings LOGO" title="Eternalstrings LOGO" width="130px">
        <button type="button" class="btn-close position-absolute top-0 right-0 mt-2 me-2" id="closeplanning"
          data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body p-0">
        <div class="col-12 px-0">
          <div class="mb-3 px-3">
            <h1 class="text-danger mt-2 text-center">Coming soon...</h1>
          </div>
          <div class="row mx-0">
            <div class="col-12 mt-3 py-2 bg-maroon w-100">
              <p class="text-center mb-0 fw-bold slide-10"> <a href="javascript:void(0);" routerLink="/home"
                  class="text-light" id="closeWelcomeModal" data-bs-dismiss="modal" aria-label="Close"><i
                    class="fas fa-long-arrow-alt-left"></i> Go to eternalstrings.com</a> </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<button style="display:none;" id="openModal2" data-bs-target="#deleteModal1" data-bs-toggle="modal"></button>
<!--compare modal modal -->
<div class="modal fade" id="deleteModal1" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true"
  data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
    <div class="modal-content position-relative">

      <div class="modal-body text-center p-4">
        <button type="button" class="btn btn-danger position-absolute top-0 right-0 m-2 center w-30-h-30"
          data-bs-dismiss="modal" aria-label="Close">
          <i class="fas fa-times fs-5"></i>
        </button>

        <img src="assets/images/second_main_logo_webp.webp" alt="Description Eternalstrings LOGO"
          title="Eternalstrings LOGO" width="200" class="mb-6">
        <div href="javascript:void(0)" routerLink="/registerDone">

          <h4 class="mt-2"> Sign Up for free</h4>
          <!-- <br> -->
          <h5 class=" ">Start finding your life partner</h5>
        </div>
      </div>

    </div>
  </div>
</div>
<app-footer></app-footer>
