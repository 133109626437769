<header class="navbar-dark bg-transparent p-0" (scroll)="onscroll()" [ngClass]="navbarFixed?'fixed':'noFixed'"
  *ngIf="!bioData">
  <div class="px-3 max-auto max-width-1200">
    <nav class="navbar navbar-expand-lg">
      <a class="navbar-brand scroll-logo mr-5" href="javascript:void(0);" routerLink="/Home"><img
          src="assets/images/second_main_logo.svg" alt=""></a>

      <div class="form-inline ms-auto pe-3 d-block d-md-none">

        <div class="d-flex align-items-center">
          <div class="width-150 d-inline-block me-2 d-none d-sm-block">
            <p class="my-auto fw-bold fs-2 p-sm text-light"> <small class="p-sm d-block mb-1"> Hello,
              </small> {{fullName}} </p>
          </div>

          <button style="display:none;" id="openModal111" data-bs-target="#upgradeModal111"
            data-bs-toggle="modal"></button>
          <div class="dropdown d-flex align-items-center">
            <div class="cursor-pointer w-50-h-50 rounded-circle">
              <img *ngIf="!havePicture" class="rounded-circle image-cover overflow-hidden"
                src="assets/images/real-wedding-img-1.jpg" alt>
              <img *ngIf="havePicture" class="rounded-circle image-cover overflow-hidden" [src]="profileSRC" alt>

            </div>
            <span class="btn dropdown-toggle fs-3 px-2 text-white" type="button" id="dropdownMenuButton1"
              data-bs-toggle="dropdown" aria-expanded="false">
            </span>

            <ul class="dropdown-menu overflow-hidden" aria-labelledby="dropdownMenuButton1">
              <li><a class="dropdown-item slide-10 text-danger" href="javascript:void(0)" (click)="backToStep()">
                  <i class="fas fa-user me-2"></i> My Profile</a></li>
              <li><a class="dropdown-item slide-10 text-danger" href="javascript:void(0)" routerLink="/setting"><i
                    class="fas fa-cog me-2"></i>
                  Account Settings</a></li>
              <li><a class="dropdown-item slide-10 text-danger" href="javascript:void(0)" (click)="signOut()"><i class="fas
                                            fa-sign-out-alt me-2 fs-5 "></i>Logout</a>
              </li>

            </ul>
          </div>
        </div>

      </div>
      <button class="navbar-toggler border border-2 border-white btn p-1 " type="button" data-bs-toggle="collapse"
        data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false"
        aria-label="Toggle navigation" id="closeNav">
        <i class="fas fa-bars text-white fs-3"></i>
      </button>
      <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <ul class="navbar-nav m-auto">
          <li class="nav-item">
            <a class="nav-link mx-2 text-white" routerLinkActive="active" routerLink="/Home"> Home</a>
          </li>

          <ng-container *ngIf="register_id >= 7 ">
            <!-- <ng-container> -->
            <li class="nav-item">
              <a class="nav-link mx-2 text-white" [ngClass]="{'active': currentPage == 'about'}"
                routerLink="/about">About
                Us</a>
            </li>

            <li class="nav-item">
              <a class="nav-link mx-2 text-white" [ngClass]="{'active': currentPage == 'dashboard'}"
                routerLink="/myProfile">My
                Dashboard</a>
            </li>
            <li class="nav-item">
              <a class="nav-link mx-2 text-white" [ngClass]="{'active': currentPage == 'matches'}"
                routerLink="/matches">Matches</a>
            </li>
            <li class="nav-item">
              <a class="nav-link mx-2 text-white" [ngClass]="{'active': currentPage == 'communications'}"
                routerLink="/communications/comunication-inbox">
                Communications </a>
            </li>
            <li class="nav-item">
              <a class="nav-link mx-2 text-white" [ngClass]="{'active': currentPage == 'chat'}" routerLink="/chat">
                Chat</a>
            </li>
            <ng-container >
              <!-- *ngIf=" urlchek=='https://demoprojects.itsabacus.net/2757_JubWeMet/' " -->
              <!-- urlchek=='http://localhost:4200/'  || -->
            <li class="nav-item">
              <a class="nav-link mx-2 text-white" [ngClass]="{'active': currentPage == 'blog'}" routerLink="/blog">
                Blog</a>
            </li>
            </ng-container>

          </ng-container>
        </ul>
        <div class="form-inline my-2 my-lg-0 d-none d-md-block">

          <div class="d-flex align-items-center">
            <div class="w-auto d-inline-block me-2">
              <p class="my-auto fw-bold font-size-1-rem text-light"> <small class="p-sm d-block mb-1">
                  Hello, </small> {{fullName}}</p>
            </div>

            <div class="dropdown d-flex align-items-center">
              <div class="cursor-pointer1 w-50-h-50 rounded-circle">
                <img *ngIf="!havePicture" class="rounded-circle image-contain overflow-hidden"
                  src="assets/images/men-avtar-2.svg" alt>
                <img *ngIf="havePicture" class="rounded-circle image-cover overflow-hidden" [src]="profileSRC" alt>
              </div>
              <span class="btn dropdown-toggle fs-3 px-2 text-white" type="button" id="dropdownMenuButton1"
                data-bs-toggle="dropdown" aria-expanded="false">
              </span>

              <ul class="dropdown-menu overflow-hidden" aria-labelledby="dropdownMenuButton1">
                <li><a class="dropdown-item slide-10 text-danger" href="javascript:void(0)" (click)="backToStep()"><i
                      class="fas
                                            fa-user me-2"></i>
                    My Profile</a></li>
                <li><a class="dropdown-item slide-10 text-danger" href="javascript:void(0)" routerLink="/setting"><i
                      class="fas fa-cog
                                            me-2"></i>
                    Account Settings</a></li>
                <li><a class="dropdown-item slide-10 text-danger" href="javascript:void(0)" (click)="signOut()"><i
                      class="fas
                                            fa-sign-out-alt me-2 fs-5 "></i>Logout</a>
                </li>
              </ul>
            </div>
          </div>

        </div>
      </div>
    </nav>
  </div>
</header>

<div class="modal fade" id="upgradeModal111" #upgradeModalForClose tabindex="-1" aria-labelledby="upgradeModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
    <div class="modal-content position-relative">

      <div class="modal-body text-center p-4">
        <button type="button" class="btn btn-danger position-absolute top-0 right-0 m-2 center w-30-h-30"
          data-bs-dismiss="modal" aria-label="Close">
          <i class="fas fa-times fs-5"></i>
        </button>

        <img src="assets/images/logo.png" alt="" width="200" class="mb-3">

        <p class="p-sm m-2">
          <!-- Upgrade Now to start communicating with {{displayName}} -->
          <strong> You need to complete your 7 Register Steps.</strong>
          <!-- // You need to complete your 7 Register Steps. -->
        </p>
        <p class="p-sm mb-4">
          <!-- Upgrade Now to start communicating with {{displayName}} -->
          <strong> Please click 0K to continue.</strong>
          <!-- // You need to complete your 7 Register Steps. -->
        </p>

        <button (click)="backOnRealstep()" class="btn btn-danger rounded-pill" data-bs-dismiss="modal"
          aria-label="Close">OK </button>
      </div>

    </div>
  </div>
</div>