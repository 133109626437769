<div class="w-100 bg-maroon">
  <app-header *ngIf="!isLoggedIn"></app-header>
</div>
<div class="content mt-4 mb-5">

  <h2 class="text-center py-4 marginefaq">FAQ</h2>

  <div class="container">
    <div class="row">
      <div class="col-md-3 mb-0 mb-md-0 position-relative col-sm-12 col-12">
        <div class="sidebar position-sticky">
          <div class="widget widget-search">
            <!-- widget-search -->
            <h3 class="widget-title">Search</h3>
            <!-- ({{searchCount}}) -->
            <div class="input-group mb-3">
              <input type="text" class="w-100 border rounded-lg py-1 px-2 ps-2 pe-4" placeholder="Search Here"
                aria-label="search here" aria-describedby="button-addon2" [(ngModel)]="search"
                (keyup)="handleKeyboardEvent($event)">
              <div class="input-group-append position-absolute top-50 start-100 translate-middle">
                <span *ngIf="search == ''"> <i class="fas fa-search searchButton"
                    (click)="Search()"></i></span>
                <button *ngIf="search != ''" (click)="clearSearch()" class="searchButton btn btn-small p-0 fw-semibold"
                  type="button" id="button-addon2">X</button>
              </div>
            </div>
          </div>
          <div class="widget widget-category mb-0">
            <h3 class="widget-title">Categories</h3>
            <!-- ({{totalCategory}}) -->
            <ul class="">
              <li class="slide-10" [ngClass]="category_id == 0 ? 'active' : ''"><a href="javascript:void(0);"
                  (click)="filterByCategory(0,'all')"><i class="fa fa-arrow-right" aria-hidden="true"></i>
                  All Categories <strong class='ml-1'>({{this.TotalCount}})</strong></a>
                <!-- ({{totalCategory }}) -->
              </li>
              <ng-container *ngFor="let category of categoryList; index as i">
                <li class="slide-10" [ngClass]="category_id == category.id ? 'active' : ''"><a
                    href="javascript:void(0);" (click)="filterByCategory(category.id,category.name)"><i
                      class="fa fa-arrow-right" aria-hidden="true"></i>
                    {{category.name}} <strong *ngIf="category.category_count> 0"
                      class='ml-1'>({{category.category_count}})</strong></a></li>
              </ng-container>
            </ul>
          </div>

        </div>
      </div>
      <div class="col-md-9 col-12 mt-5" *ngIf="TotalRecord==0">
        <!-- <p class="text-center mt-3"> <strong> Coming soon!!</strong> </p> -->
        <!-- <p class="text-center mt-3"> <strong> NO FAQs Available!!</strong> </p> -->
        <h4 class="text-center fw-bold mt-3">No FAQs Available</h4>
        <!-- text-danger -->
      </div>
      <div class="col-md-9 col-sm-12 col-12">
        <div class="row">
          <div class="col-md-12 col-sm-12 col-12">
            <div class="accordion" id="accordionExample">

              <div class="accordion" id="accordionExample">

                <div class="accordion-item" *ngFor="let faqdetails of faqList; index as i">
                  <h2 class="accordion-header" id="heading{{i}}">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      [attr.data-bs-target]="'#collapse' + i"
                      [attr.aria-expanded]="i === defaultOpenIndex ? 'true' : 'false'"
                      [attr.aria-controls]="'collapse' + i" [ngClass]="{'collapsed': i !== defaultOpenIndex}">
                      <!-- {{i + 1 + pageAdd}}. {{faqdetails.question}} -->
                      <span class="limited-width" [innerHTML]="(i + 1 + pageAdd) + '. ' + faqdetails.question"></span>
                    </button>
                  </h2>
                  <div [id]="'collapse'+i" class="accordion-collapse collapse" [attr.aria-labelledby]="'heading' + i"
                    data-bs-parent="#accordionExample" [ngClass]="{'collapsed': i === defaultOpenIndex}">
                    <!-- [ngClass]="{'show': i === defaultOpenIndex}"  for open-->
                    <div class="accordion-body">
                      <!-- {{faqdetails.answer}} -->
                      <!-- <span [innerHTML]="faqdetails.answer"></span> -->

                      <span class="limited-width" style="display: flow-root;"   [innerHTML]="faqdetails.answer1"></span>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>


        </div>
      </div>

    </div>
  </div>
  <div class="row m-0 mt-3 justify-content-end" *ngIf="TotalRecord> 8">
    <div class="col-md-5 col-8 justify-content-center">
      <mat-paginator #paginator [pageSizeOptions]="[8, 16, 50, 100]" [length]="TotalRecord" showFirstLastButtons
        aria-label="Select page of periodic elements" (page)="handlePage($event)" [pageSize]="pageSize"
        [hidePageSize]="hidePageSize">
      </mat-paginator>
    </div>
    <div class="col-md-2 col-4 text-center" style="text-align:start; padding-top:20px;">
      <p class="mat-paginator-range">Page {{currentPage}} of {{lastPage}}</p>
    </div>
  </div>
</div>
<app-footer></app-footer>