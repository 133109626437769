<section class="px-3 mt-3 container min_content_height_178 mt-120">

  <div class="row position-relative">
    <div class="col-12 col-md-4 col-lg-3 mb-3 mb-md-0">
      <div class="nav flex-column nav-pills p-3 bg-white border border-1 rounded-1 position-sticky z-index-60 top-90"
        id="v-pills-tab" role="tablist" aria-orientation="vertical">

        <button [ngClass]="(tabAleart!='aleart' && tabHide!='hideProfile' )?' active':''"
          class="btn btn-outline-danger mb-2 " id="v-pills-home-tab" data-bs-toggle="pill"
          data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home"
          (click)="getTabInfo($event)" aria-selected="true">Account Settings</button>

        <button *ngIf="!blankUserDetail && registerDone" class="btn btn-outline-danger mb-2" id="v-pills-profile-tab"
          data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab"
          aria-controls="v-pills-profile" (click)="getTabInfo($event)" aria-selected="false">Privacy Options</button>

        <button *ngIf="!blankUserDetail && registerDone" class="btn btn-outline-danger mb-2"
          [ngClass]="(tabAleart=='aleart')?'active':''" id="OtherAccountSetting-tab" data-bs-toggle="pill"
          data-bs-target="#OtherAccountSetting" type="button" role="tab" aria-controls="OtherAccountSetting"
          (click)="getTabInfo($event)" aria-selected="false">Alert
          settings</button>

        <button *ngIf="!blankUserDetail && registerDone" class="btn btn-outline-danger mb-2" id="v-pills-hide-tab"
          data-bs-toggle="pill" data-bs-target="#v-pills-hide" type="button" role="tab" aria-controls="v-pills-hide"
          (click)="getTabInfo($event)" aria-selected="false" [ngClass]="(tabHide=='hideProfile')?'active':''">Hide
          Profile</button>

        <button class="btn btn-outline-danger mb-2" id="v-pills-messages-tab" data-bs-toggle="pill"
          data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages"
          (click)="getTabInfo($event)" aria-selected="false">Delete Profile or Account</button>

        <button class="btn btn-outline-danger" *ngIf="registerDone" id="v-pills-plans-tab" data-bs-toggle="pill"
          data-bs-target="#v-pills-plans" type="button" role="tab" aria-controls="v-pills-plans"
          (click)="getTabInfo($event)" aria-selected="false">My plans</button>

      </div>
    </div>
    <div class="col-12 col-md-8 col-lg-9 tab-content rounded-3 tab-pane" id="v-pills-tabContent">

      <!------------------------------------Account settings--------------------------------------------->
      <div [ngClass]="(tabAleart!='aleart' && tabHide!='hideProfile' )?'show active':''" class="tab-pane fade "
        id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">

        <div class="card mb-1">
          <div class="row card-body">
            <h5 class="fw-bold">My Account</h5>

            <div class="col-12 py-0 mt-1 col-md-3">
              <label for="primaryEmail" id="primaryEmail" class="fw-bold ">Primary Email Id </label>
            </div>
            <div class="col-12 col-md-9 py-0">
              <!-- : shah.tarun500@gmail.com -->
              <p *ngIf=" userData?.email">
                : {{userData?.email}}
              </p>
              <p [ngClass]="(!userData)?'mb-1 blank-box-communication animated-background-name':''"
                *ngIf=" !userData?.email"> &nbsp;
              </p>
            </div>

            <div class="col-12 py-0 col-md-3 my-auto">
              <label for="primaryPhone" id="primaryPhone" class="fw-bold ">Primary Phone Number </label>
            </div>
            <div class="col-12 col-md-9 py-0">
              <!-- : 76926585658 -->
              <p *ngIf=" userData?.mobileNo &&  userData?.countryNumberCode">
                : {{ userData?.countryNumberCode }}-{{userData?.mobileNo}}
              </p>
              <p [ngClass]="(!userData)?'mb-1 blank-box-communication animated-background-name':''"
                *ngIf=" !userData?.mobileNo &&  !userData?.countryNumberCode"> &nbsp;
              </p>

            </div>

            <p class="mb-3 mt-2 text-muted"> <strong>Note:</strong> Above Details cannot be changed</p>

            <form id=contact [formGroup]="contactForm">
              <div class="row">

                <div class="col-12 py-2 col-md-3 my-auto">

                  <label for="MobileEdit" class="form-label mb-0 fw-bold">Alternate Email Id</label>
                </div>

                <div class="col-10 col-md-6 py-2">
                  <input type="email" class="form-control" id="email" *ngIf="isEditContactDetail"
                    [formControlName]="'alternate_email'" [readonly]="!isEditContactDetail" [ngClass]="{
                    'is-invalid': !submitted &&
                    g.alternate_email.errors }" (keyup)="emailCheck($event)" />
                  <div *ngIf="!emailValid" class="form-label mb-2 py-1 fw-bold text-danger">Email must be a valid email
                    address</div>
                  <div *ngIf="!submitted && g.alternate_email && g.alternate_email.errors" class="invalid-feedback">

                  </div>

                  <p class="mb-0 text-muted text-break"
                    *ngIf="!isEditContactDetail && userDetailsSetting?.alternate_email">: {{
                    userDetailsSetting?.alternate_email
                    }}
                  </p>
                  <p class="mb-0 blank-box-communication animated-background-name "
                    *ngIf=" !isEditContactDetail && loaderData"> &nbsp;
                  </p>
                  <p class="mb-0 text-muted text-break"
                    *ngIf="!isEditContactDetail && !userDetailsSetting?.alternate_email">
                    &nbsp; </p>
                </div>

                <div class="col-2   mb-1 text-end">
                  <a href="javascript:void(0);" class="btn btn-sm btn-danger px-1 py-0" (click)="editContactDetail()"
                    *ngIf="!isEditContactDetail"> Edit</a>
                </div>
              </div>

              <div class="row">
                <div class="col-12 d-flex align-items-center justify-content-between mb-1">
                </div>
                <div class="col-12 py-2 col-md-3 my-auto ">

                  <label for="ContactPersonEdit" class="form-label mb-0 fw-bold">Alternate Phone Number</label>
                </div>
                <div class="col-12 col-md-6 py-2">
                  <div class="row" *ngIf="isEditContactDetail">
                    <!-- country code  -->
                    <div class="col-12 col-md-4 mb-md-3 pe-md-0">
                      <ng-select [items]="this.countries" bindLabel="fullnumber" bindValue="country_code"
                        [formControlName]="'countryCode'" [(ngModel)]="phoneCode" placeholder="Select Country Code"
                        dropdownPosition="auto" [virtualScroll]="true" (change)="OnchangeCountryCode($event)">
                        <ng-template ng-label-tmp let-item="item">
                          <img height="20" width="20"
                            src="assets/images/flag/Flag_of_{{item.country_name | replaceSpaceWithUnderscore}}.svg"
                            alt="" style="margin-right:5px;" />
                          <b> {{item.country_name}}&nbsp;</b>{{item.country_code}}
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-index="index">
                          <img height="20" width="20"
                            src="assets/images/flag/Flag_of_{{item.country_name | replaceSpaceWithUnderscore}}.svg"
                            alt="" style="margin-right:5px;" />
                          <b> {{item.country_name}}&nbsp;</b>{{item.country_code}}
                        </ng-template>
                      </ng-select>

                      <div *ngIf="submitted && g.countryCode.errors">
                        <div class="form-label mb-2 py-1 fw-bold text-danger">Country code is required</div>
                      </div>

                    </div>
                    <!-- country code  -->
                    <!-- mobile number  -->
                    <div class="col-12 col-md-8">
                      <input type="text" class="form-control" id="phonenumber" placeholder="Enter"
                        [formControlName]="'alternate_mobile_no'" [readonly]="!isEditContactDetail" [ngClass]="{ 'is-invalid':
              submitted && g.alternate_mobile_no.errors }" (input)="keyPressNumeric($event)"
                        (paste)="pasteNumeric($event)" autocomplete="" maxlength="15">
                      <div *ngIf="submitted && g.alternate_mobile_no.errors">
                        <div *ngIf="g.alternate_mobile_no.errors.required">
                          <div class="form-label mb-2 py-1 fw-bold text-danger">Mobile number is required</div>
                        </div>
                        <div *ngIf="g.alternate_mobile_no.errors.minlength">
                          <div class="form-label mb-2 py-1 fw-bold text-danger">Mobile Number must be at least 6
                            characters</div>
                        </div>
                        <div *ngIf="g.alternate_mobile_no.errors.maxlength">
                          <div class="form-label mb-2 py-1 fw-bold text-danger">Mobile Number must be at most 15
                            characters</div>
                        </div>
                      </div>
                      <div *ngIf="!submitted">
                        <div *ngIf="minNumberLength ">
                          <div class="form-label mb-2 py-1 fw-bold text-danger">Mobile Number must be at least 6
                            characters</div>
                        </div>
                        <div *ngIf="maxNumberLength">
                          <div class="form-label mb-2 py-1 fw-bold text-danger">Mobile Number must be at most 15
                            characters</div>
                        </div>
                      </div>
                    </div>
                    <!-- mobile number  -->
                  </div>
                  <p class="mb-0 text-muted text-break" *ngIf="
                    !isEditContactDetail && userDetailsSetting?.alternate_mobile_no">:
                    {{userDetailsSetting?.alternate_mobile_no}}</p>
                  <p class="mb-0 blank-box-communication animated-background-name "
                    *ngIf=" !isEditContactDetail && loaderData"> &nbsp;
                  </p>
                  <p class="mb-0 text-muted text-break"
                    *ngIf="!isEditContactDetail && !userDetailsSetting?.alternate_mobile_no"> &nbsp; </p>
                </div>
              </div>

              <div class="col-12" *ngIf="isEditContactDetail">
                <button type="button" class="me-2 btn btn-sm btn-danger" [disabled]="contactForm.invalid"
                  (click)="saveAccount()">Save</button>
                <button type="button" class="btn btn-light btn-sm border border-1 border-danger mx-2 shadow-sm"
                  (click)="disabled()" *ngIf="isEditContactDetail">Cancel</button>
              </div>
            </form>

          </div>
        </div>

        <div class="card mb-1">
          <div class="row card-body">
            <h5 class="fw-bold">Change Password</h5>

            <div class="col-6 py-2">
              <h6 class="mb-0 fw-bold">Password </h6>
            </div>
            <div class="col-6 py-2 text-end">
              <a href="javascript:void(0);" class="btn btn-sm btn-danger px-1 py-0" *ngIf="disabledBox"
                (click)=enableBox()> Edit</a>
            </div>
            <form #resetPasswordNgForm="ngForm" [formGroup]="resetPasswordForm">
              <div class="col-12 col-md-6 pb-2">
                <label for="NewPassword" id="phone" class="fw-bold ">New Password <span class="text-danger">
                    *</span></label>
                <div class="position-relative">
                  <input [type]="showPassword ?'text':'password'" class="form-control"
                    [attr.disabled]="disabledBox?'':null" name="phone" id="NewPassword" required
                    [formControlName]="'newPassword'" (keyup)="validatePassword($event)" (click)="matchPassword($event)"
                    maxlength="15" (paste)="onPaste($event)">
                  <div class="position-absolute eye-btn right-0 text-danger" (click)="showHidePassword()">
                    <div *ngIf="showPassword"><i class="fa fa-eye"></i></div>
                    <div *ngIf="!showPassword"><i class="fa fa-eye-slash"></i></div>
                  </div>
                </div>
              </div>
              <div class="form-label mb-2 py-1 fw-bold text-danger">{{errormsg}}</div>
              <div class="w-100"></div>

              <div class="col-12 col-md-6 pb-2">
                <label for="NewPassword" id="phone" class="fw-bold ">Confirm New Password <span class="text-danger">
                    *</span></label>
                <div class="position-relative">
                  <input [type]="showPassword2 ?'text':'password'" class="form-control"
                    [attr.disabled]="disabledBox?'':null" name="phone" id="confirmPassword" required
                    [formControlName]="'ConfirmPassword'" (keyup)="matchPassword($event)" (paste)="onPaste($event)">
                  <div class="position-absolute eye-btn right-0 text-danger" (click)="showHidePassword2()">
                    <div *ngIf="showPassword2"><i class="fa fa-eye"></i></div>
                    <div *ngIf="!showPassword2"><i class="fa fa-eye-slash"></i></div>
                  </div>
                </div>
                <div class="form-label mb-2 py-1 fw-bold text-danger" *ngIf="!passmatch"> <span
                    *ngIf="showPasswordMsg1">Password does not match</span>
                </div>

                <div class="form-label mb-2 py-1 fw-bold text-danger"> <span>{{errmsg1}}</span>
                </div>
              </div>
              <div class="col-12" *ngIf="!disabledBox">
                <button type="button" class="me-2 btn btn-sm btn-danger shadow-sm border border-1"
                  (click)="resetPasswordDone()" [disabled]="!passwordBtn">Save</button>
                <button type="button" class="btn btn-sm btn-light border border-1 border-danger mx-2 shadow-sm"
                  [attr.disabled]="disabledBox?'':null" (click)="disabled()">Cancel</button>
              </div>
            </form>
          </div>
        </div>

        <div class="card mb-3">

          <div class="card-body">
            <h5 class="fw-bold">2 Step Verification</h5>

            <div class="col-12 my-auto py-2">
              <p class="mb-0 fw-bold">SMS or Email</p>
            </div>
            <form [formGroup]="userSettingOtp2">
              <div class="col-12">
                <div class="form-check ps-0">
                  <input class="checkbox me-2 cursor-pointer" (change)="loginsettingsEmail2($event)" type="radio"
                    id="off_otp" value="false" [formControlName]="'action'">
                  <label class for="off_otp">
                    Off
                  </label>
                </div>
                <div class="form-check ps-0">
                  <input class="checkbox me-2 cursor-pointer" (change)="loginsettingsEmail2($event)" type="radio"
                    id="smsEmail_otp_login" value="true" [formControlName]="'action'">
                  <label class for="smsEmail_otp_login">
                    On
                  </label>
                </div>
              </div>
            </form>
          </div>

        </div>
      </div>

      <!------------------------------------Privacy Options---------------------------------------------->
      <div class="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
        <div class="row">
          <div class="col-12">
            <h5 class="fw-bold">Privacy Options</h5>
          </div>
        </div>

        <div class="card mb-1" *ngIf="!displayname">
          <div class="row card-body">
            <div class="col-4">
              <p class=" mb-0 fw-bold">Display Name</p>
            </div>
            <div class="col-6">
              <p class="mb-0" *ngIf="dNameStr == 'Hide my last name'"
                [ngClass]="(!userDetails?.unique_key)?'blank-box-communication animated-background-name':''">:
                {{userDetails?.first_name}}
                {{userDetails?.last_name | shortName }} </p>
              <p class="mb-0" *ngIf="dNameStr == 'Hide my first name'"
                [ngClass]="(!userDetails?.unique_key)?'blank-box-communication animated-background-name':''">:
                {{userDetails?.last_name}}
                {{userDetails?.first_name | shortName}} </p>
              <p class="mb-0" *ngIf="dNameStr == 'Hide my full name' || this.userDetailsSetting?.display_name == null "
                [ngClass]="(!userDetails?.unique_key)?'blank-box-communication animated-background-name':''">:
                {{userDetails?.unique_key}} </p>
            </div>
            <div class="col-2 text-end">
              <a *ngIf="!displayname" href="javascript:void(0);" class="btn btn-sm btn-danger px-1 py-0"
                (click)="editDisplay()"> Edit</a>
            </div>
          </div>
        </div>
        <form [formGroup]="userSettingForm1" (ngSubmit)="saveUserSettingForm1()">
          <div class="card mb-3" *ngIf="displayname">
            <div class="row card-body">
              <div class="col-12 mb-3">
                <p class=" mb-0 fw-bold">Display Name</p>
              </div>
              <div class="col-6 mb-3">
                <label for="FirstName" id="FirstName" class="fw-bold">First Name</label>
                <input type="text" class=" form-control" name="first_name" id="first_name" value
                  placeholder="First Name" required formControlName="first_name" readonly>
              </div>
              <div class="col-6 mb-3">
                <label for="LastName" id="LastName" class=" fw-bold">Last Name</label>
                <input type="text" class=" form-control " name="last_name" id="last_name" value placeholder="Last Name"
                  required formControlName="last_name" readonly>

              </div>

              <div class="col-12">
                <h6 class="fw-bold">Display Name</h6>
              </div>
              <div class="col-12 mb-3">
                <div class="form-check ps-0">
                  <input class="checkbox me-2 cursor-pointer" type="radio" name="display_name" id="DisplayName11"
                    checked formControlName="display_name" value="Hide my last name">
                  <label class for="DisplayName11">
                    Hide my last name ({{userDetails?.first_name}} {{userDetails?.last_name | shortName }})
                  </label>
                </div>
                <div class="form-check ps-0">
                  <input class="checkbox me-2 cursor-pointer" type="radio" name="display_name" id="DisplayName12"
                    formControlName="display_name" value="Hide my first name">
                  <label class=" " for="DisplayName12">
                    Hide my first name ({{userDetails?.last_name}} {{userDetails?.first_name | shortName}})
                  </label>
                </div>
                <div class="form-check ps-0">
                  <input class="checkbox me-2 cursor-pointer" type="radio" name="display_name" id="DisplayName13"
                    formControlName="display_name" value="Hide my full name">
                  <label class=" " for="DisplayName13">
                    Hide my full name (Displays only Profile ID: {{userDetails?.unique_key}})
                  </label>
                </div>
              </div>
              <div class="col-12">
                <input type="submit" value="Save" class="mx-2 btn btn-danger shadow-sm border border-1">
                <button type="button" class="btn btn-light border border-1 border-danger mx-2 shadow-sm"
                  (click)="cancelDisplay()">Cancel</button>
              </div>
            </div>
          </div>
        </form>

        <div class="card mb-1" *ngIf="!phonNumber">
          <div class="row card-body">
            <div class="col-4">
              <p class="mb-0 fw-bold">Contact Details</p>
            </div>
            <div class="col-6">
              <p class="mb-0" [ngClass]="(loaderData)?'blank-box-communication animated-background-name':''">:
                {{phonNumberstr}}</p>
            </div>
            <div class="col-2 text-end">
              <a *ngIf="!phonNumber" href="javascript:void(0);" id="EditNumberPrivacy"
                class="btn btn-sm btn-danger px-1 py-0" (click)=" editphonNumber()"> Edit</a>
            </div>
          </div>
        </div>
        <form [formGroup]="userSettingForm2">
          <div class="card mb-3" *ngIf="phonNumber">
            <div class="row card-body">
              <div class="col-12">
                <h6 class="fw-bold">Contact Details</h6>
              </div>
              <div class="col-12 mb-3">
                <div class="form-check ps-0">
                  <input class="checkbox me-2 cursor-pointer" type="radio" id="Phone1" value="Only to connected members"
                    formControlName="contact_detail">
                  <label class for="Phone1">
                    Only to connected members
                  </label>
                </div>
                <div class="form-check ps-0">
                  <input class="checkbox me-2 cursor-pointer" type="radio" id="Phone4" value="Only by Permissions"
                    formControlName="contact_detail">
                  <label class for="Phone4">
                    Only by Permissions
                  </label>
                </div>
              </div>
              <div class="col-12">
                <button type="button" class="mx-2 btn btn-danger shadow-sm border border-1"
                  (click)=" saveUserSettingForm2()">Save</button>
                <button type="button" class="btn btn-light border border-1 border-danger mx-2 shadow-sm "
                  (click)=" cancelphonNumber()">Cancel</button>
              </div>
            </div>
          </div>
        </form>
        <!-- email hide  -->

        <div class="card mb-1" *ngIf="!photos">
          <div class="row card-body">
            <div class="col-4">
              <p class=" mb-0 fw-bold">Photos </p>
              <!-- (Cover photo & Profile photo) -->
            </div>
            <div class="col-6">
              <p class="mb-0" [ngClass]="(loaderData)?'blank-box-communication animated-background-name':''">:
                {{photosstr}}</p>
            </div>
            <div class="col-2 text-end">
              <a href="javascript:void(0);" class="btn btn-sm btn-danger px-1 py-0" *ngIf="!photos"
                (click)=" editphotos()"> Edit</a>
            </div>
          </div>
        </div>
        <form [formGroup]="userSettingForm4">
          <div class="card mb-3" *ngIf="photos">
            <div class="row card-body">
              <div class="col-12">
                <p class="fw-bold">Photos </p>
                <!-- (Cover photo & Profile photo) -->
              </div>
              <div class="col-12 mb-3">
                <div class="form-check ps-0">
                  <input class="checkbox me-2 cursor-pointer" type="radio" name="photo_visiblity" id="Photo1"
                    value="Visible to all members" formControlName="photo_visiblity">
                  <label class for="Photo1">
                    <!-- Profile Photo and Cover  -->
                    Visible to all members
                  </label>
                </div>
                <div class="form-check ps-0">
                  <input class="checkbox me-2 cursor-pointer" type="radio" name="photo_visiblity" id="Photo2"
                    value="Visible to connected members" formControlName="photo_visiblity">
                  <label class for="Photo2">
                    Visible to connected members
                  </label>
                </div>
                <div class="form-check ps-0">
                  <input class="checkbox me-2 cursor-pointer" type="radio" name="photo_visiblity" id="Photo4"
                    value="No one" formControlName="photo_visiblity">
                  <label class for="Photo4">
                    No one </label>
                </div>
              </div>
              <div class="col-12">
                <button type="button" class="mx-2 btn btn-danger shadow-sm border border-1"
                  (click)=" saveUserSettingForm4()">Save</button>
                <button type="button" class="btn btn-light border border-1 border-danger mx-2 shadow-sm"
                  (click)=" cancelphotos()">Cancel</button>
              </div>
            </div>

          </div>
        </form>

        <div class="card mb-1" *ngIf="!astro_detail">
          <div class="row card-body">
            <div class="col-4 ">
              <p class=" mb-0 fw-bold">Astro Details</p>
            </div>
            <div class="col-6">
              <p class=" mb-0" [ngClass]="(loaderData)?'blank-box-communication animated-background-name':''">:
                {{dobstr}}</p>
            </div>
            <div class="col-2 text-end">
              <a *ngIf="!astro_detail" href="javascript:void(0);" class="btn btn-sm btn-danger px-1 py-0"
                (click)="editastro_detail()">
                Edit</a>
            </div>
          </div>
        </div>
        <form [formGroup]="userSettingForm5">
          <div class="card mb-3" *ngIf="astro_detail">
            <div class="row card-body">
              <div class="col-12 my-auto py-2">
                <p class=" mb-0 fw-bold">Astro Details</p>
              </div>
              <div class="col-12 py-2">
                <div class="form-check ps-0">
                  <input class="checkbox me-2 cursor-pointer" type="radio" name="astro_detail" id="Birth1"
                    value="Only to connected members" formControlName="astro_detail">
                  <label class for="Birth1">
                    Only to connected members
                  </label>
                </div>
                <div class="form-check ps-0">
                  <input class="checkbox me-2 cursor-pointer" type="radio" name="astro_detail" id="Birth3"
                    value="Only by Permissions" formControlName="astro_detail">
                  <label class for="Birth3">
                    Only by Permissions
                  </label>
                </div>
              </div>
              <div class="col-12">
                <button type="button" class="mx-2 btn btn-danger shadow-sm border border-1"
                  (click)=" saveUserSettingForm5()">Save</button>
                <button type="button" class="btn btn-light mx-2 shadow-sm border border-1 border-danger"
                  (click)="cancelastro_detail()">Cancel</button>
              </div>
            </div>
          </div>
        </form>

        <div class="card mb-1" *ngIf="!viewBy">
          <div class="row card-body">
            <div class="col-4">
              <p class="mb-0 fw-bold"> Viewed By Others </p>
            </div>
            <div class="col-6">
              <p class="mb-0" [ngClass]="(loaderData)?'blank-box-communication animated-background-name':''">:
                {{viewBystr}} </p>
            </div>
            <div class="col-2 text-end">
              <a *ngIf="!viewBy" href="javascript:void(0);" class="btn btn-sm btn-danger px-1 py-0"
                (click)="editviewBy()"> Edit</a>
            </div>
          </div>
        </div>
        <form [formGroup]="userSettingForm6">
          <div class="card mb-3" *ngIf="viewBy">
            <div class="row card-body">
              <div class="col-12 mt-2">
                <h6 class="fw-bold"> Viewed By Others </h6>
              </div>
              <div class="col-12 py-2">
                <div class="form-check ps-0">
                  <input class="checkbox me-2 cursor-pointer" type="radio" name="viewed_by_others" id="DisplayName1"
                    value="Let other Members know that I have visited their Profile."
                    formControlName="viewed_by_others">
                  <label class for="DisplayName1">
                    Let other Members know that I have visited their Profile.
                  </label>
                </div>
                <div class="form-check ps-0">
                  <input class="checkbox me-2 cursor-pointer" type="radio" name="viewed_by_others" id="DisplayName2"
                    value="Do not let other Members know that I have visited their Profile."
                    formControlName="viewed_by_others">
                  <label class=" " for="DisplayName2">
                    Do not let other Members know that I have visited their Profile.
                  </label>
                </div>
              </div>
              <div class="col-12">
                <button type="button" class="btn-sm btn btn-danger" (click)=" saveUserSettingForm6()">Save</button>
                <button type="button" class="btn-sm btn btn-light border border-1 border-danger ms-2 shadow-sm"
                  (click)="cancelviewBy()">Cancel</button>
              </div>
            </div>
          </div>
        </form>

        <div class="card mb-1" *ngIf="!myshortlist">
          <div class="row card-body">
            <div class="col-4">
              <p class="mb-0 fw-bold">My Shortlist</p>
            </div>
            <div class="col-6">
              <p class="mb-0" [ngClass]="(loaderData)?'blank-box-communication animated-background-name':''">:
                {{myshortliststr}} </p>
            </div>
            <div class="col-2 text-end">
              <a *ngIf="!myshortlist" href="javascript:void(0);" class="btn btn-sm btn-danger px-1 py-0"
                (click)="editmyshortlist()"> Edit</a>
            </div>
          </div>
        </div>
        <form [formGroup]="userSettingForm7">
          <div class="card mb-3" *ngIf="myshortlist">
            <div class="row card-body">
              <div class="col-12">
                <h6 class="fw-bold"> My Shortlist</h6>
              </div>
              <div class="col-12 mb-3">
                <div class="form-check ps-0">
                  <input class="checkbox me-2 cursor-pointer" type="radio" name="my_shortlist" id="DisplayName3"
                    value="Let other Members know that I have Shortlisted their Profile."
                    formControlName="my_shortlist">
                  <label class for="DisplayName3">
                    Let other Members know that I have Shortlisted their Profile.
                  </label>
                </div>
                <div class="form-check ps-0">
                  <input class="checkbox me-2 cursor-pointer" type="radio" name="my_shortlist" id="DisplayName4"
                    value="Do not let other Members know that I have Shortlisted their Profile."
                    formControlName="my_shortlist">
                  <label class=" " for="DisplayName4">
                    Do not let other Members know that I have Shortlisted their Profile.
                  </label>
                </div>
              </div>
              <div class="col-12">
                <button type="button" class="btn-sm btn btn-danger" (click)=" saveUserSettingForm7()">Save</button>
                <button type="button" class="btn-sm btn btn-light border border-1 border-danger ms-2 shadow-sm"
                  (click)="cancelmyshortlist()">Cancel</button>
              </div>
            </div>
          </div>
        </form>

        <div class="card mb-1" *ngIf="!myfavourit">
          <div class="row card-body">
            <div class="col-4">
              <p class="mb-0 fw-bold">My Favourite</p>
            </div>
            <div class="col-6">
              <p class="mb-0" [ngClass]="(loaderData)?'blank-box-communication animated-background-name':''">:
                {{myfavouritstr}} </p>
            </div>
            <div class="col-2 text-end">
              <a *ngIf="!myfavourit" href="javascript:void(0);" class="btn btn-sm btn-danger px-1 py-0"
                (click)="editmyfavourit()"> Edit</a>
            </div>
          </div>
        </div>
        <form [formGroup]="userSettingForm8">
          <div class="card mb-3" *ngIf="myfavourit">
            <div class="row card-body">
              <div class="col-12">
                <h6 class="fw-bold"> My Favourite </h6>
              </div>
              <div class="col-12 mb-3">
                <div class="form-check ps-0">
                  <input class="checkbox me-2 cursor-pointer" type="radio" name="my_favourite" id="DisplayName5"
                    value="Let other Members know that I have Favourite their Profile." formControlName="my_favourite">
                  <label class for="DisplayName5">
                    Let other Members know that I have Favourite their Profile.
                  </label>
                </div>
                <div class="form-check ps-0">
                  <input class="checkbox me-2 cursor-pointer" type="radio" name="my_favourite" id="DisplayName6"
                    value="Do not let other Members know that I have Favourite their Profile."
                    formControlName="my_favourite">
                  <label class=" " for="DisplayName6">
                    Do not let other Members know that I have Favourite their Profile.
                  </label>
                </div>
              </div>
              <div class="col-12">
                <button type="button" class="btn-sm btn btn-danger me-2" (click)=" saveUserSettingForm8()">Save</button>
                <button type="button" class="btn-sm btn btn-light border border-1 border-danger shadow-sm"
                  (click)="cancelmyfavourit()">Cancel</button>
              </div>
            </div>
          </div>
        </form>

        <!-- annual income  -->
        <div class="card mb-1" *ngIf="!annualIncome">
          <div class="row card-body">
            <div class="col-4">
              <p class=" mb-0 fw-bold">Annual Income</p>
            </div>
            <div class="col-6">
              <p class=" mb-0" [ngClass]="(loaderData)?'blank-box-communication animated-background-name':''">:
                {{annualIncomestr}} </p>
            </div>
            <div class="col-2 text-end">
              <a *ngIf="!annualIncome" href="javascript:void(0);" class="btn btn-sm btn-danger px-1 py-0"
                (click)="editannualIncome()"> Edit</a>
            </div>
          </div>
        </div>
        <form [formGroup]="userSettingForm9">
          <div class="card mb-3" *ngIf="annualIncome">
            <div class="row card-body">
              <div class="col-4 my-auto py-2">
                <p class="fw-bold">Annual Income</p>
              </div>
              <div class="col-12 mb-3">
                <div class="form-check ps-0">
                  <input class="checkbox me-2 cursor-pointer" type="radio" name="annual_income_visiblity" id="Income1"
                    value="Visible to all members" formControlName="annual_income_visiblity">
                  <label class for="Income1">
                    Visible to all members
                  </label>
                </div>
                <div class="form-check ps-0">
                  <input class="checkbox me-2 cursor-pointer" type="radio" name="annual_income_visiblity" id="Income4"
                    formControlName="annual_income_visiblity" value="Visible to connected members">
                  <label class for="Income4">
                    Visible to connected members
                  </label>
                </div>
                <div class="form-check ps-0">
                  <input class="checkbox me-2 cursor-pointer" type="radio" name="annual_income_visiblity" id="Income2"
                    value="No one" formControlName="annual_income_visiblity">
                  <label class for="Income2">
                    No one
                  </label>
                </div>

                <div class="col-12">
                  <button type="button" class="mx-2 btn btn-danger shadow-sm border border-1"
                    (click)=" saveUserSettingForm9()">Save</button>
                  <button type="button" class="btn btn-light mx-2 shadow-sm border-danger border border-1"
                    (click)="cancelannualIncome()">Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </form>
        <!-- annual income  -->

        <div class="card mb-2" *ngIf="!subscribe">
          <div class="row card-body">
            <div class="col-4">
              <p class="mb-0 fw-bold">Subscribe for Updates</p>
            </div>
            <div class="col-6">
              <p class="mb-0" [ngClass]="(loaderData)?'blank-box-communication animated-background-name':''">:
                {{subscribestr}} </p>
            </div>
            <div class="col-2 text-end">
              <a *ngIf="!subscribe" href="javascript:void(0);" class="btn btn-sm btn-danger px-1 py-0"
                (click)="editsubscribe()"> Edit</a>
            </div>
          </div>
        </div>
        <form [formGroup]="userSettingForm10">
          <div class="card mb-3" *ngIf="subscribe">
            <div class="row card-body">
              <div class="col-12">
                <p class="fw-bold">Subscribe for Updates</p>
              </div>
              <div class="col-12 mb-3">
                <div class="form-check ps-0">
                  <input class="checkbox me-2 cursor-pointer" type="checkbox" formControlName="subscribe_for_update">
                  <label class for="Notifications1">
                    Unsubscribe
                  </label>
                </div>
              </div>
              <div class="col-12">
                <button type="button" class="btn btn-sm btn-danger me-2" (click)="saveUserSettingForm10()">Save</button>
                <button type="button" class="btn btn-sm btn-light shadow-sm border border-1 border-danger"
                  (click)="cancelsubscribe()">Cancel</button>
              </div>
            </div>
          </div>

        </form>

      </div>

      <!------------------------------------Alert account setting---------------------------------------------->
      <div [ngClass]="(tabAleart=='aleart')?'show active':''" class="tab-pane fade" id="OtherAccountSetting"
        role="tabpanel" aria-labelledby="OtherAccountSetting-tab">
        <div class="row">
          <div class="col-12">
            <h5 class="fw-bold">Alert Settings</h5>
          </div>
        </div>

        <div class="row g-3">
          <form [formGroup]="userSettingForm11">

            <div class="col-lg-12">
              <div class="card mb-1">
                <div class="row card-body">
                  <div class="col-9 mt-2">
                    <h6 class="fw-bold"> Frequency of Alerts</h6>
                  </div>
                  <div class="col-3 text-end">
                    <a *ngIf="!Frequency_of_alerts" href="javascript:void(0);" class="btn btn-sm btn-danger px-1 py-0"
                      (click)=" editfrequency_of_alerts()"> Edit</a>
                  </div>

                  <div class="col-12 py-2" *ngIf="!!Frequency_of_alerts">
                    <div class="form-check ps-0">
                      <input class="checkbox me-2 cursor-pointer" type="radio" id="DisplayName21" checked
                        formControlName="frequency_of_alert" value="1">
                      <label class for="DisplayName21">
                        Instantly
                      </label>
                    </div>
                    <div class="form-check ps-0">
                      <input class="checkbox me-2 cursor-pointer" type="radio" id="DisplayName22"
                        formControlName="frequency_of_alert" value="2">
                      <label class=" " for="DisplayName22">
                        End of each day
                      </label>
                    </div>
                    <div class="form-check ps-0">
                      <input class="checkbox me-2 cursor-pointer" type="radio" id="DisplayName23"
                        formControlName="frequency_of_alert" value="3">
                      <label class=" " for="DisplayName23">
                        Every other day
                      </label>
                    </div>
                    <div class="form-check ps-0">
                      <input class="checkbox me-2 cursor-pointer" type="radio" id="DisplayName24"
                        formControlName="frequency_of_alert" value="4">
                      <label class=" " for="DisplayName24">
                        Weekly
                      </label>
                    </div>
                    <div class="form-check ps-0">
                      <input class="checkbox me-2 cursor-pointer" type="radio" id="DisplayName25"
                        formControlName="frequency_of_alert" value="5">
                      <label class=" " for="DisplayName25">
                        Biweekly
                      </label>
                    </div>
                    <div class="form-check ps-0">
                      <input class="checkbox me-2 cursor-pointer" type="radio" id="DisplayName26"
                        formControlName="frequency_of_alert" value="0">
                      <label class=" " for="DisplayName26">
                        No notifications
                      </label>
                    </div>
                  </div>

                  <div class="col-12 py-2" *ngIf="!Frequency_of_alerts">
                    <ul>
                      <li> {{this.frequency_of_alert}} </li>
                    </ul>

                  </div>

                  <div class="col-12">
                    <button *ngIf="!!Frequency_of_alerts" type="button" class="btn-sm btn btn-danger"
                      (click)=" saveUserSettingForm11()">Save</button>
                    <button *ngIf="!!Frequency_of_alerts" type="button"
                      class="btn-sm btn btn-light border border-1 border-danger ms-2 shadow-sm"
                      (click)="cancelUserSettingForm11()">Cancel</button>
                  </div>

                </div>
              </div>

            </div>
          </form>
          <form [formGroup]="userSettingForm12">

            <div class="col-lg-12">
              <div class="card mb-2">
                <div class="row card-body">
                  <div class="col-9 mt-2">
                    <h6 class="fw-bold"> Type of Alerts </h6>
                  </div>
                  <div class="col-3 text-end">
                    <a *ngIf="!Type_of_alerts" href="javascript:void(0);" class="btn btn-sm btn-danger px-1 py-0"
                      (click)=" edittype_of_alerts()"> Edit</a>
                  </div>
                  <div class="col-12 py-2" *ngIf="!!Type_of_alerts">
                    <container>
                      <div class="form-check ps-0" *ngFor="let item of alert_types; index as i ">
                        <input class="checkbox me-2 cursor-pointer" type="checkbox" name="type_of_alerts"
                          [checked]="item.isChecked" id={{i}} formControlName="type_of_alert"
                          (change)="checkboxId($event)" value={{item.id}}>
                        <label class for={{i}}>
                          {{item.alert_name }}
                        </label>
                      </div>
                    </container>
                  </div>

                  <div class="col-12 py-2" *ngIf="!Type_of_alerts">
                    <ol>
                      <li *ngFor="let item1 of this.alert_types_arr; index as i">

                        {{item1}}

                      </li>
                    </ol>
                  </div>

                  <div class="col-12">
                    <button *ngIf="!!Type_of_alerts" type="button" class="btn-sm btn btn-danger"
                      (click)=" saveUserSettingForm12()">Save</button>
                    <button *ngIf="!!Type_of_alerts" type="button"
                      class="btn-sm btn btn-light border border-1 border-danger ms-2 shadow-sm"
                      (click)="cancelUserSettingForm12()">Cancel</button>
                  </div>

                </div>
              </div>

            </div>
          </form>
        </div>

      </div>
      <!------------------------------------Alert account setting---------------------------------------------->
      <!------------------------------------ Hide Profile---------------------------------------------->
      <div [ngClass]="(tabHide=='hideProfile')?'show active':''" class="tab-pane fade" id="v-pills-hide" role="tabpanel"
        aria-labelledby="v-pills-hide-tab">
        <div class="row">
          <div class="col-12">
            <h5 class="fw-bold">Hide Profile</h5>
          </div>
        </div>

        <div class="card">
          <div class="row card-body">
            <div class="col-4 my-auto">
              <p class="mb-0 fw-bold">Hide Profile</p>
            </div>
            <div class="col-6 my-auto">
              <p class="p-sm mb-0 fw-bold" *ngIf="!hideUnhideBtn">Your profile is currently visible</p>
              <p class="p-sm mb-0 fw-bold" *ngIf="hideUnhideBtn">Your profile is currently hidden <sapn
                  *ngIf="tillDate != ''">till {{ tillDate }}</sapn>
              </p>

            </div>
            <div class="col-2 text-end">
              <button type="button" class="btn btn-sm btn-danger border-0" data-bs-toggle="modal"
                data-bs-target="#exampleModal" (click)="checkAccount()"> Edit</button>
            </div>
          </div>
        </div>
      </div>

      <!------------------------------------ Delete Profile---------------------------------------------->
      <div class="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
        <div class="row">
          <div class="col-12">
            <h5 class="fw-bold">Delete Profile or Account</h5>
          </div>
        </div>

        <div class="card mb-2">
          <form [formGroup]="deletProfileAccountForm">
            <div class="row card-body">
              <div class="col-3 my-auto"
                *ngIf="this.userDetails!='' && this.userDetails!=null && this.userDetails.register_done>0 && this.userDetails.profile_for!='Self'  ">

                <div class="form-check ps-0 ">
                  <input class="checkbox me-2 cursor-pointer" (change)="deleteSetting($event)" type="radio"
                    id="delete_profile" value="Delete Profile" [formControlName]="'delete'">
                  <label class for="delete_profile" role="">
                    Delete Profile
                  </label>
                </div>
              </div>

              <div class="col-5 my-auto"
                *ngIf="(this.userDetails!='' && this.userDetails!=null && (this.userDetails.register_done==0 || this.userDetails.profile_for=='Self')) || !!blankUserDetail  ">
                <div class="form-check ps-0 ">
                  <input class="checkbox me-2 cursor-pointer" (change)="deleteSetting($event)" type="radio"
                    id="delete_account" value="Delete Account" [formControlName]="'delete'">
                  <label class for="delete_account" role="">
                    Delete Account
                  </label>
                </div>
              </div>

              <div class="col-6 my-auto" *ngIf="this.deleteBy == 'delete_account'">
                <p class="p-sm mb-0 fw-bold">Delete your Account from Eternal Strings </p>
              </div>

              <div class="col-6 my-auto" *ngIf="this.deleteBy == 'delete_profile'">
                <p class="p-sm mb-0 fw-bold">Delete your Profile from Eternal Strings </p>
              </div>

            </div>
          </form>
        </div>

        <div class="card mb-3" *ngIf="!!this.deleteBy">
          <div class="row mx-0">
            <div class="col-12 my-auto py-2">
              <p class="mb-0 fw-bold" *ngIf="this.deleteBy == 'delete_profile'"> Why have you decided to Delete your
                Eternal Strings Profile? </p>
              <p class="mb-0 fw-bold" *ngIf="this.deleteBy == 'delete_account'"> Why have you decided to Delete your
                Eternal Strings account? </p>
              <p class="mb-0 fw-bold text-danger">Please share your reason with us</p>
            </div>
            <div class="col-12 py-2">
              <div class="form-check ps-0" *ngIf="this.userDetails?.register_done==7">
                <input class="checkbox me-2 cursor-pointer" type="radio" name="Birth" id="deleteAccount1"
                  value="Found match on Eternal Strings?" (change)="deleteReason($event)" data-bs-toggle="modal"
                  href="#exampleModalToggle11">
                <label class for="deleteAccount1" data-bs-toggle="modal" href="#exampleModalToggle11" type=" button">
                  Found match on Eternal Strings?
                </label>
              </div>
              <div class="form-check ps-0">
                <input class="checkbox me-2 cursor-pointer" type="radio" name="Birth" id="deleteAccount2"
                  data-bs-toggle="modal" href="#exampleModalToggle22" value="Found match elsewhere?">
                <label class for="deleteAccount2" type=" button" data-bs-toggle="modal" href="#exampleModalToggle22">
                  Found match elsewhere?
                </label>
              </div>
              <div class="form-check ps-0">
                <input class="checkbox me-2 cursor-pointer" type="radio" name="Birth" id="deleteAccount3"
                  value="Changed your mind?" data-bs-toggle="modal" href="#exampleModalToggle33">
                <label class for="deleteAccount3" type=" button" data-bs-toggle="modal" href="#exampleModalToggle33">
                  Changed your mind?
                </label>
              </div>
              <div class="form-check ps-0" *ngIf="this.userDetails && this.userDetails.is_hidden==0">
                <input class="checkbox me-2 cursor-pointer" type="radio" name="Birth" id="deleteAccount4"
                  value="Not ready yet?" data-bs-toggle="modal" href="#exampleModalToggle44">
                <label class for="deleteAccount4" type=" button" data-bs-toggle="modal" href="#exampleModalToggle44">
                  Not ready yet?
                </label>
              </div>
              <div class="form-check ps-0">
                <input class="checkbox me-2 cursor-pointer" type="radio" name="Birth" id="deleteAccount5"
                  value="Unsatisfactory experience on Eternal Strings?" data-bs-toggle="modal"
                  href="#exampleModalToggle55">
                <label class for="deleteAccount5" type=" button" data-bs-toggle="modal" href="#exampleModalToggle55">
                  Unsatisfactory experience on Eternal Strings?
                </label>
              </div>
              <div class="form-check ps-0">
                <input class="checkbox me-2 cursor-pointer" type="radio" value="Other" (change)="deleteReason($event)"
                  name="Birth" id="deleteAccount" data-bs-toggle="modal" data-bs-target="#exampleModalToggle">
                <label class for="deleteAccount" data-bs-toggle="modal" href="#exampleModalToggle" type=" button">
                  Other Reason.
                </label>
              </div>
            </div>

          </div>

        </div>

        <ng-container class="user_email_verified">
          <div class="row mt-4 " style="display: none;">
            <div class="col-12">
              <h5 class="fw-bold">Deleted Profile history</h5>
            </div>
          </div>
          <div class="card mb-2" style="display: none;">
            <div class="row card-body">
              <div class="col-12 table-responsive">
                <table class="table table-striped">
                  <caption style="display: none;">delete profile histry</caption>
                  <thead>
                    <tr>
                      <th scope="col">No.</th>
                      <th scope="col">User Id </th>
                      <th scope="col">Name</th>
                      <th scope="col">Profile for</th>
                      <th scope="col">Member</th>
                      <th scope="col">Verified</th>
                      <th scope="col">Email</th>
                      <th scope="col">Mobile No.</th>
                      <th scope="col">Expiry Date</th>
                      <th scope="col">Gender</th>
                      <th scope="col">Country</th>
                    </tr>
                  </thead>
                  <tbody *ngIf="deletedProfileList.length> 0">
                    <tr *ngFor="let plan of deletedProfileList; index as i">
                      <th scope="row">{{ i + 1 }}</th>
                      <td>{{plan?.unique_key}}</td>
                      <td>{{plan?.full_name}} </td>
                      <td>{{plan?.profile_for}}</td>
                      <td>{{plan?.package_id }}</td>
                      <td>{{plan?.is_verify_profile_for }}</td>
                      <td>{{plan?.user_email}}</td>
                      <td>{{plan?.end_date}}</td>
                      <td>{{plan?.gender}}</td>
                      <td>{{plan?.living_in_country_name}}</td>
                    </tr>
                  </tbody>
                  <tbody *ngIf="deletedProfileList.length == 0">
                    <tr>
                      <td colspan="12" class="text-center">No deleted profile available</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </ng-container>
      </div>

      <!------------------------------------ My plans---------------------------------------------->
      <div class="tab-pane fade" id="v-pills-plans" role="tabpanel" aria-labelledby="v-pills-plans-tab">
        <div class="row mx-0 my-2 ">
          <div class="col-6 px-0">
            <h5 class="fw-bold">Running plans</h5>
          </div>
          <div class="col-6 px-0 text-right-in">
            <a href="javascript:void(0);" *ngIf="currentActivePlan?.package_id != 3" routerLink="/upgrade"
              class="me-2 btn btn-sm btn-danger">
              Upgrade Plan</a>
            <a href="javascript:void(0);" *ngIf="remainingDays >= 0 && remainingDays <= 30 " routerLink="/renewal"
              class="me-2 btn btn-sm btn-danger">
              Renew Plan</a>


          </div>
        </div>
        <div class="card mb-2">
          <div class="row card-body">
            <div class="col-12 table-responsive">
              <table class="table table-striped">
                <caption style="display: none;">Renew Plan</caption>
                <thead>
                  <tr>
                    <th scope="col">No.</th>
                    <th scope="col">Plan name </th>
                    <th scope="col">Amount</th>
                    <th scope="col">Plan duration</th>
                    <th scope="col">Purchase date</th>
                    <th scope="col">start date</th>
                    <th scope="col">end date</th>
                    <th scope="col">Remaining days</th>
                    <th scope="col">Status</th>
                    <!-- <th scope="col"> Extended By</th> -->

                  </tr>
                </thead>
                <tbody *ngIf="active_plan.length> 0">
                  <tr *ngFor="let plan of active_plan; index as i">
                    <th scope="row">{{ i + 1 }}</th>
                    <td>{{plan?.package_name}}</td>
                    <td>{{plan?.country_currency}} {{plan?.package_amount/100}}</td>
                    <td>{{plan?.subscription_plan}}</td>
                    <td>{{plan?.payment_date | date:'mediumDate'}}</td>
                    <ng-container *ngIf="!!plan?.active_plan_id">


                      <td>{{plan?.start_date | date:'mediumDate'}}</td>
                      <td>{{plan?.end_date | date:'mediumDate'}}</td>

                      <td>
                        <span *ngIf="plan?.plan_remaining_days > 0" >{{plan?.plan_remaining_days}}
                         days <br /> </span>

                         <span *ngIf="plan?.plan_remaining_days <= 0" >0
                          days <br /> </span>
                        <small *ngIf="!!plan?.active_plan_id">(Plan expiry : {{plan?.expiry_date}})</small>
                      </td>
                    </ng-container>
                    <td>
                      <button *ngIf="!plan?.active_plan_id" type="button" class="me-2 btn btn-sm btn-danger"
                        (click)="activePlanNow(plan?.user_id,plan?.package_id,plan?.id)"
                        style="display:none">Active</button>
                      <!-- <button *ngIf="!!plan?.active_plan_id" type="button"
                        class="btn btn-light btn-sm border border-1 border-danger shadow-sm">Active</button> -->

                        <span *ngIf="!!plan?.active_plan_id" > Active </span>
                        
                    </td>

                  </tr>
                  <ng-container *ngIf="plan_extended_history.length>0">
                    <tr *ngFor="let plan of plan_extended_history; index as i">
                      <th scope="row">{{ active_plan.length + 1 + i }}</th>
                      <td>
                        <span *ngIf="plan?.package_id == 2">
                          Premium
                        </span>
                        <span *ngIf="plan?.package_id == 3">
                          Elite
                        </span> - Ext
                      </td>
                      <td>0</td>
                      <td>{{plan?.duration}}</td>
                      <td>{{plan?.created_at | date:'mediumDate'}}</td>
                      <td>{{plan?.extension_start_at | date:'mediumDate'}}</td>
                      <td>{{plan?.extension_end_at | date:'mediumDate'}}</td>

                      <td>
                        <span>
                          Reason : {{plan?.reason}}
                        </span>
                      </td>
                      <td>
                        <button *ngIf="!plan?.active_plan_id" type="button" class="me-2 btn btn-sm btn-danger"
                          (click)="activePlanNow(plan?.user_id,plan?.package_id,plan?.id)"
                          style="display:none">Active</button>
                        <button *ngIf="!!plan?.active_plan_id" type="button"
                          class="btn btn-light btn-sm border border-1 border-danger shadow-sm">Active</button>
                      </td>

                    </tr>
                  </ng-container>

                </tbody>
                <tbody *ngIf="active_plan.length == 0">
                  <tr>
                    <td colspan="8" class="text-center">No plan available</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-12">
            <h5 class="fw-bold">Plan history</h5>
          </div>
        </div>

        <div class="card mb-2">
          <div class="row card-body">
            <div class="col-12 table-responsive">
              <table class="table table-striped">
                <caption style="display: none;">Plan histry</caption>
                <thead>
                  <tr>
                    <th scope="col">No.</th>
                    <th scope="col">Plan name </th>
                    <th scope="col">Amount</th>
                    <th scope="col">Plan duration</th>
                    <th scope="col">Purchase date</th>
                    <th scope="col">Plan expiry</th>
                    <th scope="col">Payment status</th>
                    <th scope="col">Reason</th>
                  </tr>
                </thead>
                <tbody *ngIf="expired_plan.length> 0">
                  <tr *ngFor="let plan of expired_plan; index as i">
                    <th scope="row">{{ i + 1 }}</th>
                    <td>{{plan?.package_name}}</td>
                    <td>{{plan?.country_currency}} {{plan?.package_amount/100}}</td>
                    <td>{{plan?.subscription_plan}}</td>
                    <td>{{plan?.payment_date | date:'mediumDate'}}</td>
                    <td>{{plan?.plan_expiry_date | date:'mediumDate'}}</td>
                    <td>{{plan?.payment_status}}</td>
                    <td>{{plan?.reason}}</td>
                  </tr>
                </tbody>
                <tbody *ngIf="expired_plan.length == 0">
                  <tr>
                    <td colspan="8" class="text-center">No plan available</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>

</section>

<app-login-footer></app-login-footer>

<!-- Hide Profile Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body p-4">
        <button type="button" class="btn btn-danger position-absolute top-0 right-0 m-2 center w-30-h-30"
          data-bs-dismiss="modal" aria-label="Close" id="btnClosed">
          <i class="fas fa-times fs-5"></i>
        </button>

        <div class="row mt-3">
          <div class="col-12" *ngIf="!hideUnhideBtn">
            <h5 class="fw-600 text-danger mb-0"> Hide Profile </h5>
            <p class=" fw-500 text-danger pt-3"> How long would you like to hide your profile for? </p>
          </div>
          <div class="col-12" *ngIf="hideUnhideBtn">
            <h5 class="fw-600 text-danger mb-0">Un-Hide Profile </h5>
            <p class=" fw-500 text-danger pt-3"> Un-hide your profile to find a match. </p>
          </div>
          <div class="col-12 text-center">
            <!-- example  -->
            <mat-form-field class="example-full-width" appearance="fill" [formGroup]="campaignOne">
              <input matInput [matDatepicker]="picker" formControlName="start" (dateChange)="onDateChange($event)"
                [min]="yesterday">
              <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker [dateClass]="dateClass" #picker></mat-datepicker>
            </mat-form-field>
            <!-- example -->
          </div>
        </div>
        <div class="w-100 text-end">
          <button class="btn btn-sm btn-outline-danger" data-bs-dismiss="modal" aria-label="Close"
            (click)="hidecancel()">Cancel </button>
          <button class="btn btn-sm btn-danger ms-2" (click)="hideAccount()" [disabled]="dateRequired"
            data-bs-dismiss="modal" aria-label="Close">Hide
            Profile </button>
          <button class="btn btn-sm btn-danger ms-2" *ngIf="hideUnhideBtn" (click)="unHideAccount()"
            data-bs-dismiss="modal" aria-label="Close">Un-Hide
            Profile</button>
        </div>
      </div>

    </div>
  </div>
</div>

<!-- delete modal -->

<div class="modal fade" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel"
  tabindex="-1">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
    <div class="modal-content">

      <div class="row modal-body">
        <div class="col-12">
          <h5 class="fw-600 text-danger"> Delete Profile </h5>
        </div>
        <div class="col-12">
          <p class="fw-500 lh-base">
            Please share your reason with us. We are always here to help with
            your questions and concerns.
          </p>
        </div>
        <div class="col-12 mb-3">
          <label for="NewPassword" id="reason" class="fw-bold"> Reason</label>
          <textarea name id="reason" rows="3" class="form-control" [(ngModel)]="textContent"></textarea>
        </div>
        <div class="col-12 text-end">
          <button class="btn btn-danger" [disabled]="textContent == ''" (click)="deleteOtherReason(textContent)"
            data-bs-target="#exampleModalToggle2" data-bs-toggle="modal" data-bs-dismiss="modal">Submit</button>
        </div>
      </div>

    </div>
  </div>
</div>
<div class="modal fade" id="exampleModalToggle2" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2"
  tabindex="-1">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
    <div class="modal-content">

      <div class="row modal-body">
        <div class="col-12">
          <h5 *ngIf="deleteBy =='delete_account'" class="fw-600 text-danger"> Delete Account </h5>
          <h5 *ngIf="deleteBy == 'delete_profile' " class="fw-600 text-danger"> Delete Profile </h5>
        </div>
        <div class="col-12">
          <p *ngIf="deleteBy =='delete_account'" class="fw-500 lh-base">
            Once you Delete the Account all your information will be permanently Deleted. However, you can use the same
            Email ID to create a new account and go through the Signup process again.
          </p>
          <p *ngIf="deleteBy == 'delete_profile' " class="fw-500 lh-base">
            Once you Delete the Profile you will permanently lose all profile information, match interaction and paid
            membership. However, you can create another Profile using the same Login information i.e. your Email ID or
            Phone Number and Password.
          </p>

        </div>
        <form #deleteNgForm="ngForm" [formGroup]="deleteForm">
          <div class="col-12 mb-3">
            <label for="NewPassword" id="phone" class="fw-bold"> Password <span
                class="fw-bold text-danger">*</span></label>

            <div class="position-relative">
              <input [type]="showPassword1 ?'text':'password'" class="form-control" [formControlName]="'password'"
                name="phone" id="password" value required (keyup)="matchPassword1($event)" (paste)="onPaste($event)">
              <div class="position-absolute eye-btn right-0 text-danger" (click)="showHidePassword1()">
                <div *ngIf="showPassword1"><i class="fa fa-eye"></i></div>
                <div *ngIf="!showPassword1"><i class="fa fa-eye-slash"></i></div>
              </div>
            </div>
            <div *ngIf="passRequired" class="form-label mb-2 py-1 fw-bold text-danger"> Password is required</div>
          </div>

          <div class="col-12 mb-3">
            <label for="NewPassword" id="phone" class="fw-bold"> Confirm Password <span
                class="fw-bold text-danger">*</span></label>

            <div class="position-relative">
              <input [type]="showPassword ?'text':'password'" class="form-control" [formControlName]="'matchPassword'"
                name="phone" id="matchPassword" value required (keyup)="matchPassword1($event)"
                (paste)="onPaste($event)">
              <div class="position-absolute eye-btn right-0 text-danger" (click)="showHidePassword()">
                <div *ngIf="showPassword"><i class="fa fa-eye"></i></div>
                <div *ngIf="!showPassword"><i class="fa fa-eye-slash"></i></div>
              </div>
            </div>
            <div class="form-label mb-2 py-1 fw-bold text-danger" *ngIf="!pwmatch"> <span *ngIf="showPasswordMsg">
                Password does not match </span></div>
          </div>

          <div class="col-12 text-end">
            <button class="btn btn-danger me-2" *ngIf="pwmatch  && deleteBy == 'delete_profile' "
              (click)="deleteProfile()" data-bs-dismiss="modal">Delete
              Profile</button>

            <button class="btn btn-danger me-2" disabled *ngIf="!pwmatch && deleteBy == 'delete_profile' ">Delete
              Profile</button>

            <button class="btn btn-danger me-2" *ngIf="pwmatch && deleteBy =='delete_account'" (click)="deleteProfile()"
              data-bs-dismiss="modal">Delete
              Account</button>

            <button class="btn btn-danger me-2" disabled *ngIf="!pwmatch && deleteBy =='delete_account' ">Delete
              Account</button>

            <button class="btn btn-light border border-1 border-danger" data-bs-dismiss="modal">Cancel</button>

          </div>
        </form>
      </div>

    </div>
  </div>
</div>

<!-- delete profile modal  -->
<div class="modal fade" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel"
  tabindex="-1">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
    <div class="modal-content">

      <div class="row modal-body">
        <div class="col-12">
          <h5 class="fw-600 text-danger"> Other Reason </h5>
        </div>
        <div class="col-12">
          <p class="fw-500 lh-base">
            Please share your reason with us. We are always here to help with
            your questions and concerns.
          </p>
        </div>
        <div class="col-12 mb-3">
          <label for="NewPassword" id="reason" class="fw-bold"> Reason</label>
          <textarea name id="reason" rows="3" class="form-control"></textarea>
        </div>
        <div class="col-12 text-end">
          <button class="btn btn-danger" data-bs-target="#exampleModalToggle2" data-bs-toggle="modal"
            data-bs-dismiss="modal">Submit</button>
        </div>
      </div>

    </div>
  </div>
</div>

<div class="modal fade" id="exampleModalToggle2" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2"
  tabindex="-1">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
    <div class="modal-content">

      <div class="row modal-body">
        <div class="col-12">
          <h5 class="fw-600 text-danger"> Delete Profile </h5>
        </div>
        <div class="col-12">
          <p class="fw-500 lh-base">
            You will permanently lose all profile information, Match interactions
            and paid memberships
          </p>
        </div>
        <div class="col-12 mb-3">
          <label for="NewPassword" id="phone" class="fw-bold"> Password <span
              class="fw-bold text-danger">*</span></label>

          <div class="position-relative">
            <input type="password" class="form-control " name="phone" id="NewPassword" value required>
            <div class="position-absolute eye-btn right-0 text-danger">
              <i class="fa fa-eye"></i>
            </div>
          </div>
        </div>

        <div class="col-12 mb-3">
          <label for="NewPassword" id="phone" class="fw-bold"> Confirm Password <span
              class="fw-bold text-danger">*</span></label>

          <div class="position-relative">
            <input type="password" class="form-control" name="phone" id="NewPassword" value required>
            <div class="position-absolute eye-btn right-0 text-danger">
              <i class="fa fa-eye"></i>
            </div>
          </div>
        </div>

        <div class="col-12 text-end">
          <button class="btn btn-danger me-2">Delete Profile</button>

          <button class="btn btn-light border border-1 border-danger" data-bs-dismiss="modal">Cancel</button>
        </div>
      </div>

    </div>
  </div>
</div>

<!-- delete modal 2 -->
<div class="modal fade" id="exampleModalToggle11" aria-hidden="true" aria-labelledby="exampleModalToggleLabel"
  tabindex="-1">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
    <div class="modal-content">

      <div class="row modal-body">
        <div class="col-12">
          <h5 class="fw-600 text-danger"> Found match on Eternal Strings? </h5>
        </div>
        <div class="col-12">
          <p class="fw-500 lh-base">
            Congratulations on finding your Life Partner. Please take a moment and tell us
            about your experience.
          </p>
        </div>
        <div class="col-12 mb-3">
          <label for="NewPassword" id="reason" class="fw-bold"> Experience: </label>
          <textarea name id="reason" rows="3" class="form-control" [(ngModel)]="Experience"></textarea>
        </div>
        <div class="col-12 text-end">
          <button class="btn btn-danger" data-bs-target="#exampleModalToggle2" data-bs-toggle="modal"
            data-bs-dismiss="modal" [disabled]="Experience == ''" (click)="deleteReasonsExperience()">Submit</button>
        </div>
      </div>

    </div>
  </div>
</div>

<!-- delete modal 3 -->
<div class="modal fade" id="exampleModalToggle22" aria-hidden="true" aria-labelledby="exampleModalToggleLabel"
  tabindex="-1">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
    <div class="modal-content">

      <div class="row modal-body">
        <div class="col-12">
          <h5 class="fw-600 text-danger"> Found match elsewhere? </h5>
        </div>
        <div class="col-12">
          <p class="fw-500 lh-base">
            We are very happy that you found your match and we wish you all the best.
          </p>
        </div>

        <div class="col-12 text-end">
          <button class="btn btn-danger" data-bs-target="#exampleModalToggle2" data-bs-toggle="modal"
            data-bs-dismiss="modal" value="Found your match elsewhere?" (click)="deleteReason($event)">Continue</button>
        </div>
      </div>

    </div>
  </div>
</div>

<!-- delete modal 4 -->
<div class="modal fade" id="exampleModalToggle33" aria-hidden="true" aria-labelledby="exampleModalToggleLabel"
  tabindex="-1">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
    <div class="modal-content">

      <div class="row modal-body">
        <div class="col-12">
          <h5 class="fw-600 text-danger">Changed your mind? </h5>
        </div>
        <div class="col-12">
          <p class="fw-500 lh-base">
            Please share with us why you have changed your mind. We appreciate your
            feedback.
          </p>
        </div>
        <div class="col-12 mb-3">
          <label for="NewPassword" id="reason" class="fw-bold"> Feedback:</label>
          <textarea name id="reason" rows="3" class="form-control" [(ngModel)]="Feedback"></textarea>
        </div>
        <div class="col-12 text-end">
          <button class="btn btn-danger" data-bs-target="#exampleModalToggle2" data-bs-toggle="modal"
            data-bs-dismiss="modal" [disabled]="Feedback == ''" (click)="deleteReasonsFeedback()">Submit</button>
        </div>
      </div>

    </div>
  </div>
</div>

<!-- delete modal 4 -->
<div class="modal fade" id="exampleModalToggle44" aria-hidden="true" aria-labelledby="exampleModalToggleLabel"
  tabindex="-1">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
    <div class="modal-content">

      <div class="row modal-body">
        <div class="col-12">
          <h5 class="fw-600 text-danger"> Not ready yet? </h5>
        </div>
        <div class="col-12">
          <p class="fw-500 lh-base">
            Please let us know if we can help in any way. Did you know that you can Hide
            Profile for some time and come back to it later.
          </p>
        </div>
        <div class="col-12 mb-3">
          <div class="form-check ps-0">
            <label class for="hide">
              <strong>Hide Profile</strong> Do you want to hide your profile temporarily?
            </label>
          </div>
        </div>
        <div class="col-12 text-end">
          <button class="btn btn-danger me-2" data-bs-target="#exampleModal" data-bs-toggle="modal"
            data-bs-dismiss="modal">Yes</button>
          <button class="btn btn-light border border-1 border-danger me-2" data-bs-dismiss="modal">No</button>
          <button class="btn btn-light border border-1 border-danger" data-bs-target="#exampleModalToggle2"
            data-bs-toggle="modal" data-bs-dismiss="modal" value="Not ready yet?" (click)="deleteReason($event)">Delete
            now</button>
        </div>
      </div>

    </div>
  </div>
</div>

<!-- delete modal 5 -->
<div class="modal fade" id="exampleModalToggle55" aria-hidden="true" aria-labelledby="exampleModalToggleLabel"
  tabindex="-1">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
    <div class="modal-content">

      <div class="row modal-body">
        <div class="col-12">
          <h5 class="fw-600 text-danger"> Unsatisfactory experience on Eternal Strings ?</h5>
        </div>
        <div class="col-12">
          <p class="fw-500 lh-base">
            Please select reason:
          </p>
        </div>
        <div class="col-12">
          <div class="form-check ps-0">
            <input class="checkbox me-2" type="radio" name="Serious" id="experience" data-bs-toggle="modal"
              href="#profileModal">
            <label class for="experience" data-bs-toggle="modal" href="#profileModal" type=" button">
              Unhappy with Matches
            </label>
          </div>
          <div class="form-check ps-0">
            <input class="checkbox me-2" type="radio" name="Serious" id="experience1" data-bs-toggle="modal"
              href="#profileModal1">
            <label class for="experience1" data-bs-toggle="modal" href="#profileModal1" type=" button">
              No Serious Profile
            </label>
          </div>
          <div class="form-check ps-0">
            <input class="checkbox me-2" type="radio" name="Serious" id="experience2" data-bs-toggle="modal"
              href="#profileModal2">
            <label class for="experience2" data-bs-toggle="modal" href="#profileModal2" type=" button">
              Misuse
            </label>
          </div>
          <div class="form-check ps-0">
            <input class="checkbox me-2" type="radio" name="Serious" id="experience3" data-bs-toggle="modal"
              href="#profileModal3">
            <label class for="experience3" data-bs-toggle="modal" href="#profileModal3" type=" button">
              Received Too many calls
            </label>
          </div>
          <div class="form-check ps-0">
            <input class="checkbox me-2" type="radio" name="Serious" id="experience4" data-bs-toggle="modal"
              href="#profileModal4">
            <label class for="experience4" data-bs-toggle="modal" href="#profileModal4" type=" button">
              Unable to edit Profile detail.
            </label>
          </div>

          <div class="col-12 text-end">
            <button class="btn btn-danger border border-1 border-danger me-2" data-bs-dismiss="modal">Cancel</button>
          </div>
        </div>

      </div>

    </div>
  </div>
</div>

<!-- reason-1 -->
<div class="modal fade" id="profileModal" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
    <div class="modal-content">

      <div class="row modal-body">
        <div class="col-12">
          <h5 class="fw-600 text-danger"> Unhappy with Matches </h5>
        </div>
        <div class="col-12">
          <p class="fw-500 lh-base">
            We are sorry to hear that. Did you know that you can choose exactly which
            Matches you want to see on Eternal Strings by updating your Partner Preferences?
            Would you like to update your Partner Preferences?
          </p>
        </div>
        <div class="col-12">
          <div class="form-check ps-0">
            <label class for="experience">
              Update Partner Preferences
            </label>
          </div>
        </div>

        <div class="col-12 text-end">
          <button class="btn btn-danger me-2" data-bs-dismiss="modal" (click)="EditPartnerPrefrence()"> Yes
          </button>
          <button class="btn btn-light border border-1 border-danger me-2" data-bs-dismiss="modal"
            data-bs-toggle="modal" href="#exampleModalToggle55">No</button>
          <button class="btn btn-light border border-1 border-danger" data-bs-toggle="modal" href="#exampleModalToggle2"
            role="button" data-bs-dismiss="modal"
            value="Unsatisfactory experience on Eternal Strings, Unhappy with Matches" (click)="deleteReason($event)">
            Delete
            now
          </button>

        </div>
      </div>

    </div>
  </div>
</div>

<!-- reason-2 -->
<div class="modal fade" id="profileModal1" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
    <div class="modal-content">

      <div class="row modal-body">
        <div class="col-12">
          <h5 class="fw-600 text-danger"> No Serious Profile </h5>
        </div>
        <div class="col-12">
          <p class="fw-500 lh-base">
            We are sorry to hear that. Did you know that you can choose exactly which
            Matches you want to see on Eternal Strings by updating your Partner Preferences?
            Would you like to update your Partner Preferences?
          </p>
        </div>
        <div class="col-12">
          <div class="form-check ps-0">
            <label class for="experience">
              Update Partner Preferences
            </label>
          </div>
        </div>

        <div class="col-12 text-end">
          <button class="btn btn-danger me-2" data-bs-dismiss="modal" (click)="EditPartnerPrefrence()"> Yes
          </button>
          <button class="btn btn-light border border-1 border-danger me-2" data-bs-dismiss="modal"
            data-bs-toggle="modal" href="#exampleModalToggle55">No</button>
          <button class="btn btn-light border border-1 border-danger" data-bs-dismiss="modal" data-bs-toggle="modal"
            href="#exampleModalToggle2" role="button"
            value="Unsatisfactory experience on Eternal Strings, No Serious Profile" (click)="deleteReason($event)">
            Delete
            now
          </button>
        </div>
      </div>

    </div>
  </div>
</div>

<!-- reason 4 -->

<div class="modal fade" id="profileModal3" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
    <div class="modal-content">

      <div class="row modal-body">
        <div class="col-12">
          <h5 class="fw-600 text-danger"> Received Too many calls </h5>
        </div>
        <div class="col-12">
          <p class="fw-500 lh-base">
            You have the option to choose who sees your Phone number in your Privacy
            Options in the Contact Details section to control who can contact you. Would
            you like to change your Privacy Options:
          </p>
        </div>
        <div class="col-12">
          <div class="form-check ps-0">
            <label class for="experience">
              <strong> Privacy Options.</strong> Do you want to change your Privacy Options – Contact Details section
            </label>
          </div>
        </div>

        <div class="col-12 text-end">
          <button class="btn btn-danger me-2" data-bs-dismiss="modal" (click)="EditPrivacyOption()"> Yes
          </button>
          <button class="btn btn-light border border-1 border-danger me-2" data-bs-dismiss="modal"
            data-bs-toggle="modal" href="#exampleModalToggle55">No</button>

          <button class="btn btn-light border border-1 border-danger" data-bs-toggle="modal" href="#exampleModalToggle2"
            role="button" value="Unsatisfactory experience on Eternal Strings, Received Too many calls"
            (click)="deleteReason($event)"> Delete now </button>

        </div>
      </div>

    </div>
  </div>
</div>

<!-- reason 5 -->
<div class="modal fade" id="profileModal4" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
    <div class="modal-content">

      <div class="row modal-body">
        <div class="col-12">
          <h5 class="fw-600 text-danger"> Unable to edit Profile detail </h5>
        </div>
        <div class="col-12">
          <p class="fw-500 lh-base">
            Please go to our <a class="text-danger fw-500" target="_blank" routerLink="/faq">FAQ</a> to see if you can
            find the answer
            on how to Edit My
            Profile. If you are not able to find your answer there, we are here to help you.
            Please feel free to <a class="text-danger fw-500" target="_blank" routerLink="/contact"> Contact Us </a> and
            we will help you
            resolve this issue.
          </p>
        </div>
        <div class="col-12">
          <div class="form-check ps-0">
            <label class for="experience">
              Did this resolve your issue?
            </label>
          </div>
        </div>

        <div class="col-12 text-end">
          <button class="btn btn-danger me-2" data-bs-dismiss="modal" (click)="editProfile()"> Yes
          </button>
          <button class="btn btn-light border border-1 border-danger me-2" data-bs-dismiss="modal"
            data-bs-toggle="modal" href="#exampleModalToggle55">No</button>
          <button class="btn btn-light border border-1 border-danger" data-bs-toggle="modal" href="#exampleModalToggle2"
            role="button" value="Unsatisfactory experience on Eternal Strings, Unable to edit Profile detail"
            (click)="deleteReason($event)"> Delete now </button>

        </div>
      </div>

    </div>
  </div>
</div>
<!-- reason 3 -->
<div class="modal fade" id="profileModal2" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
    <div class="modal-content">

      <div class="row modal-body">
        <div class="col-12">
          <h5 class="fw-600 text-danger"> Misuse </h5>
        </div>
        <div class="col-12">
          <p class="fw-500 lh-base">
            We are sorry to hear that you have had a bad poor experience with a member.
            You have the option to Block & Report such members. We take these very
            seriously. This information is not shared with the person that you are
            reporting. Please help us to make Eternal Strings safer.
          </p>
        </div>
        <div class="col-12 mb-3">
          <textarea name id="reason" rows="3" class="form-control" [(ngModel)]="blockReason"></textarea>
        </div>

        <div class="col-12 text-end">
          <button class="btn btn-danger  border border-1 border-danger me-2" data-bs-dismiss="modal"
            data-bs-toggle="modal" href="#exampleModalToggle55">Cancel</button>
          <button class="btn btn-light border border-1 border-danger  me-2" data-bs-toggle="modal"
            href="#exampleModalToggle2" data-bs-dismiss="modal" role="button" [disabled]="blockReason == ''"
            (click)="deleteReasonsBlockReason()"> Delete now
          </button>

        </div>

      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="exampleModalToggleEdit" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2"
  tabindex="-1">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
    <div class="modal-content">

      <div class="row modal-body">
        <div class="col-12">
          <p class="fw-500 lh-base">
            You can go to Edit My Profile.
          </p>
        </div>
        <div class="col-12">
          <div class="form-check ps-0">
            <input class="checkbox me-2" type="radio" name="Preferences" id="experience">
            <label class for="experience">
              <strong> Edit My Profile </strong> This should take them to the <a class="text-danger"
                routerLink="/myProfile"> My Profile Preview</a>
              page?
            </label>
          </div>
        </div>

        <div class="col-12 text-end">
          <a routerLink="/myProfile" target="_blank" data-bs-dismiss="modal"> <button
              class="btn btn-danger me-2">Yes</button></a>

          <button class="btn btn-light border border-1 border-danger" data-bs-toggle="modal"
            href="#exampleModalToggle555" role="button">No</button>
        </div>
      </div>

    </div>
  </div>
</div>

<div class="modal fade" id="exampleModalToggle555" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2"
  tabindex="-1">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
    <div class="modal-content">

      <div class="row modal-body">
        <div class="col-12">
          <p class="fw-500 lh-base">
            You will permanently lose all profile information, Match interactions
            and paid memberships
          </p>
        </div>
        <div class="col-12 mb-3">
          <p class="fw-500 p-sm lh-base">Confirm Profile Deletion:</p>
          <label for="NewPassword" id="phone" class="fw-bold"> Password <span
              class="fw-bold text-danger">*</span></label>

          <div class="position-relative">
            <input type="password" class="form-control " name="phone" id="NewPassword" value required>
            <div class="position-absolute eye-btn right-0 text-danger">
              <i class="fa fa-eye"></i>
            </div>
          </div>
        </div>

        <div class="col-12 mb-3">
          <label for="NewPassword" id="phone" class="fw-bold"> Confirm Password <span
              class="fw-bold text-danger">*</span></label>

          <div class="position-relative">
            <input type="password" class="form-control" name="phone" id="NewPassword" value required>
            <div class="position-absolute eye-btn right-0 text-danger">
              <i class="fa fa-eye"></i>
            </div>
          </div>
        </div>

        <div class="col-12 text-end">
          <button class="btn btn-danger me-2">Delete Profile</button>

          <button class="btn btn-light border border-1 border-danger" data-bs-dismiss="modal">Cancel</button>
        </div>
      </div>

    </div>
  </div>
</div>

<!-- delete profile modal  -->