<div class="container border border-1 shadow-sm my-4 p-0 position-relative myall-section">
  <nav class="position-sticky z-index-60 top-70 index-9999">
    <div class="nav nav-tabs d-flex align-items-center justify-content-between p-2 bg-warning" id="nav-tab"
      role="tablist">



      <a class="my-2 py-2 px-3 btn-outline-danger border-0 flex-1 rounded-pill mx-1 dashboard-card123 text-center"
        routerLink="/myProfile" [class.notClickable]="notClickable">My
        Dashboard</a>

      <a class="my-2 py-2 px-3 btn-outline-danger active border-0 flex-1 rounded-pill mx-1 text-center"
        routerLink="/myInfo">My Profile</a>

      <a class="my-2 py-2 px-3 btn-outline-danger border-0 flex-1 rounded-pill mx-1 dashboard-card123 text-center"
        routerLink="/myPhotoGallery" [class.notClickable]="notClickable">My Photo
        Gallery</a>

    </div>
  </nav>
  <div class="p-3 bg-white pt-4 mt-68">
    <div class="p-0 mx-auto active">
      <div class="row">
        <div class="col-12 px-0 profile-banner position-relative">
          <app-progressive-image class="progressive-image">
            <img appProgressiveImage [src]="coverSRC" alt="" class="img-fluid">

            <div appProgressiveImagePlaceholder class="placeholderOneOrThree">
              <div id="loaderProgressive" class="centerProgressive"></div>
            </div>
            <div appProgressiveImageFallback class="fallback">
              <img appProgressiveImageFallback class="img-fluid" src="assets/images/banner-4.jpg" alt="">
            </div>
          </app-progressive-image>
          <div class="position-absolute profile-banner-edit-btn top-0">
            <div class="w-30-h-30 rounded-circle p-1 btn btn-danger center">

                <div data-bs-toggle="modal" data-bs-target="#coverPhoto" class="w-30-h-30 rounded-circle p-1 top-0 right-0 btn btn-danger center position-absolute">
                  <i class="fas fa-camera h6 mb-0"></i>
                </div>
              
            </div>
          </div>
          <p class="text-center mb-3">
            <span *ngIf="details?.cover_photo_approved == 1 && details?.cover_photo"
              class="text-success border-success"> Approved</span>
            <span
              *ngIf="details?.cover_photo_approved == 0 && (details?.cover_photo != null && details?.cover_photo != '')"
              class="text-warning border-warning">
              Awaiting Approval</span>
            <span
              *ngIf="details?.cover_photo_approved == 2  && (details?.cover_photo != null && details?.cover_photo != '')"
              class="text-danger border-danger"> Not Approved</span>
            <span *ngIf="details?.cover_photo == null || details?.cover_photo == ''"
              class="text-warning border-warning"> Default</span>
          </p>

        </div>
      </div>
      <div class="row position-relative">
        <div class="col-12 col-sm-3 col-lg-2 profile-box">
          <div class="w-115-h-115 rounded-circle position-relative mx-auto pad-left">
            <app-progressive-image class="progressive-image">
              <img appProgressiveImage [src]="croppedImage" alt=""
                class="img-fluid rounded-circle border image-cover overflow-hidden bg-white">

              <div appProgressiveImagePlaceholder class="placeholderOneOrThree-suggest">
                <div id="loaderProgressive" class="centerProgressive"></div>
              </div>
              <div appProgressiveImageFallback class="fallback">
                <img appProgressiveImageFallback
                  class="img-fluid rounded-circle border image-cover overflow-hidden bg-white h-115"
                  src="assets/images/default-profile-picture.svg" alt="">
              </div>
            </app-progressive-image>
            <div data-bs-toggle="modal" data-bs-target="#loginModal" class="w-30-h-30 rounded-circle p-1 top-0 right-0 btn btn-danger center position-absolute">
                <i class="fas fa-camera h6 mb-0"></i>
            </div>
          </div>
          <div class="card-footer">
            <p class="text-center py-2"> <span *ngIf="details?.profile_photo_approved == 1 && details?.profile_photo"
                class="text-success border-success"> Approved</span>
              <span
                *ngIf="details?.profile_photo_approved == 0 && (details?.profile_photo !=null && details?.profile_photo !='')"
                class="text-warning border-warning">
                Awaiting Approval</span>
              <span
                *ngIf="details?.profile_photo_approved == 2 && (details?.profile_photo !=null && details?.profile_photo !='')"
                class="text-danger border-danger"> Not Approved </span>
              <span *ngIf="details?.profile_photo == null || details?.profile_photo == ''"
                class="text-warning border-warning"> Default</span>
            </p>
          </div>

        </div>
        <div class="col-12 col-sm-9 col-lg-10 text-center text-sm-start py-2 py-sm-3 ps-sm-0">
          <div class="col-12 px-0 d-flex justify-content-center justify-content-sm-start">
            <h5 class="mb-1 fw-bold">
              {{ displayName }}&nbsp;
            </h5>
            <span class="user_email_verified"
              [ngbTooltip]="details?.is_user_verified ==1 ? 'Verified user': 'Verified email'" placement="bottom">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                class="bi bi-shield-fill-check mb-1 " [ngClass]="{'text-muted':details?.is_user_verified != 1,
                                                 'text-success':details?.is_user_verified == 1}" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M8 0c-.69 0-1.843.265-2.928.56-1.11.3-2.229.655-2.887.87a1.54 1.54 0 0
                                      0-1.044
                                      1.262c-.596 4.477.787 7.795 2.465 9.99a11.777 11.777 0 0 0 2.517
                                      2.453c.386.273.744.482
                                      1.048.625.28.132.581.24.829.24s.548-.108.829-.24a7.159
                                      7.159 0 0 0 1.048-.625 11.775 11.775 0 0 0 2.517-2.453c1.678-2.195
                                      3.061-5.513
                                      2.465-9.99a1.541 1.541 0 0 0-1.044-1.263 62.467 62.467 0 0
                                      0-2.887-.87C9.843.266
                                      8.69 0 8 0zm2.146 5.146a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708
                                      0l-1.5-1.5a.5.5 0
                                      1 1 .708-.708L7.5 7.793l2.646-2.647z" />
              </svg>
            </span>

            <span class="user_email_verified1" *ngIf="details?.is_user_verified== 0 " placement="bottom"
              ngbTooltip="Verified email"><i class="fa fa-check fs-5" style="color:green"></i>
            </span>
            <span class="user_email_verified1" *ngIf="details?.is_user_verified== 1 " placement="bottom"
              ngbTooltip="Verified user"><i class="fa fa-check-double fs-5 " style="color:green"></i>
            </span>

            <div class="ms-2">
              <img src="assets/images/icon/elite.svg" class="width-30" alt placement="right" ngbTooltip="Elite Member"
                *ngIf="details.package_id == 3" />
              <img src="assets/images/icon/premium.svg" class="width-30" alt placement="right"
                ngbTooltip="Premium Member" *ngIf="details.package_id == 2" />
            </div>

          </div>
          <p class="p-sm mb-1">
            {{ this.age }}/{{ details.height|heightTop:"Ft"}}, {{ details.religion | limit :20}}{{ community | limit :20
            }}
          </p>

          <p class="p-sm mb-1">
            {{ details.current_city | limit :20}}, {{ headingStateName }}, {{ headingCountryName }}
          </p>

        </div>
        <div class="position-absolute mt-4 right-0 text-end ">
          <div class="display_option">
            <a class="btn btn-danger btn-sm p-sm" target="_blank" routerLink="/Profile-Preview">My Profile
              Preview</a>
            <button style="display:none;" id="openModal1" data-bs-target="#upgradeModal1"
              data-bs-toggle="modal"></button>
            <button style="display:none;" id="openModal12" data-bs-target="#upgradeModal12"
              data-bs-toggle="modal"></button>

            <button id="fields" style="display:none;" data-bs-target="#fieldsModal" data-bs-toggle="modal"></button>

            <div class="dropdown mt-2">
              <!-- <a class="btn btn-danger btn-sm p-sm" (click)="unhide()">Hide Profile</a> -->

              <button *ngIf="this.details && this.details?.is_hidden == 0" class="btn btn-danger btn-sm p-sm"
                  data-bs-toggle="modal" data-bs-target="#exampleModal1" (click)="unhide()">Hide Profile</button>
                <button *ngIf=" this.details && this.details?.is_hidden == 1" class="btn btn-danger btn-sm p-sm"
                  data-bs-toggle="modal" data-bs-target="#exampleModal1" (click)="unhide()">Un-Hide
                  Profile</button>

              <form #memberNgForm="ngForm" [formGroup]="userPlanForm" style="display: none;">
                <button class="btn btn-outline-danger btn-sm dropdown-toggle" type="button" id="dropdownMenuButton1"
                  data-bs-toggle="dropdown" aria-expanded="false"> Display Option
                </button>
                <ul class="dropdown-menu border-danger mt-2 h-130 right-0 mx-width-280 p-sm"
                  aria-labelledby="dropdownMenuButton1" style="z-index:1;">
                  <li>
                    <div class="dropdown-item px-2">
                      <input class="checkbox me-2" type="checkbox" [formControlName]="'visible_to_all'" value="1"
                        id="visible_to_all_members" (change)="memberShip('visible_to_all')" />
                      <label class for="visible_to_all_members"> Visible to all Members </label>
                    </div>
                  </li>
                  <li>
                    <div class="dropdown-item px-2">
                      <input class="checkbox me-2" type="checkbox" [formControlName]="'visible_to_premium_elite'"
                        value="1" (change)="memberShip('visible_to_premium_elite')"
                        id="visible_to_premium_elite_members" />
                      <label class for="visible_to_premium_elite_members">Visible to Premium & Elite members</label>
                    </div>
                  </li>
                  <li>
                    <div class="dropdown-item px-2">
                      <input class="checkbox me-2" type="checkbox" [formControlName]="'visible_to_elite'" value="1"
                        (change)="memberShip('visible_to_elite')" id="visible_to_elite_members" />
                      <label class for="visible_to_elite_members"> Visible to Elite Members </label>
                    </div>
                  </li>
                  <li>
                    <div class="dropdown-item px-2">
                      <input class="checkbox me-2" type="checkbox" [formControlName]="'visible_to_no_one'" value="1"
                        (change)="memberShip('visible_to_no_one')" id="visible_to_no_one" />
                      <label class for="visible_to_no_one"> Visible to No One </label>
                    </div>
                  </li>
                </ul>
              </form>
            </div>

          </div>
        </div>

        <div class="row m-0 mt-3" id="pillChange">
          <div class="col-12 col-sm-4 col-lg-3">
            <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
              <button class="btn btn-outline-danger mb-2 " [ngClass]="(editId=='aboutYou')?'active':''"
                id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab"
                aria-controls="v-pills-home" aria-selected="true" (click)="openTabSection()"> About You </button>

              <button class="btn btn-outline-danger mb-2" [ngClass]="(editId=='basicAndLifestyle')?'active':''"
                id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button"
                role="tab" aria-controls="v-pills-profile" aria-selected="false" (click)="openTabSection()"> Basics &
                Lifestyle
              </button>

              <button class="btn btn-outline-danger mb-2" [ngClass]="(editId=='religion')?'active':''"
                id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button"
                role="tab" aria-controls="v-pills-messages" aria-selected="false" (click)="openTabSection()"> Religious
                Views </button>

              <button class="btn btn-outline-danger mb-2" [ngClass]="(editId=='astro')?'active':''"
                id="v-pills-settings-tab" data-bs-toggle="pill" data-bs-target="#v-pills-settings" type="button"
                role="tab" aria-controls="v-pills-settings" aria-selected="false" (click)="openTabSection()"> Astro
                Details </button>

              <button class="btn btn-outline-danger mb-2" [ngClass]="(editId=='familyDetails')?'active':''"
                id="v-pills-Family-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Family" type="button" role="tab"
                aria-controls="v-pills-Family" aria-selected="false" (click)="openTabSection()"> Family Details
              </button>
              <button class="btn btn-outline-danger mb-2" [ngClass]="(editId=='education')?'active':''"
                id="v-pills-EducationCareer-tab" data-bs-toggle="pill" data-bs-target="#v-pills-EducationCareer"
                type="button" role="tab" aria-controls="v-pills-EducationCareer" aria-selected="false"
                (click)="openTabSection()">
                Education & Professional Details </button>
              <button class="btn btn-outline-danger mb-2" [ngClass]="(editId=='hobby')?'active':''"
                id="v-pills-Hobbies-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Hobbies" type="button"
                role="tab" aria-controls="v-pills-Hobbies" aria-selected="false" (click)="openTabSection()"> Hobbies,
                Interests & more </button>
              <button class="btn btn-outline-danger mb-2" [ngClass]="(editId=='location')?'active':''"
                id="v-pills-LocationGroom-tab" data-bs-toggle="pill" data-bs-target="#v-pills-LocationGroom"
                type="button" role="tab" aria-controls="v-pills-LocationGroom" aria-selected="false"
                (click)="openTabSection()"> Your
                Location </button>
              <button class="btn btn-outline-danger mb-2" [ngClass]="(editId=='contact')?'active':''"
                id="v-pills-ContactDetail-tab" data-bs-toggle="pill" data-bs-target="#v-pills-ContactDetail"
                type="button" role="tab" aria-controls="v-pills-ContactDetail" aria-selected="false"
                (click)="openTabSection()">
                Contact Details </button>
              <button *ngIf="this.details?.profile_for!='Self'" class="btn btn-outline-danger mb-2"
                id="v-pills-HandoverProfile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-HandoverProfile"
                type="button" role="tab" aria-controls="v-pills-HandoverProfile" aria-selected="false"
                (click)="openTabSection()">
                Handover Profile </button>
            </div>
          </div>
          <div class="col-12 col-sm-8 col-lg-9 mt-3 mt-sm-0 px-0">
            <div class="tab-content" id="v-pills-tabContent">
              <div class="tab-pane fade show " [ngClass]="(editId=='aboutYou')?'active show':''" id="v-pills-home"
                role="tabpanel" aria-labelledby="v-pills-home-tab">
                <div class="row mx-0 mb-3 px-25">
                  <div class="col-9 px-0 border border-2 border-top-0 border-start-0 border-end-0">
                    <h4 class="mb-2 fw-bold">About You</h4>
                  </div>
                  <div class="col-3 px-0 text-end border border-2 border-top-0 border-start-0 border-end-0">
                    <button class="btn btn-danger py-0 px-2 ml-10px" (click)="editAboutYou()" *ngIf="!isEditAboutYou">
                      Edit
                    </button>
                  </div>
                </div>

                <!-- about you section1 start -->
                <form id="aboutYou" [formGroup]="aboutYouForm">
                  <div class="row m-0 p-o">
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="ageEdit" class="form-label mb-0 fw-bold"> First Name <span class="text-danger">
                              *</span>
                          </label>
                        </div>
                        <div class="col-12">
                          <input *ngIf="isEditAboutYou" (input)="NameSpaceNotAllowed($event)"
                            (paste)="pasteText($event)" (keypress)="omit_special_char($event)"
                            [ngClass]="{ 'is-invalid': submitted && aboutYouF.first_name.errors }" type="text"
                            class="form-control" [formControlName]="'first_name'" id="nameEdit"
                            autocomplete="" />
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditAboutYou && details.first_name">
                            {{ details.first_name }}
                          </p>
                          <p class="mb-0 blank-box animated-background-name" style="
                          width :60%" *ngIf="!isEditAboutYou && !details.first_name">&nbsp;
                          </p>
                        </div>
                        <div *ngIf="
                        aboutYouSubmitted && aboutYouF.first_name.errors
                        ">
                          <div *ngIf="aboutYouF.first_name.errors.required"
                            class="form-label mb-2 py-1 fw-bold text-danger"> First Name is
                            required </div>
                          <div *ngIf="aboutYouF.first_name.errors.pattern"
                            class="form-label mb-2 py-1 fw-bold text-danger">First name is not valid</div>
                          <div *ngIf="aboutYouF.first_name.hasError('maxlength')"
                            class="form-label mb-2 py-1 fw-bold text-danger">Your First name cannot exceed 25
                            characters.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="ageEdit" class="form-label mb-0 fw-bold"> Last Name <span class="text-danger">
                              *</span>
                          </label>
                        </div>
                        <div class="col-12">
                          <input *ngIf="isEditAboutYou" (input)="NameSpaceNotAllowed($event)"
                            (paste)="pasteTextLastname($event)" (keypress)="omit_special_char($event)"
                            [ngClass]="{ 'is-invalid': submitted && aboutYouF.last_name.errors }" type="text"
                            class="form-control" [formControlName]="'last_name'" id="nameEdit"
                            autocomplete="" />
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditAboutYou && details.last_name">
                            {{ details.last_name }}
                          </p>
                          <p class="mb-0 blank-box-communication animated-background-name"
                            *ngIf="!isEditAboutYou && !details.last_name">
                            &nbsp;</p>
                        </div>
                        <div *ngIf="
                        aboutYouSubmitted && aboutYouF.last_name.errors
                        ">
                          <div *ngIf="aboutYouF.last_name.errors.required"
                            class="form-label mb-2 py-1 fw-bold text-danger"> Last Name is
                            required </div>
                          <div *ngIf="aboutYouF.last_name.errors.pattern"
                            class="form-label mb-2 py-1 fw-bold text-danger">Last name is not valid</div>

                          <div *ngIf="aboutYouF.last_name.hasError('maxlength')"
                            class="form-label mb-2 py-1 fw-bold text-danger">Your Last name cannot exceed 25 characters.
                          </div>

                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="ageEdit" class="form-label mb-0 fw-bold"> Display Name <span class="text-danger">
                              *</span>
                          </label>
                        </div>
                        <div class="col-12 select-box">
                          <div class="col-auto" *ngIf="isEditAboutYou">
                            <div class="form-check form-check-inline p-0 me-2 mb-1" *ngIf="isEditAboutYou">
                              <input class="form-check-input" type="radio" [formControlName]="'display_name'" value="{{
                              displayFirstName }}" id="firstName" />
                              <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="firstName">
                                {{ displayFirstName }}
                              </label>
                            </div>
                            <div class="form-check form-check-inline p-0 me-2 mb-1" *ngIf="isEditAboutYou">
                              <input class="form-check-input" type="radio" [formControlName]="'display_name'" value="{{
                              displayLastName }}" id="lastName" />
                              <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="lastName">
                                {{ displayLastName }}
                              </label>
                            </div>
                            <div class="form-check form-check-inline p-0 me-2 mb-1" *ngIf="isEditAboutYou">
                              <input class="form-check-input" type="radio" [formControlName]="'display_name'" value="{{
                              displayUniqueKey }}" id="displayName" />
                              <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="displayName">
                                {{ displayUniqueKey }}
                              </label>
                            </div>
                            <div *ngIf="
                            aboutYouSubmitted &&
                            aboutYouF.display_name.errors
                            ">
                              <div class="form-label mb-2 py-1 fw-bold text-danger"> Display
                                Name is required </div>
                            </div>
                          </div>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditAboutYou && details.display_name">
                            {{ details.display_name }}
                          </p>
                          <p class="mb-0 "
                            [ngClass]="(!details.display_name && !details.unique_key)?'blank-box-communication animated-background-name':''"
                            *ngIf="!isEditAboutYou && !details.display_name">
                            {{ details.unique_key }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="ageEdit" class="form-label mb-0 fw-bold">Age <span class="text-danger"> *</span>
                          </label>
                        </div>
                        <div class="col-12">
                          <div class="col-auto" *ngIf="isEditAboutYou">
                            <input type="text" class="form-control" [readonly]="isEditAboutYou"
                              value="{{ this.age }} " />
                            <input class="form-check-input" type="" [formControlName]="'age'" />
                          </div>
                          <p class="mb-0" *ngIf="!isEditAboutYou && details.age">
                            {{ this.age }} years
                          </p>
                          <p class="mb-0 " *ngIf="!isEditAboutYou && !details.age">
                            {{ calculatedAge }}
                          </p>

                          <p class="mb-0 blank-box-communication animated-background-name "
                            *ngIf="!isEditAboutYou && !details.age && !calculatedAge ">

                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="ageEdit" class="form-label mb-0 fw-bold">Height <span class="text-danger">
                              *</span>
                          </label>
                        </div>
                        <div class="col-12">
                          <div class="col-auto" *ngIf="isEditAboutYou">
                            <select class="form-select" aria-label="Default
                            select example" [formControlName]="'height'">
                              <option value selected disabled hidden> Select </option>
                              <option value="{{ height.i }}" *ngFor="let height of this.height">
                                {{ height.i }} cm ({{ height.i | heightConverter : "Ft" }})
                              </option>
                            </select>
                            <div *ngIf="
                            aboutYouSubmitted && aboutYouF.height.errors
                            ">
                              <div class="form-label mb-2 py-1 fw-bold text-danger"> Height is
                                required </div>
                            </div>
                          </div>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditAboutYou && details.height">
                            {{ details.height }} cm ({{ details.height | heightConverter : "Ft"
                            }})
                          </p>
                          <p class="mb-0 blank-box-communication animated-background-name"
                            *ngIf="!isEditAboutYou && !details.height">
                            &nbsp; </p>
                        </div>
                      </div>
                    </div>
                    <!-- weight  -->
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="ageEdit" class="form-label mb-0 fw-bold">Weight <span class="text-danger">
                              *</span>
                          </label>
                        </div>
                        <div class="col-12">
                          <div class="col-auto" *ngIf="isEditAboutYou">
                            <select class="form-select" aria-label="Default
                            select example" [formControlName]="'weight'">
                              <option value selected disabled hidden> Select </option>
                              <option value="{{ weight.i }}" *ngFor="let weight of this.weight">
                                {{ weight.i}} kg ({{ weight.i | weightConverter : "Pounds" }} lb)
                              </option>
                            </select>
                            <div *ngIf="
                            aboutYouSubmitted && aboutYouF.weight.errors
                            ">
                              <div class="form-label mb-2 py-1 fw-bold text-danger"> Weight is
                                required </div>
                            </div>
                          </div>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditAboutYou && details.weight">
                            {{ details.weight
                            }} kg ({{ details.weight | weightConverter : "Pounds" }} lb)
                          </p>
                          <p class="mb-0 blank-box-communication animated-background-name"
                            *ngIf="!isEditAboutYou && !details.weight"> &nbsp; </p>
                        </div>
                      </div>
                    </div>
                    <!-- language  -->
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="ageEdit" class="form-label mb-0 fw-bold"> Preferred Language
                            <span class="text-danger"> *</span>
                          </label>
                        </div>
                        <div class="col-12">
                          <div class="col-auto" *ngIf="isEditAboutYou">
                            <select class="form-select" aria-label="Default
                            select example" [formControlName]="'languages'" (change)="onChangeAYLanguage()">
                              <option value selected disabled hidden> Select </option>
                              <option value="Bengali">Bengali</option>
                              <option value="English">English</option>
                              <option value="Hindi">Hindi</option>
                              <option value="Punjabi">Punjabi</option>
                              <option value="Gujarati">Gujarati</option>
                              <option value="Marathi">Marathi</option>
                              <option value="Tamil">Tamil</option>
                              <option value="Telugu">Telugu</option>
                              <option value="other">Other</option>
                            </select>
                            <input *ngIf="isAboutYouLanguageOther" type="text" class="form-control mt-2"
                              [formControlName]="'otherLanguage'" autocomplete=""
                              (input)="NameSpaceNotAllowed($event)" id="otherLanguage" />
                            <div *ngIf="
                            aboutYouSubmitted && aboutYouF.languages.errors
                            ">
                              <div class="form-label mb-2 py-1 fw-bold text-danger"> Preferred Language is required
                              </div>
                            </div>
                          </div>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditAboutYou && details.languages">
                            {{ details.languages  | addSpaceAfterComma}}
                          </p>
                          <p class="mb-0 blank-box-communication animated-background-name"
                            *ngIf="!isEditAboutYou && !details.languages"> &nbsp;
                          </p>
                        </div>
                        <div *ngIf="
                        aboutYouSubmitted && aboutYouF.languages.errors
                        ">
                          <div *ngIf="aboutYouF.languages.errors.required"
                            class="form-label mb-2 py-1 fw-bold text-danger"> Language is
                            required </div>
                        </div>
                        <div *ngIf="
                        aboutYouSubmitted && aboutYouF.otherLanguage.errors
                        ">
                          <div *ngIf="aboutYouF.otherLanguage.errors.required"
                            class="form-label mb-2 py-1 fw-bold text-danger"> Language is
                            required </div>
                          <div *ngIf="aboutYouF.otherLanguage.hasError('maxlength')"
                            class="form-label mb-2 py-1 fw-bold text-danger">Language cannot exceed 25 characters.
                          </div>

                        </div>
                      </div>
                    </div>
                    <!-- marital_status -->
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="ageEdit" class="form-label mb-0 fw-bold"> Marital Status
                            <span class="text-danger"> *
                            </span>
                          </label>
                        </div>
                        <div class="col-12">
                          <div class="col-auto" *ngIf="isEditAboutYou">
                            <select class="form-select" aria-label="Default
                            select example" [formControlName]="'marital_status'" (change)="checkMaritalStatus()">
                              <option value selected disabled hidden> Select </option>
                              <option value="Annulled">Annulled</option>
                              <option value="Awaiting Divorce"> Awaiting Divorce </option>
                              <option value="Divorced">Divorced</option>
                              <option value="Single">Single</option>
                              <option value="Widowed">Widowed</option>
                            </select>
                          </div>
                          <div *ngIf="!isEditAboutYou">
                            <p class="mb-0 text-muted text-break" *ngIf=" details.marital_status">
                              {{ details.marital_status }}
                            </p>
                            <span *ngIf="!isEditAboutYou"></span>
                            <p class="mb-0 text-muted text-break blank-box-communication animated-background-name"
                              *ngIf="!details.marital_status"> &nbsp; </p>
                          </div>
                        </div>
                        <div *ngIf="
                        aboutYouSubmitted && aboutYouF.marital_status.errors
                        ">
                          <div class="form-label mb-2 py-1 fw-bold text-danger"> Marital Status is
                            required </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-md-6" *ngIf="aboutYouShowChildren">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="PartnermaritalStatusEdit" class="form-label mb-0 fw-bold">
                            Have Children </label>
                        </div>
                        <div class="col-12">
                          <div class="col-auto" *ngIf="isEditAboutYou">
                            <select class="form-select" aria-label="Default
                            select example" [formControlName]="'have_children'" (change)="checkHaveChildren()">
                              <option value selected disabled hidden> Select </option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                          </div>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditAboutYou && details.have_children">
                            {{ details.have_children }}
                          </p>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditAboutYou && !details.have_children">
                            &nbsp;
                          </p>
                        </div>
                      </div>
                    </div>

                    <!-- have children  -->

                    <div class="col-12 mt-3" *ngIf="aboutYouHaveChildren">
                      <div class="row row-cols-2 gx-5 text-center">
                        <div class="col-12 col-md-6 d-flex">
                          <div class>
                            <div class="form-check form-check-inline p-0 mb-0 me-1" *ngIf="!isEditAboutYou">
                              <input class="form-check-input" [disabled]="!isTrue" type="checkbox"
                                [formControlName]="'boy_child'" id="BoyChild" value="BoyChild">
                              <label class="form-check-label p-2 rounded-3 shadow-sm border border-1 text-primary"
                                for="BoyChild">
                                <i class="fas fa-male fs-2"></i>
                              </label>
                            </div>
                            <div class="form-check form-check-inline p-0 mb-0 me-1" *ngIf="isEditAboutYou">
                              <input class="form-check-input" type="checkbox" [formControlName]="'boy_child'"
                                id="BoyChild" value="BoyChild" (change)="checkBoyChild()">
                              <label class="form-check-label p-2 rounded-3 shadow-sm border border-1 text-primary"
                                for="BoyChild">
                                <i class="fas fa-male fs-2"></i>
                              </label>
                            </div>

                          </div>
                          <div class="mx-2" *ngIf="aboutYouBoyChild">
                            <input type="text" class="width-80 form-control text-center"
                              [formControlName]="'boy_child_not_married'" maxlength="2" *ngIf="isEditAboutYou"
                              (input)="keyPressNumeric1($event)" (paste)="pasteBoyChildNotMarried($event)"
                              id="boyChildNotMarried" placeholder="0">
                            <input type="text" class="width-80 form-control text-center"
                              [formControlName]="'boy_child_not_married'" *ngIf="!isEditAboutYou" readonly
                              id="boyChildNotMarried" placeholder="0">
                            <p class="p-sm mb-0">Not married</p>

                          </div>
                          <div class *ngIf="aboutYouBoyChild">
                            <input type="text" class="width-80 form-control text-center"
                              [formControlName]="'boy_child_married'" *ngIf="!isEditAboutYou" readonly
                              id="boyChildMarried" placeholder="0">
                            <input type="text" class="width-80 form-control text-center"
                              [formControlName]="'boy_child_married'" maxlength="2" *ngIf="isEditAboutYou"
                              (input)="keyPressNumeric1($event)" (paste)="pasteBoyChildMarried($event)"
                              id="boyChildMarried" placeholder="0">
                            <p class="p-sm mb-0">Married</p>
                          </div>
                        </div>

                        <div class="col-12 col-md-6 d-flex">
                          <div class>
                            <div class="form-check form-check-inline p-0 mb-0 me-1" *ngIf="isEditAboutYou">
                              <input class="form-check-input" type="checkbox" [formControlName]="'girl_child'"
                                id="GirlChild" value="GirlChild" (change)="checkGirlChild()">
                              <label class="form-check-label p-2 rounded-3 shadow-sm border border-1 " for="GirlChild">
                                <i class="fas fa-female fs-2"></i>
                              </label>
                            </div>
                            <div class="form-check form-check-inline p-0 mb-0 me-1" *ngIf="!isEditAboutYou">
                              <input class="form-check-input" type="checkbox" [formControlName]="'girl_child'"
                                id="GirlChild" value="GirlChild" [disabled]="isTrue">
                              <label class="form-check-label p-2 rounded-3 shadow-sm border border-1 " for="GirlChild">
                                <i class="fas fa-female fs-2"></i>
                              </label>
                            </div>
                          </div>
                          <div class="mx-2" *ngIf="aboutYouGirlChild">
                            <input type="text" class="width-80 form-control text-center"
                              [formControlName]="'girl_child_not_married'" *ngIf="isEditAboutYou" maxlength="2"
                              (input)="keyPressNumeric1($event)" (paste)="pasteGirlChildNotMarried($event)"
                              id="girlChildNotMarried" placeholder="0">
                            <input type="text" class="width-80 form-control text-center"
                              [formControlName]="'girl_child_not_married'" *ngIf="!isEditAboutYou" readonly
                              id="girlChildNotMarried" placeholder="0">
                            <p class="p-sm mb-0">Not married</p>
                          </div>
                          <div class *ngIf="aboutYouGirlChild">
                            <input type="text" class="width-80 form-control text-center"
                              [formControlName]="'girl_child_married'" id="girl_child_married" *ngIf="!isEditAboutYou"
                              readonly placeholder="0">

                            <input type="text" class="width-80 form-control text-center"
                              [formControlName]="'girl_child_married'" *ngIf="isEditAboutYou" id="girl_child_married"
                              maxlength="2" (input)="keyPressNumeric1($event)" (paste)="pasteGirlChildMarried($event)"
                              placeholder="0">
                            <p class="p-sm mb-0">Married</p>
                          </div>
                        </div>
                      </div>

                    </div>
                    <!-- have children  -->

                    <div class="col-12">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="ageEdit" class="form-label mb-0 fw-bold"> Personality
                            Description <span class="text-danger"> *</span>
                          </label>
                        </div>
                        <div class="col-12" [ngClass]="!(isEditAboutYou)?'scroller-h':''">
                          <textarea *ngIf="isEditAboutYou" class="form-control w-400" rows="4"
                            [formControlName]="'description'" [(ngModel)]="details.description" id="nameEdit"
                            autocomplete="" maxlength="1000" (keydown)="wordCounteraboutMe($event)" #aboutMe
                            (paste)="pasteNumeric8($event)" [ngClass]="{
                          'is-invalid':
                          aboutYouSubmitted &&
                          aboutYouF.description.errors
                          }"></textarea>
                          <div class="form-label mb-2 py-1 fw-bold text-danger" *ngIf="isEditAboutYou"> <span
                              *ngIf="exceedLimit"> You cannot enter
                              more than the maximum of 100 words </span></div>
                          <p *ngIf="isEditAboutYou"><span class="text-muted p-xsm">Max 100 Words.
                            </span>
                            <span class="text-muted p-xsm">Entered- {{words1}} Words</span>
                          </p>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditAboutYou && details.description">
                            {{ details.description }}
                          </p>
                          <p class="mb-0 blank-box animated-background-name"
                            *ngIf="!isEditAboutYou && !details.description"> &nbsp;
                          </p>
                          <div *ngIf="
                          aboutYouSubmitted && aboutYouF.description.errors
                          ">
                            <div class="form-label mb-2 py-1 fw-bold text-danger"> Personality
                              Description is required
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="row">
                        <div class="col-12 mt-" *ngIf="isEditAboutYou">
                          <button class="btn btn-danger shadow-sm" (click)="saveAboutYou()" [disabled]="aboutYouButton">
                            Save & Update </button>
                          <button class="btn btn-light border border-1 border-danger shadow-sm ms-3"
                            (click)="closeAboutYou()"> Cancel </button>
                        </div>
                      </div>
                    </div>
                    <div class="mb-2"></div>
                  </div>
                </form>
                <!-- about you section ends -->
              </div>
              <!-- about content loader -->

              <!-- about content loader end -->
              <!--   Basics & Lifestyle section start    -->
              <div class="tab-pane mx-0 fade h-472" [ngClass]="(editId=='basicAndLifestyle')?'active show':''"
                id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                <div class="row mx-0 mb-3 px-25">
                  <div class="col-9 px-0 border border-2 border-top-0 border-start-0 border-end-0 ps-sm-0">
                    <h4 class="mb-2 fw-bold">Basics & Lifestyle</h4>
                  </div>
                  <div class="col-3 px-0 text-end border border-2 border-top-0 border-start-0 border-end-0">
                    <button class="btn btn-danger py-0 px-2 ml-10px" (click)="editBasicLifeStyle()"
                      *ngIf="!isEditBasic">
                      Edit
                    </button>
                  </div>
                </div>
                <form id="basicAndLifestyle" [formGroup]="basicAndLifestyleForm">
                  <div class="row mx-0 px-0">
                    <div class="col-12 col-md-6" hidden>
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="DOBEdit" class="form-label mb-0 fw-bold">Date of Birth <span class="text-danger">
                              *</span>
                          </label>
                        </div>
                        <div class="col-12">
                          <div class="col-12" *ngIf="isEditBasic">
                            <div class="col-4 mb-3" style="float: left">
                              <ng-select [items]="years" bindLabel="name" bindValue="name" placeholder="Select
                              Year" [formControlName]="'year'" (change)="onChangeBasicYear()" [ngClass]="{
                              'is-invalid':
                              basicAndLifestyleSubmitted &&
                              basicAndLifestyleF.year.errors
                              }"></ng-select>
                              <div *ngIf="
                              basicAndLifestyleSubmitted &&
                              basicAndLifestyleF.year.errors
                              ">
                                <div class="form-label mb-2 py-1 fw-bold text-danger"> Year
                                  is required </div>
                              </div>
                            </div>
                            <div class="col-4 mb-3" style="float: left">
                              <ng-select [items]="monthSel" bindLabel="name" bindValue="id" placeholder="Select
                              Month" labelForId="mont" [formControlName]="'month'" (change)="onChangeBasicMonth()"
                                [ngClass]="{
                              'is-invalid':
                              basicAndLifestyleSubmitted &&
                              basicAndLifestyleF.month.errors
                              }"></ng-select>
                              <div *ngIf="
                              basicAndLifestyleSubmitted &&
                              basicAndLifestyleF.month.errors
                              ">
                                <div class="form-label mb-2 py-1 fw-bold text-danger"> Month
                                  is required </div>
                              </div>
                            </div>
                            <div class="col-4 mb-3" style="float: left">
                              <ng-select [items]="dateSel" bindLabel="name" bindValue="name" placeholder="Select
                              Date" [formControlName]="'day'" [ngClass]="{
                              'is-invalid':
                              basicAndLifestyleSubmitted &&
                              basicAndLifestyleF.day.errors
                              }"></ng-select>
                              <div *ngIf="
                              basicAndLifestyleSubmitted &&
                              basicAndLifestyleF.day.errors
                              ">
                                <div class="form-label mb-2 py-1 fw-bold text-danger"> Date
                                  is required </div>
                              </div>
                            </div>
                          </div>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditBasic && details.dob">
                            {{ details.dob | date : "dd-MM-yyyy" }}
                          </p>
                          <p class="mb-0" *ngIf="!isEditBasic && !details.dob"> &nbsp; </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="FoodPreferenceEdit" class="form-label mb-0 fw-bold">Food
                            Preference <span class="text-danger"> *</span>
                          </label>
                        </div>
                        <div class="col-12">
                          <div class="col-auto" *ngIf="isEditBasic">
                            <select class="form-select" aria-label="Default
                            select example" [formControlName]="'food_preference'" (change)="basicFoodCheck()">
                              <option value selected disabled hidden> Select </option>
                              <option value="Veg">Veg</option>
                              <option value="Non Veg">Non Veg</option>
                              <option value="Occasionally Non-Veg">Occasionally Non-Veg
                              </option>
                              <option value="Eggetarian">Eggetarian</option>
                              <option value="Pescatarian">Pescatarian</option>
                              <option value="Jain">Jain</option>
                              <option value="Vegan">Vegan</option>
                              <option value="Other">Other</option>

                            </select>
                            <input *ngIf="isBasicFoodOther" (input)="NameSpaceNotAllowed($event)" type="text"
                              class="form-control mt-2" [formControlName]="'basicOtherFood'" id="nameEdit">

                          </div>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditBasic && details.food_preference">
                            {{ details.food_preference | addSpaceAfterComma }}
                          </p>
                          <p class="mb-0" *ngIf="!isEditBasic && !details.food_preference"> &nbsp;
                          </p>
                        </div>

                        <div *ngIf="basicAndLifestyleSubmitted && basicAndLifestyleF.food_preference.errors">
                          <div *ngIf="basicAndLifestyleF.food_preference.errors.required "
                            class="form-label mb-2 py-1 fw-bold text-danger"> food
                            Preference is required </div>
                          <!-- basicAndLifestyleF.basicOtherFood.errors.required -->
                        </div>

                        <div *ngIf="basicAndLifestyleSubmitted && basicAndLifestyleF.basicOtherFood.errors">
                          <div *ngIf="basicAndLifestyleF.basicOtherFood.errors.required"
                            class="form-label mb-2 py-1 fw-bold text-danger">food
                            Preference is required.
                          </div>
                          <div *ngIf="basicAndLifestyleF.basicOtherFood.hasError('maxlength')"
                            class="form-label mb-2 py-1 fw-bold text-danger">food
                            Preference cannot exceed 25 characters.
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="GrewUpInEdit" class="form-label mb-0 fw-bold">Grew Up
                            In</label>
                        </div>
                        <div class="col-12">
                          <ng-select *ngIf="isEditBasic" [items]="this.countries" bindLabel="fullnumber" bindValue="id"
                            [formControlName]="'grew_up_in'" [(ngModel)]="GrewUpPhoneCode" placeholder="Select
                          Country" dropdownPosition="auto" [virtualScroll]="true">
                            <ng-template ng-label-tmp let-item="item">
                              <img height="20" width="20" src="assets/images/flag/Flag_of_{{
                              item.country_name | replaceSpaceWithUnderscore
                              }}.svg" alt="" style="margin-right: 5px" />
                              <b> {{ item.country_name }}</b>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-index="index">
                              <img height="20" width="20" src="assets/images/flag/Flag_of_{{
                              item.country_name | replaceSpaceWithUnderscore
                              }}.svg" alt="" style="margin-right: 5px" />
                              <b> {{ item.country_name }}</b>
                            </ng-template>
                          </ng-select>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditBasic && details.grew_up_in">
                            {{ grewUpInCountryName }}
                          </p>
                          <p class="mb-0" *ngIf="!isEditBasic && !details.grew_up_in">&nbsp;</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="PersonalValuesEdit" class="form-label mb-0 fw-bold">Personal
                            Values</label>
                        </div>
                        <div class="col-12">
                          <input *ngIf="isEditBasic" (input)="NameSpaceNotAllowed($event)" type="text"
                            class="form-control" [formControlName]="'personal_values'" id="nameEdit" />
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditBasic && details.personal_values">
                            {{ details.personal_values }}
                          </p>
                          <p class="mb-0" *ngIf="!isEditBasic && !details.personal_values"> &nbsp;
                          </p>
                        </div>

                        <div *ngIf="
                        basicAndLifestyleSubmitted && basicAndLifestyleF.personal_values.errors
                        ">
                          <div *ngIf="basicAndLifestyleF.personal_values.hasError('maxlength')"
                            class="form-label mb-2 py-1 fw-bold text-danger">Personal
                            Values cannot exceed 25 characters.
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="SunSignEdit" class="form-label mb-0 fw-bold"> Do you
                            smoke?</label>
                        </div>
                        <div class="col-12">
                          <div class="col-auto" *ngIf="isEditBasic">
                            <select class="form-select" aria-label="Default
                            select example" [formControlName]="'do_smoke'">
                              <option value selected disabled hidden> Select </option>
                              <option value></option>
                              <option value="Regularly">Regularly</option>
                              <option value="Sometimes">Sometimes</option>
                              <option value="Never">Never</option>
                            </select>
                          </div>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditBasic && details.do_smoke">
                            {{ details.do_smoke }}
                          </p>
                          <p class="mb-0" *ngIf="!isEditBasic && !details.do_smoke"> &nbsp; </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="SunSignEdit" class="form-label mb-0 fw-bold">Do you
                            Drink?</label>
                        </div>
                        <div class="col-12">
                          <div class="col-auto" *ngIf="isEditBasic">
                            <select class="form-select" aria-label="Default
                            select example" [formControlName]="'do_drink'">
                              <option value selected disabled hidden> Select </option>
                              <option value></option>
                              <option value="Regularly">Regularly</option>
                              <option value="Sometimes">Sometimes</option>
                              <option value="Never">Never</option>
                            </select>
                          </div>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditBasic && details.do_drink">
                            {{ details.do_drink }}
                          </p>
                          <p class="mb-0" *ngIf="!isEditBasic && !details.do_drink"> &nbsp; </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="SunSignEdit" class="form-label mb-0 fw-bold">Do you have
                            pets?</label>
                        </div>
                        <div class="col-12">
                          <div class="col-auto" *ngIf="isEditBasic">
                            <select class="form-select" aria-label="Default
                            select example" [formControlName]="'have_pet'">
                              <option value selected disabled hidden> Select </option>
                              <option value></option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                          </div>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditBasic && details.have_pet">
                            {{ details.have_pet }}
                          </p>
                          <p class="mb-0" *ngIf="!isEditBasic && !details.have_pet"> &nbsp; </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="SunSignEdit" class="form-label mb-0 fw-bold">Sun
                            Sign</label>
                        </div>
                        <div class="col-12">
                          <div class="col-auto" *ngIf="isEditBasic">
                            <select class="form-select" aria-label="Default
                            select example" [formControlName]="'sun_sign'">
                              <option value selected disabled hidden> Select </option>
                              <option value="Aries">Aries</option>
                              <option value="Taurus">Taurus</option>
                              <option value="Gemini">Gemini</option>
                              <option value="Cancer">Cancer</option>
                              <option value="Leo">Leo</option>
                              <option value="Virgo">Virgo</option>
                              <option value="Libra">Libra</option>
                              <option value="Scorpio">Scorpio</option>
                              <option value="Sagittarius">Sagittarius</option>
                              <option value="Capricorn">Capricorn</option>
                              <option value="Aquarius">Aquarius</option>
                              <option value="Pisces">Pisces</option>
                            </select>
                          </div>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditBasic && details.sun_sign">
                            {{ details.sun_sign }}
                          </p>
                          <p class="mb-0" *ngIf="!isEditBasic && !details.sun_sign"> &nbsp; </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="BloodGroupEdit" class="form-label mb-0 fw-bold">Blood
                            Group</label>
                        </div>
                        <div class="col-12">
                          <div class="col-auto" *ngIf="isEditBasic">
                            <select class="form-select" aria-label="Default
                            select example" [formControlName]="'blood_group'">
                              <option value selected disabled hidden> Select </option>
                              <option value="A+">A+</option>
                              <option value="A-">A-</option>
                              <option value="B+">B+</option>
                              <option value="B-">B-</option>
                              <option value="O+">O+</option>
                              <option value="O-">O-</option>
                              <option value="AB+">AB+</option>
                              <option value="AB-">AB-</option>
                            </select>
                          </div>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditBasic && details.blood_group">
                            {{ details.blood_group }}
                          </p>
                          <p class="mb-0" *ngIf="!isEditBasic && !details.blood_group"> &nbsp;
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="HealthInformationEdit" class="form-label mb-0 fw-bold">Health Information</label>
                        </div>
                        <div class="col-12">
                          <input *ngIf="isEditBasic" autocomplete="" (input)="NameSpaceNotAllowed($event)"
                            type="text" class="form-control" [formControlName]="'health_info'" id="nameEdit" />
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditBasic && details.health_info">
                            {{ details.health_info }}
                          </p>
                          <p class="mb-0" *ngIf="!isEditBasic && !details.health_info"> &nbsp;
                          </p>
                        </div>

                        <div *ngIf="
                        basicAndLifestyleSubmitted && basicAndLifestyleF.health_info.errors
                        ">
                          <div *ngIf="basicAndLifestyleF.health_info.hasError('maxlength')"
                            class="form-label mb-2 py-1 fw-bold text-danger">Health Information cannot exceed 25
                            characters.
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="DisabilityEdit" class="form-label mb-0 fw-bold">Disability</label>
                        </div>
                        <div class="col-12">
                          <input *ngIf="isEditBasic" autocomplete="" (input)="NameSpaceNotAllowed($event)"
                            type="text" class="form-control" [formControlName]="'disability'" id="nameEdit" />
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditBasic && details.disability">
                            {{ details.disability }}
                          </p>
                          <p class="mb-0" *ngIf="!isEditBasic && !details.disability"> &nbsp; </p>
                        </div>

                        <div *ngIf="
                        basicAndLifestyleSubmitted && basicAndLifestyleF.disability.errors
                        ">
                          <div *ngIf="basicAndLifestyleF.disability.hasError('maxlength')"
                            class="form-label mb-2 py-1 fw-bold text-danger">Disability cannot exceed 25 characters.
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="col-12">
                      <div class="row">
                        <div class="col-12 mt-2 mb-5" *ngIf="isEditBasic">
                          <button class="btn btn-danger shadow-sm" (click)="saveBasic()"
                            [disabled]="basicAndLifestyleButton"> Save & Update </button>
                          <button class="btn btn-light border border-1 border-danger shadow-sm ms-3"
                            (click)="closeBasicLifeStyle()"> Cancel </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <!-- basic details section ends -->
              </div>
              <div class="tab-pane fade mx-0 h-472" [ngClass]="(editId=='religion')?'active show':''"
                id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                <div class="row mx-0 mb-3 px-25">
                  <div class="col-9 px-0 border border-2 border-top-0 border-start-0 border-end-0 ps-sm-0">
                    <h4 class="mb-2 fw-bold">Religious Views</h4>
                  </div>
                  <div class="col-3 px-0 text-end border border-2 border-top-0 border-start-0 border-end-0">
                    <button class="btn btn-danger py-0 px-2 ml-10px" (click)="editReligion()" *ngIf="!isEditReligion">
                      Edit
                    </button>
                  </div>
                </div>
                <!-- Religious  section3 start -->
                <form id="religion" [formGroup]="religionForm">
                  <div class="row mx-0 px-0">
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="ReligionEdit" class="form-label mb-0 fw-bold">Religion <span class="text-danger">
                              *</span>
                          </label>
                        </div>
                        <div class="col-12">
                          <div class *ngIf="isEditReligion">
                            <select class="form-select" aria-label="Default
                            select example" [formControlName]="'religion'" (change)="onChangeReligion()">
                              <option value selected disabled hidden> Select </option>
                              <option value="Buddhist">Buddhist</option>
                              <option value="Christian">Christian</option>
                              <option value="Hindu">Hindu</option>
                              <option value="Jain">Jain</option>
                              <option value="Muslim">Muslim</option>
                              <option value="Parsi">Parsi</option>
                              <option value="Sikh">Sikh</option>
                              <option value="No Religion">No Religion</option>
                              <option value="Other">Other</option>
                            </select>
                            <input *ngIf="isReligionOther" (input)="NameSpaceNotAllowed($event)" type="text"
                              class="form-control mt-2" [formControlName]="'otherReligion'" autocomplete=""
                              id="otherReligion" />
                          </div>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditReligion && details.religion">
                            {{ details.religion  | addSpaceAfterComma}}
                          </p>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditReligion && !details.religion"> &nbsp;
                          </p>
                          <div *ngIf="religionSubmitted && religionF.religion.errors">
                            <div class="form-label mb-2 py-1 fw-bold text-danger"> Religion is
                              required </div>
                          </div>

                          <div *ngIf="
                          religionSubmitted && religionF.otherReligion.errors
                          ">
                            <div *ngIf="religionF.otherReligion.errors.required"
                              class="form-label mb-2 py-1 fw-bold text-danger"> Religion is
                              required </div>

                            <div *ngIf="religionF.otherReligion.hasError('maxlength')"
                              class="form-label mb-2 py-1 fw-bold text-danger">Religion cannot exceed 25 characters.
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="CommunityEdit" class="form-label mb-0 fw-bold">Community</label>
                        </div>
                        <div class="col-12">
                          <input *ngIf="isEditReligion" type="text" class="form-control" [formControlName]="'community'"
                            id="nameEdit" autocomplete="" (input)="NameSpaceNotAllowed($event)" />
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditReligion && details.community">
                            {{ details.community }}
                          </p>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditReligion && !details.community"> &nbsp;
                          </p>
                        </div>

                        <div *ngIf="
                        religionSubmitted && religionF.community.errors
                        ">
                          <div *ngIf="religionF.community.hasError('maxlength')"
                            class="form-label mb-2 py-1 fw-bold text-danger"> Community cannot exceed 25 characters.
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="CasteEdit" class="form-label mb-0 fw-bold">Caste</label>
                        </div>
                        <div class="col-12">
                          <input *ngIf="isEditReligion" type="text" class="form-control" [formControlName]="'caste'"
                            id="nameEdit" autocomplete="" (input)="NameSpaceNotAllowed($event)" />
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditReligion && details.caste">
                            {{ details.caste }}
                          </p>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditReligion && !details.caste"> &nbsp; </p>
                        </div>

                        <div *ngIf="
                        religionSubmitted && religionF.caste.errors
                        ">
                          <div *ngIf="religionF.caste.hasError('maxlength')"
                            class="form-label mb-2 py-1 fw-bold text-danger"> Caste cannot exceed 25 characters.
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="CasteEdit" class="form-label mb-0 fw-bold">Gothra/Gothram</label>
                        </div>
                        <div class="col-12">
                          <input *ngIf="isEditReligion" type="text" class="form-control" [formControlName]="'gothra'"
                            id="nameEdit" autocomplete="" (input)="NameSpaceNotAllowed($event)" />
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditReligion && details.gothra">
                            {{ details.gothra }}
                          </p>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditReligion && !details.gothra"> &nbsp; </p>
                        </div>

                        <div *ngIf="
                        religionSubmitted && religionF.gothra.errors
                        ">
                          <div *ngIf="religionF.gothra.hasError('maxlength')"
                            class="form-label mb-2 py-1 fw-bold text-danger"> Gothra/Gothram cannot exceed 25
                            characters.
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="col-12 mt-2 mb-5" *ngIf="isEditReligion">
                      <button class="btn btn-danger shadow-sm" (click)="saveReligion()" [disabled]="religionButton">
                        Save
                        &
                        Update </button>
                      <button class="btn btn-light border border-1 border-danger shadow-sm ms-3"
                        (click)="closeReligion()">
                        Cancel </button>
                    </div>
                  </div>
                </form>
              </div>
              <div class="tab-pane fade h-472" [ngClass]="(editId=='astro')?'tab-pane fade active show ':''"
                id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                <div class="row mx-0 mb-3 px-25">
                  <div class="col-9 px-0 border border-2 border-top-0 border-start-0 border-end-0 ps-sm-0">
                    <h4 class="mb-2 fw-bold">Astro Details</h4>
                  </div>
                  <div class="col-3 px-0 text-end border border-2 border-top-0 border-start-0 border-end-0">
                    <button class="btn btn-danger py-0 px-2 ml-10px" (click)="editAstro()" *ngIf="!isEditAstro"> Edit
                    </button>
                  </div>
                </div>
                <!-- Astro Details  section4 start -->
                <form id="astro" [formGroup]="astroForm">
                  <div class="row mx-0 px-0">
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="ManglikEdit" class="form-label mb-0 fw-bold"> Manglik
                          </label>
                        </div>
                        <div class="col-12">
                          <div class="col-auto" *ngIf="isEditAstro">
                            <select class="form-select" aria-label="Default
                            select example" [formControlName]="'manglik'">
                              <option value selected disabled hidden> Select </option>
                              <option value></option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                          </div>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditAstro && details.manglik">
                            {{ details.manglik }}
                          </p>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditAstro && !details.manglik"> &nbsp; </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="Dob1Edit" class="form-label mb-0 fw-bold">Date of Birth
                            <span class="text-danger"> *</span>
                          </label>
                        </div>
                        <div class="col-12">
                          <div class="col-12">
                            <div class="col-12" *ngIf="isEditAstro">
                              <div class="col-4 mb-3" style="float: left">
                                <ng-select [items]="astroyears" bindLabel="name" bindValue="name" placeholder="Select
                                Year" [formControlName]="'year'" (change)="onChangeAstroYear()" [ngClass]="{
                                'is-invalid':
                                astroSubmitted && astroF.year.errors
                                }"></ng-select>
                                <div *ngIf="astroSubmitted && astroF.year.errors">
                                  <div class="form-label mb-2 py-1 fw-bold text-danger">
                                    Year is required </div>
                                </div>
                              </div>
                              <div class="col-4 mb-3" style="float: left">
                                <ng-select [items]="astromonthSel" bindLabel="name" bindValue="id" placeholder="Select
                                Month" labelForId="mont" [formControlName]="'month'" (change)="onChangeAstroMonth()"
                                  [ngClass]="{
                                'is-invalid':
                                astroSubmitted && astroF.month.errors
                                }"></ng-select>
                                <div *ngIf="astroSubmitted && astroF.month.errors">
                                  <div class="form-label mb-2 py-1 fw-bold text-danger">
                                    Month is required </div>
                                </div>
                              </div>
                              <div class="col-4 mb-3" style="float: left">
                                <ng-select [items]="astrodateSel" bindLabel="name" bindValue="name" placeholder="Select
                                Date" [formControlName]="'day'" [ngClass]="{
                                'is-invalid':
                                astroSubmitted && astroF.day.errors
                                }"></ng-select>
                                <div *ngIf="astroSubmitted && astroF.day.errors">
                                  <div class="form-label mb-2 py-1 fw-bold text-danger">
                                    Date is required </div>
                                </div>
                              </div>
                            </div>
                            <p class="mb-0 text-muted text-break" *ngIf="!isEditAstro && details.dob">
                              {{ details.dob | date : "dd-MM-yyyy" }}
                            </p>
                            <p class="mb-0 text-muted text-break" *ngIf="!isEditAstro && !details.dob"> &nbsp; </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="TOFEdit" class="form-label mb-0 fw-bold"> Time of Birth
                          </label>
                        </div>
                        <div class="col-12 my-auto">
                          <div class="col-12" *ngIf="isEditAstro">
                            <div class="col-4 mb-3" style="float: left">
                              <select class="form-select" aria-label="Default
                              select example" [formControlName]="'hour'">
                                <option value selected disabled hidden> Select </option>
                                <option value="{{ hour.i }}" *ngFor="let hour of this.hour">
                                  {{ hour.i }}
                                </option>
                              </select>
                            </div>
                            <div class="col-4 mb-3" style="float: left">
                              <select class="form-select" aria-label="Default select example"
                                [formControlName]="'minute'">
                                <option value selected disabled hidden> Select </option>
                                <option>00</option>
                                <option>01</option>
                                <option>02</option>
                                <option>03</option>
                                <option>04</option>
                                <option>05</option>
                                <option>06</option>
                                <option>07</option>
                                <option>08</option>
                                <option>09</option>
                                <option value="{{minute.i}}" *ngFor="let minute of this.minute"> {{minute.i}}
                                </option>
                              </select>
                            </div>
                            <div class="col-4 mb-3" style="float: left">
                              <select class="form-select" aria-label="Default
                              select example" [formControlName]="'am_pm'">
                                <option value selected disabled hidden> Select </option>
                                <option>AM</option>
                                <option>PM</option>
                              </select>
                            </div>
                          </div>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditAstro && details.time_of_birth">
                            {{ details.time_of_birth }}
                          </p>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditAstro && !details.time_of_birth"> &nbsp;
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="COBEdit" class="form-label mb-0 fw-bold"> Country of
                            Birth<span class="text-danger"> *</span></label>
                        </div>
                        <div class="col-12 my-auto">
                          <!-- country code -->
                          <div class="col-12" *ngIf="isEditAstro">
                            <ng-select [items]="this.countries" bindLabel="fullnumber" bindValue="id"
                              [formControlName]="'country_of_birth'" [(ngModel)]="countryOfBirth" placeholder="Select
                            Country" dropdownPosition="auto" [virtualScroll]="true">
                              <ng-template ng-label-tmp let-item="item">
                                <img height="20" width="20" src="assets/images/flag/Flag_of_{{
                                item.country_name
                                | replaceSpaceWithUnderscore
                                }}.svg" alt="" style="margin-right: 5px" />
                                <b> {{ item.country_name }}</b>
                              </ng-template>
                              <ng-template ng-option-tmp let-item="item" let-index="index">
                                <img height="20" width="20" src="assets/images/flag/Flag_of_{{
                                item.country_name
                                | replaceSpaceWithUnderscore
                                }}.svg" alt="" style="margin-right: 5px" />
                                <b> {{ item.country_name }}</b>
                              </ng-template>
                            </ng-select>
                            <div *ngIf="astroSubmitted && astroF.country_of_birth.errors">
                              <div class="form-label mb-2 py-1 fw-bold text-danger">
                                Country of Birth is required </div>
                            </div>
                          </div>
                          <!-- country code  -->
                          <p class="mb-0 text-muted text-break" *ngIf="
                          !isEditAstro && details.country_of_birth">
                            {{ birthCountryName }}
                          </p>
                          <p class="mb-0 text-muted text-break" *ngIf="
                          !isEditAstro && !details.country_of_birth"> &nbsp; </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="COBEdit" class="form-label mb-0 fw-bold"> City/State of
                            Birth</label>
                        </div>
                        <div class="col-12">
                          <input *ngIf="isEditAstro" type="text" class="form-control"
                            [formControlName]="'city_of_birth'" id="nameEdit" (input)="NameSpaceNotAllowed($event)"
                            (keypress)="omit_special_char($event)" />
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditAstro && details.city_of_birth">
                            {{ details.city_of_birth }}
                          </p>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditAstro && !details.city_of_birth"> &nbsp;
                          </p>
                        </div>

                        <div *ngIf="
                        astroSubmitted && astroF.city_of_birth.errors
                        ">
                          <div *ngIf="astroF.city_of_birth.hasError('maxlength')"
                            class="form-label mb-2 py-1 fw-bold text-danger"> City/State of
                            Birth cannot exceed 25 characters.
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="col-12 mt-2 mb-5" *ngIf="isEditAstro">
                      <button class="btn btn-danger shadow-sm" (click)="saveAstro()" [disabled]="astroButton"> Save &
                        Update
                      </button>
                      <button class="btn btn-light border border-1 border-danger shadow-sm ms-3" (click)="closeAstro()">
                        Cancel
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div class="tab-pane fade" [ngClass]="(editId=='familyDetails')?'active show':''" id="v-pills-Family"
                role="tabpanel" aria-labelledby="v-pills-Family-tab">
                <div class="row mx-0 mb-3 px-25">
                  <div class="col-9 px-0 border border-2 border-top-0 border-start-0 border-end-0 ps-sm-0">
                    <h4 class="mb-2 fw-bold">Family Details</h4>
                  </div>
                  <div class="col-3 px-0 text-end border border-2 border-top-0 border-start-0 border-end-0">
                    <button class="btn btn-danger py-0 px-2 ml-10px" (click)="editFamily()" *ngIf="!isEditFamily"> Edit
                    </button>
                  </div>
                </div>
                <form id="familyDetails" [formGroup]="familyDetailForm">
                  <div class="row mx-0 px-0">
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="FatherStatusEdit" class="form-label mb-0 fw-bold">Father's
                            Status</label>
                        </div>
                        <div class="col-12">
                          <input *ngIf="isEditFamily" type="text" class="form-control"
                            [formControlName]="'father_status'" id="nameEdit" autocomplete=""
                            (input)="NameSpaceNotAllowed($event)" (keypress)="omit_special_char($event)" />
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditFamily && details.father_status">
                            {{ details.father_status }}
                          </p>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditFamily && !details.father_status"> &nbsp;
                          </p>
                        </div>

                        <div *ngIf="
                        familySubmitted && familyDetailF.father_status.errors
                        ">
                          <div *ngIf="familyDetailF.father_status.hasError('maxlength')"
                            class="form-label mb-2 py-1 fw-bold text-danger"> Father's
                            Status cannot exceed 25 characters.
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="MotherStatusEdit" class="form-label mb-0 fw-bold">Mother's
                            Status</label>
                        </div>
                        <div class="col-12">
                          <input *ngIf="isEditFamily" type="text" class="form-control"
                            [formControlName]="'mother_status'" id="nameEdit" autocomplete=""
                            (input)="NameSpaceNotAllowed($event)" (keypress)="omit_special_char($event)" />
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditFamily && details.mother_status">
                            {{ details.mother_status }}
                          </p>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditFamily && !details.mother_status"> &nbsp;
                          </p>
                        </div>

                        <div *ngIf="
                        familySubmitted && familyDetailF.mother_status.errors
                        ">
                          <div *ngIf="familyDetailF.mother_status.hasError('maxlength')"
                            class="form-label mb-2 py-1 fw-bold text-danger"> Mother's
                            Status cannot exceed 25 characters.
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="NoBrothersEdit" class="form-label mb-0 fw-bold">No. of
                            Brothers</label>
                        </div>
                        <div class="col-12">
                          <input *ngIf="isEditFamily" type="text" class="form-control"
                            [formControlName]="'no_of_brother'" maxlength="2" id="nameEdit"
                            (input)="keyPressNumeric1($event)" (paste)="pasteNumbrother($event)" />
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditFamily && details.no_of_brother">
                            {{ details.no_of_brother }}
                          </p>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditFamily && !details.no_of_brother"> &nbsp;
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="NoSistersEdit" class="form-label mb-0 fw-bold">No. of
                            Sisters</label>
                        </div>
                        <div class="col-12">
                          <input *ngIf="isEditFamily" type="text" class="form-control"
                            [formControlName]="'no_of_sister'" maxlength="2" id="nameEdit"
                            (input)="keyPressNumeric1($event)" (paste)="pasteNumsister($event)" />
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditFamily && details.no_of_sister">
                            {{ details.no_of_sister }}
                          </p>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditFamily && !details.no_of_sister"> &nbsp;
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="StateResidenceEdit" class="form-label mb-0 fw-bold">Country</label>
                        </div>
                        <div class="col-12 my-auto">
                          <div class="col-12" *ngIf="isEditFamily">
                            <ng-select [items]="this.countries" bindLabel="fullnumber" bindValue="id"
                              [formControlName]="'family_living_in_country'" (change)="onChangeFamilyCountry($event)"
                              [(ngModel)]="familyPhoneCode" placeholder="Select
                            Country" dropdownPosition="auto" [virtualScroll]="true">
                              <ng-template ng-label-tmp let-item="item">
                                <img height="20" width="20" src="assets/images/flag/Flag_of_{{
                                item.country_name
                                | replaceSpaceWithUnderscore
                                }}.svg" alt="" style="margin-right: 5px" />
                                <b> {{ item.country_name }}</b>
                              </ng-template>
                              <ng-template ng-option-tmp let-item="item" let-index="index">
                                <img height="20" width="20" src="assets/images/flag/Flag_of_{{
                                item.country_name
                                | replaceSpaceWithUnderscore
                                }}.svg" alt="" style="margin-right: 5px" />
                                <b> {{ item.country_name }}</b>
                              </ng-template>
                            </ng-select>
                          </div>
                          <p class="mb-0 text-muted text-break" *ngIf="
                          !isEditFamily && details.family_living_in_country
                          ">
                            {{ familyCountryName }}
                          </p>
                          <p class="mb-0 text-muted text-break" *ngIf="
                          !isEditFamily && !details.family_living_in_country
                          "> &nbsp; </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="StateResidenceEdit" class="form-label mb-0 fw-bold">State/Province</label>
                        </div>
                        <div class="col-12 my-auto">
                          <div class="col-12" *ngIf="isEditFamily">
                            <select class="form-select py-2 bg-light" id="State" aria-label="Default
                            select example" [formControlName]="'family_state_name'">
                              <option value selected disabled hidden> Select </option>
                              <option value="{{ state.id }}" *ngFor="let state of this.familyStates">
                                {{ state.state_name }}
                              </option>
                            </select>
                          </div>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditFamily && details.family_state_name">
                            {{ familyStateName }}
                          </p>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditFamily && !details.family_state_name">
                            &nbsp;
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="StateResidenceEdit" class="form-label mb-0 fw-bold">City</label>
                        </div>
                        <div class="col-12 my-auto">
                          <div class="col-12" *ngIf="isEditFamily">
                            <input type="text" class="form-control bg-light" id="Currentcity" placeholder="Enter"
                              (input)="NameSpaceNotAllowed($event)" (keypress)="omit_special_char($event)"
                              autocomplete="" [formControlName]="'family_current_city'" />
                          </div>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditFamily && details.family_current_city">
                            {{ details.family_current_city }}
                          </p>
                          <p class="mb-0 text-muted text-break" *ngIf="
                          !isEditFamily && !details.family_current_city
                          "> &nbsp; </p>
                        </div>

                        <div *ngIf="
                        familySubmitted && familyDetailF.family_current_city.errors
                        ">
                          <div *ngIf="familyDetailF.family_current_city.hasError('maxlength')"
                            class="form-label mb-2 py-1 fw-bold text-danger"> City cannot exceed 25 characters.
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="PostalCodeEdit" class="form-label mb-0 fw-bold">Zip/Pin/Postal Code</label>
                        </div>
                        <div class="col-12 my-auto">
                          <input *ngIf="isEditFamily" type="text" class="form-control"
                            [formControlName]="'family_zip_code'" id="nameEdit" (input)="NameSpaceNotAllowed($event)"
                            (input)="keyPressFamilypostal($event)" />
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditFamily && details.family_zip_code">
                            {{ details.family_zip_code }}
                          </p>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditFamily && !details.family_zip_code">
                            &nbsp;
                          </p>
                        </div>

                        <div *ngIf="
                        familySubmitted && familyDetailF.family_zip_code.errors
                        ">
                          <div *ngIf="familyDetailF.family_zip_code.hasError('maxlength')"
                            class="form-label mb-2 py-1 fw-bold text-danger"> Zip/Pin/Postal Code can not exceed 15
                            characters.
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="FamilyTypeEdit" class="form-label mb-0 fw-bold"> Living With
                            Family <span class="text-danger"> *</span></label>
                        </div>
                        <div class="col-12">
                          <div class="col-auto" *ngIf="isEditFamily">
                            <select class="form-select" aria-label="Default
                            select example" [formControlName]="'living_with_family'" (change)="familyTypeCheck()">
                              <option value selected disabled hidden> Select </option>
                              <option>Yes</option>
                              <option>No</option>
                            </select>
                          </div>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditFamily && details.living_with_family">
                            {{ details.living_with_family }}
                          </p>
                          <p class="mb-0 text-muted text-break" *ngIf="
                          !isEditFamily &&
                          !details.living_with_family
                          "> &nbsp; </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6" *ngIf="FamilyTypeSelf">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="FamilyTypeEdit" class="form-label mb-0 fw-bold"> Family
                            Type</label>
                        </div>
                        <div class="col-12 my-auto">
                          <div class="col-12" style="float: left" *ngIf="isEditFamily">
                            <select class="form-select" aria-label="Default
                            select example" [formControlName]="'family_type'">
                              <option value selected disabled hidden> Select </option>
                              <option value="Extended Family"> Extended Family </option>
                              <option value="Immediate Family"> Immediate Family </option>
                              <option value="Joint Family"> Joint Family </option>
                              <option value="Nuclear Family"> Nuclear Family </option>
                            </select>
                          </div>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditFamily && details.family_type">
                            {{ details.family_type }}
                          </p>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditFamily && !details.family_type"> &nbsp;
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="FamilyValuesEdit" class="form-label mb-0 fw-bold"> Family
                            Values </label>
                        </div>
                        <div class="col-12 my-auto">
                          <input *ngIf="isEditFamily" type="text" class="form-control"
                            [formControlName]="'family_values'" id="nameEdit" (input)="NameSpaceNotAllowed($event)"
                            (keypress)="omit_special_char($event)" autocomplete="" />
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditFamily && details.family_values">
                            {{ details.family_values }}
                          </p>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditFamily && !details.family_values"> &nbsp;
                          </p>
                        </div>

                        <div *ngIf="
                        familySubmitted && familyDetailF.family_values.errors
                        ">
                          <div *ngIf="familyDetailF.family_values.hasError('maxlength')"
                            class="form-label mb-2 py-1 fw-bold text-danger"> Family
                            Values cannot exceed 25 characters.
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="NativePlaceEdit" class="form-label mb-0 fw-bold"> Native
                            Place </label>
                        </div>
                        <div class="col-12 my-auto">
                          <input *ngIf="isEditFamily" type="text" class="form-control"
                            [formControlName]="'native_place'" id="nameEdit" (input)="NameSpaceNotAllowed($event)"
                            (keypress)="omit_special_char($event)" autocomplete="" />
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditFamily && details.native_place">
                            {{ details.native_place }}
                          </p>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditFamily && !details.native_place"> &nbsp;
                          </p>
                        </div>

                        <div *ngIf="
                        familySubmitted && familyDetailF.native_place.errors
                        ">
                          <div *ngIf="familyDetailF.native_place.hasError('maxlength')"
                            class="form-label mb-2 py-1 fw-bold text-danger"> Native
                            Place cannot exceed 25 characters.
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="col-12 col-md-6" *ngIf="Affluence">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="FamilyAffluenceEdit" class="form-label mb-0 fw-bold"> Family
                            Affluence </label>
                        </div>
                        <div class="col-12 my-auto">
                          <input *ngIf="isEditFamily" type="text" class="form-control"
                            [formControlName]="'family_affluence'" id="nameEdit" maxlength="100"
                            autocomplete="" />
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditFamily && details.family_affluence">
                            {{ details.family_affluence }}
                          </p>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditFamily && !details.family_affluence">
                            &nbsp;
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mt-2 mb-5" *ngIf="isEditFamily">
                      <button class="btn btn-danger shadow-sm" (click)="saveFamily()" [disabled]="familyButton"> Save &
                        Update
                      </button>
                      <button class="btn btn-light border border-1 border-danger shadow-sm ms-3"
                        (click)="closeFamily()">
                        Cancel
                      </button>
                    </div>
                  </div>
                </form>
                <!-- family section ends -->
              </div>
              <div class="tab-pane fade h-472" [ngClass]="(editId=='education')?'active show':''"
                id="v-pills-EducationCareer" role="tabpanel" aria-labelledby="v-pills-EducationCareer-tab">
                <div class="row mx-0 mb-3 px-25">
                  <div class="col-9 px-0 border border-2 border-top-0 border-start-0 border-end-0 ps-sm-0">
                    <h4 class="mb-2 fw-bold"> Education & Professional Details </h4>
                  </div>
                  <div class="col-3 px-0 text-end border border-2 border-top-0 border-start-0 border-end-0">
                    <button class="btn btn-danger py-0 px-2 ml-10px" (click)="editEducation()" *ngIf="!isEditEducation">
                      Edit
                    </button>
                  </div>
                </div>
                <!-- Education section6 start -->
                <form id="education" [formGroup]="educationForm">
                  <div class="row mx-0 px-0">
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="EducationEdit" class="form-label mb-0 fw-bold">Education
                            <span class="text-danger"> *</span>
                          </label>
                        </div>
                        <div class="col-12">
                          <div *ngIf="isEditEducation">
                            <select class="form-select" [formControlName]="'qualification'" [ngClass]="{
                            'is-invalid':
                            educationSubmitted &&
                            educationF.qualification.errors
                            }" (change)="educationCheck()">
                              <option value selected disabled hidden> Select </option>
                              <option value="High School"> High School </option>
                              <option value="Some College"> Some College </option>
                              <option value="Some University"> Some University </option>
                              <option value="Associate`s Degree"> Associate's Degree </option>
                              <option value="Bachelor`s Degree"> Bachelor's Degree </option>
                              <option value="Master`s Degree"> Master's Degree </option>
                              <option value="Graduate Degree"> Graduate Degree </option>
                              <option value="PhD/Post Doctorate"> PhD/Post Doctorate </option>
                              <option value="Other">Other</option>
                            </select>
                            <input *ngIf="isQualificationOther" type="text" class="form-control mt-2"
                              [formControlName]="'otherQualification'" autocomplete=""
                              (input)="NameSpaceNotAllowed($event)" id="otherQualification">
                          </div>
                          <div *ngIf="
                          educationSubmitted &&
                          educationF.qualification.errors
                          ">
                            <div class="form-label mb-2 py-1 fw-bold text-danger"> Education is
                              required </div>
                          </div>

                          <div *ngIf="
                          educationSubmitted &&
                          educationF.otherQualification.errors
                          ">
                            <div *ngIf="educationF.otherQualification.errors.required"
                              class="form-label mb-2 py-1 fw-bold text-danger"> Education is
                              required </div>

                            <div *ngIf="educationF.otherQualification.hasError('maxlength')"
                              class="form-label mb-2 py-1 fw-bold text-danger">Religion cannot exceed 50 characters.
                            </div>
                          </div>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditEducation && details.qualification">
                            {{ details.qualification  | addSpaceAfterComma}}
                          </p>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditEducation && !details.qualification">
                            &nbsp;
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="CollegeNameEdit" class="form-label mb-0 fw-bold">
                            College/University Name </label>
                        </div>
                        <div class="col-12 my-auto">
                          <input *ngIf="isEditEducation" type="text" class="form-control"
                            [formControlName]="'college_name'" id="nameEdit" (input)="NameSpaceNotAllowed($event)"
                            autocomplete="" />
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditEducation && details.college_name">
                            {{ details.college_name }}
                          </p>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditEducation && !details.college_name">
                            &nbsp;
                          </p>
                        </div>

                        <div *ngIf="
                        educationSubmitted && educationF.college_name.errors
                        ">
                          <div *ngIf="educationF.college_name.hasError('maxlength')"
                            class="form-label mb-2 py-1 fw-bold text-danger"> College/University Name cannot exceed 100
                            characters.
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="WorkingWithEdit" class="form-label mb-0 fw-bold">Nature of
                            Work <span class="text-danger"> *</span>
                          </label>
                        </div>
                        <div class="col-12 my-auto">
                          <div *ngIf="isEditEducation">
                            <select class="form-select" [formControlName]="'nature_of_work'" (change)="NatureOfWork()"
                              [ngClass]="{
                            'is-invalid':
                            educationSubmitted &&
                            educationF.nature_of_work.errors
                            }">
                              <option value selected disabled hidden> Select </option>
                              <option value="Business">Business</option>
                              <option value="Government Company"> Government Company </option>
                              <option value="Private Company"> Private Company </option>
                              <option value="Self Employed"> Self Employed </option>
                              <option value="Not Working">Not Working</option>
                              <option value="Other">Other</option>
                            </select>
                            <input *ngIf="isNatureOfWorkOther" type="text" class="form-control mt-2"
                              [formControlName]="'otherNatureOfWork'" autocomplete=""
                              (input)="NameSpaceNotAllowed($event)" id="otherNatureOfWork" />
                          </div>

                          <p class="mb-0 text-muted text-break" *ngIf="!isEditEducation && details.nature_of_work">
                            {{ details.nature_of_work  | addSpaceAfterComma}}
                          </p>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditEducation && !details.nature_of_work">
                            &nbsp;
                          </p>
                        </div>

                        <div *ngIf="educationSubmitted && educationF.nature_of_work.errors">
                          <div class="form-label mb-2 py-1 fw-bold text-danger"> Nature of
                            Work is required </div>
                        </div>

                        <div *ngIf="educationSubmitted && educationF.otherNatureOfWork.errors">
                          <div *ngIf="educationF.otherNatureOfWork.errors.required"
                            class="form-label mb-2 py-1 fw-bold text-danger"> Education is
                            required </div>
                          <div *ngIf="educationF.otherNatureOfWork.hasError('maxlength')"
                            class="form-label mb-2 py-1 fw-bold text-danger">Religion cannot exceed 50 characters.
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="WorkingAsEdit" class="form-label mb-0 fw-bold">Profession
                            Area</label>
                        </div>
                        <div class="col-12 my-auto">
                          <div class="col-auto" *ngIf="isEditEducation">
                            <select class="form-select" aria-label="Default
                            select example" [formControlName]="'working_as'" (change)="onChangeProfession()">
                              <option value selected disabled hidden> Select </option>
                              <option>Accounting - Banking & Finance</option>
                              <option>Administration & HR</option>
                              <option>Airline & Aviation</option>
                              <option>Agriculture</option>
                              <option>Beauty - Fashion & Jewellery Designers</option>
                              <option>Sales & Marketing</option>
                              <option>Merchant Navy</option>
                              <option>Other</option>
                            </select>
                            <input *ngIf="isProfessionOther" type="text" class="form-control mt-2"
                              [formControlName]="'otherProfession'" autocomplete=""
                              (input)="NameSpaceNotAllowed($event)" id="otherProfession" />
                          </div>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditEducation && details.working_as">
                            {{ details.working_as | addSpaceAfterComma}}
                          </p>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditEducation && !details.working_as"> &nbsp;
                          </p>
                        </div>

                        <div *ngIf="educationSubmitted && educationF.otherProfession.errors">
                          <div *ngIf="educationF.otherProfession.hasError('maxlength')"
                            class="form-label mb-2 py-1 fw-bold text-danger">Profession
                            Area cannot exceed 50 characters.
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="CompanyNameEdit" class="form-label mb-0 fw-bold"> Company
                            Name </label>
                        </div>
                        <div class="col-12 my-auto">
                          <input *ngIf="isEditEducation" type="text" class="form-control"
                            [formControlName]="'company_name'" id="nameEdit" (input)="NameSpaceNotAllowed($event)"
                            autocomplete="" />
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditEducation && details.company_name">
                            {{ details.company_name }}
                          </p>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditEducation && !details.company_name">
                            &nbsp;
                          </p>
                        </div>

                        <div *ngIf="educationSubmitted && educationF.company_name.errors">
                          <div *ngIf="educationF.company_name.hasError('maxlength')"
                            class="form-label mb-2 py-1 fw-bold text-danger">Company
                            Name cannot exceed 50 characters.
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="AnnualIncomeEdit" class="form-label mb-0 fw-bold">Annual
                            Income</label>
                        </div>
                        <div class="col-12 my-auto">
                          <div *ngIf="isEditEducation">
                            <div class="col-12 mb-3" style="float: left">
                              <ng-select [items]="this.countries" bindLabel="fullnumber" bindValue="id"
                                [formControlName]="'income_currency'" [(ngModel)]="currencyCode" placeholder="Select
                              Country Code" dropdownPosition="auto" [virtualScroll]="true">
                                <ng-template ng-label-tmp let-item="item">
                                  <img height="20" width="20" src="assets/images/flag/Flag_of_{{
                                  item.country_name
                                  | replaceSpaceWithUnderscore
                                  }}.svg" alt="" style="margin-right: 5px" />
                                  {{ item.country_name }} <b> {{ item.currency }}</b>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-index="index">
                                  <img height="20" width="20" src="assets/images/flag/Flag_of_{{
                                  item.country_name
                                  | replaceSpaceWithUnderscore
                                  }}.svg" alt="" style="margin-right: 5px" />
                                  {{ item.country_name }} <b> {{ item.currency }}</b>
                                </ng-template>
                              </ng-select>
                            </div>
                            <div class="col-12 mb-1" style="float: left">
                              <input *ngIf="!!this.educationForm.value.income_currency" type="text" class="form-control"
                                [formControlName]="'annual_income'" id="nameEdit" maxlength="12"
                                (input)="keyPressNumeric1($event)" (paste)="pasteNumber($event)"
                                (input)="onIncomeChange($event)" />
                            </div>
                          </div>
                          <div class="col-12" *ngIf="!isEditEducation">
                            <p class="mb-0 text-muted">
                              <span>{{ CurrencyCountryName }}
                                {{ details.annual_income | number }}
                              </span>
                            </p>
                            <p class="mb-0 text-muted" *ngIf="!details.annual_income"> &nbsp;
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mt-2 mb-5" *ngIf="isEditEducation">
                      <button class="btn btn-danger shadow-sm" (click)="saveEducation()" [disabled]="educationButton">
                        Save &
                        Update </button>
                      <button class="btn btn-light border border-1 border-danger shadow-sm ms-3"
                        (click)="closeEducation()">
                        Cancel </button>
                    </div>
                  </div>
                </form>
                <!-- Education section ends -->
              </div>
              <div class="tab-pane fade h-472" [ngClass]="(editId=='location')?'active show':''"
                id="v-pills-LocationGroom" role="tabpanel" aria-labelledby="v-pills-LocationGroom-tab">
                <div class="row mx-0 mb-3 px-25">
                  <div class="col-9 px-0 border border-2 border-top-0 border-start-0 border-end-0 ps-sm-0">
                    <h4 class="mb-2 fw-bold">Your Location</h4>
                  </div>
                  <div class="col-3 px-0 text-end border border-2 border-top-0 border-start-0 border-end-0">
                    <button class="btn btn-danger py-0 px-2 ml-10px" (click)="editLocation()" *ngIf="!isEditLocation">
                      Edit
                    </button>
                  </div>
                </div>
                <!-- Residency section7 start -->
                <form id="location" [formGroup]="locationForm">
                  <div class="row mx-0 px-0">
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="StateResidenceEdit" class="form-label mb-0 fw-bold">Country
                            <span class="text-danger">
                              *</span>
                          </label>
                        </div>
                        <div class="col-12 my-auto">
                          <!-- country code -->
                          <div class="col-12" *ngIf="isEditLocation">
                            <ng-select [items]="this.countries5" bindLabel="fullnumber" bindValue="id"
                              [formControlName]="'living_in_country'" (change)="onChangeCountryLocation($event)"
                              [(ngModel)]="phoneCode" placeholder="Select
                            Country" dropdownPosition="auto" [virtualScroll]="true" [ngClass]="{
                            'is-invalid':
                            locationSubmitted &&
                            locationF.state_name.errors
                            }">
                              <ng-template ng-label-tmp let-item="item">
                                <img height="20" width="20" src="assets/images/flag/Flag_of_{{
                                item.country_name
                                | replaceSpaceWithUnderscore
                                }}.svg" alt="" style="margin-right: 5px" />
                                <b> {{ item.country_name }}</b>
                              </ng-template>
                              <ng-template ng-option-tmp let-item="item" let-index="index">
                                <img height="20" width="20" src="assets/images/flag/Flag_of_{{
                                item.country_name
                                | replaceSpaceWithUnderscore
                                }}.svg" alt="" style="margin-right: 5px" />
                                <b> {{ item.country_name }}</b>
                              </ng-template>
                            </ng-select>
                            <div *ngIf="countryRequired">
                              <div class="form-label mb-2 py-1 fw-bold text-danger"> Country
                                is required</div>
                            </div>
                          </div>
                          <!-- country code  -->
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditLocation && details.living_in_country">
                            {{locationCountryName}}
                          </p>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditLocation && !details.living_in_country">
                            &nbsp; </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="StateResidenceEdit" class="form-label mb-0 fw-bold">State/Province <span
                              class="text-danger"> *</span>
                          </label>
                        </div>
                        <div class="col-12 my-auto">
                          <div class="col-12" *ngIf="isEditLocation">
                            <select class="form-select py-2 bg-light" id="State" aria-label="Default
                            select example" [formControlName]="'state_name'" [ngClass]="{
                            'is-invalid':
                            locationSubmitted &&
                            locationF.state_name.errors
                            }">
                              <option value selected disabled hidden> Select </option>
                              <option value="{{ state.id }}" *ngFor="let state of this.locationstates">
                                {{ state.state_name }}
                              </option>
                            </select>
                            <div *ngIf="
                            locationSubmitted && locationF.state_name.errors
                            ">
                              <div class="form-label mb-2 py-1 fw-bold text-danger"> State is
                                required </div>
                            </div>
                          </div>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditLocation && details.state_name">
                            {{ locationStateName }}
                          </p>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditLocation && !details.state_name"> &nbsp;
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="StateResidenceEdit" class="form-label mb-0 fw-bold"> Current
                            City <span class="text-danger"> *</span>

                          </label>
                        </div>
                        <div class="col-12 my-auto">
                          <input *ngIf="isEditLocation" type="text" class="form-control"
                            [formControlName]="'current_city'" id="nameEdit" maxlength="100" autocomplete=""
                            (input)="NameSpaceNotAllowed($event)" (keypress)="omit_special_char($event)" [ngClass]="{
                          'is-invalid':
                          locationSubmitted &&
                          locationF.current_city.errors
                          }" />

                          <p class="mb-0 text-muted text-break" *ngIf="!isEditLocation && details.current_city">
                            {{ details.current_city }}
                          </p>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditLocation && !details.current_city"> &nbsp;
                          </p>
                        </div>
                        <div *ngIf="
                        locationSubmitted && locationF.current_city.errors
                        ">
                          <div *ngIf="locationF.current_city.errors.required"
                            class="form-label mb-2 py-1 fw-bold text-danger">Current City
                            is required </div>

                          <div *ngIf="locationF.current_city.hasError('maxlength')"
                            class="form-label mb-2 py-1 fw-bold text-danger">Current City cannot exceed 50 characters.
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="PostalCodeEdit" class="form-label mb-0 fw-bold">Zip/Pin/Postal Code
                          </label>
                        </div>
                        <div class="col-12 my-auto">
                          <input *ngIf="isEditLocation" type="text" class="form-control"
                            [formControlName]="'postal_code'" id="nameEdit" maxlength="15"
                            (input)="keyPressLocationpostal($event)" [ngClass]="{
                          'is-invalid':
                          locationSubmitted &&
                          locationF.postal_code.errors
                          }" />
                          <div *ngIf="
                          locationSubmitted && locationF.postal_code.errors
                          ">
                            <div class="form-label mb-2 py-1 fw-bold text-danger"> Zip/Postal
                              code is required </div>
                          </div>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditLocation && details.postal_code">
                            {{ details.postal_code }}
                          </p>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditLocation && !details.postal_code"> &nbsp;
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="ResidencyStatusEdit" class="form-label mb-0 fw-bold">Residency Status <span
                              class="text-danger"> *</span>
                          </label>
                        </div>
                        <div class="col-12 my-auto">
                          <div *ngIf="isEditLocation">
                            <select class="form-select" aria-label="Default
                            select example" [formControlName]="'residency_status'" [ngClass]="{
                            'is-invalid':
                            locationSubmitted &&
                            locationF.residency_status.errors
                            }" (change)="checkResidency()">
                              <option value selected disabled hidden> Select </option>
                              <option value="Citizen">Citizen</option>
                              <option value="Work Permit">Work Permit </option>
                              <option value="Permanent Resident"> Permanent Resident </option>
                              <option value="Student Visa"> Student Visa </option>
                              <option value="Visitor Visa"> Visitor Visa </option>
                              <option value="Other"> Other </option>
                            </select>
                            <input *ngIf="isResidencyOther" type="text" class="form-control mt-2"
                              [formControlName]="'otherResidency'" autocomplete="" maxlength="100"
                              id="otherResidency" />
                            <div *ngIf="locationSubmitted && locationF.residency_status.errors || locationSubmitted &&
                            locationF.otherResidency.errors ">
                              <div class="form-label mb-2 py-1 fw-bold text-danger"> Residency
                                Status is required </div>
                            </div>
                          </div>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditLocation && details.residency_status">
                            {{ details.residency_status | addSpaceAfterComma}}
                          </p>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditLocation && !details.residency_status">
                            &nbsp;
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mt-2" *ngIf="isEditLocation">
                      <button class="btn btn-danger shadow-sm" (click)="saveLocation()" [disabled]="locationButton">
                        Save
                        &
                        Update </button>
                      <button class="btn btn-light border border-1 border-danger shadow-sm ms-3"
                        (click)="closeLocation()">
                        Cancel </button>
                    </div>
                  </div>
                </form>
                <!-- Residency section ends -->
              </div>
              <div class="tab-pane fade h-472" [ngClass]="(editId=='hobby')?'active show':''" id="v-pills-Hobbies"
                role="tabpanel" aria-labelledby="v-pills-Hobbies-tab">
                <div class="row mx-0 mb-3 px-25">
                  <div class="col-9 px-0 border border-2 border-top-0 border-start-0 border-end-0 ps-sm-0">
                    <h4 class="mb-2 fw-bold">Hobbies, Interests & more</h4>
                  </div>
                  <div class="col-3 px-0 text-end border border-2 border-top-0 border-start-0 border-end-0">
                    <button class="btn btn-danger py-0 px-2 ml-10px" (click)="editHobbies()" *ngIf="!isEditHobbies">
                      Edit
                    </button>
                  </div>
                </div>
                <!-- Hobbies section8 start -->
                <form id="hobby" [formGroup]="hobbyForm">
                  <div class="row mx-0 px-0">
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="HobbiesEdit" class="form-label mb-0 fw-bold">Hobbies
                          </label>
                        </div>
                        <div class="col-12 my-auto">
                          <input *ngIf="isEditHobbies" type="text" class="form-control" [formControlName]="'hobbies'"
                            id="nameEdit" />
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditHobbies && details.hobbies">
                            {{ details.hobbies | replaceSpaceWithComa }}
                          </p>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditHobbies && !details.hobbies"> &nbsp; </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="InterestsEdit" class="form-label mb-0 fw-bold"> Interests
                          </label>
                        </div>
                        <div class="col-12 my-auto">
                          <input *ngIf="isEditHobbies" type="text" class="form-control" id="nameEdit"
                            [formControlName]="'interests'" maxlength="100" autocomplete="" />
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditHobbies && details.interests">
                            {{ details.interests }}
                          </p>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditHobbies && !details.interests"> &nbsp;
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="PreferredMoviesEdit" class="form-label mb-0 fw-bold">
                            Preferred Movies </label>
                        </div>
                        <div class="col-12 my-auto">
                          <input *ngIf="isEditHobbies" type="text" class="form-control" id="nameEdit"
                            [formControlName]="'preferred_movie'" maxlength="100" autocomplete="" />
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditHobbies && details.preferred_movie">
                            {{ details.preferred_movie }}
                          </p>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditHobbies && !details.preferred_movie">
                            &nbsp;
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="favoriteMusicEdit" class="form-label mb-0 fw-bold">
                            Favourite Music </label>
                        </div>
                        <div class="col-12 my-auto">
                          <input *ngIf="isEditHobbies" type="text" class="form-control" id="nameEdit"
                            [formControlName]="'favorite_music'" maxlength="100" autocomplete="" />
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditHobbies && details.favorite_music">
                            {{ details.favorite_music }}
                          </p>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditHobbies && !details.favorite_music">
                            &nbsp;
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="FitnessActivitiesEdit" class="form-label mb-0 fw-bold">
                            Sports/Fitness Activities
                          </label>
                        </div>
                        <div class="col-12 my-auto">
                          <input *ngIf="isEditHobbies" type="text" class="form-control" id="nameEdit"
                            [formControlName]="'sports_fitness'" maxlength="100" autocomplete="" />
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditHobbies && details.sports_fitness">
                            {{ details.sports_fitness }}
                          </p>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditHobbies && !details.sports_fitness">
                            &nbsp;
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="favoriteCuisineEdit" class="form-label mb-0 fw-bold">
                            Favourite Cuisine </label>
                        </div>
                        <div class="col-12 my-auto">
                          <input *ngIf="isEditHobbies" type="text" class="form-control" id="nameEdit"
                            [formControlName]="'favorite_cuisine'" maxlength="100" autocomplete="" />
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditHobbies && details.favorite_cuisine">
                            {{ details.favorite_cuisine }}
                          </p>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditHobbies && !details.favorite_cuisine">
                            &nbsp;
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="PreferredStyleEdit" class="form-label mb-0 fw-bold">
                            Preferred Dress Style </label>
                        </div>
                        <div class="col-12 my-auto">
                          <input *ngIf="isEditHobbies" type="text" class="form-control" id="nameEdit"
                            [formControlName]="'preferred_dress_style'" maxlength="100" autocomplete="" />
                          <p class="mb-0 text-muted text-break" *ngIf="
                          !isEditHobbies && details.preferred_dress_style
                          ">
                            {{ details.preferred_dress_style }}
                          </p>
                          <p class="mb-0 text-muted text-break" *ngIf="
                          !isEditHobbies && !details.preferred_dress_style
                          "> &nbsp; </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mt-2 mb-5" *ngIf="isEditHobbies">
                      <button class="btn btn-danger shadow-sm" (click)="saveHobbies()" [disabled]="hobbyButton"> Save &
                        Update
                      </button>
                      <button class="btn btn-light border border-1 border-danger shadow-sm ms-3"
                        (click)="closeHobbies()">
                        Cancel </button>
                    </div>
                  </div>
                </form>
                <!-- Hobbies section ends -->
              </div>

              <div class="tab-pane fade" id="v-pills-OtherDetails" role="tabpanel"
                aria-labelledby="v-pills-OtherDetails-tab">
                <div class="row mx-0 mb-3 px-25">
                  <div class="col-9 px-0 border border-2 border-top-0 border-start-0 border-end-0 ps-sm-0">
                    <h4 class="mb-2 fw-bold">Other Details</h4>
                  </div>
                  <div class="col-3 px-0 text-end border border-2 border-top-0 border-start-0 border-end-0">
                    <button class="btn btn-danger py-0 px-2 ml-10px"> Edit </button>
                  </div>
                </div>
                <div class="row mx-0 px-0">
                  <div class="col-12 col-md-6">
                    <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                      <div class="col-12 py-2 mb-0">
                        <label for="ProfileCreatedEdit" class="form-label mb-0 fw-bold"> Profile
                          Created By </label>
                      </div>
                      <div class="col-12">
                        <p class="mb-0 text-muted text-break">Self</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                      <div class="col-12 py-2 mb-0">
                        <label for="FoodPreferenceEdit1" class="form-label mb-0 fw-bold"> Food
                          Preference </label>
                      </div>
                      <div class="col-12 my-auto">
                        <p class="mb-0 text-muted text-break">Veg</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 mt-2 mb-5">
                    <button class="btn btn-danger shadow-sm"> Save & Update </button>
                    <button class="btn btn-light border border-1 border-danger shadow-sm ms-3"> Cancel
                    </button>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade h-472" active [ngClass]="(editId=='contact')?'active show':''"
                id="v-pills-ContactDetail" role="tabpanel" aria-labelledby="v-pills-ContactDetail-tab">
                <!-- contact section9 start -->
                <div class="row mx-0 mb-3 px-25">
                  <div class="col-9 px-0 border border-2 border-top-0 border-start-0 border-end-0 ps-sm-0">
                    <h4 class="mb-2 fw-bold">Contact Details</h4>
                  </div>
                  <div class="col-3 px-0 text-end border border-2 border-top-0 border-start-0 border-end-0">
                    <button class="btn btn-danger py-0 px-2 ml-10px" (click)="editContactDetail()"
                      *ngIf="!isEditContactDetail">
                      Edit </button>
                  </div>
                </div>
                <!-- $('#contact').find('.row').find('.col-12').find('.pb-1').addClass('removeBorder'); -->
                <form id="contact" [formGroup]="contactForm">
                  <div class="row mx-0 px-0">
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="ProfileCreatedEdit" class="form-label mb-0 fw-bold"> Profile
                            Created By </label>
                        </div>
                        <div class="col-12">
                          <input type="text" class="form-control" *ngIf="isEditContactDetail"
                            [readonly]="isEditContactDetail" value=" {{ createdBy
                          }}" />

                          <p class="mb-0 text-muted text-break" *ngIf="
                          !isEditContactDetail && details.profile_created_by
                          ">
                            {{ createdBy }}
                          </p>
                          <p class="mb-0 text-muted text-break" *ngIf="
                          !isEditContactDetail &&
                          !details.profile_created_by
                          ">
                            {{ createdBy }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <!-- new first name start -->
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="ProfileCreatedEdit" class="form-label mb-0 fw-bold">
                            First Name <span class="text-danger"> *</span> </label>
                        </div>
                        <div class="col-12">
                          <input type="text" class="form-control" *ngIf="isEditContactDetail"
                            [formControlName]="'firstName'" />
                          <p class="mb-0 text-muted text-break" *ngIf="
      !isEditContactDetail && firstName
      ">
                            {{ creatorDetails?.firstName }}
                          </p>
                          <p class="mb-0 text-muted text-break" *ngIf="
      !isEditContactDetail &&
      !firstName
      ">
                            {{ creatorDetails?.firstName }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="ProfileCreatedEdit" class="form-label mb-0 fw-bold">
                            Last Name <span class="text-danger"> *</span> </label>
                        </div>
                        <div class="col-12">
                          <input type="text" class="form-control" *ngIf="isEditContactDetail"
                            [formControlName]="'lastName'" />
                          <p class="mb-0 text-muted text-break" *ngIf="
      !isEditContactDetail && lastName
      ">
                            {{ creatorDetails?.lastName }}
                          </p>
                          <p class="mb-0 text-muted text-break" *ngIf="
      !isEditContactDetail &&
      !lastName
      ">
                            {{ creatorDetails?.lastName }}
                          </p>
                        </div>
                      </div>
                    </div>

                    <!-- new first name end -->
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="MobileEdit" class="form-label mb-0 fw-bold"> Mobile No.
                          </label>
                        </div>
                        <div class="col-12">
                          <!-- mobileno code -->
                          <div *ngIf="isEditContactDetail" class="row">                                  
                            <div class="col-12 ">
                              <input type="text" class="form-control" [readonly]="isEditContactDetail"
                                value=" {{ createdByMobilCode }} - {{ createdByMobile}}" />
                            </div>
                          </div>                         
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditContactDetail && details.mobile_no">
                            {{ createdByMobilCode }} - {{ createdByMobile
                            }}
                          </p>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditContactDetail && !details.mobile_no">
                            &nbsp;
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="MobileEdit" class="form-label mb-0 fw-bold"> Email </label>
                        </div>
                        <div class="col-12">
                          <input type="text" class="form-control" *ngIf="isEditContactDetail"
                            [readonly]="isEditContactDetail" value="{{ createdByEmail
                          }}" />                          
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditContactDetail && details.email">
                            {{ createdByEmail }}
                          </p>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditContactDetail && !details.email"> &nbsp;
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6" *ngIf="details?.profile_for!='Self'">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="ContactPersonEdit" class="form-label mb-0 fw-bold"> Contact
                            Person </label>
                        </div>
                        <div class="col-12 my-auto">
                          <input *ngIf="isEditContactDetail" type="text" class="form-control" id="nameEdit"
                            [formControlName]="'contact_person'" [readonly]="isEditContactDetail" value=" {{ createdBy
                          }}" />                         
                          <p class="mb-0 text-muted text-break" *ngIf="
                          !isEditContactDetail
                          ">
                            {{ createdBy
                            }}
                          </p>                          
                          <p class="mb-0 text-muted text-break" *ngIf="
                          !isEditContactDetail && details.contact_person
                          "> &nbsp; </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6" *ngIf="details?.profile_for!='Self'">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="RelationshipMemberEdit" class="form-label mb-0 fw-bold">
                            Relationship With The Member</label>
                        </div>
                        <div class="col-12 my-auto">
                          <input type="text" class="form-control" *ngIf="isEditContactDetail"
                            [readonly]="isEditContactDetail" value="{{
                          details.profile_for }}" />                         
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditContactDetail && details.profile_for">
                            {{ details.profile_for }}
                          </p>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditContactDetail && !details.profile_for">
                            &nbsp;
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="row pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="ConvenientTimeEdit" class="form-label mb-0 fw-bold">
                            Convenient Time To Call</label>
                        </div>

                        <ng-container *ngIf="isEditContactDetail">
                          <div class="row">
                            <div class="col-6">
                              <div class="form-check p-0 mb-3">
                                <ng-select [items]="timeZoneList" bindLabel="value" bindValue="value" [multiple]="false"
                                  [(ngModel)]="selectedTimeZoneDay" placeholder="Select Time Zone"
                                  (change)="checkTimeZone()" [formControlName]="'day_time_zone'">
                                </ng-select>
                              </div>
                              <div *ngIf="!selectedTimeZoneDay && days.length == 0"
                                class="form-label mb-2 py-1 fw-bold text-danger">
                                Please select time-zone to select days
                              </div>
                            </div>
                          </div>

                          <div class="row" *ngIf="selectedTimeZoneDay">
                            <div class="col-3">
                              <div class="form-check p-0 mb-3">
                                <input class="form-check-input" type="checkbox" [formControlName]="'days'" id="Sunday"
                                  value="Sunday" (change)="dayCheck($event)" *ngIf="selectedTimeZoneDay"
                                  [(ngModel)]="selectedTimeSunday" />
                                <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="Sunday"
                                  style="
                                height: 50px"> Sunday</label>
                              </div>
                            </div>
                            <div class="col-3" *ngIf="selectedTimeSunday || selectedTimeSundayFrom">
                              <ng-select [items]="sundayTimeFrom" bindLabel="value" bindValue="value" [multiple]="false"
                                [(ngModel)]="selectedTimeSundayFrom" placeholder="Select Time from"
                                (change)="checkTimeRangeSunday()" [formControlName]="'sunday_hour_from'">
                              </ng-select>
                            </div>
                            <div class="col-3" *ngIf="selectedTimeSundayFrom">
                              <ng-select [items]="sundayTimeTo" bindLabel="value" bindValue="value" [multiple]="false"
                                [(ngModel)]="selectedTimeSundayTo" placeholder="Select Time To"
                                (change)="checkTimeRangeSunday()" [formControlName]="'sunday_hour_to'">
                              </ng-select>
                            </div>
                          </div>

                          <div class="row" *ngIf="selectedTimeZoneDay">
                            <div class="col-3">
                              <div class="form-check p-0 mb-3">
                                <input class="form-check-input" type="checkbox" [formControlName]="'days'" id="Monday"
                                  value="Monday" (change)="dayCheck($event)" *ngIf="selectedTimeZoneDay"
                                  [(ngModel)]="selectedTimeMonday" />
                                <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="Monday"
                                  style="
                                height: 50px"> Monday
                                </label>
                              </div>
                            </div>
                            <div class="col-3" *ngIf="selectedTimeMonday || selectedTimeMondayFrom">
                              <ng-select [items]="mondayTimeFrom" bindLabel="value" bindValue="value" [multiple]="false"
                                [(ngModel)]="selectedTimeMondayFrom" placeholder="Select Time From"
                                [formControlName]="'monday_hour_from'" (change)="checkTimeRangeMonday()">
                              </ng-select>
                            </div>
                            <div class="col-3" *ngIf="selectedTimeMondayFrom">
                              <ng-select [items]="mondayTimeTo" bindLabel="value" bindValue="value" [multiple]="false"
                                [(ngModel)]="selectedTimeMondayTo" placeholder="Select Time To"
                                (change)="checkTimeRangeMonday()" [formControlName]="'monday_hour_to'">
                              </ng-select>
                            </div>
                          </div>

                          <div class="row" *ngIf="selectedTimeZoneDay">
                            <div class="col-3">
                              <div class="form-check p-0 mb-3">
                                <input class="form-check-input" type="checkbox" [formControlName]="'days'" id="Tuesday"
                                  value="Tuesday" (change)="dayCheck($event)" *ngIf="selectedTimeZoneDay"
                                  [(ngModel)]="selectedTimeTuesday" />
                                <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="Tuesday"
                                  style="
                                height: 50px"> Tuesday</label>
                              </div>
                            </div>
                            <div class="col-3" *ngIf="selectedTimeTuesday || selectedTimeTuesdayFrom">
                              <ng-select [items]="tuesdayTimeFrom" bindLabel="value" bindValue="value"
                                [multiple]="false" [(ngModel)]="selectedTimeTuesdayFrom" value="Tuesday"
                                (change)="checkTimeRangeTuesday()" placeholder="Select Time from"
                                [formControlName]="'tuesday_hour_from'">

                              </ng-select>
                            </div>
                            <div class="col-3" *ngIf="selectedTimeTuesdayFrom">
                              <ng-select [items]="tuesdayTimeTo" bindLabel="value" bindValue="value" [multiple]="false"
                                [(ngModel)]="selectedTimeTuesdayTo" placeholder="Select Time To"
                                (change)="checkTimeRangeTuesday()" [formControlName]="'tuesday_hour_to'">
                              </ng-select>

                            </div>
                          </div>

                          <div class="row" *ngIf="selectedTimeZoneDay">
                            <div class="col-3">
                              <div class="form-check p-0 mb-3">
                                <input class="form-check-input" type="checkbox" [formControlName]="'days'"
                                  id="Wednesday" value="Wednesday" (change)="dayCheck($event)"
                                  *ngIf="selectedTimeZoneDay" [(ngModel)]="selectedTimeWednesday" />
                                <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="Wednesday"
                                  style="
                                height: 50px">
                                  Wednesday </label>
                              </div>
                            </div>
                            <div class="col-3" *ngIf="selectedTimeWednesday ||selectedTimeWednesdayFrom">
                              <ng-select [items]="wednesdayTimeFrom" bindLabel="value" bindValue="value"
                                [multiple]="false" [(ngModel)]="selectedTimeWednesdayFrom"
                                placeholder="Select Time from" (change)="checkTimeRangeWednesday()"
                                [formControlName]="'wednesday_hour_from'">

                              </ng-select>

                            </div>
                            <div class="col-3" *ngIf="selectedTimeWednesdayFrom">      
                              <ng-select [items]="wednesdayTimeTo" bindLabel="value" bindValue="value"
                                [multiple]="false" [(ngModel)]="selectedTimeWednesdayTo" placeholder="Select Time To"
                                (change)="checkTimeRangeWednesday()" [formControlName]="'wednesday_hour_to'">

                              </ng-select>

                            </div>
                          </div>

                          <div class="row" *ngIf="selectedTimeZoneDay">
                            <div class="col-3">
                              <div class="form-check p-0 mb-3">
                                <input class="form-check-input" type="checkbox" [formControlName]="'days'" id="Thursday"
                                  value="Thursday" (change)="dayCheck($event)" *ngIf="selectedTimeZoneDay"
                                  [(ngModel)]="selectedTimeThursday" />
                                <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="Thursday"
                                  style="
                                height: 50px">
                                  Thursday</label>
                              </div>
                            </div>
                            <div class="col-3" *ngIf="selectedTimeThursday || selectedTimeThursdayFrom">
                              <ng-select [items]="thursdayTimeFrom" bindLabel="value" bindValue="value"
                                [multiple]="false" [(ngModel)]="selectedTimeThursdayFrom" placeholder="Select Time from"
                                (change)="checkTimeRangeThursday()" [formControlName]="'thursday_hour_from'">

                              </ng-select>

                            </div>
                            <div class="col-3" *ngIf="selectedTimeThursdayFrom">
                              <ng-select [items]="thursdayTimeTo" bindLabel="value" bindValue="value" [multiple]="false"
                                [(ngModel)]="selectedTimeThursdayTo" placeholder="Select Time To"
                                (change)="checkTimeRangeThursday()" [formControlName]="'thursday_hour_to'">

                              </ng-select>

                            </div>
                          </div>

                          <div class="row" *ngIf="selectedTimeZoneDay">
                            <div class="col-3">
                              <div class="form-check p-0 mb-3">
                                <input class="form-check-input" type="checkbox" [formControlName]="'days'" id="Friday"
                                  value="Friday" (change)="dayCheck($event)" *ngIf="selectedTimeZoneDay"
                                  [(ngModel)]="selectedTimeFriday" />
                                <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="Friday"
                                  style="
                                height: 50px">Friday</label>
                              </div>
                            </div>
                            <div class="col-3" *ngIf="selectedTimeFriday || selectedTimeFridayFrom">
                              <ng-select [items]="fridayTimeFrom" bindLabel="value" bindValue="value" [multiple]="false"
                                [(ngModel)]="selectedTimeFridayFrom" placeholder="Select Time from"
                                (change)="checkTimeRangeFriday()" [formControlName]="'friday_hour_from'">

                              </ng-select>

                            </div>
                            <div class="col-3" *ngIf="selectedTimeFridayFrom">
                              <ng-select [items]="fridayTimeTo" bindLabel="value" bindValue="value" [multiple]="false"
                                [(ngModel)]="selectedTimeFridayTo" placeholder="Select Time To"
                                (change)="checkTimeRangeFriday()" [formControlName]="'friday_hour_to'">

                              </ng-select>

                            </div>
                          </div>

                          <div class="row" *ngIf="selectedTimeZoneDay">
                            <div class="col-3">
                              <div class="form-check p-0 mb-3">
                                <input class="form-check-input" type="checkbox" [formControlName]="'days'" id="Saturday"
                                  value="Saturday" (change)="dayCheck($event)" *ngIf="selectedTimeZoneDay"
                                  [(ngModel)]="selectedTimeSaturday" />
                                <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="Saturday"
                                  style="
                                height: 50px">
                                  Saturday</label>
                              </div>
                            </div>
                            <div class="col-3" *ngIf="selectedTimeSaturday || selectedTimeSaturdayFrom">
                              <ng-select [items]="saturdayTimeFrom" bindLabel="value" bindValue="value"
                                [multiple]="false" [(ngModel)]="selectedTimeSaturdayFrom" placeholder="Select Time from"
                                (change)="checkTimeRangeSaturday()" [formControlName]="'saturday_hour_from'">

                              </ng-select>

                            </div>
                            <div class="col-3" *ngIf="selectedTimeSaturdayFrom">
                              <ng-select [items]="saturdayTimeTo" bindLabel="value" bindValue="value" [multiple]="false"
                                [(ngModel)]="selectedTimeSaturdayTo" placeholder="Select Time To"
                                (change)="checkTimeRangeSaturday()" [formControlName]="'saturday_hour_to'">

                              </ng-select>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-3">
                              <div class="form-check p-0 mb-3">
                                <input class="form-check-input" type="checkbox" [formControlName]="'days'" id="Any-Time"
                                  value="Any-Time" (change)="dayCheck($event)" />
                                <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="Any-Time"
                                  style="
                                height: 50px">
                                  Any-Time </label>
                              </div>
                            </div>
                            <div *ngIf="selectedTimeZoneDay && days.length == 0"
                              class="form-label mb-2 py-1 fw-bold text-danger">
                              Please select time to call
                            </div>
                          </div>
                        </ng-container>
                        <div *ngIf="
                        !isEditContactDetail &&
                        details.convenient_time_to_call
                        ">
                          <h6 class="mb-0 text-muted text-break" *ngFor="let mes of showTime; let i= index">{{mes |
                            replaceUnderscoreWithComa}} </h6>

                        </div>
                        <p class="mb-0 text-muted text-break" *ngIf="
                        !isEditContactDetail &&
                        !details.convenient_time_to_call
                        "> &nbsp; </p>
                      </div>
                    </div>
                    <div class="col-12 mt-2 mb-5" *ngIf="isEditContactDetail">
                      <button class="btn btn-danger shadow-sm" (click)="saveContactDetail()" [disabled]="contactButton"
                        *ngIf="contactButton">
                        Save &
                        Update </button>
                      <button class="btn btn-danger shadow-sm" (click)="saveContactDetail()" [disabled]="contactButton"
                        *ngIf="!contactButton">
                        Save &
                        Update </button>
                      <button class="btn btn-light border border-1 border-danger shadow-sm ms-3"
                        (click)="closeContactDetail()">
                        Cancel </button>
                    </div>
                  </div>
                </form>
              </div>
              <div *ngIf="this.details?.profile_for!='Self'" class="tab-pane fade" id="v-pills-HandoverProfile"
                role="tabpanel" aria-labelledby="v-pills-HandoverProfile-tab">
                <div class="row mx-0 mb-3 px-25">
                  <div class="col-xl-9 col-sm-6 px-0 border border-2 border-top-0 border-start-0 border-end-0 ps-sm-0">
                    <h4 class="mb-2 fw-bold">Handover Profile</h4>
                  </div>
                  <div class="col-xl-3 col-sm-6 px-0 text-end border border-2 border-top-0 border-start-0 border-end-0">
                    <button class="btn btn-danger py-0 px-2 my-2 ml-10px" (click)="confirmHandover()"
                      *ngIf="!isEditHandOverForm">
                      Handover now
                    </button>
                    <button class="btn btn-danger py-0 px-2 ml-10px" (click)="editHandOver()"
                      *ngIf="!isEditHandOverForm">
                      Edit
                    </button>


                  </div>
                </div>
                <form id="handoverform" [formGroup]="handoverForm">
                  <div class="row mx-0 px-0">
                    <div class="col-12 col-md-6">
                      <div class="row">
                        <div class="col-12 mb-3">
                          <h6 class="fw-600">From :</h6>
                        </div>
                        <div class="col-12">
                          <div class="opacity-0 pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0
                          border-end-0">
                            <div class="col-12 py-2 mb-0">
                              <label for="ProfileCreatedEdit" class="form-label mb-0 fw-bold">
                                Profile Created By
                              </label>
                            </div>
                            <div class="col-12">
                              <p class="mb-0 text-muted text-break">&nbsp;</p>
                            </div>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                            <div class="col-12 py-2 mb-0">
                              <label for="ProfileCreatedEdit" class="form-label mb-0 fw-bold">
                                First Name </label>
                            </div>
                            <div class="col-12">
                              <input *ngIf="isEditHandOverForm" type="text" class="form-control"
                                [formControlName]="'first_name'" id="nameEdit" autocomplete="" maxlength="100"
                                readonly />
                              <p class="mb-0 text-muted text-break"
                                *ngIf="!isEditHandOverForm && creatorDetails?.firstName">
                                {{ creatorDetails?.firstName }}
                              </p>
                              <p class="mb-0" *ngIf="!isEditHandOverForm && !creatorDetails?.firstName">&nbsp;
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                            <div class="col-12 py-2 mb-0">
                              <label for="ProfileCreatedEdit" class="form-label mb-0 fw-bold">
                                Last Name </label>
                            </div>
                            <div class="col-12" *ngIf="isEditHandOverForm">
                              <input type="text" class="form-control" [formControlName]="'last_name'" id="nameEdit"
                                autocomplete="" maxlength="100" readonly />
                            </div>

                            <p class="mb-0 text-muted text-break"
                              *ngIf="!isEditHandOverForm && creatorDetails?.lastName">
                              {{creatorDetails?.lastName}}
                            </p>
                            <p class="mb-0" *ngIf="!isEditHandOverForm && !creatorDetails?.lastName">
                              &nbsp;</p>

                          </div>
                        </div>
                        <div class="col-12">
                          <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                            <div class="col-12 py-2 mb-0">
                              <label for="ProfileCreatedEdit" class="form-label mb-0 fw-bold">
                                Email ID </label>
                            </div>
                            <div class="col-12" *ngIf="isEditHandOverForm">
                              <input type="text" class="form-control" [formControlName]="'email'" id="nameEdit"
                                autocomplete="" maxlength="100" readonly />
                            </div>
                            <p class="mb-0 text-muted text-break" *ngIf="!isEditHandOverForm && creatorDetails?.email">
                              {{creatorDetails?.email}}
                            </p>
                            <p class="mb-0" *ngIf="!isEditHandOverForm && !creatorDetails?.email">&nbsp;
                            </p>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                            <div class="col-12 py-2 mb-0">
                              <label for="ProfileCreatedEdit" class="form-label mb-0 fw-bold">
                                Phone </label>
                            </div>
                            <div class="col-12" *ngIf="isEditHandOverForm">
                              <div class="col-4 mb-2">
                              </div>
                              <div class="col-8">
                                <input type="text" class="form-control" id="Mobile" placeholder="Enter"
                                  [formControlName]="'mobile_no'" readonly />

                              </div>

                            </div>
                            <p class="mb-0 text-muted text-break"
                              *ngIf="!isEditHandOverForm && creatorDetails?.mobileNo">
                              {{creatorDetails?.countryNumberCode}}
                              - {{creatorDetails?.mobileNo}}</p>
                            <p class="mb-0" *ngIf="!isEditHandOverForm && !creatorDetails?.mobileNo">
                              &nbsp;</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="row">
                        <div class="col-12 mb-3">
                          <h6 class="fw-600">To :</h6>
                        </div>
                        <div class="col-12">
                          <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                            <div class="col-12 py-2 mb-0">
                              <label for="ProfileCreatedEdit" class="form-label mb-0 fw-bold">
                                Relationship </label>
                            </div>
                            <div class="col-12" *ngIf="isEditHandOverForm"><input type="text" class="form-control"
                                [formControlName]="'relation_ship'" id="nameEdit" autocomplete=""
                                maxlength="100" readonly />
                            </div>
                            <p class="mb-0 text-muted text-break" *ngIf="!isEditHandOverForm && details?.profile_for">
                              {{details?.profile_for}}
                            </p>
                            <p class="mb-0" *ngIf="!isEditHandOverForm && !details?.profile_for">&nbsp;</p>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                            <div class="col-12 py-2 mb-0">
                              <label for="ProfileCreatedEdit" class="form-label mb-0 fw-bold">
                                First Name </label>
                            </div>
                            <div class="col-12" *ngIf="isEditHandOverForm">
                              <input type="text" class="form-control" [formControlName]="'first_name_to'" id="nameEdit"
                                autocomplete="" maxlength="100" readonly />
                            </div>
                            <p class="mb-0 text-muted text-break" *ngIf="!isEditHandOverForm && details?.first_name">
                              {{ details?.first_name }}
                            </p>
                            <p class="mb-0" *ngIf="!isEditHandOverForm && !details?.first_name  ">&nbsp;</p>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                            <div class="col-12 py-2 mb-0">
                              <label for="ProfileCreatedEdit" class="form-label mb-0 fw-bold">
                                Last Name </label>
                            </div>
                            <div class="col-12" *ngIf="isEditHandOverForm"><input type="text" class="form-control"
                                [formControlName]="'last_name_to'" id="nameEdit" autocomplete="" maxlength="100"
                                readonly />
                            </div>
                            <p class="mb-0 text-muted text-break" *ngIf="!isEditHandOverForm && details?.last_name">
                              {{details?.last_name}}
                            </p>
                            <p class="mb-0" *ngIf="!isEditHandOverForm && !details?.last_name">&nbsp;</p>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                            <div class="col-12 py-2 mb-0">
                              <label for="ProfileCreatedEdit" class="form-label mb-0 fw-bold">
                                Email ID <span class="text-danger">
                                  *</span> </label>
                            </div>
                            <div class="col-12" *ngIf="isEditHandOverForm">
                              <input type="text" class="form-control" [formControlName]="'email_to'" id="nameEdit"
                                autocomplete="" maxlength="100"
                                [ngClass]="{ 'is-invalid': handoverSubmitted && handOverF.email_to.errors }" />
                              <div *ngIf="handoverSubmitted && handOverF.email_to.errors">
                                <div *ngIf="handOverF.email_to.errors.required">
                                  <div class="form-label mb-2 py-1 fw-bold text-danger">
                                    Email is required</div>
                                </div>
                                <div *ngIf="handOverF.email_to.errors.email || handOverF.email_to.errors.pattern">
                                  <div class="form-label mb-2 py-1 fw-bold text-danger">
                                    Email must be a valid email address</div>
                                </div>
                              </div>
                            </div>
                            <p class="mb-0 text-muted text-break" *ngIf="!isEditHandOverForm && details?.email">
                              {{details?.email}}
                            </p>
                            <p class="mb-0" *ngIf="!isEditHandOverForm && !details?.email">
                              &nbsp;</p>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                            <div class="col-12 py-2 mb-0">
                              <label for="ProfileCreatedEdit" class="form-label mb-0 fw-bold">
                                Phone<span class="text-danger">
                                  *</span></label>
                            </div>
                            <div class="col-4 mb-2" *ngIf="isEditHandOverForm">

                              <ng-select [items]="this.countries" bindLabel="fullnumber" bindValue="id"
                                [formControlName]="'country_code_to'" [(ngModel)]="countryNumberTo" placeholder="Select
                              Country Code" dropdownPosition="auto" [virtualScroll]="true" [readonly]="false"
                                [clearable]="false" (change)="onChangeCountry1($event)">
                                <ng-template ng-label-tmp let-item="item">
                                  <img height="20" width="20" src="assets/images/flag/Flag_of_{{item.country_name |
                                  replaceSpaceWithUnderscore}}.svg" alt="" style="margin-right:5px;" />
                                  <b> {{item.country_name}}</b>{{item.country_code}}
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-index="index">
                                  <img height="20" width="20" src="assets/images/flag/Flag_of_{{item.country_name |
                                  replaceSpaceWithUnderscore}}.svg" alt="" style="margin-right:5px;" />
                                  <b> {{item.country_name}}</b>{{item.country_code}}
                                </ng-template>
                              </ng-select>
                            </div>
                            <div class="col-12" *ngIf="isEditHandOverForm"><input type="text" class="form-control"
                                [formControlName]="'mobile_no_to'" maxlength="15" id="nameEdit" autocomplete=""
                                [ngClass]="{ 'is-invalid': handoverSubmitted && handOverF.mobile_no_to.errors }"
                                (input)="keyPressNumericMobile($event)" (paste)="pasteNumericMobileTo($event)" />

                              <div *ngIf="handoverSubmitted && handOverF.mobile_no_to.errors">
                                <div *ngIf="handOverF.mobile_no_to.errors.required">
                                  <div class="form-label mb-2 py-1 fw-bold text-danger">
                                    Mobile number is required</div>
                                </div>
                                <div *ngIf="handOverF.mobile_no_to.errors.minlength">
                                  <div class="form-label mb-2 py-1 fw-bold text-danger">
                                    Mobile Number must be between 6 to 15 digits
                                  </div>
                                </div>
                                <div *ngIf="handOverF.mobile_no_to.errors.maxlength">
                                  <div class="form-label mb-2 py-1 fw-bold text-danger">
                                    Mobile Number must be between 6 to 15 digits
                                  </div>
                                </div>
                              </div>
                              <div *ngIf="!handoverSubmitted">
                                <div *ngIf="minNumberLength ">
                                  <div class="form-label mb-2 py-1 fw-bold text-danger">
                                    Mobile Number must be between 6 to 15 digits
                                  </div>
                                </div>
                                <div *ngIf="maxNumberLength">
                                  <div class="form-label mb-2 py-1 fw-bold text-danger">
                                    Mobile Number must be between 6 to 15 digits
                                  </div>
                                </div>
                              </div>
                              <div *ngIf="handoverSubmitted && errors">
                                <div *ngIf="errors.mobileNo">
                                  <div class="form-label mb-2 py-1 fw-bold text-danger">
                                    {{ errors?.mobileNo }}</div>
                                </div>
                              </div>

                            </div>
                            <p class="mb-0 text-muted text-break" *ngIf="!isEditHandOverForm && details?.mobile_no">
                              {{details?.country_number_code}}
                              - {{details?.mobile_no}}
                            </p>
                            <p class="mb-0" *ngIf="!isEditHandOverForm && !details?.mobile_no">&nbsp;</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mt-2 mb-5" *ngIf="isEditHandOverForm">
                      <button class="btn btn-danger shadow-sm" (click)="updateHandOver()" [disabled]="handOverButton">
                        Save
                        &
                        Update </button>
                      <button class="btn btn-light border border-1 border-danger shadow-sm ms-3"
                        (click)="closeHandOver()">
                        Cancel </button>
                    </div>
                  </div>
                </form>

              </div>
            </div>
          </div>
        </div>
        <div class="col-12 py-1 rounded-3 bg-light-2 mb-4">
          <h4 class="fw-bold text-danger mb-0 py-2" id="scrollDiv">About Partner Preferences</h4>
        </div>
        <div class="row mt-2 pt-4 h-400">
          <div class="col-12 col-sm-4 col-lg-3">
            <ul class="nav nav-pills mb-3 flex-column" id="pills-tab" role="tablist" aria-orientation="vertical">
              <li class="nav-item" role="presentation">
                <button (click)="openPartnerTabSection()" class="w-100 btn btn-outline-danger active mb-3"
                  id="pills-BasicInfo-tab" data-bs-toggle="pill" data-bs-target="#pills-BasicInfo" type="button"
                  role="tab" aria-controls="pills-BasicInfo" aria-selected="true">
                  Basics and
                  Lifestyle </button>
              </li>
              <li class="nav-item mb-3" role="presentation">
                <button (click)="openPartnerTabSection()" class="w-100 btn btn-outline-danger"
                  id="pills-ReligiousView2-tab" data-bs-toggle="pill" data-bs-target="#pills-ReligiousView2"
                  type="button" role="tab" aria-controls="pills-ReligiousView2" aria-selected="false">
                  Religious Views </button>
              </li>
              <li class="nav-item mb-3" role="presentation">
                <button (click)="openPartnerTabSection()" class="w-100 btn btn-outline-danger" id="pills-Family2-tab"
                  data-bs-toggle="pill" data-bs-target="#pills-Family2" type="button" role="tab"
                  aria-controls="pills-Family2" aria-selected="false"> Family Details </button>
              </li>
              <li class="nav-item mb-3" role="presentation">
                <button (click)="openPartnerTabSection()" class="w-100 btn btn-outline-danger"
                  id="pills-EducationCareer-tab" data-bs-toggle="pill" data-bs-target="#pills-EducationCareer"
                  type="button" role="tab" aria-controls="pills-EducationCareer" aria-selected="false">
                  Education & Professional Details </button>
              </li>
              <li class="nav-item" role="presentation">
                <button (click)="openPartnerTabSection()" class="w-100 btn btn-outline-danger"
                  id="pills-LocationDetails2-tab" data-bs-toggle="pill" data-bs-target="#pills-LocationDetails2"
                  type="button" role="tab" aria-controls="pills-LocationDetails2" aria-selected="false">
                  Location of Partner </button>
              </li>
            </ul>
          </div>
          <div class="col-12 col-sm-8 col-lg-9">
            <div class="tab-content" id="pills-tabContent">
              <div class="tab-pane fade show active" id="pills-BasicInfo" role="tabpanel"
                aria-labelledby="pills-BasicInfo-tab">
                <div class="row mb-3 px-25">
                  <div class="col-9 px-0 border border-2 border-top-0 border-start-0 border-end-0 ps-sm-0">
                    <h4 class="mb-2 fw-bold">Basics and Lifestyle</h4>
                  </div>

                  <div class="col-3 px-0 text-end border border-2 border-top-0 border-start-0 border-end-0">
                    <button class="btn btn-danger py-0 px-2 ml-10px" (click)="editPartnerBasic()"
                      *ngIf="!isEditPartnerBasic">
                      Edit </button>
                  </div>
                </div>
                <form class="h-auto" id="partnerBasic" [formGroup]="partnerBasicForm">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="PartnerAgeEdit" class="form-label mb-0 fw-bold"> Age <span class="text-danger">
                              *</span>
                          </label>
                        </div>
                        <div class="col-12 my-auto">
                          <div class="col-6" style="float: left" *ngIf="isEditPartnerBasic">
                            <select class="form-select" aria-label="Default
                            select example" [formControlName]="'age_from'" (change)="onChangeAgeFrom($event)"
                              [ngClass]="{
                            'is-invalid':
                            partnerBasicSubmitted &&
                            partnerBasicF.age_from.errors
                            }">
                              <option value selected disabled hidden> Select </option>
                              <option value="{{ age.id }}" *ngFor="let age of this.age_from">
                                {{ age.id }}
                              </option>
                            </select>
                          </div>
                          <div class="col-6 mb-2" style="float: right" *ngIf="isEditPartnerBasic">
                            <select class="form-select" aria-label="Default
                            select example" [formControlName]="'age_to'" [ngClass]="{
                            'is-invalid':
                            partnerBasicSubmitted &&
                            partnerBasicF.age_to.errors
                            }">
                              <option value selected disabled hidden> Select </option>
                              <option value="{{ age.id }}" *ngFor="let age of this.age_to">
                                {{ age.id }}
                              </option>
                            </select>
                            <div *ngIf="
                            (partnerBasicSubmitted &&
                            partnerBasicF.age_to.errors) ||
                            (partnerBasicSubmitted &&
                            partnerBasicF.age_to.errors)
                            ">
                              <div class="form-label mb-2 py-1 fw-bold text-danger"> Age is
                                required </div>
                            </div>
                          </div>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditPartnerBasic && partnerDetail.age_to">
                            {{ partnerDetail.age_from }} years - {{ partnerDetail.age_to }}
                            years
                          </p>
                          <p class="mb-0 text-muted text-break blank-box-communication animated-background-name"
                            *ngIf="!isEditPartnerBasic && !partnerDetail.age_to">
                            &nbsp;
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="PartnerHeightEdit" class="form-label mb-0 fw-bold"> Height
                            <span class="text-danger">
                              *</span>
                          </label>
                        </div>
                        <div class="col-12 my-auto">
                          <div class="col-6 mb-2" style="float: left" *ngIf="isEditPartnerBasic">
                            <select class="form-select" aria-label="Default
                            select example" [formControlName]="'height_from'" (change)="onChangeHeightFrom($event)"
                              [ngClass]="{
                            'is-invalid':
                            partnerBasicSubmitted &&
                            partnerBasicF.height_from.errors
                            }">
                              <option value selected disabled hidden> Select </option>
                              <option value="{{ height_from.i }}" *ngFor="let height_from of this.height_from">
                                {{ height_from.i }} cm ({{ height_from.i | heightConverter :
                                "Ft" }})
                              </option>
                            </select>
                          </div>
                          <div class="col-6 mb-2" style="float: right" *ngIf="isEditPartnerBasic">
                            <select class="form-select" aria-label="Default
                            select example" [formControlName]="'height_to'" [ngClass]="{
                            'is-invalid':
                            partnerBasicSubmitted &&
                            partnerBasicF.height_to.errors
                            }">
                              <option value selected disabled hidden> Select </option>
                              <option value="{{ height_to.i }}" *ngFor="let height_to of this.height_to">
                                {{ height_to.i }} cm ({{ height_to.i | heightConverter :
                                "Ft" }})
                              </option>
                            </select>
                            <div *ngIf="
                            (partnerBasicSubmitted &&
                            partnerBasicF.height_to.errors) ||
                            (partnerBasicSubmitted &&
                            partnerBasicF.height_from.errors)
                            ">
                              <div class="form-label mb-2 py-1 fw-bold text-danger"> Height is
                                required </div>
                            </div>
                          </div>
                          <p class="mb-0 text-muted text-break" *ngIf="
                          !isEditPartnerBasic && partnerDetail.height_to
                          ">
                            {{ partnerDetail.height_from }} cm ({{ partnerDetail.height_from |
                            heightConverter : "Ft" }}) -
                            {{ partnerDetail.height_to }} cm ({{ partnerDetail.height_to |
                            heightConverter : "Ft" }})
                          </p>
                          <p class="mb-0 text-muted text-break blank-box-communication animated-background-name" *ngIf="
                          !isEditPartnerBasic && !partnerDetail.height_to
                          "> &nbsp; </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="PartnerHeightEdit" class="form-label mb-0 fw-bold"> Weight

                          </label>
                        </div>
                        <div class="col-12 my-auto">
                          <div class="col-6 mb-2" style="float: left" *ngIf="isEditPartnerBasic">
                            <select class="form-select" aria-label="Default
                            select example" [formControlName]="'weight_from'" (change)="onChangeWeightFrom($event)">
                              <option value selected disabled hidden> Select </option>
                              <option value="{{ weight_from.i }}" *ngFor="let weight_from of this.weight_from">
                                {{
                                weight_from.i }} kg ({{ weight_from.i | weightConverter : "Pounds" }} lb)
                              </option>
                            </select>
                          </div>
                          <div class="col-6 mb-2" style="float: right" *ngIf="isEditPartnerBasic">
                            <select class="form-select" aria-label="Default
                            select example" [formControlName]="'weight_to'">
                              <option value selected disabled hidden> Select </option>
                              <option value="{{ weight_to.i }}" *ngFor="let weight_to of this.weight_to">
                                {{
                                weight_to.i }} kg ({{ weight_to.i | weightConverter : "Pounds" }} lb)
                              </option>
                            </select>

                          </div>
                          <p class="mb-0 text-muted text-break " *ngIf="
                          !isEditPartnerBasic && partnerDetail.weight_to
                          ">
                            {{ partnerDetail.weight_from }} kg ({{ partnerDetail.weight_from
                            | weightConverter : "Pounds"
                            }} lb) - {{ partnerDetail.weight_to }} kg ({{
                            partnerDetail.weight_to | weightConverter :
                            "Pounds"
                            }} lb )
                          </p>
                          <ng-container *ngIf="
                          !isEditPartnerBasic && !partnerDetail.weight_to
                          ">
                            <p class="mb-0 text-muted text-break "
                              [ngClass]="(!partnerDetail?.age_to)?'blank-box-communication animated-background-name':''">
                              &nbsp; </p>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="SunSignEdit" class="form-label mb-0 fw-bold"> Do you prefer
                            a partner who smokes?</label>
                        </div>
                        <div class="col-12">
                          <div class="col-auto" *ngIf="isEditPartnerBasic">
                            <select class="form-select" aria-label="Default
                            select example" [formControlName]="'do_smoke'">
                              <option value selected disabled hidden> Select </option>

                              <option value="Regularly">Regularly</option>
                              <option value="Sometimes">Sometimes</option>
                              <option value="Never">Never</option>
                            </select>
                          </div>
                          <p class="mb-0 text-muted text-break" *ngIf="
                          !isEditPartnerBasic && partnerDetail.do_smoke
                          ">
                            {{ partnerDetail.do_smoke }}
                          </p>
                          <ng-container *ngIf="!isEditPartnerBasic && !partnerDetail.do_smoke">
                            <p class="mb-0 text-muted text-break "
                              [ngClass]="(!partnerDetail.food_preference)?'blank-box-communication animated-background-name':''">
                              &nbsp; </p>
                          </ng-container>
                        </div>

                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="SunSignEdit" class="form-label mb-0 fw-bold"> Do you prefer
                            a partner who drinks alcohol?</label>
                        </div>
                        <div class="col-12">
                          <div class="col-auto" *ngIf="isEditPartnerBasic">
                            <select class="form-select" aria-label="Default
                            select example" [formControlName]="'do_drink'">
                              <option value selected disabled hidden> Select </option>
                              <option value="Regularly">Regularly</option>
                              <option value="Sometimes">Sometimes</option>
                              <option value="Never">Never</option>
                            </select>
                          </div>
                          <p class="mb-0 text-muted text-break" *ngIf="
                          !isEditPartnerBasic && partnerDetail.do_drink
                          ">
                            {{ partnerDetail.do_drink }}
                          </p>
                          <ng-container *ngIf="
                          !isEditPartnerBasic && !partnerDetail.do_drink
                          ">
                            <p class="mb-0 text-muted text-break "
                              [ngClass]="(!partnerDetail.food_preference)?'blank-box-communication animated-background-name':''">
                              &nbsp; </p>
                          </ng-container>

                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="SunSignEdit" class="form-label mb-0 fw-bold">Do you prefer a
                            partner who has pets?</label>
                        </div>
                        <div class="col-12">
                          <div class="col-auto" *ngIf="isEditPartnerBasic">
                            <select class="form-select" aria-label="Default
                            select example" [formControlName]="'have_pet'">
                              <option value selected disabled hidden> Select </option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                          </div>
                          <p class="mb-0 text-muted text-break" *ngIf="
                          !isEditPartnerBasic && partnerDetail.have_pet
                          ">
                            {{ partnerDetail.have_pet }}
                          </p>

                          <ng-container *ngIf="
                          !isEditPartnerBasic && !partnerDetail.have_pet
                          ">
                            <p class="mb-0 text-muted text-break "
                              [ngClass]="(!partnerDetail.food_preference)?'blank-box-communication animated-background-name':''">
                              &nbsp; </p>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="Partner1thLangEdit" class="form-label mb-0 fw-bold">Preferred Language <span
                              class="text-danger"> *</span>
                          </label>
                        </div>
                        <div class="col-12">
                          <div *ngIf="isEditPartnerBasic">
                            <ng-select [items]="partnerLanguages" bindLabel="name" bindValue="name" [multiple]="true"
                              [(ngModel)]="selectedlanguages" placeholder="Selectlanguages"
                              (change)="onSelectLanguages()" [formControlName]="'languages'">
                              <p>Selected Languages: {{ selectedlanguages }}</p>
                            </ng-select>
                            <input *ngIf="partnerOtherLanguage" type="text" class="form-control mt-2"
                              [formControlName]="'otherLanguage'" autocomplete="" maxlength="100"
                              id="otherLanguage" />
                          </div>
                          <div *ngIf="
                          partnerBasicSubmitted &&
                          partnerBasicF.languages.errors
                          ">
                            <div class="form-label mb-2 py-1 fw-bold text-danger"> Preferred Language is required </div>
                          </div>


                          <div>
                            <div *ngIf="partnerLanguageOtherValue && !partnerBasicF.languages.errors"
                              class="form-label mb-2 py-1 fw-bold text-danger">
                              Preferred Language
                              is required </div>
                          </div>
                          <!-- language  -->
                          <p class="mb-0 text-muted text-break " *ngIf="
                          !isEditPartnerBasic && partnerDetail.languages
                          ">
                            {{ showLanguage }}
                          </p>
                          <p class="mb-0 text-muted text-break blank-box-communication animated-background-name" *ngIf="
                          !isEditPartnerBasic && !partnerDetail.languages
                          "> &nbsp; </p>
                        </div>
                      </div>
                    </div>
                    <!-- food prefrenece start  -->
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="PartnermaritalStatusEdit" class="form-label mb-0 fw-bold">
                            Food Preference <span class="text-danger"> *</span>
                          </label>
                        </div>
                        <div class="col-12">

                          <div class="col-auto" *ngIf="isEditPartnerBasic">
                            <ng-select *ngIf="isEditPartnerBasic" [items]="partnerFoodPreference" bindLabel="name"
                              bindValue="name" [multiple]="true" [(ngModel)]="selectedFoodPreference" placeholder="Select
                            Food Preference" (change)="onSelectFoodPreference()" [formControlName]="'food_preference'">
                              <p> Selected Food Preference: {{ selectedFoodPreference }}
                              </p>
                            </ng-select>
                            <input *ngIf="partnerOtherFood" type="text" class="form-control mt-2"
                              [formControlName]="'otherFood'" autocomplete="" maxlength="100" id="otherFood" />
                          </div>

                          <div *ngIf="
                          partnerBasicSubmitted &&
                          partnerBasicF.food_preference.errors
                          ">
                            <div class="form-label mb-2 py-1 fw-bold text-danger"> Food
                              Preference is required </div>
                          </div>

                          <div>
                            <div *ngIf="partnerFoodOtherValue && !partnerBasicF.food_preference.errors"
                              class="form-label mb-2 py-1 fw-bold text-danger">
                              Food Preference is required </div>
                          </div>

                          <p class="mb-0 text-muted text-break" *ngIf="
                          !isEditPartnerBasic &&
                          partnerDetail.food_preference
                          ">
                            {{ showFood }}
                          </p>
                          <p class="mb-0 text-muted text-break blank-box-communication animated-background-name " *ngIf="
                          !isEditPartnerBasic &&
                          !partnerDetail.food_preference
                          "> &nbsp; </p>
                        </div>
                      </div>
                    </div>
                    <!-- food Preference end  -->

                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="PartnermaritalStatusEdit" class="form-label mb-0 fw-bold">
                            Marital Status <span class="text-danger"> *</span>
                          </label>
                        </div>
                        <div class="col-12">

                          <div class="col-auto" *ngIf="isEditPartnerBasic">
                            <ng-select *ngIf="isEditPartnerBasic" [items]="partnerMaritalStatus" bindLabel="name"
                              bindValue="name" [multiple]="true" [(ngModel)]="selectedMaritalStatus" placeholder="Select
                            marital status" (change)="onSelectmaritalStatus()" [formControlName]="'marital_status'"
                              [ngClass]="{
                            'is-invalid':
                            partnerBasicSubmitted &&
                            partnerBasicF.marital_status.errors
                            }">
                              <p> Selected Marital Status: {{ selectedMaritalStatus }}
                              </p>
                            </ng-select>
                          </div>
                          <div *ngIf="
                          partnerBasicSubmitted &&
                          partnerBasicF.marital_status.errors
                          ">
                            <div class="form-label mb-2 py-1 fw-bold text-danger"> Marital
                              Status is required </div>
                          </div>

                          <p class="mb-0 text-muted text-break" *ngIf="
                          !isEditPartnerBasic &&
                          partnerDetail.marital_status
                          ">
                            {{ showMarital }}
                          </p>
                          <p class="mb-0 text-muted text-break blank-box-communication animated-background-name" *ngIf="
                          !isEditPartnerBasic &&
                          !partnerDetail.marital_status
                          "> &nbsp; </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="PartnermaritalStatusEdit" class="form-label mb-0 fw-bold">
                            Have Children </label>
                        </div>
                        <div class="col-12">

                          <div class="col-auto" *ngIf="isEditPartnerBasic">
                            <select class="form-select" aria-label="Default
                            select example" [formControlName]="'have_children'">
                              <option value selected disabled hidden> Select </option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                              <option value="Does Not Matter"> Does Not Matter </option>
                            </select>
                          </div>
                          <p class="mb-0 text-muted text-break" *ngIf="
                          !isEditPartnerBasic && partnerDetail.have_children
                          ">
                            {{ partnerDetail.have_children }}
                          </p>

                          <ng-container *ngIf="
                          !isEditPartnerBasic &&
                          !partnerDetail.have_children
                          ">
                            <p class="mb-0 text-muted text-break "
                              [ngClass]="(!partnerDetail.food_preference)?'blank-box-communication animated-background-name':''">
                              &nbsp; </p>
                          </ng-container>

                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="ManglikEdit" class="form-label mb-0 fw-bold">Manglik</label>
                        </div>
                        <div class="col-12">

                          <div class="col-auto" *ngIf="isEditPartnerBasic">
                            <select class="form-select" aria-label="Default
                            select example" [formControlName]="'manglik'">
                              <option value selected disabled hidden> Select </option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                              <option value="Does Not Matter"> Does Not Matter </option>
                            </select>
                          </div>
                          <p class="mb-0 text-muted text-break" *ngIf="!isEditPartnerBasic && partnerDetail.manglik">
                            {{ partnerDetail.manglik }}
                          </p>

                          <ng-container *ngIf="
                          !isEditPartnerBasic && !partnerDetail.manglik
                          ">
                            <p class="mb-0 text-muted text-break "
                              [ngClass]="(!partnerDetail.food_preference)?'blank-box-communication animated-background-name':''">
                              &nbsp; </p>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="ageEdit" class="form-label mb-0 fw-bold"> Partner
                            Expectations <span class="text-danger"> *</span>
                          </label>
                        </div>
                        <div class="col-12">
                          <textarea *ngIf="isEditPartnerBasic" class="form-control w-400" rows="4"
                            [formControlName]="'description'" maxlength="1000" [(ngModel)]="partnerDetail.description"
                            id="nameEdit" autocomplete="" (keydown)="wordCounteraboutMe2($event)" #aboutMe2
                            (paste)="pasteNumeric82($event)"></textarea>
                          <p *ngIf="isEditPartnerBasic"><span class="text-muted p-xsm">Max 100
                              Words. </span>
                            <span class="text-muted p-xsm">Entered- {{words2}} Words</span>
                          </p>
                          <p class="mb-0 text-muted text-break" *ngIf="
                          !isEditPartnerBasic && partnerDetail.description
                          ">
                            {{ partnerDetail.description }}
                          </p>
                          <p class="mb-0 blank-box animated-background-name" *ngIf="
                          !isEditPartnerBasic && !partnerDetail.description
                          "> &nbsp; </p>
                          <div class="form-label mb-2 py-1 fw-bold text-danger" *ngIf="isEditPartnerBasic"> <span
                              *ngIf="exceedLimit1"> You cannot
                              enter more than the maximum of 100 words</span></div>

                          <div *ngIf="
                          partnerBasicSubmitted &&
                          partnerBasicF.description.errors
                          ">
                            <div class="form-label mb-2 py-1 fw-bold text-danger"> Partner
                              Expectations is required </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mt-2 mb-5" *ngIf="isEditPartnerBasic">
                      <button class="btn btn-danger shadow-sm" (click)="savePartnerBasic()"
                        [disabled]="partnerBasicButton">
                        Save & Update </button>
                      <button class="btn btn-light border border-1 border-danger shadow-sm ms-3"
                        (click)="closePartnerBasic()">
                        Cancel </button>
                    </div>
                  </div>
                </form>
              </div>
              <div class="tab-pane fade" id="pills-ReligiousView2" role="tabpanel"
                aria-labelledby="pills-ReligiousView2-tab">
                <div class="row mb-3 px-25">
                  <div class="col-9 px-0 border border-2 border-top-0 border-start-0 border-end-0 ps-sm-0">
                    <h4 class="mb-2 fw-bold">Religious Views</h4>
                  </div>
                  <div class="col-3 px-0 text-end border border-2 border-top-0 border-start-0 border-end-0">
                    <button class="btn btn-danger py-0 px-2 ml-10px" (click)="editPartnerReligion()"
                      *ngIf="!isEditPartnerReligion"> Edit </button>
                  </div>
                </div>
                <div class="row">
                  <form id="partnerReligion" [formGroup]="partnerReligionForm">
                    <div class="row">
                      <div class="col-12 col-md-6">
                        <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                          <div class="col-12 py-2 mb-0">
                            <label for="ReligionEdit" class="form-label mb-0 fw-bold">Religion
                              <span class="text-danger">
                                *</span>
                            </label>
                          </div>
                          <div class="col-12">
                            <div *ngIf="isEditPartnerReligion">
                              <ng-select [items]="partnerReligion" bindLabel="name" bindValue="name" [multiple]="true"
                                [(ngModel)]="selectedReligion" placeholder="Select
                              religion" (change)="onSelectReligion()" [formControlName]="'religion'">
                                <p>Selected Religion: {{ selectedReligion }}</p>
                              </ng-select>
                              <input *ngIf="partnerOtherReligion" type="text" class="form-control mt-2"
                                [formControlName]="'otherReligion'" autocomplete="" maxlength="100"
                                id="otherReligion" />
                            </div>
                            <div *ngIf="
                            partnerReligionSubmitted &&
                            partnerReligionF.religion.errors">
                              <div class="form-label mb-2 py-1 fw-bold text-danger"> Religion
                                is required </div>
                            </div>

                            <div>
                              <div *ngIf="partnerReligionOtherValue && !partnerReligionF.religion.errors"
                                class="form-label mb-2 py-1 fw-bold text-danger">
                                Religion
                                is required </div>
                            </div>
                            <p class="mb-0 text-muted text-break"
                              *ngIf="!isEditPartnerReligion && partnerDetail.religion">
                              {{ showReligion }}
                            </p>
                            <p class="mb-0 text-muted text-break" *ngIf="
                            !isEditPartnerReligion &&
                            !partnerDetail.religion
                            "> &nbsp; </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                          <div class="col-12 py-2 mb-0">
                            <label for="CommunityEdit" class="form-label mb-0 fw-bold">Community</label>
                          </div>
                          <div class="col-12">

                            <div class="col-12">
                              <input *ngIf="isEditPartnerReligion" type="text" class="form-control"
                                [formControlName]="'community'" id="nameEdit" autocomplete="" maxlength="100" />
                              <p class="mb-0 text-muted text-break" *ngIf="
                              !isEditPartnerReligion &&
                              partnerDetail.community
                              ">
                                {{ partnerDetail.community }}
                              </p>
                              <p class="mb-0 text-muted text-break" *ngIf="
                              !isEditPartnerReligion &&
                              !partnerDetail.community
                              "> &nbsp; </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                          <div class="col-12 py-2 mb-0">
                            <label for="CasteEdit" class="form-label mb-0 fw-bold">Caste</label>
                          </div>
                          <div class="col-12">
                            <input *ngIf="isEditPartnerReligion" type="text" class="form-control"
                              [formControlName]="'caste'" id="nameEdit" autocomplete="" maxlength="100" />
                            <p class="mb-0 text-muted text-break" *ngIf="
                            !isEditPartnerReligion && partnerDetail.caste
                            ">
                              {{ partnerDetail.caste }}
                            </p>
                            <p class="mb-0 text-muted text-break" *ngIf="
                            !isEditPartnerReligion && !partnerDetail.caste
                            "> &nbsp; </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                          <div class="col-12 py-2 mb-0">
                            <label for="CasteEdit" class="form-label mb-0 fw-bold">Gothra/Gothram</label>
                          </div>
                          <div class="col-12">
                            <input *ngIf="isEditPartnerReligion" type="text" class="form-control"
                              [formControlName]="'gothra'" id="nameEdit" autocomplete="" maxlength="100" />
                            <p class="mb-0 text-muted text-break" *ngIf="
                            !isEditPartnerReligion && partnerDetail.gothra
                            ">
                              {{ partnerDetail.gothra }}
                            </p>
                            <p class="mb-0 text-muted text-break" *ngIf="
                            !isEditPartnerReligion && !partnerDetail.gothra
                            "> &nbsp; </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 mt-2 mb-5" *ngIf="isEditPartnerReligion">
                        <button class="btn btn-danger shadow-sm" (click)="savePartnerReligion()"
                          [disabled]="partnerReligionButton"> Save & Update
                        </button>
                        <button class="btn btn-light border border-1 border-danger shadow-sm ms-3"
                          (click)="closePartnerReligion()"> Cancel </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div class="tab-pane fade" id="pills-EducationCareer" role="tabpanel"
                aria-labelledby="pills-EducationCareer-tab">
                <div class="row mb-3 px-25">
                  <div class="col-9 px-0 border border-2 border-top-0 border-start-0 border-end-0 ps-sm-0">
                    <h4 class="mb-2 fw-bold"> Education & Professional Details </h4>
                  </div>
                  <div class="col-3 px-0 border border-2 border-top-0 border-start-0 border-end-0 text-end">
                    <button class="btn btn-danger py-0 px-2 ml-10px" (click)="editPartnerEducation()"
                      *ngIf="!isEditPartnerEducation"> Edit </button>
                  </div>
                </div>
                <form id="partnerEducation" [formGroup]="partnerEducationForm">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="PartnerEducationEdit" class="form-label mb-0 fw-bold">
                            Education <span class="text-danger"> *</span>
                          </label>
                        </div>
                        <div class="col-12 my-auto">
                          <div *ngIf="isEditPartnerEducation">
                            <ng-select [items]="partnerEducation" bindLabel="name" bindValue="name" [multiple]="true"
                              [(ngModel)]="selectedPartnerEducation" placeholder="Select
                            Education" (change)="onSelectPartnerEducation()" [formControlName]="'education'">
                              <p> Selected Eduaction: {{ selectedPartnerEducation }}
                              </p>
                            </ng-select>
                            <input *ngIf="partnerOtherEducation" type="text" class="form-control mt-2"
                              [formControlName]="'otherEducation'" autocomplete="" maxlength="100"
                              id="otherEducation" />
                            <div *ngIf="
                            partnerEducationSubmitted &&
                            partnerEducationF.education.errors">
                              <div class="form-label mb-2 py-1 fw-bold text-danger"> Education
                                is required </div>
                            </div>

                            <div>
                              <div *ngIf="partnerEducationOtherValue && !partnerEducationF.education.errors"
                                class="form-label mb-2 py-1 fw-bold text-danger">
                                Education
                                is required </div>
                            </div>
                          </div>

                          <p class="mb-0 text-muted text-break" *ngIf="
                          !isEditPartnerEducation && partnerDetail.education
                          ">
                            {{showEducation}}
                          </p>
                          <p class="mb-0 text-muted text-break" *ngIf="
                          !isEditPartnerEducation &&
                          !partnerDetail.education
                          "> &nbsp; </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-9 my-auto py-2 mb-0">
                          <label for="PartnerWorkingWithEdit" class="form-label mb-0 fw-bold">
                            Nature of work <span class="text-danger"> *</span>
                          </label>
                        </div>
                        <div class="col-12 my-auto">
                          <div *ngIf="isEditPartnerEducation">
                            <ng-select [items]="partnerNatureOfWork" bindLabel="name" bindValue="name" [multiple]="true"
                              [(ngModel)]="selectedPartnerNatureOfWork" placeholder="Select
                            Nature Of Work" (change)="onSelectPartnerNatureOfWork()"
                              [formControlName]="'nature_of_work'">
                              <p> Selected Nature Of Work: {{ selectedPartnerNatureOfWork }}
                              </p>
                            </ng-select>
                            <input *ngIf="partnerOtherNatureOfWork" type="text" class="form-control mt-2"
                              [formControlName]="'otherNatureOfWork'" autocomplete="" maxlength="100"
                              id="otherNatureOfWork" />
                            <div *ngIf="
                            partnerEducationSubmitted &&
                            partnerEducationF.nature_of_work.errors">
                              <div class="form-label mb-2 py-1 fw-bold text-danger"> Nature of
                                work
                                is required </div>
                            </div>

                            <div>
                              <div *ngIf="partnerNatureOfWorkOtherValue && !partnerEducationF.nature_of_work.errors"
                                class="form-label mb-2 py-1 fw-bold text-danger">
                                Nature of
                                work
                                is required </div>
                            </div>
                          </div>

                          <p class="mb-0 text-muted text-break" *ngIf="
                          !isEditPartnerEducation &&
                          partnerDetail.nature_of_work
                          ">
                            {{ showNatureOfWork }}
                          </p>
                          <p class="mb-0 text-muted text-break" *ngIf="
                          !isEditPartnerEducation &&
                          !partnerDetail.nature_of_work
                          "> &nbsp; </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="PartnerProfessionAreaEdit" class="form-label mb-0 fw-bold">
                            Profession Area </label>
                        </div>
                        <div class="col-12">
                          <div *ngIf="isEditPartnerEducation">
                            <ng-select [items]="partnerProfession" bindLabel="name" bindValue="name" [multiple]="true"
                              [(ngModel)]="selectedPartnerProfession" placeholder="Select
                            Nature Of Work" (change)="onSelectPartnerProfession()"
                              [formControlName]="'profession_area'">
                              <p> Selected Profession: {{ selectedPartnerProfession }}
                              </p>
                            </ng-select>
                            <input *ngIf="partnerOtherProfession" type="text" class="form-control mt-2"
                              [formControlName]="'otherProfession'" autocomplete="" maxlength="100"
                              id="otherProfession" />
                          </div>
                          <p class="mb-0 text-muted text-break" *ngIf="
                          !isEditPartnerEducation &&
                          partnerDetail.profession_area
                          ">
                            {{ showProfession}}
                          </p>
                          <p class="mb-0 text-muted text-break" *ngIf="
                          !isEditPartnerEducation &&
                          !partnerDetail.profession_area
                          "> &nbsp; </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="PartnerAnnualIncomeEdit" class="form-label mb-0 fw-bold">
                            Annual Income<span class="text-danger"> *</span> </label>
                        </div>
                        <div class="col-12">
                          <div *ngIf="isEditPartnerEducation">
                            <select class="form-select" [formControlName]="'annual_income'" (change)="annualIncome()">
                              <option value selected disabled hidden> Select </option>
                              <option value="Open To All">Open To All</option>
                              <option value="SpecifyRange"> Specify Range </option>
                            </select>
                          </div>
                          <div *ngIf="partnerEducationF.annual_income.errors">
                            <div class="form-label mb-2 py-1 fw-bold text-danger">Annual income is required</div>
                          </div>
                          <p class="mb-0 text-muted text-break" *ngIf="
                          !isEditPartnerEducation &&
                          partnerDetail.annual_income
                          ">
                            {{ partnerDetail.annual_income }}
                          </p>
                          <p class="mb-0 text-muted text-break" *ngIf="
                          !isEditPartnerEducation &&
                          !partnerDetail.annual_income
                          "> &nbsp; </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6" *ngIf="partnerIncomeRange">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-12 py-2 mb-0">
                          <label for="AnnualIncomeEdit" class="form-label mb-0 fw-bold">Annual
                            Income Range</label>
                        </div>
                        <div *ngIf="isEditPartnerEducation" class="row mb-1">
                          <div class="col-12 mb-3">
                            <ng-select [items]="this.countries" bindLabel="fullnumber" bindValue="id"
                              [formControlName]="'income_currency'" [(ngModel)]="partnerCurrencyCode" placeholder="Select
                            Country Code" dropdownPosition="auto" [virtualScroll]="true">
                              <ng-template ng-label-tmp let-item="item">
                                <img height="20" width="20" src="assets/images/flag/Flag_of_{{
                                item.country_name
                                | replaceSpaceWithUnderscore
                                }}.svg" alt="" style="margin-right: 5px" />
                                {{ item.country_name }} <b> {{ item.currency }}</b>
                              </ng-template>
                              <ng-template ng-option-tmp let-item="item" let-index="index">
                                <img height="20" width="20" src="assets/images/flag/Flag_of_{{
                                item.country_name
                                | replaceSpaceWithUnderscore
                                }}.svg" alt="" style="margin-right: 5px" />
                                {{ item.country_name }} <b> {{ item.currency}}</b>
                              </ng-template>
                            </ng-select>
                          </div>
                          <div *ngIf=" partnerEducationF.income_currency.errors">
                            <div class="form-label mb-2 py-1 fw-bold
                                text-danger">
                              Annual income currency is required
                            </div>
                          </div>


                          <div class="col-6">
                            <input type="text" class="form-control" [formControlName]="'income_range_from'"
                              id="nameEdit" maxlength="12" (keyup)="checkIncomeRange()"
                              (input)="keyPressNumeric1($event)" (paste)="pasteNumericFrom($event)" />
                          </div>

                          <div *ngIf=" partnerEducationF.income_range_from.errors">
                            <div class="form-label mb-2 py-1 fw-bold
                                text-danger">
                              Annual income range from is required
                            </div>
                          </div>

                          <div class="col-6">
                            <input type="text" class="form-control" [formControlName]="'income_range_to'" id="nameEdit"
                              maxlength="12" (keyup)="checkIncomeRange()" (input)="keyPressNumeric1($event)"
                              (paste)="pasteNumericTo($event)" />
                          </div>
                          <div *ngIf="partnerEducationF.income_range_to.errors">
                            <div class="form-label mb-2 py-1 fw-bold
                                text-danger">
                              Annual income range to is required
                            </div>
                          </div>

                          <div *ngIf="incomeRange">
                            <div class="form-label mb-2 py-1 fw-bold text-danger"> Range is not
                              valid </div>
                          </div>

                        </div>
                        <p class="mb-0 text-muted text-break" *ngIf="
                        !isEditPartnerEducation &&
                        partnerDetail.income_range_from &&
                        partnerDetail.income_range_to
                        ">
                          {{ PartnerCurrencyCountryName}}
                          {{ partnerDetail.income_range_from | number }} - {{
                          partnerDetail.income_range_to | number }}
                        </p>
                        <p class="mb-0 text-muted text-break" *ngIf="
                        !isEditPartnerEducation &&
                        !partnerDetail.income_range_from &&
                        partnerDetail.income_range_to
                        "> &nbsp; </p>
                      </div>
                    </div>
                    <div class="col-12 mt-2 mb-5" *ngIf="isEditPartnerEducation">
                      <button class="btn btn-danger shadow-sm" (click)="savePartnerEducation()"
                        [disabled]="partnerEducationButton"> Save & Update
                      </button>
                      <button class="btn btn-light border border-1 border-danger shadow-sm ms-3"
                        (click)="closePartnerEducation()"> Cancel </button>
                    </div>
                  </div>
                </form>
              </div>
              <div class="tab-pane fade" id="pills-LocationDetails2" role="tabpanel"
                aria-labelledby="pills-LocationDetails2-tab">
                <div class="row mb-3 px-25">
                  <div class="col-9 px-0 border border-2 border-top-0 border-start-0 border-end-0 ps-sm-0">
                    <h4 class="mb-2 fw-bold">Location of Partner</h4>
                  </div>
                  <div class="col-3 px-0 border border-2 border-top-0 border-start-0 border-end-0 text-end">
                    <button class="btn btn-danger py-0 px-2 ml-10px" (click)="editPartnerLocation()"
                      *ngIf="!isEditPartnerLocation"> Edit </button>
                  </div>
                </div>
                <div class="row">
                  <form id="partnerLocation" [formGroup]="partnerLocationForm">
                    <div class="row">
                      <div class="col-12 col-md-6">
                        <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                          <div class="col-12 py-2 mb-0">
                            <label for="PartnerCountryLivingEdit" class="form-label mb-0 fw-bold"> Country Living In
                            </label>
                          </div>
                          <div class="col-12 my-auto">

                            <div class="col-12" *ngIf="isEditPartnerLocation">
                              <ng-select [items]="this.countries1" bindLabel="fullnumber" bindValue="id"
                                [formControlName]="'country_name'" (change)="
                              onChangeCountryPartnerLocation($event)
                              " [(ngModel)]="partnerLocationPhoneCode" placeholder="Select
                              Country" dropdownPosition="auto" [virtualScroll]="true">
                                <ng-template ng-label-tmp let-item="item">
                                  <img height="20" width="20" src="assets/images/flag/Flag_of_{{
                                  item.country_name
                                  | replaceSpaceWithUnderscore
                                  }}.svg" alt="" style="margin-right: 5px" />
                                  <b> {{ item.country_name }}</b>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-index="index">
                                  <img height="20" width="20" src="assets/images/flag/Flag_of_{{
                                  item.country_name
                                  | replaceSpaceWithUnderscore
                                  }}.svg" alt="" style="margin-right: 5px" />
                                  <b> {{ item.country_name }}</b>
                                </ng-template>
                              </ng-select>

                            </div>
                            <!-- country code  -->
                            <p class="mb-0 text-muted text-break" *ngIf="
                            !isEditPartnerLocation &&
                            partnerLocationCountryName">
                              {{ partnerLocationCountryName }}
                            </p>
                            <p class="mb-0 text-muted text-break" *ngIf="
                           !isEditPartnerLocation &&
                            !partnerLocationCountryName"> &nbsp; </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                          <div class="col-12 py-2 mb-0">
                            <label for="PartnerStateLivingEdit" class="form-label mb-0 fw-bold">
                              State/Province
                            </label>
                          </div>
                          <div class="col-12 my-auto">
                            <!-- state code  -->
                            <div class="col-12" *ngIf="isEditPartnerLocation">
                              <select class="form-select py-2 bg-light" id="State" aria-label="Default
                              select example" [formControlName]="'state_name'" [ngClass]="{
                              'is-invalid':
                              partnerLocationSubmitted &&
                              partnerLocationF.state_name.errors
                              }">
                                <option value selected disabled hidden> Select </option>
                                <option value="Any">Any</option>
                                <option value="{{ state.id }}" *ngFor="
                                let state of this.partnerLocationStates
                                ">
                                  {{ state.state_name }}
                                </option>
                              </select>

                            </div>
                            <!-- state code  -->
                            <p class="mb-0 text-muted text-break" *ngIf="
                            !isEditPartnerLocation &&
                            partnerDetail.state_name
                            ">
                              {{ this.partnerLocationStateName }}
                            </p>
                            <p class="mb-0 text-muted text-break" *ngIf="
                            !isEditPartnerLocation &&
                            !partnerDetail.state_name
                            "> &nbsp; </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                          <div class="col-12 py-2 mb-0">
                            <label for="PartnerCityEdit" class="form-label mb-0 fw-bold"> City
                            </label>
                          </div>
                          <div class="col-12 my-auto">
                            <input *ngIf="isEditPartnerLocation" type="text" class="form-control"
                              [formControlName]="'city_name'" id="nameEdit" />
                            <p class="mb-0 text-muted text-break" *ngIf="
                            !isEditPartnerLocation &&
                            partnerDetail.city_name
                            ">
                              {{ partnerDetail.city_name }}
                            </p>
                            <p class="mb-0 text-muted text-break" *ngIf="
                            !isEditPartnerLocation &&
                            !partnerDetail.city_name
                            "> &nbsp; </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                          <div class="col-12 py-2 mb-0">
                            <label for="PartnerCityEdit" class="form-label mb-0 fw-bold">
                              Residency Status <span class="text-danger"> *</span>
                            </label>
                          </div>
                          <div class="col-12 my-auto">
                            <div *ngIf="isEditPartnerLocation">
                              <ng-select [items]="partnerResidencyStatus" bindLabel="name" bindValue="name"
                                [multiple]="true" [(ngModel)]="selectedPartnerResidencyStatus" placeholder="Select
                              Residency
                              Status" (change)="onSelectPartnerResidencyStatus()"
                                [formControlName]="'residency_status'" [ngClass]="{
                                  'is-invalid':
                                  (partnerLocationSubmitted && partnerLocationF.residency_status.errors )|| (partnerLocationSubmitted &&
                          partnerLocationF.otherResidencyStatus.errors)
                                  }">
                                <p> Selected Residency Status: {{
                                  selectedPartnerResidencyStatus }}
                                </p>
                              </ng-select>
                              <input *ngIf="partnerOtherResidencyStatus" type="text" class="form-control mt-2"
                                [formControlName]="'otherResidencyStatus'" autocomplete="" maxlength="100"
                                id="otherResidencyStatus" />
                            </div>
                            <p class="mb-0 text-muted text-break" *ngIf="
                            !isEditPartnerLocation &&
                            partnerDetail.residency_status
                            ">
                              {{showResidency }}
                            </p>
                            <p class="mb-0 text-muted text-break" *ngIf="
                            !isEditPartnerLocation &&
                            !partnerDetail.residency_status
                            "> &nbsp; </p>
                          </div>

                          <div *ngIf="(partnerLocationSubmitted && partnerLocationF.residency_status.errors )|| (partnerLocationSubmitted &&
                          partnerLocationF.otherResidencyStatus.errors) ">
                            <div class="form-label mb-2 py-1 fw-bold text-danger"> Residency
                              Status is required </div>
                          </div>

                          <div
                            *ngIf="(partnerResidencyStatusOtherValue && !partnerLocationF.residency_status.errors ) ">
                            <div class="form-label mb-2 py-1 fw-bold text-danger"> Residency
                              Status is required </div>
                          </div>

                        </div>
                      </div>
                      <div class="col-12 mt-2 mb-5" *ngIf="isEditPartnerLocation">
                        <button class="btn btn-danger shadow-sm" (click)="savePartnerLocation()"
                          [disabled]="partnerLocationButton"> Save & Update
                        </button>
                        <button class="btn btn-light border border-1 border-danger shadow-sm ms-3"
                          (click)="closePartnerLocation()"> Cancel </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div class="tab-pane fade" id="pills-Family2" role="tabpanel" aria-labelledby="pills-Family2-tab">
                <div class="row mb-3 px-25">
                  <div class="col-9 px-0 border border-2 border-top-0 border-start-0 border-end-0 ps-sm-0">
                    <h4 class="mb-2 fw-bold">Family Details</h4>
                  </div>
                  <div class="col-3 px-0 border border-2 border-top-0 border-start-0 border-end-0 text-end">
                    <button class="btn btn-danger py-0 px-2 ml-10px" (click)="editPartnerFamily()"
                      *ngIf="!isEditPartnerFamily">
                      Edit </button>
                  </div>
                </div>
                <div class="row">
                  <form id="partnerFamily" [formGroup]="partnerFamilyForm">
                    <div class="row mx-0 px-0">
                      <div class="col-12 col-md-6">
                        <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                          <div class="col-9 my-auto py-2 mb-0">
                            <label for="FatherStatusEdit" class="form-label mb-0 fw-bold">Father's Status</label>
                          </div>
                          <div class="col-12">
                            <input *ngIf="isEditPartnerFamily" type="text" class="form-control"
                              [formControlName]="'father_occupation'" id="nameEdit" />
                            <p class="mb-0 text-muted text-break" *ngIf="
                            !isEditPartnerFamily &&
                            partnerDetail.father_occupation
                            ">
                              {{ partnerDetail.father_occupation }}
                            </p>
                            <p class="mb-0 text-muted text-break" *ngIf="
                            !isEditPartnerFamily &&
                            !partnerDetail.father_occupation
                            "> &nbsp; </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                          <div class="col-12 py-2 mb-0">
                            <label for="MotherStatusEdit" class="form-label mb-0 fw-bold">Mother's Status</label>
                          </div>
                          <div class="col-12">
                            <input *ngIf="isEditPartnerFamily" type="text" class="form-control"
                              [formControlName]="'mother_occupation'" id="nameEdit" />
                            <p class="mb-0 text-muted text-break" *ngIf="
                            !isEditPartnerFamily &&
                            partnerDetail.mother_occupation
                            ">
                              {{ partnerDetail.mother_occupation }}
                            </p>
                            <p class="mb-0 text-muted text-break" *ngIf="
                            !isEditPartnerFamily &&
                            !partnerDetail.mother_occupation
                            "> &nbsp; </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                          <div class="col-12 py-1 mb-0">
                            <label for="FamilyLocationEdit" class="form-label mb-0 fw-bold">Family Location</label>
                          </div>
                          <div class="row">
                            <div class="col-12 col-md-6" *ngIf="isEditPartnerFamily">
                              <label for="LivingIn" class="form-label mb-3 fw-bold"> Country
                              </label>
                              <ng-select [items]="this.countries" bindLabel="fullnumber" bindValue="id"
                                [formControlName]="'family_living_in_country'"
                                (change)="onChangePartnerFamilyCountry($event)" [(ngModel)]="partnerFamilyPhoneCode"
                                placeholder="Select
                              Country" dropdownPosition="auto" [virtualScroll]="true">
                                <ng-template ng-label-tmp let-item="item">
                                  <img height="20" width="20" src="assets/images/flag/Flag_of_{{
                                  item.country_name
                                  | replaceSpaceWithUnderscore
                                  }}.svg" alt="" style="margin-right: 5px" />
                                  <b> {{ item.country_name }}</b>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-index="index">
                                  <img height="20" width="20" src="assets/images/flag/Flag_of_{{
                                  item.country_name
                                  | replaceSpaceWithUnderscore
                                  }}.svg" alt="" style="margin-right: 5px" />
                                  <b> {{ item.country_name }}</b>
                                </ng-template>
                              </ng-select>

                            </div>
                            <div class="col-12 col-md-6" *ngIf="isEditPartnerFamily">
                              <label for="StateProvince" class="form-label mb-2 py-1 fw-bold">
                                State / Province
                              </label>
                              <select class="form-select mb-1 bg-light" id="State" aria-label="Default
                              select example" [formControlName]="'family_state_name'">
                                <option value selected disabled hidden> Select </option>
                                <option value="Any">Any</option>
                                <option value="{{ state.id }}" *ngFor="let state of this.partnerFamilyStates">
                                  {{ state.state_name }}
                                </option>
                              </select>

                            </div>
                            <p class="mb-0 text-muted text-break" *ngIf="
                            !isEditPartnerFamily &&
                            partnerDetail.family_living_in_country
                            ">

                              <sapn *ngIf="partnerDetail.family_state_name">{{ partnerFamilyStateName}},</sapn>
                              {{partnerFamilyCountryName}}
                            </p>
                            <p class="mb-0 text-muted text-break" *ngIf="
                            !isEditPartnerFamily &&
                            !partnerDetail.family_living_in_country
                            "> &nbsp; </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                          <div class="col-12 py-2 mb-0">
                            <label for="FamilyTypeEdit" class="form-label mb-0 fw-bold"> Living
                              With Family</label>
                          </div>
                          <div class="col-12">
                            <div class="col-auto" *ngIf="isEditPartnerFamily">
                              <select class="form-select" aria-label="Default
                              select example" [formControlName]="'living_with_family'"
                                (change)="partnerFamilyTypeCheck()">
                                <option value selected disabled hidden> Select </option>
                                <option value></option>
                                <option>Yes</option>
                                <option>No</option>
                                <option>Does Not Matter</option>
                              </select>
                            </div>
                            <p class="mb-0 text-muted text-break" *ngIf="
                            !isEditPartnerFamily &&
                            partnerDetail.living_with_family
                            ">
                              {{ partnerDetail.living_with_family }}
                            </p>
                            <p class="mb-0 text-muted text-break" *ngIf="
                            !isEditPartnerFamily &&
                            !partnerDetail.living_with_family
                            "> &nbsp; </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6" *ngIf="FamilyTypePartner">
                        <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                          <div class="col-12 py-2 mb-0">
                            <label for="FamilyTypeEdit" class="form-label mb-0 fw-bold"> Family
                              Type</label>
                          </div>
                          <div class="col-12">
                            <div class="col-auto" *ngIf="isEditPartnerFamily">
                              <ng-select *ngIf="isEditPartnerFamily" [items]="partnerFamilyType" bindLabel="name"
                                bindValue="name" [multiple]="true" (change)="onSelectFamilyType()"
                                [(ngModel)]="selectedPartnerFamilyType" placeholder="Select
                              Family Type" [formControlName]="'family_type'" [ngClass]="{
                              'is-invalid':
                              partnerFamilySubmitted &&
                              partnerFamilyF.family_type.errors
                              }">
                                <p> Selected Family Type: {{ selectedPartnerFamilyType }}
                                </p>
                              </ng-select>
                            </div>
                            <p class="mb-0 text-muted text-break" *ngIf="
                            !isEditPartnerFamily &&
                            partnerDetail.family_type
                            ">
                              {{ showFamilyType }}
                            </p>
                            <p class="mb-0 text-muted text-break" *ngIf="
                            !isEditPartnerFamily &&
                            !partnerDetail.family_type
                            "> &nbsp; </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 mt-3 mb-5" *ngIf="isEditPartnerFamily">
                        <button class="btn btn-danger shadow-sm" (click)="savePartnerFamily()"
                          [disabled]="partnerFamilyButton">
                          Save & Update
                        </button>
                        <button class="btn btn-light border border-1 border-danger shadow-sm ms-3"
                          (click)="closePartnerFamily()"> Cancel </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div class="tab-pane fade" id="pills-LocationBride" role="tabpanel"
                aria-labelledby="pills-LocationBride-tab">
                <div class="row">
                  <div class="col-9 border border-2 border-top-0 border-start-0 border-end-0 ps-sm-0">
                    <h4 class="mb-2 fw-bold">Location of Partner 22</h4>
                  </div>
                  <div class="col-3 border border-2 border-top-0 border-start-0 border-end-0">
                    <div class="col-3 text-end">
                      <button class="btn btn-danger py-0 px-1">Edit</button>
                    </div>
                  </div>
                  <div class="row mx-0 px-0">
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-9 my-auto py-2 mb-0">
                          <label for="CurrentResidenceEdit" class="form-label mb-0 fw-bold">Current Residence</label>
                        </div>
                        <div class="col-3 my-auto text-end">
                          <button class="btn btn-danger py-0 px-1 btn-hide"> Edit </button>
                        </div>
                        <div class="col-12">
                          <p class="mb-0 text-muted text-break"> Indore, India </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-9 my-auto py-2 mb-0">
                          <label for="StateResidenceEdit" class="form-label mb-0 fw-bold">State Of
                            Residence</label>
                        </div>
                        <div class="col-3 my-auto text-end">
                          <button class="btn btn-danger py-0 px-1 btn-hide"> Edit </button>
                        </div>
                        <div class="col-12 my-auto">
                          <p class="mb-0 text-muted text-break"> Madhya Pradesh </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-9 my-auto py-2 mb-0">
                          <label for="ResidencyStatusEdit" class="form-label mb-0 fw-bold">Residency Status</label>
                        </div>
                        <div class="col-3 my-auto text-end">
                          <button class="btn btn-danger py-0 px-1 btn-hide"> Edit </button>
                        </div>
                        <div class="col-12 my-auto">
                          <p class="mb-0 text-muted text-break">Citizen</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="pb-1 btn-show mb-2 border border-1 border-top-0 border-start-0 border-end-0">
                        <div class="col-9 my-auto py-2 mb-0">
                          <label for="PostalCodeEdit" class="form-label mb-0 fw-bold">Zip/Pin/Postal Code</label>
                        </div>
                        <div class="col-3 my-auto text-end">
                          <button class="btn btn-danger py-0 px-1 btn-hide"> Edit </button>
                        </div>
                        <div class="col-12 my-auto">
                          <p class="mb-0 text-muted text-break">452010</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mt-2 mb-5">
                      <button class="btn btn-danger shadow-sm"> Save & Update </button>
                      <button class="btn btn-light border border-1 border-danger shadow-sm ms-3">
                        Cancel </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- upgrade popup start -->

  <div class="modal fade" id="upgradeModal1" tabindex="-1" aria-labelledby="upgradeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
      <div class="modal-content position-relative">

        <div class="modal-body text-center p-4">
          <button type="button" class="btn btn-danger position-absolute top-0 right-0 m-2 center w-30-h-30"
            data-bs-dismiss="modal" aria-label="Close">
            <i class="fas fa-times fs-5"></i>
          </button>

          <img src="assets/images/logo.png" alt="" width="200" class="mb-6">

          <p class="p-sm mb-4">
            <strong> Upgrade Now to Premium or Elite.</strong>
            <!-- Upgrade now . -->
          </p>
          <button class="btn btn-danger rounded-pill" data-bs-dismiss="modal" aria-label="Close"
            routerLink="/upgrade">Upgrade Now </button>
        </div>

      </div>
    </div>
  </div>
  <!-- upgrad popup end -->
  <!-- confirm popup start -->

  <div class="modal fade" id="upgradeModal12" tabindex="-1" aria-labelledby="upgradeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
      <div class="modal-content position-relative">

        <div class="modal-body text-center p-4">
          <button type="button" class="btn btn-danger position-absolute top-0 right-0 m-2 center w-30-h-30"
            data-bs-dismiss="modal" aria-label="Close" (click)="closeHandOver()">
            <i class="fas fa-times fs-5"></i>
          </button>

          <img src="assets/images/logo.png" alt="" width="200" class="mb-6">
          <h6 class="modal-title mb-1 fw-bold">Are you sure?</h6>
          <p class="p-sm mb-4">
            Once you hand over the profile you will not be able to sign in again.
          </p>
          <button class="btn btn-outline-danger rounded-pill " data-bs-dismiss="modal" aria-label="Close"
            (click)="closeHandOver()">No </button>

          <button class="btn btn-outline-danger rounded-pill ms-2 " data-bs-dismiss="modal" aria-label="Close"
            (click)="saveHandOver()">Yes </button>

        </div>

      </div>
    </div>
  </div>
  <!-- confirm popup end -->
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0)" size="medium" color="#fff" type="ball-clip-rotate" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>
<app-login-footer></app-login-footer>

<!-- modals -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content rounded-5 border border-1 border-warning">
      <div class="modal-header pb-0 border-0">
        <h5 class="modal-title mb-0 fw-bold" id="exampleModalLabel">
          {{ displayName }}
          <small class="p-sm">S8585555</small>
        </h5>
        <button type="button" class="btn" data-bs-dismiss="modal" aria-label="Close">
          <i class="fas fa-times fs-4 text-danger"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="ratio-1">
          <img src="assets/images/manish-patel-tLEjmTGvWr8-unsplash.jpg"
            class="image-cover rounded-5 border border-1 border-warning" alt />
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content rounded-5 border border-1 border-warning">
      <div class="modal-header pb-0 border-0">
        <h5 class="modal-title mb-0 fw-bold" id="exampleModalLabel">
          {{ displayName }}
          <small class="p-sm">S8585555</small>
        </h5>
        <button type="button" class="btn" data-bs-dismiss="modal" aria-label="Close">
          <i class="fas fa-times fs-4 text-danger"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="ratio-2">
          <img src="assets/images/family-group-photo.jpg" class="image-cover rounded-5 border border-1 border-warning"
            alt />
        </div>
      </div>
    </div>
  </div>
</div>

<!-- upload photo start  -->

<div class="modal fade" id="loginModal" tabindex="-1" aria-labelledby="loginModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content mx-auto mx-width-400 rounded-10 position-relative overflow-hidden">
      <div class="modal-header border-0 pt-3 mb-3">
        <img src="assets/images/logo.png" class="mx-auto" alt="ETERNALSTRINGS LOGO" width="130px">

        <button type="button" class="btn-close position-absolute top-0 right-0 mt-2 me-2" id="closeLoginModal"
          data-bs-dismiss="modal" aria-label="Close" (click)="cancelImg()"></button>
      </div>
      <div class="modal-body p-0">

        <div class="col-12 px-0">

        </div>

        <form [formGroup]="imageUploadForm" (ngSubmit)="submitForm()">
          <div class="mb-3 px-3">

            <input type="file" (change)=" uploadFile($event)" (click)="fileInput.value= null" #fileInput name
              class="upload" accept="image/png,image/jpeg,image/webp,image/bmp" id="profilePicture" />
            <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
              [containWithinAspectRatio]="containWithinAspectRatio" [aspectRatio]="4 / 4" [resizeToWidth]="256"
              [cropperMinWidth]="128" [onlyScaleDown]="true" [roundCropper]="true" [canvasRotation]="canvasRotation"
              [transform]="transform" [alignImage]="'left'" [style.display]="showCropper ? null :
              'none'" format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
              (cropperReady)="cropperReady($event)" (loadImageFailed)="loadImageFailed()"></image-cropper>

          </div>
          <div class="col-12 text-center" *ngIf="imgSelected">
            <a href="javascript:void(0)" class="btn btn-light border border-1 border-danger me-2"
              (click)="rotateLeft()"><i class="fas
                fa-sync-alt"></i></a>
            <button type="submit" class="btn btn-danger" id="closeLoginModal" data-bs-dismiss="modal">Save</button>
          </div>
          <div class="row mx-0">

            <div class="col-6 ps-0 text-end">

            </div>

            <div class="col-12 mt-3 py-2 bg-maroon w-100">

              <p class="text-center mb-0 fw-bold slide-10"> <a href="javascript:void(0);" routerLink="/home"
                  class="text-light" id="closeLoginModal" data-bs-dismiss="modal" aria-label="Close"><i
                    class="fas fa-long-arrow-alt-left"></i> Go
                  to eternalstrings.com</a> </p>
            </div>
          </div>
        </form>
      </div>
    </div>

  </div>
</div>

<!-- upload photo end  -->

<!-- upload coverphoto start  -->
<div class="modal fade" id="coverPhoto" tabindex="-1" aria-labelledby="coverModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content mx-auto mx-width-400 rounded-10 position-relative overflow-hidden">
      <div class="modal-header border-0 pt-3 mb-3">
        <img src="assets/images/logo.png" class="mx-auto" alt="ETERNALSTRINGS LOGO" width="130px">

        <button type="button" class="btn-close position-absolute top-0 right-0 mt-2 me-2" id="closeLoginModal"
          data-bs-dismiss="modal" aria-label="Close" (click)="cancelImg()"></button>
      </div>
      <div class="modal-body p-0">

        <div class="col-12 px-0">

        </div>

        <form [formGroup]="coverUploadForm" (ngSubmit)="submitCoverPicture()">
          <div class="mb-3 px-3">

            <input type="file" (change)=" uploadCover($event)" (click)="fileInput.value= null" #fileInput name
              class="upload" accept="image/png,image/jpeg,image/webp,image/bmp" id="profilePicture" />
            <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
              [containWithinAspectRatio]="containWithinAspectRatio" [aspectRatio]="2.7 / 1" [resizeToWidth]="1310"
              [cropperMinWidth]="1310" [onlyScaleDown]="true" [roundCropper]="false" [canvasRotation]="canvasRotation"
              [transform]="transform" [alignImage]="'left'" [style.display]="showCropper ? null :
              'none'" format="png" (imageCropped)="imageCropped1($event)" (imageLoaded)="imageLoaded()"
              (cropperReady)="cropperReady($event)" (loadImageFailed)="loadImageFailed()"></image-cropper>

          </div>
          <div class="col-12 text-center" *ngIf="imageChangedEvent">
            <a href="javascript:void(0)" class="btn btn-light border border-1 border-danger me-2"
              (click)="rotateLeft()"><i class="fas
                fa-sync-alt"></i></a>
            <button type="submit" class="btn btn-danger" id="closeLoginModal" data-bs-dismiss="modal">Save</button>
          </div>
          <div class="row mx-0">

            <div class="col-6 ps-0 text-end">

            </div>

            <div class="col-12 mt-3 py-2 bg-maroon w-100">

              <p class="text-center mb-0 fw-bold slide-10"> <a href="javascript:void(0);" routerLink="/home"
                  class="text-light" id="closeLoginModal" data-bs-dismiss="modal" aria-label="Close"><i
                    class="fas fa-long-arrow-alt-left"></i> Go
                  to eternalstrings.com</a> </p>
            </div>
          </div>
        </form>
      </div>
    </div>

  </div>
</div>

<!-- upload cover photo end  -->
<!-- modal -->
<div class="modal fade" id="guidelinesModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content position-relative">
      <button type="button" class="btn-close position-absolute top-0 right-0 m-3 z-index-99" data-bs-dismiss="modal"
        aria-label="Close"></button>
      <div class="modal-body">
        <div class="row">
          <div class="col-12 col-sm-12">
            <h5 class="p-0 fw-bold text-muted">Photos Guidelines</h5>
            <div class="row my-3">
              <div class="col-12 d-flex align-items-center">
                <div class>
                  <small>
                    <i class="fas fa-check text-success"></i> Close Up </small>
                  <div class="w-85-h-85">
                    <img class="image-cover border border-1 border-danger rounded-3 bg-danger"
                      src="assets/images/close-up-view.png" alt />
                  </div>
                </div>
                <div class="mx-3">
                  <small>
                    <i class="fas fa-check text-success"></i> Half View </small>
                  <div class="w-85-h-85">
                    <img class="image-cover border border-1 border-danger rounded-3 bg-danger"
                      src="assets/images/half-view.png" alt />
                  </div>
                </div>
                <div class>
                  <small>
                    <i class="fas fa-check text-success"></i> Full View </small>
                  <div class="w-85-h-85">
                    <img class="image-cover border border-1 border-danger rounded-3 bg-danger"
                      src="assets/images/full-view.png" alt />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 pb-4">
            <p class="lh-lg p-sm fw-bold px-3 mb-0">
              <i class="fas fa-check text-success"></i> Do's
            </p>
            <ul class>
              <li class="lh-lg p-sm"> Your profile photo should be front-facing and your entire photo
                should be visible. </li>
              <li class="lh-lg p-sm"> Ensure that your photo is recent and not with a group. </li>
              <li class="lh-lg p-sm"> You can upload upto 20 photos to your gallery. </li>
              <li class="lh-lg p-sm"> Each photo must be less than 5 MB in size and must be in one of the
                following formats: jpg,
                gif,
                png, bmp or
                tiff. </li>
            </ul>
            <p class="h-lg p-sm fw-bold px-3 mb-0">
              <i class="fas fa-times text-danger"></i> Don'ts
            </p>
            <ul class>
              <li class="lh-lg p-sm"> Watermarked, digitally enhanced, morphed photos or photos with your
                personal information will
                be
                rejected. </li>
              <li class="lh-lg p-sm"> Irrelevant photographs will lead to deactivation of your profile and
                membership. </li>
              <li class="lh-lg p-sm">The Picture should not be Blurry.</li>
              <li class="lh-lg p-sm">Do not upload vulgar or nude images.</li>
            </ul>
          </div>
          <div class="col-12 rounded mx-auto py-2 px-3 border border-1 shadow-sm bg-light">
            <p class="p-sm mb-0 text-center">
              <span class="fs-5 fw-bold text-danger">*</span> Photos will be screened, and added to your
              profile within 5 business
              days.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- incomplete fields start  -->

<div class="modal fade" id="fieldsModal" tabindex="-1" aria-labelledby="fieldsModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content mx-auto mx-width-400 rounded-10 position-relative overflow-hidden">
      <div class="modal-header border-0 pt-3 mb-3">
        <img src="assets/images/logo.png" class="mx-auto" alt="ETERNALSTRINGS LOGO" width="130px">

        <button type="button" class="btn-close position-absolute top-0 right-0 mt-2 me-2" id="closeLoginModal"
          data-bs-dismiss="modal" aria-label="Close" (click)="removeNotification()"></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12 col-sm-12">
            <h6 class="p-0 fw-bold text-muted">Missing fields</h6>
          </div>
          <div class="col-12 pb-4">

            <span *ngIf="inCompleteUserField?.length > 0">
              <p class="lh-lg p-sm fw-bold px-3 mb-0">
                Your Details
              </p>
              <ul>
                <li class="lh-lg p-sm" *ngFor="let mes of this.inCompleteUserField"> {{mes}}. </li>
              </ul>
            </span>
            <span *ngIf="inCompletePartnerField?.length > 0">
              <p class="h-lg p-sm fw-bold px-3 mb-0">
                Your Partner's Preference
              </p>
              <ul class>
                <li class="lh-lg p-sm" *ngFor="let mes of this.inCompletePartnerField"> {{mes}}. </li>
              </ul>
            </span>
          </div>
          <div class="col-12 text-center">

            <button type="button" class="btn btn-danger" id="closeLoginModal" data-bs-dismiss="modal"
              (click)="removeNotification()">Ok</button>
          </div>
          <div class="col-12 mt-3 py-2 bg-maroon w-100">
            <p class="text-center mb-0 fw-bold slide-10"> <a href="javascript:void(0);" routerLink="/home"
                class="text-light" id="closeLoginModal" data-bs-dismiss="modal" aria-label="Close"><i
                  class="fas fa-long-arrow-alt-left"></i> Go
                to eternalstrings.com</a> </p>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

<!-- incomplete fields   -->
