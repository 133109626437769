import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoginFooterComponent } from '../shared/login-footer/login-footer.component';
import { LoginHeaderComponent } from '../shared/login-header/login-header.component';
import { FooterComponent } from '../shared/footer/footer.component';
import { HeaderComponent } from '../shared/header/header.component';

import { SharedmoduleRoutingModule } from './sharedmodule-routing.module';
import { CookieService } from 'ngx-cookie-service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { ProgressiveImageModule } from '../Directives/progressive-image/progressive-image.module';
// import { SimpleNotificationsModule } from 'angular2-notifications';

import { HeightConverterPipe } from './pipe/HeightConvertorPipe';
import { LimitPipe } from './pipe/limit.pipe';
import { ReplaceSpaceWithUnderscorePipe } from './pipe/ReplaceSpaceWithUnderscorePipe';
import { ShortNamePipe } from './pipe/shortName.pipe';
import { WeightConverterPipe } from './pipe/WeightConvertorPipe';
import { ReplaceSpaceWithComaPipe } from './pipe/replaceSpaceWithComaPipe';
import { ReplaceUnderscoreWithComaPipe } from './pipe/replaceUnderscoreWithComa';
import { ReplaceComaWithSpacePipe } from './pipe/ReplaceComaWithSpacePipe';
import { HeightTopPipe } from './pipe/HeightTopPipe';
import { AgeConvertPipePipe } from './pipe/age-convert-pipe.pipe';
import { ReplaceEmojisPipe } from './pipe/emoji.pipe';
import { SafeHtmlPipePipe } from './pipe/safeHtmlPipe.pipe';
import { LimitHtmlPipe } from './pipe/limitHtml.pipe';
import { UtcToLocalTimePipe } from './pipe/dateTime.pipe';
import { TimezonePipe } from './pipe/timezone.pipe';
import { PhoneNumberPipe } from './pipe/phone-number.pipe';
import { GMTDatePipe } from './pipe/gmtdate.pipe';
import { AddSpaceAfterCommaPipe } from './pipe/add-space-after-comma.pipe';
import { UtcConverterService } from '../services/utc-converter.service';
import { DatePipe } from '@angular/common';



@NgModule({
  declarations: [
    LoginFooterComponent,
    LoginHeaderComponent,
    FooterComponent,
    HeaderComponent,

    HeightConverterPipe,
    LimitPipe,
    ReplaceSpaceWithUnderscorePipe,
    ShortNamePipe,
    WeightConverterPipe,
    ReplaceSpaceWithComaPipe,
    ReplaceUnderscoreWithComaPipe,
    ReplaceComaWithSpacePipe,
    HeightTopPipe,
    AgeConvertPipePipe,
    ReplaceEmojisPipe,
    SafeHtmlPipePipe,
    LimitHtmlPipe,
    UtcToLocalTimePipe,
    // DatePipe,
    TimezonePipe,
    PhoneNumberPipe,
    GMTDatePipe,
    AddSpaceAfterCommaPipe,
    // UtcConverterService,
  ],
  imports: [
    CommonModule,
    SharedmoduleRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    HttpClientModule,
    ToastrModule,
    // SimpleNotificationsModule.forRoot()
    ProgressiveImageModule,
  ],
  exports: [
    LoginFooterComponent,
    LoginHeaderComponent,
    FooterComponent,
    HeaderComponent,
    // UtcConverterService,

    HeightConverterPipe,
    LimitPipe,
    ReplaceSpaceWithUnderscorePipe,
    ShortNamePipe,
    WeightConverterPipe,
    ReplaceSpaceWithComaPipe,
    ReplaceUnderscoreWithComaPipe,
    ReplaceComaWithSpacePipe,
    HeightTopPipe,
    AgeConvertPipePipe,
    ReplaceEmojisPipe,
    SafeHtmlPipePipe,
    LimitHtmlPipe,
    // UtcToLocalTimePipe,
    // DatePipe,
    TimezonePipe,
    PhoneNumberPipe,
    GMTDatePipe,
    AddSpaceAfterCommaPipe,
  ],
  providers: [
    CookieService,
    UtcConverterService,
    DatePipe
    // UtcToLocalTimePipe,
  ],
})
export class SharedmoduleModule {
  constructor() {
    // console.log('shared module loaded')
  }
}
