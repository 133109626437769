<section class="mt-93">
  <!-- ....content loader code start cover and profile image.... -->
  <div class="container px-0" *ngIf="detailLoader ">
    <div class="row mx-0">
      <div class="col-12 position-relative px-0 profile-banner overflow-hidden"
        style="max-height: 300px; border-radius: 0 0 10px 10px">
        <img src="assets/images/default_coverphoto_for_conetent_loader.png" alt="" class="img-fluid w-100" />
      </div>
    </div>
    <div class="row position-relative mx-0">
      <div class="col-12 col-sm-4 col-lg-3">
        <div class="w-75 ratio-1 profile-box rounded-circle position-relative mx-auto bg-gray p-1">
          <img class="rounded-circle border image-cover overflow-hidden animated-background-name"
            src="assets/images/default-profile-picture.svg" alt="" />
          <div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-8 col-lg-9 py-3 mb-3">
        <div class="row py-3">
          <div class="col-12 col-md-6 py-1 lh-lg">
            <p class="mb-0 fw-bold"><small class="p-sm"></small></p>
            <p class="mb-0 py-1 p-sm"><span class="footerBlankBox mt-4 animated-background-name"></span></p>
            <p class="mb-0 py-1 p-sm"><span class="footerBlankBox mt-4 animated-background-name"></span></p>
            <p class="mb-0 py-1 p-sm"><span class="footerBlankBox mt-4 animated-background-name"></span></p>
            <p class="mb-0 py-1 p-sm"><span class="footerBlankBox mt-4 animated-background-name"></span></p>
          </div>
          <div class="col-12 col-md-6 py-1 lh-lg">
            <p class="mb-0 py-1 p-sm"><span class="footerBlankBox mt-4 animated-background-name"></span></p>
            <p class="mb-0 py-1 p-sm"><span class="footerBlankBox mt-4 animated-background-name"></span></p>
            <p class="mb-0 py-1 p-sm"><span class="footerBlankBox mt-4 animated-background-name"></span>
            <p class="mb-0 py-1 p-sm"><span class="footerBlankBox mt-4 animated-background-name"></span></p>
          </div>
        </div>
        <div class="position-absolute top-0 right-0 m-2 d-flex me-2">
          <span class="row">
          </span>
        </div>
      </div>
    </div>

  </div>
  <!-- ....content loader code end.... -->

  <div class="container px-0" *ngIf="!detailLoader ">
    <div class="row mx-0">
      <div class="col-12 position-relative px-0 profile-banner overflow-hidden"
        style="max-height: 485px; border-radius: 0 0 10px 10px">
        <ng-container
          *ngIf="(this.myRequestDetails?.photo_visiblity!='No one' && this.aboutUser?.stage==5) || this.myRequestDetails?.photo_visiblity=='Visible to all members'">
          <app-progressive-image class="progressive-image">
            <img appProgressiveImage [src]="photoSrc" alt="" class="img-fluid w-100">

            <div appProgressiveImagePlaceholder class="placeholderOneOrThree">
              <div id="loaderProgressive" class="centerProgressive"></div>
            </div>
            <div appProgressiveImageFallback class="fallback">
              <img appProgressiveImageFallback class="img-fluid w-100" src="assets/images/banner-4.jpg" alt="">
            </div>
          </app-progressive-image>
        </ng-container>

        <img *ngIf="
        (this.myRequestDetails?.photo_visiblity=='No one' || this.aboutUser?.stage!=5) && this.myRequestDetails?.photo_visiblity!='Visible to all members'
          " src="assets/images/banner-4.jpg" alt class="img-fluid w-100" />
      </div>
    </div>

    <div class="row position-relative mx-0">
      <div class="col-12 col-sm-4 col-lg-3">
        <div class="w-75 ratio-1 profile-box rounded-circle position-relative mx-auto bg-gray p-1">
          <img *ngIf="
          (this.myRequestDetails?.photo_visiblity=='No one' || this.aboutUser?.stage!=5) && this.myRequestDetails?.photo_visiblity!='Visible to all members'
            " class="img-fluid rounded-circle border image-cover overflow-hidden" src="assets/images/men-avtar-2.svg"
            alt />
          <ng-container
            *ngIf="(this.myRequestDetails?.photo_visiblity!='No one' && this.aboutUser?.stage==5) || this.myRequestDetails?.photo_visiblity=='Visible to all members'">
            <app-progressive-image class="progressive-image">
              <img appProgressiveImage [src]="profileSrc" alt="" class="img-fluid rounded-circle border image-cover cursor-pointer
                overflow-hidden" data-bs-toggle="modal" data-bs-target="#exampleModalProfile">

              <div appProgressiveImagePlaceholder class="placeholderOneOrThree-suggest">
                <div id="loaderProgressive" class="centerProgressive"></div>
              </div>
              <div appProgressiveImageFallback class="fallback">
                <img appProgressiveImageFallback
                  class="img-fluid rounded-circle border image-cover overflow-hidden cursor-pointer"
                  src="assets/images/default-profile-picture.svg" alt="">
              </div>
            </app-progressive-image>
          </ng-container>
        </div>
      </div>

      <div class="col-12 col-sm-8 col-lg-9 py-3 mb-3">
        <div class="row py-3">
          <div class="col-12 col-md-6 py-1 lh-lg">

            <button id="openModal4" style="display:none;" data-bs-toggle="modal"
              data-bs-target="#redirectModal"></button>
            <button style="display:none;" id="openModal2" data-bs-toggle="modal"
              data-bs-target="#ConnectModal"></button>

            <button style="display:none;" id="openModal1" data-bs-target="#upgradeModal"
              data-bs-toggle="modal"></button>

            <button style="display:none;" id="openModal3" data-bs-toggle="modal"
              data-bs-target="#previewImgModal"></button>
            <p class="mb-0 fw-bold">
              {{ displayName }} <small class="p-sm"></small>
              <span class="user_email_verified"
                [ngbTooltip]="partnerProfileDetail?.is_user_verified ==1 ? 'Verified user': 'Verified email'"
                placement="bottom">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                  class="bi bi-shield-fill-check mb-1 " [ngClass]="{'text-muted':partnerProfileDetail?.is_user_verified != 1,
                                               'text-success':partnerProfileDetail?.is_user_verified == 1}"
                  viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M8 0c-.69 0-1.843.265-2.928.56-1.11.3-2.229.655-2.887.87a1.54 1.54 0 0
                                    0-1.044
                                    1.262c-.596 4.477.787 7.795 2.465 9.99a11.777 11.777 0 0 0 2.517
                                    2.453c.386.273.744.482
                                    1.048.625.28.132.581.24.829.24s.548-.108.829-.24a7.159
                                    7.159 0 0 0 1.048-.625 11.775 11.775 0 0 0 2.517-2.453c1.678-2.195
                                    3.061-5.513
                                    2.465-9.99a1.541 1.541 0 0 0-1.044-1.263 62.467 62.467 0 0
                                    0-2.887-.87C9.843.266
                                    8.69 0 8 0zm2.146 5.146a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708
                                    0l-1.5-1.5a.5.5 0
                                    1 1 .708-.708L7.5 7.793l2.646-2.647z" />
                </svg>
              </span>

              <span class="user_email_verified1 mx-1" *ngIf="partnerProfileDetail?.is_user_verified== 0 "
                placement="bottom" ngbTooltip="Verified email"><i class="fa fa-check fs-5" style="color:green"></i>
              </span>
              <span class="user_email_verified1 mx-1" *ngIf="partnerProfileDetail?.is_user_verified== 1 "
                placement="bottom" ngbTooltip="Verified user"><i class="fa fa-check-double fs-5 "
                  style="color:green"></i>
              </span>
              <img src="assets/images/icon/elite.svg" class="width-30" alt placement="right" ngbTooltip="Elite Member"
                *ngIf="partnerProfileDetail?.package_id == 3" />
              <img src="assets/images/icon/premium.svg" class="width-30" alt placement="right"
                ngbTooltip="Premium Member" *ngIf="partnerProfileDetail?.package_id == 2" />
            </p>
            <p class="mb-0 py-1 p-sm">
              <strong>Age:</strong> {{ age }} <strong>Height:</strong>
              {{ partnerProfileDetail?.height | heightConverter : "Ft" }}
            </p>
            <p class="mb-0 py-1 p-sm">
              {{ partnerProfileDetail?.marital_status }}
            </p>
            <p class="mb-0 py-1 p-sm">
              {{ partnerProfileDetail?.religion | limit :18}}<span *ngIf="partnerProfileDetail?.caste">,{{
                partnerProfileDetail?.caste | limit :18 }}</span>
            </p>
          </div>
          <div class="col-12 col-md-6 py-1 lh-lg">
            <p class="mb-0 py-1 p-sm" *ngIf="partnerProfileDetail?.working_as">{{ partnerProfileDetail?.working_as |
              limit :28}}</p>
            <p class="mb-0 py-1 p-sm">
              {{ partnerProfileDetail?.current_city | limit :25 }}
            </p>
            <p class="mb-0 py-1 p-sm">{{ partnerProfileDetail?.state_name }}</p>
            <p class="mb-0 py-1 p-sm">
              {{ partnerProfileDetail?.living_in_country }}
            </p>
          </div>
        </div>

        <div class="position-absolute top-0 right-0 m-2 d-flex me-2">
          <!-- handel by stage start -->
          <span>
            <a href="matches-shortlist" *ngIf="!!aboutUser?.isShortListed && (aboutUser?.stage == 1 ||aboutUser?.stage == 6 ||(aboutUser?.stage == 7 || aboutUser?.stage ==
                12)) && !aboutUser?.isReported && !aboutUser?.isBlocked" placement="left"
              ngbTooltip="Shortlisted Profile" class="w-30-h-30 center btn-danger rounded-circle border-0 me-1">
              <i class="fas fa-bookmark"></i>
            </a>

            <a href="matches-favourite"
              *ngIf="!!aboutUser?.isFavourite && !aboutUser?.isShortListed && !aboutUser?.isReported && !aboutUser?.isBlocked"
              placement="left" ngbTooltip="Favourited Profile"
              class="w-30-h-30 center btn-danger rounded-circle border-0 me-1 ">
              <i class="fas fa-heart"></i>
            </a>
          </span>
          <a href="communications/inbox-sent"
            *ngIf=" aboutUser?.CommunicationType=='Receiver' && aboutUser?.stage == 3 && !aboutUser?.isReported"
            placement="left" ngbTooltip="Request Sent" class="w-30-h-30 center btn-danger rounded-circle border-0 me-1">
            <i class="far fa-paper-plane text-light"></i>
          </a>

          <a href="communications/comunication-inbox"
            *ngIf="aboutUser?.CommunicationType=='Sender' && (aboutUser?.stage == 3 || aboutUser?.stage == 9  ) && !aboutUser?.isReported && !aboutUser?.isBlocked"
            placement="left" ngbTooltip="Request Received"
            class="w-30-h-30 center btn-danger rounded-circle border-0 me-1">
            <i class="fas fa-inbox"></i>
          </a>

          <a href="communications/accepted" *ngIf="aboutUser?.stage == 5 && !aboutUser?.isReported && !aboutUser?.isBlocked"
            placement="left" ngbTooltip="Request Accepted"
            class="w-30-h-30 center btn-danger rounded-circle border-0 me-1">
            <i class="fas fa-user-friends"></i>
          </a>


          <a href="communications/inbox-cancelled"
            *ngIf="aboutUser?.CommunicationType=='Receiver' && aboutUser?.stage == 6 && !aboutUser?.isReported && !aboutUser?.isBlocked"
            placement="left" ngbTooltip="Cancelled by them" class="w-30-h-30 center btn-danger rounded-circle border-0">
            <i class="fas fa-user-times"></i>
          </a>
          <a href="communications/inbox-cancelled"
            *ngIf="aboutUser?.CommunicationType=='Receiver' && aboutUser?.stage == 7 && !aboutUser?.isReported && !aboutUser?.isBlocked"
            placement="left" ngbTooltip="Cancelled by me" class="w-30-h-30 center btn-danger rounded-circle border-0">
            <i class="fas fa-user-minus"></i>
          </a>
          <a href="communications/inbox-cancelled"
            *ngIf="aboutUser?.CommunicationType=='Receiver' && aboutUser?.stage == 15 && !aboutUser?.isReported && !aboutUser?.isBlocked"
            placement="left" ngbTooltip="Cancelled automatically"
            class="w-30-h-30 center btn-danger rounded-circle border-0">
            <i class="fas fa-user-slash"></i>
          </a>

          <a href="communications/inbox-blocked" *ngIf="aboutUser?.stage == 8 && !aboutUser?.isReported && aboutUser?.isBlocked"
            placement="left" ngbTooltip="Blocked Profile "
            class="w-30-h-30 center btn-danger rounded-circle border-0 me-1">
            <i class="fas fa-user-lock"></i>
          </a>
          <a href="communications/inbox-blocked" *ngIf="aboutUser?.stage <3 && !!aboutUser?.isBlocked && !!aboutUser?.isReported"
            placement="left" ngbTooltip="Reported Profile "
            class="w-30-h-30 center btn-danger rounded-circle border-0 me-1">
            <i class="fas fa-user-clock"></i>
          </a>
          <ng-container *ngIf="aboutUser?.stage == 6">
            <a (click)="connectCancelled(partnerProfileDetail?.user_id,partnerProfileDetail?.display_name,partnerProfileDetail?.profile_photo,partnerProfileDetail?.profile_photo_approved)"
              *ngIf="aboutUser?.CommunicationType == 'Sender' && !aboutUser?.isFavourite && !aboutUser?.isShortListed "
              placement="left" ngbTooltip="Rejected by me" class="w-30-h-30 center btn-danger rounded-circle border-0">
              <i class="fas fa-ban"></i>
            </a>

          </ng-container>
          <button style="display:none;" id="openConnectModal" data-bs-target="#ReConnectModal"
            data-bs-toggle="modal"></button>

          <a href="communications/inbox-followUp" *ngIf="aboutUser?.stage == 9  && aboutUser?.CommunicationType=='Receiver' && !aboutUser?.isReported && !aboutUser?.isBlocked"
            placement="left" ngbTooltip="Follow Up " class="w-30-h-30 center btn-danger rounded-circle border-0 me-1">
            <i class="fas fa-question"></i>
          </a>

          <a href="chat" *ngIf="aboutUser?.stage == 10 && !aboutUser?.isReported && !aboutUser?.isBlocked"
            placement="left" ngbTooltip="Chat Now " class="w-30-h-30 center btn-danger rounded-circle border-0 me-1">
            <i class="fas fa-comments fs-6"></i>
          </a>

          <a href="communications/inbox-blocked" *ngIf="aboutUser?.stage == 11 && !!aboutUser?.isReported " placement="left"
            ngbTooltip=" Blocked & Reported Profile " class="w-30-h-30 center btn-danger rounded-circle border-0 me-1">
            <i class="fas fa-user-clock"></i>
          </a>

          <a href="communications/inbox-followUp"
            *ngIf="aboutUser?.CommunicationType=='Receiver' && aboutUser?.stage == 12 && !aboutUser?.isReported && !aboutUser?.isBlocked"
            placement="left" ngbTooltip="Uncancelled " class="w-30-h-30 center btn-danger rounded-circle border-0 me-1">
            <i class="fa fa-undo"></i>
          </a>

          <!-- handel by stage end -->

          <button style="display:none;" href="javascript:void(0);" id="redirect" data-bs-target="#redirectModal"
            data-bs-toggle="modal" (click)="getMoreAboutUser()"></button>
          <button style="display:none;" href="javascript:void(0);" id="blockModal1" data-bs-target="#blockModal"
            data-bs-toggle="modal" (click)="getMoreAboutUser()"></button>
          <button style="display:none;" href="javascript:void(0);" id="upgradeModal1" data-bs-target="#upgradeModal"
            data-bs-toggle="modal" (click)="getDetail()"></button>
          <button style="display:none;" href="javascript:void(0);" id="requestAccess"
            data-bs-target="#requestAccessModal" data-bs-toggle="modal" (click)="getDetail()"></button>
          <ng-container>

            <div class="dropdown h-min-content" *ngIf="!(aboutUser?.isReported && aboutUser?.stage == 11)">
              <div class="ms-1 w-30-h-30 center rounded-circle btn-danger border-0" type="button"
                id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="fas fa-ellipsis-v"></i>
              </div>
              <ul class="dropdown-menu dropdown h-min-content py-2 border border-1 border-danger cursor-pointer"
                aria-labelledby="dropdownMenuButton1">
                <ng-container *ngIf="aboutUser?.isFavourite == false && aboutUser?.isShortListed == false && aboutUser?.isBlocked == false &&
                    aboutUser?.isReported == false">
                  <li
                    *ngIf="((aboutUser?.stage == 7 || aboutUser?.stage == 12) && aboutUser?.CommunicationType == 'Sender') || aboutUser?.stage <3   || (aboutUser.stage ==15 && aboutUser.CommunicationType=='Sender' )"
                    class=" dropdown-item p-sm px-2" id="add_to_shortlist"
                    (click)="actionSelected($event, partnerProfileDetail?.user_id)">
                    <i class="fas fa-bookmark"></i>&nbsp;Add to Shortlist
                  </li>
                  <!-- || (aboutUser?.stage ==9 && aboutUser.CommunicationType=='Sender' ) -->
                </ng-container>
                <li class="dropdown-item p-sm px-2" id="back_to_shortlist"
                  *ngIf="(aboutUser?.isFavourite==true || aboutUser?.stage ==2) && !aboutUser?.isReported && !aboutUser?.isBlocked "
                  (click)="actionSelected($event, partnerProfileDetail?.user_id)">
                  <i class="fas fa-arrow-left"></i> Back to Shortlist
                </li>
                <li class="dropdown-item px-2 p-sm" id="add_to_my_favourite" *ngIf="(aboutUser?.stage ==1 ||aboutUser?.stage == 6 || (aboutUser?.stage == 7 || aboutUser?.stage == 12)) &&
                    !aboutUser?.isBlocked && !aboutUser?.isReported && !!aboutUser?.isShortListed"
                  (click)="addToFavoriteList(partnerProfileDetail?.user_id)">
                  <i class="fas fa-heart"></i> Add To My Favourite
                </li>

                <li class="dropdown-item px-2 p-sm" id="add_to_my_favourite" *ngIf="aboutUser?.isFavourite==true && (aboutUser?.stage == 2 || (aboutUser?.stage == 7 || aboutUser?.stage == 12)) &&
                !aboutUser?.isReported && !aboutUser?.isBlocked" (click)="connectNowModal()">
                  <i class="fas fa-handshake"></i> Connect Now
                </li>

                <!-- communication inbox actions -->
                <li *ngIf="aboutUser?.CommunicationType=='Sender' && (aboutUser?.stage==3 || aboutUser?.stage==9 )   && !aboutUser?.isReported
                    " class="dropdown-item p-sm px-2" id="Respond"
                  (click)="doActionOnRequestsRespond('accept', partnerProfileDetail?.user_id)">
                  <i class="fas fa-reply"></i> Accept
                </li>
                <li *ngIf="aboutUser?.CommunicationType=='Sender' && (aboutUser?.stage==3 || aboutUser?.stage==9 ) && !aboutUser?.isReported && !aboutUser?.isBlocked
                    " class="dropdown-item p-sm px-2" id="Decline"
                  (click)="doActionOnRequests('decline', partnerProfileDetail?.user_id)">
                  <i class="fas fa-ban"></i> Reject
                </li>

                <!-- communication sent actions -->
                <li *ngIf="aboutUser?.CommunicationType=='Receiver' && (aboutUser?.stage==3 || aboutUser?.stage == 12 || aboutUser?.stage == 9 ) &&
                    !aboutUser?.isReported && !aboutUser?.isBlocked" class="dropdown-item p-sm px-2" id="cancel"
                  (click)="doActionOnRequestsSend('cancel',partnerProfileDetail?.user_id)">
                  <i class="fas fa-times-circle"></i> Cancel
                </li>

                <!-- communication followup actions -->
                <li *ngIf="aboutUser?.CommunicationType=='Receiver' && aboutUser?.cancelledBy == 'Uncancelled' && aboutUser?.stage == 12 &&
                    !aboutUser?.isReported && !aboutUser?.isBlocked" class="dropdown-item p-sm px-2" (click)="doActionOnRequestsFollow('re_submit',partnerProfileDetail?.user_id )
                    " id="re_submit">
                  <i class="fas fa-retweet"></i> Re-Submit
                </li>

                <li *ngIf="aboutUser?.cancelledBy != 'Uncancelled' && aboutUser?.stage == 9 && aboutUser?.CommunicationType=='Receiver'   && (aboutUser?.cancelledBy=='Automatic') &&
                    !aboutUser?.isReported && !aboutUser?.isBlocked
                    " class="dropdown-item p-sm px-2" id="nudge"
                  (click)="doActionOnRequestsSend('nudge',partnerProfileDetail?.user_id )">
                  <i class="fas fa-hand-point-left"></i> Nudge
                </li>
                <!-- communication cancelled actions -->

                <li *ngIf="aboutUser?.CommunicationType=='Receiver' && (aboutUser?.cancelledBy=='Me' || aboutUser?.stage == 7 ) &&
                    !aboutUser?.isReported && !aboutUser?.isBlocked" class="dropdown-item
                    p-sm px-2" id="un_cancel"
                  (click)="doActionOnRequestscancelled('un_cancel',partnerProfileDetail?.user_id)">
                  <i class="fas fa-undo"></i> Un-Cancel
                </li>
                <!-- communication cancelled actions end -->

                <li class="dropdown-item px-2 p-sm" id="remove_from_shortlist" *ngIf="!!aboutUser?.isShortListed && !aboutUser?.isFavourite && ((aboutUser?.stage == 7 || aboutUser?.stage == 12
                    )||aboutUser?.stage == 1 ||aboutUser?.stage == 6) && !aboutUser?.isBlocked"
                  (click)="actionSelected($event, partnerProfileDetail?.user_id)">
                  <i class="fas fa-bookmark"></i> Remove From Shortlist
                </li>
                <!-- communication blocked actions  -->

                <li *ngIf="(aboutUser?.stage != 8 && aboutUser?.stage!=11)" class="dropdown-item p-sm px-2"
                  id="block_profile" (click)="actionSelected($event, partnerProfileDetail?.user_id)">
                  <i class="fas fa-user-lock"></i> Block
                </li>

                <li *ngIf="aboutUser?.stage != 11" class="dropdown-item p-sm px-2" id="report_profile"
                  data-bs-toggle="modal" data-bs-target="#blockReportModal"
                  (click)="userBlockReport(partnerProfileDetail)">
                  <i class="fas fa-user-clock"></i> Block & Report
                </li>

              </ul>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</section>
<button style="display:none;" id="openModal2222" data-bs-target="#deleteModal1111" data-bs-toggle="modal"></button>
<!-- loader of detail page end -->
<section class="container">
  <div class="row mt-3">
    <div class="col-12 px-2 px-md-0">
      <div class="row mx-0">
        <div class="col-12 card p-2">
          <h5 class="pb-2 fw-bold border border-2 lh-base border-top-0 border-danger border-start-0 border-end-0">
            Conversation Starter
          </h5>

          <div *ngIf="detailLoader">
            <p class="mb-0 d-flex align-items-center">
              <span class="min-width-40"><i class="fas fa-check fs-5 text-success"></i>
              </span>
              <span class="footerBlankBox1 mt-1 animated-background-name"></span>
            </p>
            <p class="mb-0 d-flex align-items-center">
              <span class="min-width-40"><i class="fas fa-check fs-5 text-success"></i>
              </span>
              <span class="footerBlankBox1 mt-1 animated-background-name"></span>
            </p>

          </div>

          <div *ngIf="dataMatched?.length == 0 && !detailLoader">
            <p class="mb-0 d-flex align-items-center">
              <span class="min-width-40"><i class="fas fa-check fs-5 text-success"></i>
              </span>
              <span>You both have not any matches</span>
            </p>
          </div>

          <div *ngIf="dataMatched?.length> 0 && !detailLoader ">
            <div *ngFor="let conversation of dataMatched">
              <!-- 1 living_with_family-->
              <p class="mb-0 d-flex align-items-center" *ngIf="conversation.key == 'living_with_family'">
                <span class="min-width-40"><i class="fas fa-check fs-5 text-success"></i>
                </span>
                <span>You both have same living with family
                  status.</span>
              </p>
              <!-- 2 age-->
              <p class="mb-0 d-flex align-items-center" *ngIf="conversation.key == 'age1'">
                <span class="min-width-40"><i class="fas fa-check fs-5 text-success"></i></span>
                <span>You both {{conversation.value}}.</span>
              </p>
              <!-- 3 religion-->
              <p class="mb-0 d-flex align-items-center" *ngIf="conversation.key == 'religion'">
                <span class="min-width-40"><i class="fas fa-check fs-5 text-success"></i></span>
                <span>You are both {{conversation.value}}.</span>
              </p>
              <!-- 4 caste-->
              <p class="mb-0 d-flex align-items-center" *ngIf="conversation.key == 'caste'">
                <span class="min-width-40"><i class="fas fa-check fs-5 text-success"></i></span>
                <span>You both have {{conversation.value}} caste.</span>
              </p>
              <!-- 5 languages-->
              <p class="mb-0 d-flex align-items-center" *ngIf="conversation.key == 'languages'">
                <span class="min-width-40"><i class="fas fa-check fs-5 text-success"></i></span>
                <span>You both have {{conversation.value}} as your preferred language.</span>
              </p>
              <!-- 6 living_in_country-->
              <p class="mb-0 d-flex align-items-center" *ngIf="conversation.key == 'living_in_country'">
                <span class="min-width-40"><i class="fas fa-check fs-5 text-success"></i></span>
                <span> You both live in {{partnerProfileDetail?.living_in_country}}.</span>
              </p>
              <!-- 7 state_name-->
              <p class="mb-0 d-flex align-items-center" *ngIf="conversation.key == 'state_name'">
                <span class="min-width-40"><i class="fas fa-check fs-5 text-success"></i></span>
                <span>You both live in {{partnerProfileDetail?.state_name}}.</span>
              </p>
              <!-- 8 current_city-->
              <p class="mb-0 d-flex align-items-center" *ngIf="conversation.key == 'current_city'">
                <span class="min-width-40"><i class="fas fa-check fs-5 text-success"></i></span>
                <span>You both live in {{conversation.value}}.</span>
              </p>
              <!-- 9 family_type-->
              <p class="mb-0 d-flex align-items-center" *ngIf="conversation.key == 'family_type'">
                <span class="min-width-40"><i class="fas fa-check fs-5 text-success"></i></span>
                <span>You both have same family type.</span>
              </p>
              <!-- 10 residency_status-->
              <p class="mb-0 d-flex align-items-center" *ngIf="conversation.key == 'residency_status'">
                <span class="min-width-40"><i class="fas fa-check fs-5 text-success"></i></span>
                <span>Your residency status is {{conversation.value}}.</span>
              </p>
              <!-- 11 marital_status-->
              <p class="mb-0 d-flex align-items-center"
                *ngIf="conversation.key == 'marital_status' && conversation.value!='Annulled' ">
                <span class="min-width-40"><i class="fas fa-check fs-5 text-success"></i></span>

                <span>Both of you are {{conversation.value}}.</span>
              </p>
              <!-- 12 food_preference-->
              <p class="mb-0 d-flex align-items-center" *ngIf="conversation.key == 'food_preference'">
                <span class="min-width-40"><i class="fas fa-check fs-5 text-success"></i></span>
                <span>You both preferred {{conversation.value}}.</span>
              </p>
              <!-- 13 qualification-->
              <p class="mb-0 d-flex align-items-center" *ngIf="conversation.key == 'qualification'">
                <span class="min-width-40"><i class="fas fa-check fs-5 text-success"></i></span>
                <span>You both has {{conversation.value}}.</span>
              </p>
              <!-- 14 college_name-->
              <p class="mb-0 d-flex align-items-center" *ngIf="conversation.key == 'college_name'">
                <span class="min-width-40"><i class="fas fa-check fs-5 text-success"></i></span>
                <span>You both went to {{conversation.value}}.</span>
              </p>
              <!-- 15 nature_of_work-->
              <p class="mb-0 d-flex align-items-center" *ngIf="conversation.key == 'nature_of_work'">
                <span class="min-width-40"><i class="fas fa-check fs-5 text-success"></i></span>
                <span>You both have same nature of work.</span>
              </p>
              <!-- 16 sun_sign-->
              <p class="mb-0 d-flex align-items-center" *ngIf="conversation.key == 'sun_sign'">
                <span class="min-width-40"><i class="fas fa-check fs-5 text-success"></i></span>
                <span>You both are {{conversation.value}}.</span>
              </p>
              <!-- 17 blood_group-->
              <p class="mb-0 d-flex align-items-center" *ngIf="conversation.key == 'blood_group'">
                <span class="min-width-40"><i class="fas fa-check fs-5 text-success"></i></span>
                <span>You both have {{conversation.value}} blood group.</span>
              </p>
              <!-- 18 community-->
              <p class="mb-0 d-flex align-items-center" *ngIf="conversation.key == 'community'">
                <span class="min-width-40"><i class="fas fa-check fs-5 text-success"></i></span>
                <span>You both have same community.</span>
              </p>
              <!-- 19 gothra-->
              <p class="mb-0 d-flex align-items-center" *ngIf="conversation.key == 'gothra'">
                <span class="min-width-40"><i class="fas fa-check fs-5 text-success"></i></span>
                <span>You both have same gothra.</span>
              </p>
              <!-- 20 native_place-->
              <p class="mb-0 d-flex align-items-center" *ngIf="conversation.key == 'native_place'">
                <span class="min-width-40"><i class="fas fa-check fs-5 text-success"></i></span>
                <span>You both have same native place.</span>
              </p>
              <!-- 21 working_as-->
              <p class="mb-0 d-flex align-items-center" *ngIf="conversation.key == 'working_as'">
                <span class="min-width-40"><i class="fas fa-check fs-5 text-success"></i></span>
                <span>You both work as {{conversation.value}}.</span>
              </p>
              <!-- 22 country_of_birth-->
              <p class="mb-0 d-flex align-items-center" *ngIf="conversation.key == 'country_of_birth'">
                <span class="min-width-40"><i class="fas fa-check fs-5 text-success"></i></span>
                <span>You are both born in {{conversation.value}}.</span>
              </p>

              <!-- 23 hobbies-->
              <p class="mb-0 d-flex align-items-center" *ngIf="conversation.key == 'hobbies1'">
                <span class="min-width-40"><i class="fas fa-check fs-5 text-success"></i></span>
                <span>Both of you like {{conversation.value}}.</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-12 col-md-6 ps-md-0 pe-md-2 px-2">
      <div class="row mt-3 mx-0">
        <div class="col-12 card p-2 position-relative">
          <h5 class="pb-2 fw-bold border border-2 lh-base border-top-0 border-danger border-start-0 border-end-0">
            About {{ displayName }}
          </h5>

          <p [ngClass]="(!partnerProfileDetail?.first_name)?'animated-background-name':''" class="mb-0 text-muted mb-2"
            *ngIf="
              partnerProfileDetail?.display_name == '' ||
              partnerProfileDetail?.display_name == 'null'
              ">
            <strong> First Name : </strong>
            {{ partnerProfileDetail?.first_name }}
          </p>

          <p [ngClass]="(!partnerProfileDetail?.first_name)?'animated-background-name':''" class="mb-0 text-muted mb-2"
            *ngIf="
              partnerProfileDetail?.display_name == '' ||
              partnerProfileDetail?.display_name == 'null'
              ">
            <strong> Last Name : </strong> {{ partnerProfileDetail?.last_name }}
          </p>

          <p [ngClass]="(!partnerProfileDetail?.first_name)?'animated-background-name':''" class="mb-0 text-muted mb-2">
            <strong> Display Name : </strong> {{ displayName }}
          </p>

          <p [ngClass]="(!partnerProfileDetail?.first_name)?'animated-background-name':''" class="mb-0 text-muted mb-2">
            <strong> Age : </strong> {{ age }} <small class="p-sm">Years</small>
          </p>

          <p [ngClass]="(!partnerProfileDetail?.first_name)?'animated-background-name':''" class="mb-0 text-muted mb-2">
            <strong> Height : </strong> {{ partnerProfileDetail?.height }} cm
            ({{ partnerProfileDetail?.height | heightConverter : "Ft" }})
          </p>

          <p [ngClass]="(!partnerProfileDetail?.first_name)?'animated-background-name':''" class="mb-0 text-muted mb-2">
            <strong> Weight : </strong>
            {{ partnerProfileDetail?.weight }} kg ({{
            partnerProfileDetail?.weight| weightConverter : "Pounds"
            }}
            lb)
          </p>

          <p [ngClass]="(!partnerProfileDetail?.first_name)?'animated-background-name':''" class="mb-0 text-muted mb-2">
            <strong> Preferred Language : </strong>
            {{ partnerProfileDetail?.languages | addSpaceAfterComma }}
          </p>

          <p [ngClass]="(!partnerProfileDetail?.first_name)?'animated-background-name':''" class="mb-0 text-muted mb-2">
            <strong> Marital Status : </strong>
            {{ partnerProfileDetail?.marital_status }}
          </p>
          <p [ngClass]="(!partnerProfileDetail?.first_name)?'animated-background-name':''" class="mb-0 text-muted mb-2"
            *ngIf="
              partnerProfileDetail?.have_children == 'Yes' ||
              partnerProfileDetail?.have_children == 'No'
              ">
            <strong> Have Children : </strong>{{ partnerProfileDetail?.have_children }}
          </p>

          <p [ngClass]="(!partnerProfileDetail?.first_name)?'animated-background-name':''" class="mb-0 text-muted mb-2">
            <strong> Personality Description : </strong>
            {{ partnerProfileDetail?.description }}
          </p>
        </div>
      </div>

      <div class="row mt-3 mx-0">
        <div class="col-12 card p-2">
          <h5 class="pb-2 fw-bold border border-2 lh-base border-top-0 border-danger border-start-0 border-end-0">
            Basics & Lifestyle
          </h5>

          <p class="mb-0 text-muted mb-2" hidden>
            <strong> Date of Birth : </strong>
            {{ partnerProfileDetail?.dob | date : "dd-MM-yyyy" }}
          </p>

          <p [ngClass]="(!partnerProfileDetail?.first_name)?'animated-background-name':''" class="mb-0 text-muted mb-2">
            <strong> Food Preference : </strong>
            {{ partnerProfileDetail?.food_preference | addSpaceAfterComma }}
          </p>

          <p [ngClass]="(!partnerProfileDetail?.first_name)?'animated-background-name':''" class="mb-0 text-muted mb-2"
            *ngIf="
              partnerProfileDetail?.grew_up_in != '' &&
              partnerProfileDetail?.grew_up_in != null
              ">
            <strong> Grew Up In : </strong>
            {{ partnerProfileDetail?.grew_up_in }}
          </p>

          <p [ngClass]="(!partnerProfileDetail?.first_name)?'animated-background-name':''" class="mb-0 text-muted mb-2"
            *ngIf="
              partnerProfileDetail?.personal_values != '' &&
              partnerProfileDetail?.personal_values != null
              ">
            <strong> Personal Values : </strong>{{ partnerProfileDetail?.personal_values }}
          </p>

          <p [ngClass]="(!partnerProfileDetail?.first_name)?'animated-background-name':''" class="mb-0 text-muted mb-2"
            *ngIf="
              partnerProfileDetail?.do_smoke != '' &&
              partnerProfileDetail?.do_smoke != null
              ">
            <strong> Do you prefer a partner who smokes? : </strong>
            {{ partnerProfileDetail?.do_smoke }}
          </p>

          <p [ngClass]="(!partnerProfileDetail?.first_name)?'animated-background-name':''" class="mb-0 text-muted mb-2"
            *ngIf="
              partnerProfileDetail?.do_drink != '' &&
              partnerProfileDetail?.do_drink != null
              ">
            <strong> Do you prefer a partner who drinks alcohol? : </strong>
            {{ partnerProfileDetail?.do_drink }}
          </p>

          <p [ngClass]="(!partnerProfileDetail?.first_name)?'animated-background-name':''" class="mb-0 text-muted mb-2"
            *ngIf="
              partnerProfileDetail?.have_pet != '' &&
              partnerProfileDetail?.have_pet != null
              ">
            <strong> Do you prefer a partner who has pets? : </strong>
            {{ partnerProfileDetail?.have_pet }}
          </p>

          <p [ngClass]="(!partnerProfileDetail?.first_name)?'animated-background-name':''" class="mb-0 text-muted mb-2"
            *ngIf="
              partnerProfileDetail?.sun_sign != '' &&
              partnerProfileDetail?.sun_sign != null
              ">
            <strong> Sun Sign : </strong>{{ partnerProfileDetail?.sun_sign }}
          </p>

          <p [ngClass]="(!partnerProfileDetail?.first_name)?'animated-background-name':''" class="mb-0 text-muted mb-2"
            *ngIf="
              partnerProfileDetail?.blood_group != '' &&
              partnerProfileDetail?.blood_group != null
              ">
            <strong> Blood Group : </strong>
            {{ partnerProfileDetail?.blood_group }}
          </p>

          <p [ngClass]="(!partnerProfileDetail?.first_name)?'animated-background-name':''" class="mb-0 text-muted mb-2"
            *ngIf="
              partnerProfileDetail?.health_info != '' &&
              partnerProfileDetail?.health_info != null
              ">
            <strong> Health Information : </strong>{{ partnerProfileDetail?.health_info }}
          </p>

          <p [ngClass]="(!partnerProfileDetail?.first_name)?'animated-background-name':''" class="mb-0 text-muted mb-2"
            *ngIf="
              partnerProfileDetail?.disability != '' &&
              partnerProfileDetail?.disability != null
              ">
            <strong> Disability : </strong>{{ partnerProfileDetail?.disability }}
          </p>
        </div>
      </div>

      <div class="row mt-3 mx-0">
        <div class="col-12 card p-2 position-relative" id="astrocard">
          <h5 class="mb-0 fw-bold pb-2 pt-2">Astro Details</h5>

          <div
            class=" mt-2 py-2 px-0 position-relative my-3 border border-2 border-danger border-end-0 border-start-0 border-bottom-0">

            <ng-container
              *ngIf=" myRequestDetails?.request_astro_detail !== undefined &&  ((myRequestDetails?.request_astro_detail && myRequestDetails?.astro_detail_status == 2)  || (!myRequestDetails?.request_astro_detail  &&  myRequestDetails?.astro_detail_status != 1 ) ) ">
              <div class="lock-btn bg-white d-flex align-items-center justify-content-center position-absolute w-30-h-30 rounded-circle btn p-0 m-0
                border-2 border border-danger" (click)="getRequestAccess('astro_detail')" placement="bottom"
                [ngbTooltip]="myRequestDetails?.astro_detail_status == 2 ? 'Request denied, Request again for access': 'Request for access'">
                <i class="fas fa-lock text-danger"></i>
              </div>
            </ng-container>

            <ng-container *ngIf="myRequestDetails?.request_astro_detail === undefined ">
              <div class="lock-btn bg-white d-flex align-items-center justify-content-center position-absolute w-30-h-30 rounded-circle  p-0 m-0
            border-2 border border-danger" placement="bottom"
                ngbTooltip="Only connected members can request for access">
                <i class="fas fa-lock text-danger"></i>
              </div>
            </ng-container>

            <div class="lock-btn bg-white d-flex align-items-center justify-content-center position-absolute w-30-h-30 rounded-circle btn p-0 m-0
            border-2 border border-danger"
              *ngIf="myRequestDetails?.request_astro_detail && myRequestDetails?.astro_detail_status == 0"
              placement="bottom" ngbTooltip="Requested for access">
              <i class="fas fa-lock text-danger"></i>
            </div>
            <div class=" bg-white" >
              <p [ngClass]="(!partnerProfileDetail?.first_name)?'animated-background-name':''" class="mt-3 text-center">

                <span class="fw-bold text-center"  *ngIf="!myRequestDetails?.request_astro_detail && myRequestDetails?.astro_detail_status != 0  && myRequestDetails?.astro_detail_status != 2" >Request
                  access
                  to view details .</span>
                <span class="fw-bold text-center"
                  *ngIf="myRequestDetails?.request_astro_detail && myRequestDetails?.astro_detail_status == 0">Request
                  sent to
                  view details .
                </span>
                <span class="fw-bold text-center"
                  *ngIf="myRequestDetails?.request_astro_detail && myRequestDetails?.astro_detail_status == 2">
                  Request to view
                  details .</span>
              </p>
            </div>
            <ng-container [ngClass]="(!partnerProfileDetail?.first_name)?'animated-background-name':''"
              class="fw-bold mt-3"
              *ngIf="(myRequestDetails?.request_astro_detail && myRequestDetails?.astro_detail_status == 1) ">

              <!-- ||  myRequestDetails?.dob -->
              <p *ngIf="myRequestDetails?.manglik!=null && myRequestDetails?.manglik!='' " class="mb-0 text-muted mb-2">
                <strong> Manglik : </strong> {{myRequestDetails?.manglik}}
              </p>

              <p *ngIf="myRequestDetails?.dob!=null && myRequestDetails?.dob!='' " class="mb-0 text-muted mb-2">
                <strong> Date of Birth : </strong> {{myRequestDetails?.dob | date:
                'dd-MM-yyyy' }}
              </p>

              <p *ngIf="myRequestDetails?.time_of_birth!=null && myRequestDetails?.time_of_birth!='' "
                class="mb-0 text-muted mb-2"> <strong> Time of Birth : </strong> {{myRequestDetails?.time_of_birth}}
              </p>

              <p *ngIf="myRequestDetails?.country_of_birth!=null && myRequestDetails?.country_of_birth!='' "
                class="mb-0 text-muted mb-2"> <strong> Country of Birth : </strong>
                {{myRequestDetails?.country_of_birth}} </p>

              <p *ngIf="myRequestDetails?.city_of_birth!=null && myRequestDetails?.city_of_birth!='' "
                class="mb-0 text-muted mb-2"> <strong> City/State of Birth : </strong>
                {{myRequestDetails?.city_of_birth}} </p>

            </ng-container>

          </div>
        </div>
      </div>
      <div class="row mt-3 mx-0">
        <div class="col-12 card p-2 position-relative">
          <h5 class="pb-2 fw-bold border border-2 lh-base border-top-0 border-danger border-start-0 border-end-0">
            Education & Professional Details
          </h5>

          <p [ngClass]="(!partnerProfileDetail?.first_name)?'animated-background-name':''" class="mb-0 text-muted mb-2">
            <strong> Education : </strong>
            {{ partnerProfileDetail?.qualification | addSpaceAfterComma}}
          </p>

          <p [ngClass]="(!partnerProfileDetail?.first_name)?'animated-background-name':''" class="mb-0 text-muted mb-2"
            *ngIf="
              partnerProfileDetail?.college_name != '' &&
              partnerProfileDetail?.college_name != null
              ">
            <strong> College/University Name : </strong>
            {{ partnerProfileDetail?.college_name }}
          </p>

          <p [ngClass]="(!partnerProfileDetail?.first_name)?'animated-background-name':''" class="mb-0 text-muted mb-2">
            <strong> Nature of Work : </strong>
            {{ partnerProfileDetail?.nature_of_work  | addSpaceAfterComma}}
          </p>

          <p [ngClass]="(!partnerProfileDetail?.first_name)?'animated-background-name':''" class="mb-0 text-muted mb-2"
            *ngIf="
              partnerProfileDetail?.working_as != '' &&
              partnerProfileDetail?.working_as != null
              ">
            <strong> Profession Area : </strong>
            {{ partnerProfileDetail?.working_as  | addSpaceAfterComma}}
          </p>

          <p [ngClass]="(!partnerProfileDetail?.first_name)?'animated-background-name':''" class="mb-0 text-muted mb-2"
            *ngIf="
              partnerProfileDetail?.company_name != '' &&
              partnerProfileDetail?.company_name != null
              ">
            <strong> Company Name : </strong>
            {{ partnerProfileDetail?.company_name }}
          </p>
          <ng-container
            *ngIf="(myRequestDetails?.annual_income_visiblity!='No one' && this.aboutUser?.stage==5) || myRequestDetails?.annual_income_visiblity=='Visible to all members' ">
            <p [ngClass]="(!partnerProfileDetail?.first_name)?'animated-background-name':''"
              class="mb-0 text-muted mb-2" *ngIf="
              partnerProfileDetail?.annual_income != '' &&
              partnerProfileDetail?.annual_income != null
              ">
              <strong> Annual Income : </strong>
              {{ partnerProfileDetail?.income_currency }}
              {{ partnerProfileDetail?.annual_income | number }}
            </p>
          </ng-container>
        </div>
      </div>

      <div class="row mt-3 mx-0">
        <div class="col-12 card p-2">
          <h5 class="pb-2 fw-bold border border-2 lh-base border-top-0 border-danger border-start-0 border-end-0">
            My Photo Gallery
          </h5>

          <div *ngIf="detailLoader" class="row row-cols-3 row-cols-sm-4 g-2 justify-content-center">
            <div class="col">
              <div class="ratio-1 rounded-1 overflow-hidden animated-background-name">
                <img class="image-cover" alt="" />
              </div>

            </div>
          </div>

          <div *ngIf="!detailLoader" class="row row-cols-3 row-cols-sm-4 g-2">
            <div class="col-md-3" *ngFor="let url of myPhotos, let i= index  ">
              <div class="border-3 border rounded-3 border-warning">
                <div class="ratio-1_rm rounded-1 overflow-hidden cursor-pointer">
                  <img *ngIf="url.photo_thumb_url != ''" (click)="openlight(url,i)" ngbTooltip="{{url?.caption}}"
                    placement="auto" class="image-cover image-cover-2 rounded-3  " alt data-bs-toggle="modal"
                    data-bs-target="#exampleModal" src="{{ url.photo_thumb_url }}" />
                  <img *ngIf="url.photo_thumb_url == ''" (click)="openlight(url,i)" ngbTooltip="{{url?.caption}}"
                    placement="auto" class="image-cover image-cover-2 rounded-3  " alt data-bs-toggle="modal"
                    data-bs-target="#exampleModal" src="{{ url.photo_url }}" />
                </div>
              </div>

            </div>
            <p *ngIf="myPhotos.length == 0" class="mb-0 text-muted mb-2">No photo gallery found!</p>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-6 pe-md-0 ps-md-2 px-2">
      <div class="row mt-3 mx-0">
        <div class="col-12 card p-2" id="contactDeatilcard">
          <h5 class="mb-0 fw-bold pb-2 pt-2">Contact Details</h5>
          <div
            class=" mt-2 px-0 position-relative my-3 border border-2 border-danger border-end-0 border-start-0 border-bottom-0">
            <ng-container
              *ngIf=" myRequestDetails?.request_contact_detail !== undefined && ((myRequestDetails?.request_contact_detail && myRequestDetails?.contact_detail_status == 2)  || (!myRequestDetails?.request_contact_detail &&  myRequestDetails?.contact_detail_status != 1) )">
              <div class="lock-btn bg-white d-flex align-items-center justify-content-center position-absolute w-30-h-30 rounded-circle btn p-0 m-0
              border-2 border border-danger" (click)="getRequestAccess('contact_detail')" placement="bottom"
                [ngbTooltip]="myRequestDetails?.contact_detail_status == 2 ? 'Request denied, Request again for access': 'Request for access'">
                <i class="fas fa-lock text-danger"></i>
              </div>
            </ng-container>

            <ng-container *ngIf="myRequestDetails?.request_contact_detail === undefined ">
              <div class="lock-btn bg-white d-flex align-items-center justify-content-center position-absolute w-30-h-30 rounded-circle  p-0 m-0
            border-2 border border-danger" placement="bottom"
                ngbTooltip="Only connected members can request for access">
                <i class="fas fa-lock text-danger"></i>
              </div>
            </ng-container>




            <div class="lock-btn bg-white d-flex align-items-center justify-content-center position-absolute w-30-h-30 rounded-circle btn p-0 m-0
                border-2 border border-danger"
              *ngIf="myRequestDetails?.request_contact_detail && myRequestDetails?.contact_detail_status == 0"
              placement="bottom" ngbTooltip="Requested for access">
              <i class="fas fa-lock text-danger"></i>
            </div>

            <div class="bg-white" >
              <!-- card-footer  -->
              <p [ngClass]="(!partnerProfileDetail?.first_name)?'animated-background-name':''" class="mt-3 text-center">
                <span class="fw-bold" *ngIf="!myRequestDetails?.request_contact_detail && myRequestDetails?.contact_detail_status != 0  && myRequestDetails?.contact_detail_status != 2">Request
                  access to view details .</span>
                <span class="fw-bold"
                  *ngIf="myRequestDetails?.request_contact_detail && myRequestDetails?.contact_detail_status == 0">Request
                  sent
                  to view details . </span>
                <span class="fw-bold"
                  *ngIf="myRequestDetails?.request_contact_detail && myRequestDetails?.contact_detail_status == 2">
                  Request to
                  view details .</span>
              </p>

            </div>
            <ng-container [ngClass]="(!partnerProfileDetail?.first_name)?'animated-background-name':''"
              *ngIf="(myRequestDetails?.request_contact_detail && myRequestDetails?.contact_detail_status == 1)  ">
              <p *ngIf="myRequestDetails?.profile_created_by_name!=null && myRequestDetails?.profile_created_by_name!='' "
                class="mb-0 text-muted mb-2 mt-3"> <strong>
                  Profile Created By : </strong> {{myRequestDetails?.profile_created_by_name}}
                {{myRequestDetails?.profile_created_by_lastname}}
              </p>

              <p *ngIf="myRequestDetails?.profile_created_by_phone!=null && myRequestDetails?.profile_created_by_phone!='' "
                class="mb-0 text-muted mb-2"> <strong>
                  Mobile No. : </strong> {{myRequestDetails?.profile_created_by_phone}}
              </p>

              <p *ngIf="myRequestDetails?.profile_created_by_email!=null && myRequestDetails?.profile_created_by_email!='' "
                class="mb-0 text-muted mb-2"><strong>
                  Email : </strong> {{myRequestDetails?.profile_created_by_email}}
              </p>

              <p *ngIf="myRequestDetails?.contact_person!=null && myRequestDetails?.contact_person!='' "
                class="mb-0 text-muted mb-2"> <strong>
                  Contact Person : </strong> {{myRequestDetails?.contact_person}}
              </p>

              <p class="mb-0 text-muted mb-2" *ngIf="myRequestDetails?.profile_for!='Self'"> <strong>
                  Relationship With The Member : </strong> {{myRequestDetails?.profile_for}}
              </p>

              <p *ngIf="myRequestDetails?.convenient_time_to_call!=null && myRequestDetails?.convenient_time_to_call!='' "
                class="mb-0 text-muted mb-2"> <strong>
                  Convenient Time To Call : </strong> {{myRequestDetails?.convenient_time_to_call}}
              </p>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="row mt-3 mx-0">
        <div class="col-12 card p-2">
          <h5 class="pb-2 fw-bold border border-2 lh-base border-top-0 border-danger border-start-0 border-end-0">
            Religious Views
          </h5>

          <p [ngClass]="(!partnerProfileDetail?.first_name)?'animated-background-name':''" class="mb-0 text-muted mb-2">
            <strong> Religion : </strong> {{ partnerProfileDetail?.religion | addSpaceAfterComma }}
          </p>

          <p [ngClass]="(!partnerProfileDetail?.first_name)?'animated-background-name':''" class="mb-0 text-muted mb-2"
            *ngIf="
              partnerProfileDetail?.community != '' &&
              partnerProfileDetail?.community != null
              ">
            <strong> Community : </strong>{{ partnerProfileDetail?.community }}
          </p>

          <p [ngClass]="(!partnerProfileDetail?.first_name)?'animated-background-name':''" class="mb-0 text-muted mb-2"
            *ngIf="
              partnerProfileDetail?.caste != '' &&
              partnerProfileDetail?.caste != null
              ">
            <strong> Caste : </strong>{{ partnerProfileDetail?.caste }}
          </p>

          <p [ngClass]="(!partnerProfileDetail?.first_name)?'animated-background-name':''" class="mb-0 text-muted mb-2"
            *ngIf="
              partnerProfileDetail?.gothra != '' &&
              partnerProfileDetail?.gothra != null
              ">
            <strong> Gothra / Gothram : </strong>{{ partnerProfileDetail?.gothra }}
          </p>
        </div>
      </div>

      <div class="row mt-3 mx-0">
        <div class="col-12 card p-2">
          <h5 class="pb-2 fw-bold border border-2 lh-base border-top-0 border-danger border-start-0 border-end-0">
            Family Details
          </h5>

          <p [ngClass]="(!partnerProfileDetail?.first_name)?'animated-background-name':''" class="mb-0 text-muted mb-2"
            *ngIf="
              partnerProfileDetail?.father_status != '' &&
              partnerProfileDetail?.father_status != null
              ">
            <strong> Father's Status : </strong>
            {{ partnerProfileDetail?.father_status }}
          </p>
          <p [ngClass]="(!partnerProfileDetail?.first_name)?'animated-background-name':''" class="mb-0 text-muted mb-2"
            *ngIf="
              partnerProfileDetail?.mother_status != '' &&
              partnerProfileDetail?.mother_status != null
              ">
            <strong> Mother's Status : </strong>
            {{ partnerProfileDetail?.mother_status }}
          </p>

          <p [ngClass]="(!partnerProfileDetail?.first_name)?'animated-background-name':''" class="mb-0 text-muted mb-2"
            *ngIf="
              partnerProfileDetail?.no_of_brother != '' &&
              partnerProfileDetail?.no_of_brother != null
              ">
            <strong> No. of Brothers : </strong>
            {{ partnerProfileDetail?.no_of_brother }}
          </p>

          <p [ngClass]="(!partnerProfileDetail?.first_name)?'animated-background-name':''" class="mb-0 text-muted mb-2"
            *ngIf="
              partnerProfileDetail?.no_of_sister != '' &&
              partnerProfileDetail?.no_of_sister != null
              ">
            <strong> No. of Sisters : </strong>
            {{ partnerProfileDetail?.no_of_sister }}
          </p>

          <p [ngClass]="(!partnerProfileDetail?.first_name)?'animated-background-name':''" class="mb-0 text-muted mb-2"
            *ngIf="
              partnerProfileDetail?.family_living_in_country != '' &&
              partnerProfileDetail?.family_living_in_country != null
              ">
            <strong> Country : </strong>
            {{ partnerProfileDetail?.family_living_in_country }}
          </p>

          <p [ngClass]="(!partnerProfileDetail?.first_name)?'animated-background-name':''" class="mb-0 text-muted mb-2"
            *ngIf="
              partnerProfileDetail?.family_state_name != '' &&
              partnerProfileDetail?.family_state_name != null
              ">
            <strong> State/Province : </strong>
            {{ partnerProfileDetail?.family_state_name }}
          </p>

          <p [ngClass]="(!partnerProfileDetail?.first_name)?'animated-background-name':''" class="mb-0 text-muted mb-2"
            *ngIf="
              partnerProfileDetail?.family_current_city != '' &&
              partnerProfileDetail?.family_current_city != null
              ">
            <strong> City : </strong>
            {{ partnerProfileDetail?.family_current_city }}
          </p>

          <p [ngClass]="(!partnerProfileDetail?.first_name)?'animated-background-name':''" class="mb-0 text-muted mb-2"
            *ngIf="
              partnerProfileDetail?.family_zip_code != '' &&
              partnerProfileDetail?.family_zip_code != null
              ">
            <strong> Zip/Pin/Postal Code : </strong>{{ partnerProfileDetail?.family_zip_code }}
          </p>

          <p [ngClass]="(!partnerProfileDetail?.first_name)?'animated-background-name':''" class="mb-0 text-muted mb-2"
            *ngIf="
              partnerProfileDetail?.living_with_family != '' &&
              partnerProfileDetail?.living_with_family != null
              ">
            <strong> Living with family : </strong>
            {{ partnerProfileDetail?.living_with_family }}
          </p>

          <p [ngClass]="(!partnerProfileDetail?.first_name)?'animated-background-name':''" class="mb-0 text-muted mb-2"
            *ngIf="
              partnerProfileDetail?.family_type != '' &&
              partnerProfileDetail?.family_type != null
              ">
            <strong> Family Type : </strong>
            {{ partnerProfileDetail?.family_type }}
          </p>

          <p [ngClass]="(!partnerProfileDetail?.first_name)?'animated-background-name':''" class="mb-0 text-muted mb-2"
            *ngIf="
              partnerProfileDetail?.family_values != '' &&
              partnerProfileDetail?.family_values != null
              ">
            <strong> Family Values : </strong>
            {{ partnerProfileDetail?.family_values }}
          </p>

          <p [ngClass]="(!partnerProfileDetail?.first_name)?'animated-background-name':''" class="mb-0 text-muted mb-2"
            *ngIf="
              partnerProfileDetail?.native_place != '' &&
              partnerProfileDetail?.native_place != null
              ">
            <strong> Native Place : </strong>
            {{ partnerProfileDetail?.native_place }}
          </p>

          <p [ngClass]="(!partnerProfileDetail?.first_name)?'animated-background-name':''" class="mb-0 text-muted mb-2"
            *ngIf="
              partnerProfileDetail?.family_affluence != '' &&
              partnerProfileDetail?.family_affluence != null
              ">
            <strong> Family Affluence : </strong>
            {{ partnerProfileDetail?.family_affluence }}
          </p>
        </div>
      </div>

      <div class="row mt-3 mx-0">
        <div class="col-12 card p-2">
          <h5 class="pb-2 fw-bold border border-2 lh-base border-top-0 border-danger border-start-0 border-end-0">
            Hobbies, Interests & more
          </h5>

          <p [ngClass]="(!partnerProfileDetail?.first_name)?'animated-background-name':''" class="mb-0 text-muted mb-2"
            *ngIf="
              partnerProfileDetail?.hobbies != '' &&
              partnerProfileDetail?.hobbies != null
              ">
            <strong> Hobbies : </strong>
            {{ partnerProfileDetail?.hobbies | replaceSpaceWithComa }}
          </p>

          <p [ngClass]="(!partnerProfileDetail?.first_name)?'animated-background-name':''" class="mb-0 text-muted mb-2"
            *ngIf="
              partnerProfileDetail?.interests != '' &&
              partnerProfileDetail?.interests != null
              ">
            <strong> Interests : </strong> {{ partnerProfileDetail?.interests }}
          </p>

          <p [ngClass]="(!partnerProfileDetail?.first_name)?'animated-background-name':''" class="mb-0 text-muted mb-2"
            *ngIf="
              partnerProfileDetail?.preferred_movie != '' &&
              partnerProfileDetail?.preferred_movie != null
              ">
            <strong> Preferred Movies : </strong>{{ partnerProfileDetail?.preferred_movie }}
          </p>

          <p [ngClass]="(!partnerProfileDetail?.first_name)?'animated-background-name':''" class="mb-0 text-muted mb-2"
            *ngIf="
              partnerProfileDetail?.favorite_music != '' &&
              partnerProfileDetail?.favorite_music != null
              ">
            <strong> Favourite Music : </strong>{{ partnerProfileDetail?.favorite_music }}
          </p>

          <p [ngClass]="(!partnerProfileDetail?.first_name)?'animated-background-name':''" class="mb-0 text-muted mb-2"
            *ngIf="
              partnerProfileDetail?.sports_fitness != '' &&
              partnerProfileDetail?.sports_fitness != null
              ">
            <strong> Sports/Fitness Activities : </strong>{{ partnerProfileDetail?.sports_fitness }}
          </p>

          <p [ngClass]="(!partnerProfileDetail?.first_name)?'animated-background-name':''" class="mb-0 text-muted mb-2"
            *ngIf="
              partnerProfileDetail?.favorite_cuisine != '' &&
              partnerProfileDetail?.favorite_cuisine != null
              ">
            <strong> Favourite Cuisine : </strong>{{ partnerProfileDetail?.favorite_cuisine }}
          </p>

          <p [ngClass]="(!partnerProfileDetail?.first_name)?'animated-background-name':''" class="mb-0 text-muted mb-2"
            *ngIf="
              partnerProfileDetail?.preferred_dress_style != '' &&
              partnerProfileDetail?.preferred_dress_style != null
              ">
            <strong> Preferred Dress Style : </strong>{{ partnerProfileDetail?.preferred_dress_style }}
          </p>
        </div>
      </div>

      <div class="row mt-3 mx-0">
        <div class="col-12 card p-2 position-relative">
          <h5 class="pb-2 fw-bold border border-2 lh-base border-top-0 border-danger border-start-0 border-end-0">
            Your Location
          </h5>

          <p [ngClass]="(!partnerProfileDetail?.first_name)?'animated-background-name':''" class="mb-0 text-muted mb-2">
            <strong> Country : </strong>
            {{ partnerProfileDetail?.living_in_country }}
          </p>

          <p [ngClass]="(!partnerProfileDetail?.first_name)?'animated-background-name':''" class="mb-0 text-muted mb-2">
            <strong> State/Province : </strong>
            {{ partnerProfileDetail?.state_name }}
          </p>

          <p [ngClass]="(!partnerProfileDetail?.first_name)?'animated-background-name':''" class="mb-0 text-muted mb-2">
            <strong> Current City : </strong>
            {{ partnerProfileDetail?.current_city }}
          </p>

          <p [ngClass]="(!partnerProfileDetail?.first_name)?'animated-background-name':''" class="mb-0 text-muted mb-2">
            <strong> Zip/Pin/Postal Code : </strong>
            {{ partnerProfileDetail?.postal_code }}
          </p>

          <p [ngClass]="(!partnerProfileDetail?.first_name)?'animated-background-name':''" class="mb-0 text-muted mb-2">
            <strong> Residency Status : </strong>{{ partnerProfileDetail?.residency_status | addSpaceAfterComma  }}
          </p>
        </div>
      </div>

      <div class="row mt-3 mx-0">
        <div class="col-12 card p-2" [ngClass]="(!partnerPreferences?.age_from)?'animated-background':''">
          <h5 class="pb-2 fw-bold border border-2 lh-base border-top-0 border-danger border-start-0 border-end-0">
            Matches at a Glance
          </h5>

          <div class="d-flex align-items-center justify-content-between mb-4">
            <div class="text-center min-width-80"
              [ngClass]="(!myProfileDetail?.first_name)?'animated-background-name':''">
              <div class="mx-auto w-50-h-50 rounded-circle overflow-hidden position-relative">
                <img *ngIf="!myProfileDetail?.profile_photo || myProfileDetail?.profile_photo_approved!=1"
                  class="image-cover" src="assets/images/default-profile-picture.svg" alt />
                <img *ngIf="myProfileDetail?.profile_photo && myProfileDetail?.profile_photo_approved==1 "
                  class="image-cover" [src]="myProfileDetail?.profile_photo"
                  alt="default-profile-img">
              </div>
              <p class="mt-1 text-center mb-0 p-sm">
                {{ myProfileDetail?.first_name }}
                {{ myProfileDetail?.last_name }}
              </p>
            </div>

            <div class="rounded-3 bg-light border border-1 py-2 px-3 " *ngIf="showMatchesIcon">
              <p class="mb-0 p-sm text-muted text-center">
                You match {{count}}/10 Preferences
              </p>
            </div>

            <div class="text-center min-width-80"
              [ngClass]="(!partnerProfileDetail?.first_name)?'animated-background-name':''">
              <div class="mx-auto w-50-h-50 rounded-circle overflow-hidden position-relative">
                <img *ngIf="!partnerProfileDetail?.profile_photo || partnerProfileDetail?.profile_photo_approved!=1"
                  class="image-cover" src="assets/images/default-profile-picture.svg" alt />
                <ng-container *ngIf="(this.myRequestDetails?.photo_visiblity=='No one' || this.aboutUser?.stage!=5) && this.myRequestDetails?.photo_visiblity!='Visible to all members'
                  ">
                  <img *ngIf="!!partnerProfileDetail?.profile_photo || partnerProfileDetail?.profile_photo_approved==1"
                    class="image-cover" src="assets/images/default-profile-picture.svg" alt />
                </ng-container>
                <ng-container
                  *ngIf="(this.myRequestDetails?.photo_visiblity!='No one' && this.aboutUser?.stage==5) || this.myRequestDetails?.photo_visiblity=='Visible to all members'">

                  <img *ngIf="partnerProfileDetail?.profile_photo && partnerProfileDetail?.profile_photo_approved==1"
                    class="image-cover" [src]="profileSrc" alt="default-profile-img" />
                </ng-container>
              </div>
              <p class="mt-1 text-center mb-0 p-sm">{{ displayName }}</p>
            </div>
          </div>
          <div class="row py-2 border border-1 border-start-0 border-end-0 border-bottom-0 m-0">
            <div class="col-10">
              <p class="mb-0 py-1 p-sm text-muted fw-bold"
                [ngClass]="(!partnerPreferences?.age_from)?'animated-background-name':''">Age</p>
              <p class="mb-0 py-1 p-sm text-muted">
                {{ partnerPreferences?.age_from }} to
                {{ partnerPreferences?.age_to }}
              </p>
            </div>
            <div class="col-2 text-end my-auto" *ngIf="showMatchesIcon">
              <!-- {{age >= partnerPreferences?.age_from && age <= partnerPreferences?.age_to}} -->
              <div *ngIf="matchAge">
                <i class="fas fa-check-circle fs-4 text-success"></i>
              </div>
              <div *ngIf="!matchAge">
                <i class="fas fa-times-circle fs-4 text-danger"></i>
              </div>
            </div>
          </div>

          <div class="row py-2 border border-1 border-start-0 border-end-0 border-bottom-0 m-0">
            <div class="col-10">
              <p class="mb-0 py-1 p-sm text-muted fw-bold"
                [ngClass]="(!partnerPreferences?.age_from)?'animated-background-name':''">Height</p>
              <p class="mb-0 py-1 p-sm text-muted">
                {{
                partnerPreferences?.height_from | heightConverter : "Ft"
                }}
                to {{ partnerPreferences?.height_to | heightConverter : "Ft" }}
              </p>
            </div>
            <div class="col-2 text-end my-auto" *ngIf="showMatchesIcon">
              <div *ngIf="matchHeight">
                <i class="fas fa-check-circle fs-4 text-success"></i>
              </div>
              <div *ngIf="!matchHeight">
                <i class="fas fa-times-circle fs-4 text-danger"></i>
              </div>
            </div>
          </div>

          <div class="row py-2 border border-1 border-start-0 border-end-0 border-bottom-0 m-0">
            <div class="col-10">
              <p class="mb-0 py-1 p-sm text-muted fw-bold"
                [ngClass]="(!partnerPreferences?.age_from)?'animated-background-name':''">Marital Status</p>
              <p class="mb-0 py-1 p-sm text-muted">{{ showmarital_status }}</p>
            </div>
            <div class="col-2 text-end my-auto" *ngIf="showMatchesIcon">
              <div *ngIf="matchMaritalStatus">
                <i class="fas fa-check-circle fs-4 text-success"></i>
              </div>
              <div *ngIf="!matchMaritalStatus">
                <i class="fas fa-times-circle fs-4 text-danger"></i>
              </div>
            </div>
          </div>

          <div class="row py-2 border border-1 border-start-0 border-end-0 border-bottom-0 m-0">
            <div class="col-10">
              <p class="mb-0 py-1 p-sm text-muted fw-bold"
                [ngClass]="(!partnerPreferences?.age_from)?'animated-background-name':''">Religious Views</p>
              <p class="mb-0 py-1 p-sm text-muted">{{ showreligion  }}</p>
            </div>
            <div class="col-2 text-end my-auto" *ngIf="showMatchesIcon">
              <div *ngIf="matchReligion">
                <i class="fas fa-check-circle fs-4 text-success"></i>
              </div>
              <div *ngIf="!matchReligion">
                <i class="fas fa-times-circle fs-4 text-danger"></i>
              </div>
            </div>
          </div>

          <div class="row py-2 border border-1 border-start-0 border-end-0 border-bottom-0 m-0">
            <div class="col-10">
              <p class="mb-0 py-1 p-sm text-muted fw-bold"
                [ngClass]="(!partnerPreferences?.age_from)?'animated-background-name':''">
                Preffered Language
              </p>
              <p class="mb-0 py-1 p-sm text-muted">{{ showlanguages }}</p>
            </div>
            <div class="col-2 text-end my-auto" *ngIf="showMatchesIcon">
              <div *ngIf="matchFirstLanguage">
                <i class="fas fa-check-circle fs-4 text-success"></i>
              </div>
              <div *ngIf="!matchFirstLanguage">
                <i class="fas fa-times-circle fs-4 text-danger"></i>
              </div>
            </div>
          </div>

          <div class="row py-2 border border-1 border-start-0 border-end-0 border-bottom-0 m-0">
            <div class="col-10">
              <p class="mb-0 py-1 p-sm text-muted fw-bold"
                [ngClass]="(!partnerPreferences?.age_from)?'animated-background-name':''">Country Living in</p>
              <p class="mb-0 py-1 p-sm text-muted">
                {{ partnerLocationCountryName }}
              </p>
            </div>
            <div class="col-2 text-end my-auto" *ngIf="showMatchesIcon">
              <div *ngIf="matchCountryLivingIn">
                <i class="fas fa-check-circle fs-4 text-success"></i>
              </div>
              <div *ngIf="!matchCountryLivingIn">
                <i class="fas fa-times-circle fs-4 text-danger"></i>
              </div>
            </div>
          </div>

          <div class="row py-2 border border-1 border-start-0 border-end-0 border-bottom-0 m-0">
            <div class="col-10">
              <p class="mb-0 py-1 p-sm text-muted fw-bold"
                [ngClass]="(!partnerPreferences?.age_from)?'animated-background-name':''">State/Province</p>
              <p class="mb-0 py-1 p-sm text-muted">
                {{ partnerLocationStateName }}
              </p>
            </div>
            <div class="col-2 text-end my-auto" *ngIf="showMatchesIcon">
              <div *ngIf="matchStateLivingIn">
                <i class="fas fa-check-circle fs-4 text-success"></i>
              </div>
              <div *ngIf="!matchStateLivingIn">
                <i class="fas fa-times-circle fs-4 text-danger"></i>
              </div>
            </div>
          </div>

          <div class="row py-2 border border-1 border-start-0 border-end-0 border-bottom-0 m-0">
            <div class="col-10">
              <p class="mb-0 py-1 p-sm text-muted fw-bold"
                [ngClass]="(!partnerPreferences?.age_from)?'animated-background-name':''">Residency Status</p>
              <p class="mb-0 py-1 p-sm text-muted">{{ showResidencyStatus }}</p>
            </div>
            <div class="col-2 text-end my-auto" *ngIf="showMatchesIcon">
              <div *ngIf="matchResidencyStatus">
                <i class="fas fa-check-circle fs-4 text-success"></i>
              </div>
              <div *ngIf="!matchResidencyStatus">
                <i class="fas fa-times-circle fs-4 text-danger"></i>
              </div>
            </div>
          </div>

          <div class="row py-2 border border-1 border-start-0 border-end-0 border-bottom-0 m-0">
            <div class="col-10">
              <p class="mb-0 py-1 p-sm text-muted fw-bold"
                [ngClass]="(!partnerPreferences?.age_from)?'animated-background-name':''">Education</p>
              <p class="mb-0 py-1 p-sm text-muted">{{ showeducation }}</p>
            </div>
            <div class="col-2 text-end my-auto" *ngIf="showMatchesIcon">
              <div *ngIf="matchEducation">
                <i class="fas fa-check-circle fs-4 text-success"></i>
              </div>
              <div *ngIf="!matchEducation">
                <i class="fas fa-times-circle fs-4 text-danger"></i>
              </div>
            </div>
          </div>

          <div class="row py-2 border border-1 border-start-0 border-end-0 border-bottom-0 m-0">
            <div class="col-10">
              <p class="mb-0 py-1 p-sm text-muted fw-bold"
                [ngClass]="(!partnerPreferences?.age_from)?'animated-background-name':''">Annual Income</p>
              <p class="mb-0 py-1 p-sm text-muted" *ngIf="
                  partnerPreferences?.annual_income == 'OpenToAll' ||
                  partnerPreferences?.annual_income == 'Open To All'
                  ">
                {{ partnerPreferences?.annual_income }}
              </p>
              <p class="mb-0 py-1 p-sm text-muted" *ngIf="partnerPreferences?.annual_income == 'SpecifyRange'">
                {{ CurrencyCountryName }}
                {{ partnerPreferences?.income_range_from | number }} to
                {{ partnerPreferences?.income_range_to | number }}
              </p>
            </div>
            <div class="col-2 text-end my-auto" *ngIf="showMatchesIcon">
              <div *ngIf="matchAnnualIncome">
                <i class="fas fa-check-circle fs-4 text-success"></i>
              </div>
              <div *ngIf="!matchAnnualIncome">
                <i class="fas fa-times-circle fs-4 text-danger"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<app-login-footer></app-login-footer>
<div class="modal fade" id="ConnectModal" tabindex="-1" aria-labelledby="ConnectModalLabel" aria-hidden="true"
  data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
    <div class="modal-content position-relative">
      <div class="modal-body text-center p-4">
        <button type="button" class="btn btn-danger position-absolute top-0 right-0 m-2 center w-30-h-30"
          data-bs-dismiss="modal" aria-label="Close">
          <i class="fas fa-times fs-5"></i>
        </button>

        <div class="w-150 h-150 mx-auto border border-1 border-danger rounded-3 mb-2">
          <img src="{{ profileSrc }}" class="image-cover p-1 rounded-3" alt="" />
        </div>
        <h5 class="text-danger mb-4">{{ displayName }}</h5>

        <p class="p-sm mb-4">
          Do you want to connect with {{ displayName }} ?”
        </p>
        <button class="btn btn-outline-danger" data-bs-dismiss="modal" aria-label="Close">
          No
        </button>
        <button class="btn btn-danger ms-2" data-bs-dismiss="modal" (click)="connectNow()">
          Yes
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="blockModal" tabindex="-1" aria-labelledby="blockModalLabel" aria-hidden="true"
  data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
    <div class="modal-content position-relative">
      <div class="modal-body text-center p-4">
        <img src="assets/images/logo.svg" alt="" width="180" class="mb-4">
        <p class="p-sm mb-4">
          <strong>Profile Blocked! Can Not View This Profile!</strong> {{ displayName }}
        </p>
        <button class="btn btn-danger ms-2" data-bs-dismiss="modal" (click)="goNow()">
          Ok
        </button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="upgradeModal" tabindex="-1" aria-labelledby="upgradeModalLabel" aria-hidden="true"
  data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
    <div class="modal-content position-relative">

      <div class="modal-body text-center p-4">
        <button type="button" class="btn btn-danger position-absolute top-0 right-0 m-2 center w-30-h-30"
          data-bs-dismiss="modal" aria-label="Close">
          <i class="fas fa-times fs-5"></i>
        </button>

        <img src="assets/images/logo.png" alt="" width="200" class="mb-6">

        <p class="p-sm mb-4">
          Upgrade Now to view the {{this.requestFor}} details of <b>{{ displayName }}</b>
        </p>

        <button class="btn btn-danger rounded-pill " data-bs-dismiss="modal" aria-label="Close"
          routerLink="/upgrade">Upgrade Now </button>
      </div>

    </div>
  </div>
</div>
<!-- Connect to cancelled  request modal -->

<div class="modal fade" id="ReConnectModal" tabindex="-1" aria-labelledby="ReConnectModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
    <div class="modal-content position-relative">

      <div class="modal-body text-center p-4">
        <button type="button" class="btn btn-danger position-absolute top-0 right-0 m-2 center w-30-h-30"
          data-bs-dismiss="modal" aria-label="Close">
          <i class="fas fa-times fs-5"></i>
        </button>

        <div class="w-150 h-150 mx-auto border border-1 border-danger rounded-3 mb-2">
          <img
            *ngIf="(!partnerProfileDetail?.profile_photo || this.myRequestDetails?.photo_visiblity=='Visible to all members') && partnerProfileDetail?.profile_photo_approved!=1"
            class="image-cover p-1 rounded-3" src="assets/images/default-profile-picture.svg" alt />
          <ng-container *ngIf="(this.myRequestDetails?.photo_visiblity=='No one' || this.aboutUser?.stage!=5) && this.myRequestDetails?.photo_visiblity!='Visible to all members'
          ">
            <img *ngIf="!!partnerProfileDetail?.profile_photo || partnerProfileDetail?.profile_photo_approved==1"
              class="image-cover p-1 rounded-3" src="assets/images/default-profile-picture.svg" alt />
          </ng-container>
          <ng-container
            *ngIf="(this.myRequestDetails?.photo_visiblity!='No one' && this.aboutUser?.stage==5) || this.myRequestDetails?.photo_visiblity=='Visible to all members'">

            <img *ngIf="partnerProfileDetail?.profile_photo && partnerProfileDetail?.profile_photo_approved==1"
              class="image-cover p-1 rounded-3" [src]="profileSrc" alt="default-profile-img" />
          </ng-container>


        </div>
        <h5 class="text-danger mb-4"> {{displayName}} </h5>

        <p class="p-sm mb-4">
          You rejected {{displayName}}'s request , do you want to add to shortlist ?”
        </p>
        <button class="btn btn-outline-danger" data-bs-dismiss="modal" aria-label="Close">No </button>
        <button class="btn btn-danger ms-2" data-bs-dismiss="modal" aria-label="Close" (click)="actionOnProfile()">Yes
        </button>
      </div>

    </div>
  </div>
</div>

<!-- for blocked check -->

<div class="modal fade" id="deleteModal1111" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true"
  data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-fullscreen">
    <div class="modal-content position-relative">
      <div class="modal-body text-center p-4">
        <img src="assets/images/logo.png" alt="" width="200" class="mb-6">
        <p class="p-sm mb-4">
          <br>
          Sorry, you can't see profile because you are blocked.
        </p>
        <div class="m-2 text-center ">
          <button class="btn btn-light btn-sm border border-1 border-danger me-2" data-bs-dismiss="modal"
            aria-label="Close" (click)="goNow()">OK</button>
        </div>
      </div>
    </div>
  </div>
</div>



<div class="modal fade" id="previewImgModal" tabindex="-1" aria-labelledby="previewImgModalLabel" aria-hidden="true"
  data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
    <div class="modal-content position-relative">

      <div class="modal-body text-center p-4">
        <button type="button" class="btn btn-danger position-absolute top-0 right-0 m-2 center w-30-h-30"
          data-bs-dismiss="modal" aria-label="Close" id="openModal4">
          <i class="fas fa-times fs-5"></i>
        </button>

        <div class="ratio-1 rounded-1 overflow-hidden animated-background-name">
          <img class="image-cover" src="{{previewUrl }}" alt />
        </div>

      </div>

    </div>
  </div>
</div>

<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog mymodel  modal-dialog-centered">
    <div class="modal-content modal-content-trans">

      <div class="modal-body text-center">
        <div class="ratio-2_rm mb-3">
          <button type="button" class="btn btn-danger position-absolute top-0 right-0 m-2 center w-25-h-25 "
            data-bs-dismiss="modal" aria-label="Close"> <i class="fas fa-times fs-5 "></i> </button>
          <img src="{{galleryPath}}"
            class="image-cover-light gallery-image-cover rounded-5 border border-1 border-success" alt>
          <a *ngIf="this.myPhotos.length>1" class="pre-button btn btn-danger py-1 px-2 mt-2"
            (click)="previousSlides()">&#10094;</a>
          <a *ngIf="this.myPhotos.length>1" class="next-button btn btn-danger py-1 px-2 ms-2  mt-2"
            (click)=" nextSlides() ">&#10095;</a>
        </div>
        <div class="mt-3 lightBox-overlay">
          <h4 *ngIf="showCaption?.length <= 100" class="text-center"> {{showCaption }}</h4>

          <div class="marquee-container" *ngIf="showCaption?.length > 100">
            <h4>{{ showCaption }}</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="requestAccessModal" tabindex="-1" aria-labelledby="requestAccessModalLabel"
  aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
    <div class="modal-content position-relative">

      <div class="modal-body text-center p-4">
        <button type="button" class="btn btn-danger position-absolute top-0 right-0 m-2 center w-30-h-30"
          data-bs-dismiss="modal" aria-label="Close">
          <i class="fas fa-times fs-5"></i>
        </button>
        <img src="assets/images/logo.png" alt="" width="200" class="mb-6">

        <p class="p-sm mb-4" *ngIf="!aboutUser?.isBlocked">

          Do you want to send a request to view {{displayName}}'s {{requestFor}} Details
        </p>

        <p class="p-sm mb-4" *ngIf="aboutUser?.isBlocked">

          <!-- You have blocked {{displayName}}'s so you should not be sending her a message -->
          Sorry, you can't communicate because you have blocked {{displayName}}
          <!-- Sorry, you can't communicate because you are blocked. -->

        </p>

        <div class="m-2 text-end ">
          <button *ngIf="!aboutUser?.isBlocked" class="btn btn-danger btn-sm me-2" data-bs-dismiss="modal"
            aria-label="Close" (click)="proceed()">Proceed</button>
          <button *ngIf="!aboutUser?.isBlocked" class="btn btn-light btn-sm border border-1 border-danger me-2"
            data-bs-dismiss="modal" aria-label="Close">Cancel</button>
        </div>
      </div>

    </div>
  </div>
</div>

<!-- blocked modal  -->
<div class="modal fade" id="redirectModal" tabindex="-1" aria-labelledby="redirectModalLabel" aria-hidden="true"
  data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
    <div class="modal-content position-relative">

      <div class="modal-body text-center p-4">
        <button type="button" class="btn btn-danger position-absolute top-0 right-0 m-2 center w-30-h-30"
          data-bs-dismiss="modal" aria-label="Close">
          <i class="fas fa-times fs-5"></i>
        </button>
        <img src="assets/images/logo.png" alt="" width="200" class="mb-6">

        <p class="p-sm mb-4">

          You are redirecting to previous page in {{this.time}} second.
        </p>
        <div class="m-2 text-end ">
          <button class="btn btn-danger btn-sm me-2" data-bs-dismiss="modal" aria-label="Close">Proceed</button>
          <button class="btn btn-light btn-sm border border-1 border-danger me-2" data-bs-dismiss="modal"
            aria-label="Close">Cancel</button>
        </div>
      </div>

    </div>
  </div>
</div>

<!-- block modal -->

<!-- profile photo open  -->
<div class="modal fade" id="exampleModalProfile" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog  modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header pb-0 border-0">
        <h5 class="modal-title mb-0 fw-bold" id="exampleModalLabel">{{displayName}} </h5>
        <button type="button" class="btn" data-bs-dismiss="modal" aria-label="Close"> <i
            class="fas fa-times fs-4 text-danger"></i> </button>
      </div>
      <div class="modal-body">
        <div class="ratio-2">
          <img src="{{profileSrc}}" class="image-cover rounded-5 border border-1 border-success" alt>
        </div>
      </div>

    </div>
  </div>
</div>
<!-- profile photo open  -->

<!-- Report & Block Modal -->
<div class="modal fade" id="blockReportModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
    <div class="modal-content">
      <div class="row modal-body">
        <div class="col-12 mb-2">
          <h5 class="fw-600 text-danger"> Please select reason:</h5>
        </div>
        <div class="col-12">
          <div class="form-check ps-0" *ngFor='let user of reportReasonsList ;let i = index'>
            <input class="checkbox me-2" type="radio" name="Serious" id="{{user.id}}" (click)="blockReportId(user)">
            <label class role="" for="{{user.id}}">
              {{user.label}}</label>
          </div>
          <div class="col-12 mb-3" *ngIf="otherReason">
            <form #wordCountNgForm="ngForm" [formGroup]="wordCountForm">
              <label for="NewPassword" id="reason" class="fw-bold"> Reason</label>
              <textarea id="reason" #texts rows="3" class="form-control" [(ngModel)]="reportReason"
                [formControlName]="'description'" (paste)="pasteNumeric6($event)" (change)="compare()"
                (keypress)="wordCounter($event)" maxlength="500"></textarea> <!-- -->

              <div class="form-label mb-2 py-1 fw-bold text-danger" *ngIf="exceedLimit"> You cannot enter more than the
                maximum of 20 words</div>
              <p class="text-muted p-xsm">Max 20 Words. </p>
              <p class="text-muted p-xsm">Entered- {{words}} Words</p>

            </form>
          </div>
        </div>
      </div>

      <div class="mt-4 text-end">
        <button class="btn btn-light border border-1 border-danger me-2" data-bs-dismiss="modal"
          aria-label="Close">Cancel </button>
        <button class="btn btn-danger m-2" data-bs-dismiss="modal" aria-label="Close" (click)="blockAndReportNow()"
          [disabled]="!reportReason">Submit</button>

      </div>
    </div>
  </div>
</div>

<ngx-spinner bdColor="linear-gradient(120deg, #dedfe0, #dedfe0)" size="medium" color="#fff" type="ball-clip-rotate"
  [fullScreen]="true">
  <p style="color: white"> </p>
  <p style="color: white"> </p>
  <p style="color: white" class="mt-5">You are redirecting to previous page in {{time}} second.</p>
</ngx-spinner>