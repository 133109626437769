export const environment = {
  //common
  emoji_path: "assets/images/emoji/",
  app_media_path: "assets/",
  production: true,

  // demo
  // apiURL: "https://demoprojects.itsabacus.net/jub_we_met_back_end/api",
  // baseURL: window.location.origin + "/2757_JubWeMet/",
  // registerDone: "https://demoprojects.itsabacus.net/jub_we_met_back_end/registerDone",
  // editorApi: "https://demoprojects.itsabacus.net/jub_we_met_back_end/api/auth/uploadMediaFile",

  //live
  apiURL: "https://eternalstrings.com/eternalstrings_api/api",
  baseURL: window.location.origin + "/",
  registerDone: "https://eternalstrings.com/eternalstrings_api/registerDone",
  editorApi: "https://eternalstrings.com/eternalstrings_api/api/auth/uploadMediaFile"

};
